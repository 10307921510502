export const validateEmail=(email)=>{
    return (email.length!==0 && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email));
}
export const validateMobile=(mobile)=>{
    
    return (mobile?.length===10)
}
export const validateAlphabets=(input)=>{
    return (/^[a-zA-Z ]*$/i.test(input));
}
export const validateAlphaNumerics=(input)=>{
    return (/^[0-9a-zA-Z ]*$/i.test(input));
}
export const validateEmpty=(input)=> (!input || input.length===0);
export const validateOnlyNumbers=(input)=> (/^[0-9\b]+$/i.test(input))