import React,{useEffect,useState} from "react";
import ImageMarker, { Marker, MarkerComponentProps } from 'react-image-marker';
import dotsIcon from "../../../assets/icons/dots.svg"
import crossIcon from "../../../assets/icons/cross-icon"
import CrossIcon from "../../../assets/icons/cross-icon";
import SaveIcon from "../../../assets/icons/save-icon";
import SendIcon from "../../../assets/icons/send-icon";
import { useDispatch,useSelector } from "react-redux";
import { addReviewMessage,deleteReviewMessage,addReviewReply,editReviewMessage,deleteReviewReply } from "../../../actions";
import Moment from 'react-moment';
const Comment = (props) => {
    //console.log(props)
    const dispatch=useDispatch()
    const [show,setShow]=useState(false)
    const [showMoreMessage,setShowMessageMore]=useState(false)
    const [showMoreBlock,setShowBlockMore]=useState(false)
    const [showMoreReply,setShowReplyMore]=useState(null)
    const [editMessage,setEditMessage]=useState(null)
    const prodTeam=sessionStorage.getItem("prodTeam")
    const [text,setText]=useState("")
    const [editText,setEditText]=useState("")
    const profile=useSelector(state=>state.profile)
    //console.log(showMoreReply)
    const handleSubmit=(e)=>{
        e.preventDefault()
        if(props.id)
        {
            dispatch(addReviewReply({"imageID":props.imageId,"userID":profile.id,"messageID":props.id,"text":text}))
            console.log({"imageID":props.imageId,"userID":profile.id,"messageID":props.id,"text":text})
        }else{
            console.log({"top":props.top,"left":props.left,"text":text,"imageID":props.imageId})
            dispatch(addReviewMessage(profile.id,props.imageId,{"top":props.top,"left":props.left,"text":text}))
        }
        setText("")
    }
    const deleteMessage=()=>{
        if(profile.id==props.user.id || profile.user_type=='superadmin')
        {
            console.log("delete",props.id)
            dispatch(deleteReviewMessage(props.user.id,props.id,props.imageId))
        }
    }
    const setEditBlock=(userId,id,isParent,txt)=>{
        if(props.user.id==profile.id)
        {
            setEditMessage({"userId":userId,"id":id,"isParent":isParent})
            if(isParent)
            setShowMessageMore(false)
            else
              setShowReplyMore(null)
            setEditText(txt)
        }
    }
    const handleSaveEdit=()=>{
        console.log(editMessage,editText)
        dispatch(editReviewMessage(editMessage.userId,props.imageId,editMessage.id,{"text":editText,"is_parent":editMessage.isParent}))
        setEditMessage(null)
    }
    const deleteReply=(id)=>{
        if(profile.id==props.user.id || profile.user_type=='superadmin'){
            console.log(props.user.id,id)
            dispatch(deleteReviewReply(props.user.id,id,props.imageId))
            setShowReplyMore(null)
        }
    }
    //console.log(editMessage)
    return (
        <div className="comment">
            <div className="comment-pin" onClick={()=>setShow(true)}>
                <div className="comment-pin-inner comment-pin-active">{props.pinNumber}</div>
            </div>
            {show && <div className="comment-block">
                <div className="comment-header">
                    <div className="comment-actions">
                        <div className="comment-action-more">
                            <img style={{cursor:'pointer',fill : "black"}} onClick={()=>setShowBlockMore(p=>!p)}  src={dotsIcon} alt="dots"/>
                            {showMoreBlock && <div className="comment-action-more-drop">
                                <div className="comment-action-more-drop-item" onClick={deleteMessage}>Delete</div>
                            </div>}
                        </div>
                        <div><SaveIcon height="15px" width="15px" fill="#707171"/></div>
                        <div onClick={()=>setShow(false)}><CrossIcon height="15px" width="15px" fill="#707171" /></div>
                        
                    </div>
                </div>
                <div className="comment-list">
                {props.replies.map((reply,index)=><div className="comment-message-block">
                    <div className="comment-message-block-profile-img">
                    {reply.user.avatar?<img src={reply.user.avatar} alt="avatar"/>:
            <span className="comment-message-block-profile-img-default">{`${reply.user.fullName.split(" ")[0].charAt(0)} ${reply.user.fullName.split(" ")[1].charAt(0)}`} </span>
}
                    </div>
                    <div className="comment-message-block-details">
                        <div className="comment-message-block-header">
                            <div className="comment-message-block-header-content">
                                <div className="comment-message-block-profile">
                                   <div className="comment-message-block-main">{reply.user.fullName}</div>
                                   <div className="comment-message-block-sub">, {reply.user.user_type}</div>
                                </div>
                                <Moment className="comment-message-block-sub" format='h:mm a,MMMM DD YYYY' >{reply.created_at}</Moment>
                            </div>
                          <div  className="comment-message-block-more">
                            <img style={{cursor:'pointer'}} onClick={()=>{
                                if(showMoreReply!=null)
                                  setShowReplyMore(null)
                                else
                                 setShowReplyMore(reply.id)
                            }} src={dotsIcon} alt="more"/>
                            {showMoreReply===reply.id && <div className="comment-action-more-drop">
                            <div className="comment-action-more-drop-item" onClick={()=>setEditBlock(reply.user.id,reply.id,false,reply.text)}>Edit</div>
                                <div className="comment-action-more-drop-item" onClick={()=>deleteReply(reply.id)}>Delete</div>
                            </div>}
                            </div>
                        </div>
                        {(!editMessage?.isParent && editMessage?.id==reply.id)? 
                        <div className="edit-comment">
                            <textarea type="text" value={editText} onChange={(e)=>setEditText(e.target.value)}/>
                            <div className="edit-comment-actions">
                                <button className="edit-btn-fill"  onClick={handleSaveEdit}>save</button>
                                <button className="edit-btn-outline" onClick={()=>setEditMessage(null)}>cancel</button>
                            </div>
                        </div>:
                        <div className="comment-message-block-content">
                        {reply.text}
                        </div>}
                        
                    </div>
                </div>).reverse() }
                {props.id && <div className="comment-message-block">
                    <div className="comment-message-block-profile-img">
                    {props.user.avatar?<img src={props.user.avatar} alt="avatar"/>:
            <span className="comment-message-block-profile-img-default">{`${props.user.fullName.split(" ")[0].charAt(0)} ${props.user.fullName.split(" ")[1].charAt(0)}`} </span>
}
                    </div>
                    <div className="comment-message-block-details">
                        <div className="comment-message-block-header">
                            <div className="comment-message-block-header-content">
                                <div className="comment-message-block-profile">
                                   <div className="comment-message-block-main">{props.user.fullName}</div>
                                   <div className="comment-message-block-sub">, {props.user.user_type}</div>
                                </div>
                                <Moment className="comment-message-block-sub"format='h:mm a,MMMM DD YYYY'>{props.created_at}</Moment>
                            </div>
                          <div className="comment-message-block-more">
                            <img style={{cursor:'pointer'}} onClick={()=>setShowMessageMore(p=>!p)} src={dotsIcon} alt="more"/>
                            {showMoreMessage && <div className="comment-action-more-drop">
                            <div className="comment-action-more-drop-item" onClick={()=>setEditBlock(props.user.id,props.id,true,props.text)}>Edit</div>
                                
                            </div>}</div>
                        </div>
                        {(editMessage?.isParent && editMessage?.id==props.id)? 
                        <div className="edit-comment">
                        <textarea type="text" value={editText} onChange={(e)=>setEditText(e.target.value)}/>
                        <div className="edit-comment-actions">
                            <button className="edit-btn-fill" onClick={handleSaveEdit}>save</button>
                            <button className="edit-btn-outline" onClick={()=>setEditMessage(null)}>cancel</button>
                        </div>
                    </div>:<div className="comment-message-block-content">
                        {props.text}
                        </div>}
                    </div>
                </div>}
                
                </div>
                {!prodTeam &&
                <div className="comment-message-block">
                    <div className="comment-message-block-profile-img">
                    {props.user.avatar?<img src={props.user.avatar} alt="avatar"/>:
            <span className="comment-message-block-profile-img-default">{`${props.user.fullName.split(" ")[0].charAt(0)} ${props.user.fullName.split(" ")[1].charAt(0)}`} </span>
}
                    </div>
                    <div className="comment-message-block-details">
                        <div className="comment-message-block-header">
                            <div className="comment-message-block-header-content">
                                <div className="comment-message-block-profile">
                                   <div className="comment-message-block-main">{profile.fullName}</div>
                                   <div className="comment-message-block-sub">, {profile.user_type}</div>
                                </div>
                            </div>
                          {/* <div className="comment-message-block-more">
                            <img src={dotsIcon} alt="more"/>
                        </div> */}
                        </div>
                        
                        <div className="comment-message-block-input">
                          <input autoFocus placeholder="text" value={text} onChange={(e)=>setText(e.target.value)}/>
                          <button className="comment-message-submit" disabled={text.length==0} onClick={handleSubmit}><SendIcon fill={text.length==0?"#707171":"#0085FF"}/></button>
                        </div>
                    </div>
                </div>
                 }
            </div>}
        </div>
    );
};
export default Comment