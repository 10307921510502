import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { dayOptions } from "../../helpers/constants";
import uploadAvatar from "../../assets/icons/upload-avatar.svg";
import editBtn from "../../assets/icons/edit-btn.svg";
import { getTimezones, updateProfile } from "../../actions";

const AdminProfile=()=>{
    const isAuth = useSelector(state => state.isAuth);
    const profile=useSelector(state=>state.profile);
    console.log(profile);
    const timezones = useSelector(state => state.timezones);
    const [avatar, setAvatar] = useState(profile?.avatar && `${profile.avatar}`);
    const [image, setImage] = useState(null);
    const [fewTimeZones,setFewTimeZones] = useState()
    const [edit, setEdit] = useState(false);
    const [verify, setVerify] = useState(false);
    const dispatch = useDispatch();
    const [initialForm, setInitialForm] = useState({
        fullName: profile?.fullName ? profile?.fullName : '',
        title_atcompany: profile?.title_atcompany ? profile?.title_atcompany : '',
        email: profile?.email ? profile?.email : '',
        mobile_number: profile?.mobile_number ? profile?.mobile_number : '',
        location: profile?.location ? profile?.location : '',
        bio: profile?.bio ? profile?.bio : '',
        timezone: profile?.timezone ? profile?.timezone : '',
        first_day_week: profile?.first_day_week ? profile?.first_day_week : ''
    });

    useEffect(()=>{
        const result = timezones.filter(elem=>{
            if(!elem.startsWith("Africa") && !elem.startsWith("America") && !elem.startsWith("Antarctica") && !elem.startsWith("Australia")&& !elem.startsWith("Brazil")&& !elem.startsWith("Asia")&& !elem.startsWith("Atlantic")&& !elem.startsWith("Canada")&& !elem.startsWith("Europe")&& !elem.startsWith("Indian")&& !elem.startsWith("Pacific")){
                return elem
            }
        })

        setFewTimeZones(result)
    },[])

    useEffect(() => {
        console.log('profile: ', profile);
        if (!profile?.fullName || !profile?.title_atcompany || !profile?.email || !profile?.location || !profile?.bio || !profile?.timezone || !profile?.first_day_week){
            setEdit(true);
        }
    }, [profile])

    useEffect(() => {
        if(initialForm?.fullName && initialForm?.title_atcompany && initialForm?.email && initialForm?.location && initialForm?.bio && initialForm?.timezone && initialForm?.first_day_week){
            setVerify(true);
        } else {
            setVerify(false);
        }
    }, [initialForm])

    useEffect(() => {
        dispatch(getTimezones());
    }, [])

    useEffect(() => {
        console.log('edit: ', edit);
        console.log('initialForm: ', initialForm);
        console.log('verify: ', verify);
    }, [edit, verify, initialForm])

    const handleAvatarChange = () => {
        document.getElementById('changeAvatar').click();
    }

    const handleSetAvatar = (e) => {
        console.log(e.target.files[0]);
        setImage(e.target.files[0]);
        setAvatar(URL.createObjectURL(e.target.files[0]));
    }

    const handleChange = (e) => {
        console.log(e.target.value);
        setInitialForm({...initialForm, [e.target.name]: e.target.value})
    }

    const handleEdit = () => {
        setEdit(true);
    }

    const handleSave = () => {
        if(verify){
            console.log('form: ', initialForm)
            const formData = new FormData();
            for (var key in initialForm) {
                formData.append(key, initialForm[key]);
            }
            if(image) {
                formData.append('avatar', image);
            }
            dispatch(updateProfile(profile.id, formData, isAuth.access));
            setEdit(false);
        }
    }

    return (
        <div className="admin-profile">
            <div className="admin-profile-title">Manage Account</div>
            <div className="admin-profile-body">
                <div className="profile-avatar">
                    <div className="profile-avatar-title">{profile.user_type==='superadmin'? 'Super Admin' : profile.user_type==='pm'? 'PM' : 'Admin'}</div>
                    <div className="avatar-body">
                        <div>
                            {
                                avatar ? 
                                    <img className='avatar' src={avatar} alt="user" />
                                :
                                    <div className="upload-avatar">
                                        <img src={uploadAvatar} alt="user" />
                                    </div>
                            }
                            {
                                edit &&
                                <>
                                    <div onClick={handleAvatarChange} className='change-avatar'>Upload avatar</div>
                                    <input onChange={(e)=>handleSetAvatar(e)} style={{display: 'none'}} id='changeAvatar' type="file" accept='image/*' />
                                </>
                            }
                        </div>
                        {
                            !edit &&
                            <div>
                                <div className="profile-avatar-title">{profile.user_type==='superadmin'? 'Super Admin' : profile.user_type==='pm'? 'PM' : 'Admin'}</div>
                                <div className="profile-avatar-name">{profile?.fullName}</div>
                                <div className="profile-avatar-designation">{profile?.title_atcompany}</div>
                                <img className="edit-btn" onClick={handleEdit} src={editBtn} alt="edit-btn" />
                            </div>
                        }
                    </div>    
                </div>
                {
                    edit &&
                    <div className="admin-profile-subbody">
                        <div>
                            <div className='form-input-box'>
                                <label className='form-label' htmlFor='fullName'>Name</label>
                                <input onChange={handleChange} className='form-input profile-input' defaultValue={profile?.fullName} type='text' name='fullName' id='fullName'/>
                            </div>
                            
                            <div className='form-input-box'>
                                <label className='form-label' htmlFor='mobile_number'>Mobile Number</label>
                                <input onChange={handleChange} className='form-input profile-input' defaultValue={profile?.mobile_number} placeholder='mobile number' type='tel' name='mobile_number' id='mobile_number'/>
                            </div>
                            <div className='form-input-box'>
                                <label className='form-label' htmlFor='location'>Location</label>
                                <input onChange={handleChange} className='form-input profile-input' defaultValue={profile?.location} placeholder='location' type='text' name='location' id='location'/>
                            </div>
                            
                            <div className='form-input-box'>
                                <label className='form-label' htmlFor='timezone'>Time zone</label>
                                <p className='input-info'>
                                    We use your time zone setting for email notifications, event reminders, your profile, and your notification settings.
                                </p>
                                <select onChange={handleChange} className='form-input profile-input' defaultValue={profile?.timezone} name='timezone' id='timezone'>
                                    <option value=''>Choose Time zone</option>
                                    {
                                        fewTimeZones?.map((timezone, index) => (
                                            <option key={index} value={timezone}>{timezone}</option>
                                        ))
                                    }
                                    {/* <option value='(GMT-05:00) Eastern Time'>(GMT-05:00) Eastern Time</option> */}
                                </select>
                            </div>
                        </div>
                        <div>
                            <div className='form-input-box'>
                                <label className='form-label' htmlFor='title_atcompany'>Title at user's company</label>
                                <input onChange={handleChange} className='form-input profile-input' defaultValue={profile?.title_atcompany} placeholder='title' type='text' name='title_atcompany' id='title_atcompany'/>
                            </div>
                            <div className='form-input-box'>
                                <label className='form-label' htmlFor='email'>Email Address</label>
                                <input className='default-form-input profile-input' value={profile?.email} placeholder='email address' type='email' name='email' id='email'/>
                            </div>
                            <div className='form-input-box'>
                                <label className='form-label' htmlFor='bio'>Short bio/current status</label>
                                <textarea onChange={handleChange} className='form-input profile-input short-bio' defaultValue={profile?.bio} placeholder='short bio' name='bio' id='bio'/>
                            </div>
                            <div className='form-input-box'>
                                <label className='form-label' htmlFor='first_day_week'>First day of week</label>
                                {/* <p className='input-info'>
                                    Basecamp uses this when presenting calendars.
                                </p> */}
                                <select onChange={handleChange} className='form-input profile-input' defaultValue={profile?.first_day_week} name='first_day_week' id='first_day_week'>
                                    {
                                        dayOptions.map(opt=>(
                                            <option value={opt.value}>{opt.value}</option>
                                        ))
                                    }
                                </select>
                            </div>
                        </div>
                    </div>
                }
            </div>
            {
                !edit &&
                <>
                    <div className='profile-details'>
                        <div>
                            <div className='detail'>
                                Mobile Number :
                                <span style={{textTransform:"capitalize"}} className='info'>{profile?.mobile_number}</span>
                            </div>
                            <div className='detail'>
                                Email Address :
                                <span className='info'>{profile?.email}</span>
                            </div>
                            <div className='detail'>
                                    Short-bio :
                                    <span className='info'>{profile?.bio}</span>
                                </div>
                        </div>
                        <div>
                            <div className='detail'>
                                Location :
                                <span className='info'>{profile?.location}</span>
                            </div>
                            <div className='detail'>
                                Time zone :
                                <span className='info'>{profile?.timezone}</span>
                            </div>
                            <div className='detail'>
                                First day of week :
                                <span className='info'>{profile?.first_day_week}</span>
                            </div>
                        </div>
                    </div>
                </>
            }
            {
                edit &&
                <div className="profile-save-btn">
                    <button onClick={handleSave} className={`btn btn-${verify?'green-fill':'grey'}`}>Save Changes</button>
                </div>
            }
        </div>
    )
}
export default AdminProfile;