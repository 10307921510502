import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actionType from "./helpers/constants";
import { BrowserRouter, Routes, Route,Navigate, useParams } from "react-router-dom";
import './assets/css/App.css';
import "./assets/css/modal.css"
import {Login,SignUp,ResetPassword,VerifyEmail} from "./components";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Dashboard,DashboardHome,DashboardNotifications,Tutorial,PaymentPage,Activity,ProjectStatus,NotificationSettings,SearchComponent,SignProposal } from "./components/Dashboard";
import {
  Admin,AdminHome,QuoteProject,Quotes,Ppl,ProjectProjectionList,AdminProfile,Allocation,AdminNotifications
} from "./components/Admin"
import {DashboardProject,MessageBoard,Conversation, TasksToDo, DocumentsBoard,ReviewFile,CompletedBoard,MarkupTools } from "./components/ProjectDashboard"
import Home from "./components/Home";
import Disconnect from "./components/Disconnect";
const App=()=>{
   const isAuth=useSelector(state=>state.isAuth)
   const profile=useSelector(state=>state.profile)
   const prodTeam=sessionStorage.getItem("prodTeam")
    const isLoading=useSelector(state=>state.isLoading)
    const dispatch=useDispatch()

   useEffect(()=>{
     const key = window?.location?.href?.split("/")[3]?.split("=")[1];
     console.log("key:", key)
     // const key = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzE0OTA5ODM3LCJpYXQiOjE2ODMzNzM4MzcsImp0aSI6IjFmMzU1NTZhMTNmOTQ3Y2FiZDdkMjQ5NmNhN2E3Y2Q1IiwidXNlcl9pZCI6NTd9.CwWyC-TnQLie_YmHEMCqAGu1wLFvTYmMw73w43dEWEg";
    fetch("https://prod.panoramcgi.com/users/production/",{
      method:"GET",
      headers:{
        "Content-Type":"application/json",
        'Authorization': `${`Bearer ${key}`}`
      }
    })
      .then(res=>res.json()).then(data=>{
        console.log(data)
        if(data.success){
          console.log(window.location.href)
          dispatch({type:actionType.LOGOUT})
          sessionStorage.setItem("profile", JSON.stringify(data.profile[0]))
          sessionStorage.setItem("isAuth", JSON.stringify({access: key}))
          sessionStorage.setItem("prodTeam",data.success)
          console.log("projectId:",window?.location?.href?.split("/")[4]?.split("=")[1])
          sessionStorage.setItem("projectId",window?.location?.href?.split("/")[4]?.split("=")[1])
          // window.location.replace(`http://localhost:3000/`)
          window.location.replace(`https://panoram-cgi.netlify.app/`)
        }
      })
      .catch(err=>{console.log(err)
    })
   },[])
   console.log(profile)
  return (
    <div className="app">
      <BrowserRouter>
        <ToastContainer/>
        
        <Routes>
          <Route path="/disconnect" element={<Disconnect/>}/>
          <Route path="/set-password" element={<ResetPassword/>}/>
          <Route path="/login" element={isAuth?<Navigate to="/" replace/>:<Login/>}/>
          <Route path="/verify-email/:id/:token" element={isAuth?<Navigate to="/" replace/>:<VerifyEmail/>}/>
          <Route path="/sign-up" element={isAuth?<Navigate to="/" replace/>:<SignUp/>}/>
          <Route path="/" element={isAuth?<Home/>:<Navigate to="/login" replace/>}>
            {/* <Route path="/" element={production && <DashboardHome/>}/> */}
            <Route path="/" element={prodTeam?<Navigate to={`/project/${sessionStorage.getItem("projectId")}`} replace/>:(profile?.user_type==='client' ? <DashboardHome/> : <AdminHome/>)}/>
            <Route path="/quotes/:id" element={profile?.user_type==='superadmin' && <QuoteProject/>}/>
            <Route path="/quotes" element={profile?.user_type==='superadmin' && <Quotes/>}/> 
            <Route path="/notifications" element={profile?.user_type==='client'? <DashboardNotifications/>:<AdminNotifications/>}/>
            <Route path="/ppl" element={<Ppl/>}/>
            <Route path="/ppl-project/:id" element={<ProjectProjectionList/>}/>
            <Route path="/allocation" element={profile?.user_type==='superadmin' && <Allocation/>}/>
            <Route path="/profile" element={<AdminProfile/>}/>
            <Route path="/project/:id" element={<DashboardProject/>}/>
            <Route path="/message-board/:id" element={<MessageBoard/>}/>
            <Route path="/conversation/:projectID/:date/:category" element={<Conversation/>}/>
            <Route path="/tasks-to-do/:id" element={<TasksToDo/>}/>
            <Route path="/review-file/:id" element={<ReviewFile/>}/>
            <Route path="/review-file-edit/:projectID/:imageID" element={<MarkupTools/>}/>
            <Route path="/completed-file/:id" element={<CompletedBoard/>}/>
            <Route path="/documents-board/:id" element={<DocumentsBoard/>}/>
            <Route path="/activity" element={<Activity/>}/>
            <Route path="/search" element={<SearchComponent/>}/>
            <Route path="/project-status" element={<ProjectStatus/>}/>
            <Route path="/notification-settings" element={<NotificationSettings/>}/>
            <Route path="/project-payment/:id" element={<PaymentPage/>}/>
            <Route path="/project/sign-proposal/:id" element={<SignProposal/>}/>
          </Route>
        </Routes>
        </BrowserRouter>
        {
            isLoading && <div className="spinner-container">
                <div className="loading-spinner"></div>
            </div>
        }
    </div>
  );
}

export default App;