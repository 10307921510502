import React,{useState} from 'react'
import { useDispatch } from 'react-redux';
import calendarIcon from "../../../assets/icons/calender.svg"
import DatePicker from "react-datepicker";
import moment from 'moment'
import { blockUser } from '../../../actions';
const ConfirmDisableUserModal=({disableItem,setDisableItem})=>{
    const dispatch=useDispatch()
    const initialForm={
        "user_id":disableItem.id,
        "start_date":null,
        "end_date":null
    }
    const [selectedFromDate,setSelectedFromDate]=useState()
    const [selectedTillDate,setSelectedTillDate]=useState()
    const [form,setForm]=useState(initialForm)
    const verify=()=>{
        return form.start_date && form.end_date
    }
    const handleDisable=()=>{
        console.log(form)
        if(verify)
        {
            dispatch(blockUser(form))
            setForm(initialForm)
            setDisableItem(null)
        }
    }
    return (
        <div className='modal-popup'>
        <div className='modal-popup-content modal-block-user'>
            <div className='modal-popup-content-title'>Confirm</div>
            <div className='modal-popup-content-body'>Are you sure you want to delete {disableItem.name} <br/> temporarily?</div>
            <div className='modal-popup-content-body modal-popup-content-highlight'>Note: once you confirm you can not undo this step!</div>
            <div className="allocation-duration">
                <div className="date-picker">
                <img src={calendarIcon}  alt="calendar"/>
                   
                    <div>
                        <div className="date-picker-title">From date</div>
                        
                        <DatePicker className='date-picker-input'  placeholderText='DD/MM/YY' selected={selectedFromDate} minDate={new Date()} onChange={(date) => {setSelectedFromDate(date);setForm({...form,start_date:moment(date).format('YYYY-MM-DD')})}} />
                       
                    </div>
                   
                </div>
                <div className="date-picker" >
                    <img src={calendarIcon} alt="calendar"/>
                    <div>
                        <div className="date-picker-title">Till date</div>
                        <DatePicker className='date-picker-input' placeholderText='DD/MM/YY' selected={selectedTillDate} minDate={new Date()} onChange={(date) => {setSelectedTillDate(date);setForm({...form,end_date:moment(date).format('YYYY-MM-DD')})}} />
                    </div>
                    
                </div>
            </div>
            <div className='modal-popup-content-actions'>
                <button className='btn btn-green-fill' onClick={handleDisable}>Delete</button>
                <button className='btn btn-green-outline' onClick={()=>setDisableItem(null)}>Cancel</button>
            </div>
        </div>
</div>
    )
}
export default ConfirmDisableUserModal