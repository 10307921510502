import React,{useState} from "react";
import { Link } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import * as validate from "../../../helpers/validation";
import * as actionType from "../../../helpers/constants";
import notify from "../../../helpers/notify";
import bg1 from "../../../assets/images/bg-1.jpg";
import arrowRight from "../../../assets/icons/arrow-right.svg";
import warning from "../../../assets/icons/warning.svg";
import logo from "../../../assets/images/logo.png";
import bgLogo from "../../../assets/icons/bg-logo.svg";
import Notification from "../../notification";
import { AuthImages, sendOtp } from "../../../actions";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { isValidPhoneNumber,parsePhoneNumber } from 'react-phone-number-input'
import { useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useLocation } from "react-router-dom";
const LoginBase=({next,back,handleFormData,form})=>{

    const dispatch=useDispatch();
    const location  = useLocation();
    const message=useSelector(state=>state.message)
    const images=useSelector(state=>state.auth_images)
    const filteredImageUrls = Object.entries(images)
  .filter(([key, value]) => value !== null)
  .map(([key, value]) => ({ [key]: value }));
    const [emailErr,setEmailErr]=useState(null);
    const [mobileErr,setMobileErr]=useState(null);
    const [mobile,setMobile]=useState('');
    const [email,setEmail]=useState('');
    const handleChange=(e)=>{
        const name=e.target.name;
        const val=e.target.value;
        setEmail(val);
        setMobile('');
        handleFormData('mobile_no','');handleFormData('country_code','');
        setMobileErr(null)
            if(validate.validateEmail(val))
       {
        handleFormData("email",val)
        handleFormData("verification_type","email");
        setEmailErr(null);
       }else{
        setEmailErr("Not a valid email id");
        handleFormData("verification_type","");
       }
        
    }
    const handleMobileNumber=(e)=>{
        setMobile(e);
        setEmail('');
        handleFormData('email','')
        setEmailErr(null);
        //console.log(e)
        if(e!==undefined)
        {
         const parse=parsePhoneNumber(e);
         if(isValidPhoneNumber(e))
            {setMobileErr(null);handleFormData('mobile_no',parse.nationalNumber);handleFormData('country_code',"+"+parse.countryCallingCode);handleFormData("verification_type","otp");}
            else
            {setMobileErr("Not a valid phone number");handleFormData("verification_type","");}
        }
       
     }
    const verify=()=>{
        
        return form.verification_type!=='';
    }
    const handleSubmit=async(e)=>{
        e.preventDefault();
        console.log(form)
        
        if(form.verification_type!=="")
        {
            if(form.verification_type==="otp")
          {
            dispatch(sendOtp({
              "isLogin":true,
                "country_code":"+91",
             "mobile_no": form.mobile_no},next,2))
          }else{
            next(2)
          }
           
        }
    }
    useEffect(()=>{
        dispatch(AuthImages())
      },[])
      
    useEffect(()=>{
        dispatch({type:actionType.SET_MESSAGE,payload:null})
    },[])
    return (<div className="auth-page">
        <div className="auth-background">
            <div className="auth-overlay">
                <div className="auth-content">
                    <div>
                    <Link to="/" className="logo-link">
                <img className="bgLogo" src={bgLogo} alt="logo"/>
                <p className="logo-cgi">CGI</p>
                </Link>
                    </div>
                    <div className="auth-content-text">MEMBERS PORTAL</div>
                    {/* <div className="overlay-highlight">JOIN MEDIUM</div>
                    <div className="overlay-text">ARCHITECTURE +<br/>INTERIOR DESIGN</div> */}
                </div>
            </div>
            {filteredImageUrls?.length>0 &&
            <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true} showArrows={false} showStatus={false}>
            {
               filteredImageUrls?.map((e,i)=>(    
                <div key={`image-${i}`}>
                    <img src={Object?.values(e)} className="bg-img" alt="carousel"/>
                </div>
               ))
            }
            </Carousel>}
        </div>
        <div className="form-bg">
            <div className="default-header">
                <Link to="/" className="logo-link">
                <img className="bgLogo" src={bgLogo} alt="logo"/>
                <p className="logo-cgi">CGI</p>
                </Link>
                <div className="members-portal">MEMBERS PORTAL</div>
            </div>
        <form className="auth-form">
                <div className="form-heading" style={{alignSelf: location.pathname.slice(1)==='login'?'start':'center'}}>Welcome Back !</div>
                <div className="form-subheading">Login to your account</div>
                <div className="auth-form-content">
                <div className="form-input-box" style={{margin:'0'}}>
              <div className="form-label">Email address</div>
              <input className={`form-input ${emailErr && 'input-invalid'}`} type="email" name="email" value={email}  onChange={handleChange}/>
              {emailErr && <div className="input-error">
                    <img src={warning} alt="email warning"/>
                    <span>{emailErr}</span>
                    </div>}
            </div>
            <div className="form-subheading" style={{margin:'15px 0',alignSelf:'center'}}>OR</div>
            <div className="form-input-box"  style={{margin:'0',marginBottom:'8px'}}>
                <div className="form-label">Phone number</div>
                <div className={`phone-box ${mobileErr && 'input-invalid'}`}>
                   
                 <PhoneInput
  style={{width:'100%'}}
  defaultCountry="US"
  value={mobile}
  onChange={handleMobileNumber}/>
                 
                </div>
                {mobileErr && <div className="input-error">
                    <img src={warning} alt="mobile warning"/>
                    <span>{mobileErr}</span>
                    </div>}
                </div>
                <div className="form-toggle"> 
                    <span style={{color:'#D6D6D6'}}>Don’t have an account? </span>
                    <Link to="/sign-up" className="highlight">Sign up</Link>
                </div>
                <button className={`btn btn-${verify()? 'green-fill':'grey'} login-btn`} onClick={handleSubmit}> <span>Log In</span><img src={arrowRight} alt="next"/></button>
                </div>
                {message && <Notification type={message.type} message={message.message}/>}
            </form>
        </div>
            
        
    </div>)
}
export default LoginBase;