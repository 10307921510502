import { useDispatch } from "react-redux"
import { deleteUser } from "../../../actions"
const ConfirmDeleteUserModal=({deleteItem,setDeleteItem})=>{
    const dispatch=useDispatch()
    const handleDelete=()=>{
       
            dispatch(deleteUser(deleteItem.id))
         
        setDeleteItem(null)
    }
    return (
        <div className='modal-popup'>
        <div className='modal-popup-content'>
            <div className='modal-popup-content-title'>Confirm</div>
            <div className='modal-popup-content-body'>Are you sure you want to delete {deleteItem.name} <br/> permanently?</div>
            <div className='modal-popup-content-body modal-popup-content-highlight'>Note: once you confirm you can not undo this step!</div>
            <div className='modal-popup-content-actions'>
                <button className='btn btn-green-fill' onClick={handleDelete}>Delete</button>
                <button className='btn btn-green-outline' onClick={()=>setDeleteItem(null)}>Cancel</button>
            </div>
        </div>
</div>
    )
}
export default ConfirmDeleteUserModal