import React,{useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import plus from "../../../assets/icons/plus.svg"
import edit from "../../../assets/icons/edit.svg"
import sortIcon from "../../../assets/icons/sort.svg";
import filterIcon from "../../../assets/icons/filter.svg";
import share from "../../../assets/icons/share.svg"
import screenShareIcon from "../../../assets/icons/screen-share.svg"
import deleteIcon from "../../../assets/icons/delete.svg"
import SearchIcon from '../../../assets/icons/search-icon';
import saveIcon from "../../../assets/icons/save-icon.svg";
import { useDispatch,useSelector } from 'react-redux';
import Select from 'react-select';
import "../../../assets/css/Admin/ppl.css"
import dropdown from "../../../assets/icons/dropdown.svg";
import ConfirmDeleteUserModal from './confirmDeleteUser';
import ConfirmDisableUserModal from './disableUser';
import AddMember from "./addMember"
import { getAllProjects,getAllUsers,updateAllocation,searchUsers } from '../../../actions';
const initialForm={
      "user_type":"",
      "projects":"",
}

const Allocation=()=>{
    const dispatch=useDispatch()
    const [editMode,setEditMode]=useState(false);
    const [editID,setEditID]=useState(null)
    const [deleteItem,setDeleteItem]=useState(null)
    const [disableItem,setDisableItem]=useState(null)
    const [defaultSelect,setDefaultSelect]=useState([])
    const [showAddMember,setShowAddMember]=useState(false)
    const [activeSearch,setActiveSearch]=useState(false)
    const [form,setForm]=useState(initialForm)
    const [showSort,setShowSort]=useState(false)
    const [showFilter,setShowFilter]=useState(false)
    const [showSelect,setShowSelect]=useState(null)
    const users=useSelector(state=>state.all_users)
    const isLoading=useSelector(state=>state.isLoading)
    const all_projects=useSelector(state=>state.all_projects)
    const projectsSelect=all_projects.map(item=>{return {"value":item.id,"label":item.project_name}})
    
    const handleSearch=(e)=>{
      const query=e.target.value;
      console.log(query?.length>0)
      if(query?.length>0)
        dispatch(searchUsers(query))
      else
        dispatch(getAllUsers())
    }
      const openDropdown=(index)=>{
        if(showSelect!==null)
         setShowSelect(null)
         else
         setShowSelect(index)
      }
    const closeSortMenu=()=>{
        setShowSort(false);
    }
    const selectSort=(type)=>{
      dispatch(getAllProjects(type))
      closeSortMenu()
    }
    const handleEditMode=()=>{
        if(editMode)
        {
          setEditID(null)
           setEditMode(false)
        }else{
           setEditMode(true)
        }
      }
      const handleEditRole=(val)=>{
        setForm({...form,user_type:val})
        setShowSelect(null)
      }
      const handleEditTable=(user)=>{
        setEditID(user.id);
        console.log(user)
        let t=[]
        user.assigned_projects?.forEach(item=>{
            all_projects.find(i=>{
                //console.log(i)
                if(i.project_name==item)
                 { 
                    //console.log(i)
                    if(!t.find(x=>x.label===i.project_name))
                    t.push({"value":i.id,"label":i.project_name})}
            })
        })
        setDefaultSelect(t)
        //console.log(t)
        setForm({
            "user_type":user.user_type,
            "projects":t.map(i=>i.value).join(","),
        })
        document.querySelectorAll(".edit-ppl-icon").forEach(item=>{item.classList.add("hide")})
      
      }
      const saveEditTable=()=>{
        console.log(form)
        dispatch(updateAllocation(editID,form))
          document.querySelectorAll(".edit-ppl-icon").forEach(item=>{item.classList.remove("hide")})
        
         setEditID(null);
      }
      
     useEffect(()=>{
        dispatch(getAllUsers())
        dispatch(getAllProjects())
     },[dispatch])
      //console.log(users)
    return (
    <div className="allocation-page">
        {(deleteItem!==null) && <ConfirmDeleteUserModal deleteItem={deleteItem} setDeleteItem={setDeleteItem}/>}
        {(disableItem!==null) && <ConfirmDisableUserModal disableItem={disableItem} setDisableItem={setDisableItem}/>}
        {showAddMember===true && <AddMember setShowAddMember={setShowAddMember}/>}
        <div className='admin-ppl-header'>
        <div className=""></div>
            <div className='ppl-title'>
                <div className='admin-page-title'>Allocate Members</div>
            </div>
            <div className='ppl-top-buttons'>
                <button className='btn btn-green-fill' onClick={()=>setShowAddMember(true)}>New Member <img src={plus} alt="add"/></button>
                <button className={`btn btn-${editMode?'grey':'green-fill'}`} onClick={handleEditMode}>Edit <img src={edit} alt="edit"/></button>  
            </div>
        </div>
        <div className='allocation-bar'>
           <form className="search-form">
             <SearchIcon fill={activeSearch?'#D6D6D6':'#909090'}/>
             <input onFocus={()=>setActiveSearch(true)} onBlur={()=>setActiveSearch(false)} onChange={handleSearch} placeholder="Search"/>
           </form>
           <div className='allocation-bar-actions'>
            {/* <div className="sort sorting-section">
                    <div className="sort-action" onClick={()=>setShowFilter(p=>!p)}>
                    <img src={filterIcon} alt="sort"/>
                    <span>Filter</span>
                    </div>
                    <div className={`sort-options ${showFilter?'':'hide'}`}>
                        <div className="sort-option" onClick={()=>selectSort('name')}>Filter by name</div>
                        <div className="sort-option" onClick={()=>selectSort('modify')}>Filter by modified</div>
                        <div className="sort-option" onClick={()=>selectSort('created')}>Filter by created</div>
                    </div>
            </div> */}
           </div>
           <div className="sort sorting-section">
                    <div className="sort-action" onClick={()=>setShowSort(p=>!p)}>
                    <img src={sortIcon} alt="sort"/>
                    <span className="sort-resp">Sort</span>
                    </div>
                    <div className={`sort-options ${showSort?'':'hide'}`}>
                        <div className="sort-option" onClick={()=>selectSort('project_name')}>Sort by name</div>
                        <div className="sort-option" onClick={()=>selectSort('updated_at')}>Sort by modified</div>
                        <div className="sort-option" onClick={()=>selectSort('created_at')}>Sort by created</div>
                    </div>
            </div>
        </div>
        <div className='ppl-body'>
                <div className='ppl-content'>
                    <table className='ppl-table'>
                        <thead>
                            <tr>
                                <th className='first-column'>Members</th>
                                <th className='allocation-th-min'>Role</th>
                                <th style={{minWidth : "200px"}}>Assigned Project</th>
                                <th>Client contact info</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        {!isLoading && users && 
                        <tbody>
                            {users.map((user,index)=>{  
                            if(editID===user.id )
                            return (<tr className={`user-data-${user.id}`}>
                            <td >
                                <div className='first-column'>
                                {user.fullName}
                                {editID===user.id && <img style={{marginLeft:'20px'}}onClick={saveEditTable} src={saveIcon} alt="save edit"/>}
                                </div>
                                
                            </td>
                            
                            <td>
                            <div className="form-input-select">
                                
                                <div className='form-input-selected' onClick={()=>openDropdown(index)}>
                                    <div className='form-input-select-option'>{form.user_type==='admin'?'Accountant':form.user_type==='pm'?'Manager':form.user_type==='superadmin'?'Admin':form.user_type}</div>
                                    <img src={dropdown} alt="drop"/>
                                </div>
                                {(index===showSelect) && <div className='form-input-select-dropdown'>
                                    <div className='form-input-select-option' onClick={()=>handleEditRole('client')}>Client</div>
                                    <div className='form-input-select-option' onClick={()=>handleEditRole('pm')}>Manager</div>
                                    <div className='form-input-select-option' onClick={()=>handleEditRole('admin')}>Accountant</div>
                                    <div className='form-input-select-option' onClick={()=>handleEditRole('superadmin')}>Admin</div>
                                </div>}
                            </div>
                            </td>
                            <td className='ppl-col-max'>
                            <Select
                                  name="projects"
                                  styles={{
                                    control: (provided, state) => ({
                                      ...provided,
                                      backgroundColor: "rgba(217, 217, 217, 0.12)",
                                      border: "none",
                                      borderRadius: "0px",
                                      boxShadow: "none",
                                      height: "43px",
                                    }),
                                    dropdownIndicator: (provided, state) => ({
                                      ...provided,
                                      display: "none",
                                    }),
                                    option: (styles, { data }) => ({
                                        ...styles,
                                        color: 'black',
                                      }),
                                    indicatorSeparator: (provided, state) => ({
                                      ...provided,
                                      display: "none",
                                    }),
                                    valueContainer: (provided, state) => ({
                                      ...provided,
                                      overflowY: "scroll",
                                      maxHeight: "43px",
                                    })
                                  }}
                                  components={{
                                    DropdownIndicator: () => {
                                      return <img style={{paddingRight: "18px"}} src={dropdown} alt="project" />;
                                    },
                                  }}
                                  placeholder="Select Projects"
                                  isMulti
                                  defaultValue={defaultSelect}
                                  onChange={(e)=>{
                                    setForm({...form,"projects":e.map(i=>i.value).join(",")})
                                  }}
                                  options={projectsSelect}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                />
                            </td>
                            <td><div>{user.fullName}<br/>{user.email}<br/>{user.phone}</div></td>
                            <td>
                                    <div className='allocation-actions'>
                                        <img style={{cursor:'pointer'}} src={screenShareIcon} alt="share" onClick={()=>setDisableItem(user)}/>
                                        <img style={{cursor:'pointer'}} src={deleteIcon} alt="del" onClick={()=>setDeleteItem(user)}/>
                                    </div>
                                </td>
                        </tr>)
                        else return (
                            <tr className={`user-data-${user.id}`}>
                                <td  ><div>{user.fullName} { editMode && <img className='edit-ppl-icon' onClick={()=>handleEditTable(user)}src={edit} alt="edit"/>}
                                                {editID===user.id && <img onClick={saveEditTable} src={saveIcon} alt="edit"/>}</div></td>
                                <td><div>{user.user_type==='admin'?'Accountant':user.user_type==='pm'?'Manager':user.user_type==='superadmin'?'Admin':user.user_type}</div></td>
                                <td className='ppl-col-max'><div style={{wordBreak: 'break-word'}}>{user.assigned_projects?.join(",")}</div></td>
                                <td><div>{user.fullName}<br/>{user.email}<br/>{user.phone}</div></td>
                                <td>
                                    <div className='allocation-actions'>
                                        <img style={{cursor:'pointer'}} src={screenShareIcon} alt="share" onClick={()=>setDisableItem(user)}/>
                                        <img style={{cursor:'pointer'}} src={deleteIcon} alt="del" onClick={()=>setDeleteItem(user)}/>
                                    </div>
                                </td>
                                </tr>)})}
                        </tbody>
}
                    </table>
                </div>
            </div>
    </div>)
}
export default Allocation;