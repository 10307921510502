import React,{useState,useEffect} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { useParams,useNavigate } from 'react-router-dom';
import plus from "../../assets/icons/plus.svg"
import pdfLogo from './../../assets/icons/pdf-logo.svg';
import dropDown from './../../assets/icons/dropdown.svg';
import backArrow from "../../assets/icons/back-arrow.svg";
import linkIcon from "../../assets/icons/link.svg";
import copyIcon from "../../assets/icons/copy.svg";

import { getAdminProject,acceptProposal,denyProposal } from '../../actions';
import parse from 'html-react-parser';
const QuoteProject = () => {
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const { id } = useParams();
    const [acceptModal,setAcceptModal]=useState(false);
    const [denyModal,setDenyModal]=useState(false);
    const [descriptionModal,setDescriptionModal]=useState(false)
    const isAuth=useSelector(state=>state.isAuth)
    const isLoading=useSelector(state=>state.isLoading)
    const project=useSelector(state=>state.project)
    const AcceptModal=()=><div className='modal-popup'>
    <div className='modal-popup-content'>
        <div className='modal-popup-content-title'>Confirm</div>
        <div className='modal-popup-content-body'>Are you sure you want to accept this project request?</div>
        <div className='modal-popup-content-body modal-popup-content-highlight'>Note: Once you confirm, you cannot undo this step!</div>
        <div className='modal-popup-content-actions'>
            <button className='btn btn-green-fill btn-resp' style={{marginRight:'15px'}} onClick={()=>dispatch(acceptProposal(project.id,isAuth.access,navigate))}>Accept</button>
            <button className='btn btn-green-outline btn-resp' onClick={()=>setAcceptModal(false)}>Cancel</button>
        </div>
    </div>
</div>
const DenyModal=()=><div className='modal-popup'>
<div className='modal-popup-content' style={{top : "40%"}}>
    <div className='modal-popup-content-title'>Confirm</div>
    <div className='modal-popup-content-body'>Are you sure you want to deny this project request?</div>
    <div className='modal-popup-content-body modal-popup-content-highlight'>Note: Once you confirm, you cannot undo this step!</div>
    <div className='modal-popup-content-actions'>
        <button className='btn btn-green-fill' style={{marginRight:'15px',width : "88px"}} onClick={()=>dispatch(denyProposal(project.id,isAuth.access,navigate))}>Deny</button>
        <button className='btn btn-green-outline' style={{width : "88px"}} onClick={()=>setDenyModal(false)}>Cancel</button>
    </div>
</div>
</div>
const DescriptionModal=()=><div className='modal-popup'>
<div className='modal-popup-content'>
    <div className='modal-popup-content-title'>Project description</div>
    <div className='modal-popup-content-body modal-popup-content-highlight'>{parse(project?.project_description)}</div>
    <div className='modal-popup-content-actions'>
        <button className='btn btn-green-fill' onClick={()=>setDescriptionModal(false)}>Close</button>
    </div>
</div>
</div>
    const info = {
        status: 'Pending',
        name: 'Priya Soni',
        email: 'priyasoni@gmail.com',
        company: 'Semsen club',
        description: 'Panoram CGI is a boutique studio specializing in high-end 3D visualization',
        service_type: 'Still rendering',
        project_type: 'Architecture',
        quantity_of_service: '1',
        project_deadline: '31/05/2023',
    }
    const files = [
        {
            name: 'Design file.pdf',
            type: 'Pdf',
        },
        {
            name: 'References.pdf',
            type: 'Pdf',
        },
        {
            name: 'Sample.pdf',
            type: 'Pdf',
        },
        {
            name: 'ABCXYZ.pdf',
            type: 'Pdf',
        },
        {
            name: 'Copy.pdf',
            type: 'Pdf',
        }
    ]
    useEffect(()=>{
       if(id)
       {
        dispatch(getAdminProject(id,isAuth.access))
       }
    },[dispatch,id])
    return (
        <div className='admin-quotes-project'>  
        {acceptModal && <AcceptModal/>}
        {denyModal && <DenyModal/>}
        {descriptionModal && <DescriptionModal/>}
            <div className='quotes-header'>
                <button style={{justifySelf:'flex-start'}} onClick={()=>navigate(-1)} className='back-btn btn btn-green-outline' >
                    <img src={backArrow} alt="back"/>
                    <span>Back</span>
                </button>
                <div className='quotes-title admin-title'>
                    <div className='admin-quote-title'>Project Quote</div>
                    <div className={`status ${project?.project_status}-status`}>
                        <span>Status</span>
                        <span>:</span>
                        <span style={{textTransform:'capitalize'}}>{!isLoading && project && project.project_status}</span>
                    </div>
                </div>
                <div className='quote-top-buttons'>
                    <button className='quotes-btn btn btn-grey'>Assign PM <img src={plus} alt="add"/></button>
                    <button className='quotes-btn btn btn-grey'>Assign Administrator <img src={plus} alt="add"/></button>
                </div>
            </div>
            {!isLoading && project &&
              <div className='project-body'>
              <div className='project-title'>
                  <div className='project-name'>{project?.project_name}</div>
              </div>
              <div className='project-details'>
                  <div>
                      <div className='detail'>
                          Client name :
                          <span style={{textTransform:"capitalize"}} className='info'>{project?.your_name}</span>
                      </div>
                      <div className='detail'>
                          Email Address :
                          <span className='info'>{project?.email}</span>
                      </div>
                      <div className='detail'>
                          Company name :
                          <span style={{textTransform:"capitalize"}} className='info'>{project?.company_name}</span>
                      </div>
                      <div className='detail'>
                            Project name :
                            <span className='info'>{project?.project_name}</span>
                        </div>
                      <div className='detail'>
                          Project purpose :
                          <span className='info'>{project?.project_purpose}</span> 
                      </div>
                      <div className='detail'>
                          Project description :
                          <span className='info'>
                              {/* {project?.project_description.split(/\s+/).slice(0, 10).join(" ")} */}
                              <span className='view-more' onClick={()=>setDescriptionModal(true)}>view more</span>
                          </span>
                      </div>
                      
                      <div className='detail'>
                          Project purpose :
                          <span className='info'>{project?.project_purpose}</span>
                      </div>

                  </div>
                  <div>
                      <div className='detail'>
                          Service type :
                          <span className='info'>{project?.service_type}</span>
                      </div>
                      <div className='detail'>
                          Project type :
                          <span className='info'>{project?.project_type}</span>
                      </div>
                      <div className='detail'>
                          Quantity of service :
                          <span className='info'>{project?.quantity}</span>
                      </div>
                      <div className='detail'>
                          Project deadline :
                          <span className='info'>{project?.project_deadline.split("-")[1]}/{project?.project_deadline.split("-")[2]}/{project?.project_deadline.split("-")[0]}</span>
                      </div>
                        <div className='detail'>
                            Project location :
                            <span className='info'>{project?.project_location}</span>
                        </div>
                        <div className='detail'>
                            Address :
                            <span className='info'>{project?.project_address}</span>
                        </div>
                  </div>
              </div>
              {(project?.file?.length>0 || project?.link?.length>0 ) && <><div className='documents detail'>
                  Client documents :
              </div>
              <div className='files'>
                  {
                      project?.file?.length > 0 && (
                          project?.file.map((file, index) => 
                              (
                                  <a href={file.link} key={file.id} className='file-item'>
                                      <img src={file.icon} alt="file" />
                                      <div className='file-item-description'>
                                          <div>{file.name.split(".")[0]}</div>
                                          <div>{file.name.split(".")[1]}</div>
                                      </div>
                                  </a>
                              )
                          )
                      )
                  }
              </div>
              {project?.link &&
              <div className='document-link'>
                 <a href={project?.link}>{project?.link} <img src={linkIcon} alt="link"/></a>
                 <div className='document-link-copy'><img src={copyIcon} alt="copy"/></div>
                 
              </div>}</>}
              
              <div className='modal-footer'>
                  <button onClick={()=>setAcceptModal(true)} className="btn btn-green-fill">
                      Accept and proceed <img src={dropDown} alt="dropdown" style={{width:'10px', height: '10px'}}/>
                  </button>
                  <button className='btn btn-green-outline' onClick={()=>setDenyModal(true)}>
                      Deny
                  </button>
              </div>
          </div>
              }
            
            
        </div>
        
    );
};

export default QuoteProject;