import React,{useState,useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import * as validate from "../../helpers/validation";
import * as actionType from "../../helpers/constants"
import bg5 from "../../assets/images/bg-5.png";
import arrowRight from "../../assets/icons/arrow-right.svg";
import visibilityOff from "../../assets/icons/visibility_off.svg";
import visibility from "../../assets/icons/visibility.svg";
import logo from "../../assets/images/logo.png";
import bgLogo from "../../assets/icons/bg-logo.svg";
import warning from "../../assets/icons/warning.svg";
import { resetPassword } from "../../actions";
import { useNavigate,Link ,useSearchParams} from "react-router-dom";
import Notification from "../notification";
const initialForm={
    "new_password":"",
    "confirm_password":""
}
const ResetPassword=()=>{
  const [searchParams] = useSearchParams();
    const uid=searchParams.get("uid");
    const token=searchParams.get("token");
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const message=useSelector(state=>state.message)
    const [form,setForm]=useState(initialForm)
    const [newPasswordErr,setNewPasswordErr]=useState(null);
    const [confirmPasswordErr,setConfirmPasswordErr]=useState(null)
    const [showNewPassword,setShowNewPassword]=useState(false)
    const [showConfirmPassword,setShowConfirmPassword]=useState(false)
  const verify=()=>{
     return  !validate.validateEmpty(form.new_password) && !validate.validateEmpty(form.confirm_password);
  }
  const handleChange=(e)=>{
    const name=e.target.name;
    const value=e.target.value;
    setForm({...form,[name]:value})
    if(name=='new_password' && validate.validateEmpty(value))
     setNewPasswordErr("Cannot be empty")
   else
    setNewPasswordErr(null)
    if(name=='confirm_password' && validate.validateEmpty(value))
    setConfirmPasswordErr("Cannot be empty")
  else
   setConfirmPasswordErr(null)
}
const handleShowPassword=(type)=>{
    if(type==="new")
    setShowNewPassword(!showNewPassword);
    else
    setShowConfirmPassword(!showConfirmPassword)
  }
    const handleSubmit=(e)=>{
      e.preventDefault();
      console.log(form)
      dispatch(resetPassword(form,uid,token,navigate));
    }
    useEffect(()=>{
      dispatch({type:actionType.SET_MESSAGE,payload:null})
  },[])
  return (<div className="auth-page">
        <div className="auth-background">
            <div className="auth-overlay">
                <div className="auth-content">
                    <div>
                        <Link to="/" className="logo-link"><img src={bgLogo} alt="logo"/><p className="logo-cgi">CGI</p></Link>
                    </div>
                    <div className="auth-content-text">MEMBERS PORTAL</div>
                    {/* <div className="overlay-highlight">JOIN MEDIUM</div>
                    <div className="overlay-text">ARCHITECTURE +<br/>INTERIOR DESIGN</div> */}
                </div>
            </div>
            <img className="bg-img" src={bg5} alt="cover"/>
        </div>
        <div className="form-bg">
        <div className="default-header">
            <Link to="/" className="logo-link">
                <img className="bgLogo" src={bgLogo} alt="logo"/>
                <p className="logo-cgi">CGI</p>
                </Link>
              <div className="members-portal">MEMBERS PORTAL</div>
            </div>
        <form className="auth-form">
                <div className="form-heading">Create New Password</div>
                <div className="form-subheading">Enter new password</div>
                <div className="auth-form-content">
                <div className="form-input-box">
              <div className="form-label">New password</div>
              <div className="password-box">
                    <input className={`form-input ${newPasswordErr && 'input-invalid'}`} type={showNewPassword?'text':'password'} name="new_password"  onChange={handleChange}/>
                    <img onClick={()=>handleShowPassword('new')} src={showNewPassword?visibility:visibilityOff} alt="show password"/>
                  </div>
                  {newPasswordErr && <div className="input-error">
                    <img src={warning} alt="password warning"/>
                    <span>{newPasswordErr}</span>
                    </div>}
            </div>
            <div className="form-input-box">
              <div className="form-label">Confirm password</div>
              <div className="password-box">
                    <input className={`form-input ${confirmPasswordErr && 'input-invalid'}`} type={showConfirmPassword?'text':'password'} name="confirm_password"  onChange={handleChange}/>
                    <img onClick={()=>handleShowPassword("confirm")} src={showConfirmPassword?visibility:visibilityOff} alt="show password"/>
                  </div>
                  {confirmPasswordErr && <div className="input-error">
                    <img src={warning} alt="password warning"/>
                    <span>{confirmPasswordErr}</span>
                    </div>}
            </div>
            
            <button className={`btn btn-${verify()?"green-fill":"grey"}`} onClick={handleSubmit}> <span>Save password</span><img src={arrowRight} alt="next"/></button>
            
                </div>
                {message && <Notification type={message.type} message={message.message}/>}
            </form>
        </div>
            
        
    </div>)
   
}
export default ResetPassword;