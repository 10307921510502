import React from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useSelector } from 'react-redux';
import rehypeRaw from 'rehype-raw'
import profilePic from "../../assets/images/profile-pic.jpg";

const ProjectInvolved = ({isDescriptionModal, setIsDescriptionModal, setShowProjectInvolvedModal, projectID}) => {
    const project = useSelector(state => state.project);
    console.log(project);
    return (
        <div className='modal-popup'>
            <div className='modal-popup-content project-involved-modal'>
                <div className="modal-header people-involved-header">
                    <div></div>
                    <div className='modal-popup-content-title people-involved-title'>
                        {
                            isDescriptionModal ? 'Description' : 'People involved in the project'   
                        }
                    </div>
                    <button className='btn btn-green-outline' onClick={()=>{setIsDescriptionModal(false);setShowProjectInvolvedModal(false)}}>Cancel</button>
                </div>
                <div className='modal-body-content'>
                    {
                        isDescriptionModal ? (
                            <div className='modal-desc'>
                                <ReactMarkdown rehypePlugins={[rehypeRaw]} children={project.project_description} />
                            </div>
                        ) :
                        project?.invited_peoples.map((people, index) => {
                            return (
                                <div className="people-involved" key={index}>
                                    <img className='user-header-pic' src={people?.avatar ? people.avatar : profilePic} alt="user-img" />
                                    <p className='search-name'>{people?.fullName}</p>
                                </div>
                            )
                        }
                        )
                    }
                </div>
            </div>
    </div>
    );
};

export default ProjectInvolved;