const GridIcon=(props)=>(
    <svg width="20" height="20" viewBox="0 0 20 20" fill={props.fill} xmlns="http://www.w3.org/2000/svg">
<path d="M7.96875 9.375H2.65625C2.28329 9.375 1.9256 9.22684 1.66188 8.96312C1.39816 8.6994 1.25 8.34171 1.25 7.96875V2.65625C1.25 2.28329 1.39816 1.9256 1.66188 1.66188C1.9256 1.39816 2.28329 1.25 2.65625 1.25H7.96875C8.34171 1.25 8.6994 1.39816 8.96312 1.66188C9.22684 1.9256 9.375 2.28329 9.375 2.65625V7.96875C9.375 8.34171 9.22684 8.6994 8.96312 8.96312C8.6994 9.22684 8.34171 9.375 7.96875 9.375Z" />
<path d="M17.3438 9.375H12.0312C11.6583 9.375 11.3006 9.22684 11.0369 8.96312C10.7732 8.6994 10.625 8.34171 10.625 7.96875V2.65625C10.625 2.28329 10.7732 1.9256 11.0369 1.66188C11.3006 1.39816 11.6583 1.25 12.0312 1.25H17.3438C17.7167 1.25 18.0744 1.39816 18.3381 1.66188C18.6018 1.9256 18.75 2.28329 18.75 2.65625V7.96875C18.75 8.34171 18.6018 8.6994 18.3381 8.96312C18.0744 9.22684 17.7167 9.375 17.3438 9.375Z" />
<path d="M7.96875 18.75H2.65625C2.28329 18.75 1.9256 18.6018 1.66188 18.3381C1.39816 18.0744 1.25 17.7167 1.25 17.3438V12.0312C1.25 11.6583 1.39816 11.3006 1.66188 11.0369C1.9256 10.7732 2.28329 10.625 2.65625 10.625H7.96875C8.34171 10.625 8.6994 10.7732 8.96312 11.0369C9.22684 11.3006 9.375 11.6583 9.375 12.0312V17.3438C9.375 17.7167 9.22684 18.0744 8.96312 18.3381C8.6994 18.6018 8.34171 18.75 7.96875 18.75Z" />
<path d="M17.3438 18.75H12.0312C11.6583 18.75 11.3006 18.6018 11.0369 18.3381C10.7732 18.0744 10.625 17.7167 10.625 17.3438V12.0312C10.625 11.6583 10.7732 11.3006 11.0369 11.0369C11.3006 10.7732 11.6583 10.625 12.0312 10.625H17.3438C17.7167 10.625 18.0744 10.7732 18.3381 11.0369C18.6018 11.3006 18.75 11.6583 18.75 12.0312V17.3438C18.75 17.7167 18.6018 18.0744 18.3381 18.3381C18.0744 18.6018 17.7167 18.75 17.3438 18.75Z" />
</svg>

)
export default GridIcon;
