import React,{useState,useEffect} from "react";
import { useParams,useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import plus from "../../../assets/icons/plus.svg";
import sortIcon from "../../../assets/icons/sort.svg";
import arrowLeft from "../../../assets/icons/arrow-left.svg";
import replyIcon from "../../../assets/icons/reply.svg"
import { getUserProject,getAdminProject,getProjectUserList, getMessages,getReplies ,getMessageCategories} from '../../../actions';
import WriteMessage from "./writeMessage";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import Moment from 'react-moment';
import 'moment-timezone';

const MessageBoard=()=>{

const items=useSelector(state=>state.messages)
console.log("items",items)
    const {id}=useParams();
    const profile=useSelector(state=>state.profile)
    const project=useSelector(state=>state.project)
    const message_categories=useSelector(state=>state.message_categories)
    const isLoading=useSelector(state=>state.isLoading)
    const navigate=useNavigate();
    const dispatch=useDispatch()
    const [showSort,setShowSort]=useState(false)
    console.log(profile.timezone)
    const [showWriteModal,setWriteShowModal]=useState(false)
    
    const getMessageCategoryId=(category)=>{
       for(let i=0;i<message_categories.length;i++)
       {
        
        if(message_categories[i].name===category)
         return message_categories[i].id
       }
       return -1
    }
    const closeSortMenu=()=>{
        setShowSort(false);
    }
    const selectSort=(type)=>{
      closeSortMenu()
    }
    document.addEventListener('click',(e)=>{
        if(!e.target.closest('.sort-options') && !e.target.closest('.sort-action'))
        closeSortMenu()
    })
    
    useEffect(()=>{
        if(id)
        {
         if(profile.user_type==='superadmin')
          dispatch(getAdminProject(id))
          else
         dispatch(getUserProject(id))
         dispatch(getProjectUserList(id))
         dispatch(getMessages(id))
         dispatch(getMessageCategories())
        }
         
     },[dispatch,id])
     if(!isLoading && project)
    return (
    <div className={`${profile?.user_type==='superadmin'||profile?.user_type==='pm'||profile?.user_type==='accountant'?'admin-project-dashboard':''}`}>
        {showWriteModal && <WriteMessage setWriteShowModal={setWriteShowModal} projectID={project.id}/>}
    <div className='dashboard-project-header'>
    <button style={{justifySelf:'flex-start',marginRight : 'auto'}} onClick={()=>navigate(-1)} className='btn btn-green-outline dashboard-project-header-back' >
        <img src={arrowLeft} alt="back"/>
        <span>Back</span>
    </button>
    <div className='dashboard-project-header-content'>
        <div className='dashboard-project-content-1'>{project.project_name}</div>
    </div>
    <div></div>
    {/* <button className='btn btn-green-fill invite-btn-center'>Invite People <img src={plus} alt="invite people"/></button> */}
    </div>  
    <div className="message-board">
        <div className="board-header">
        {
            profile.user_type!=='superadmin' &&
            <button className='btn btn-green-fill sm-add-btn' onClick={()=>setWriteShowModal(true)}>New message <img src={plus} alt="invite people"/></button>
        }
            <div className="board-title">
              <div className="board-title-main">Message board</div>
            </div>
            <div className="sort sm-sort-btn">
            <div className="sort-action" onClick={()=>setShowSort(p=>!p)}>
                <img src={sortIcon} alt="sort"/>
                <span style={{display:"block"}}>Sort</span>
            </div>
            <div className={`sort-options ${showSort?'':'hide'}`}>
                <div className="sort-option" onClick={()=>selectSort('created')}>Sort by created</div>
            </div>
        </div>
        </div>
        {items.map((item,index)=><>
        
        {/* <div className="message-block-header">
            <div className="message-block-header-hr"></div>
            <div className="message-block-header-content">{item.date}</div>
        </div> */}
        {item?.data?.map((day,index)=><Link to={`/conversation/${id}/${item?.date}/${getMessageCategoryId(day.category)}`} className="message-block-item message-title-block" key={`message-block-${index}`}>
        <div className="person-img">
            {day.messages[0]?.created_by?.avatar?<img src={day.messages[0].created_by.avatar} alt="profile-img"/>:
            <span className="person-img-default">{`${day?.messages[0]?.created_by?.first_name.charAt(0)} ${day?.messages[0]?.project?.created_by?.last_name.charAt(0)}`} </span>
            }
         </div>
           <div className="message-block-item-content">
           <div className="message-block-item-content-header">
                <div className="message-block-item-title">{day?.messages[0]?.title}</div>
                <div className="message-block-item-time"><Moment format="LT" tz={profile.timezone}>{day?.messages[0]?.created_at}</Moment></div>
                </div>
                <div className="message-block-item-message">
                <span style={{textTransform:'capitalize'}}>{day?.category} by </span>
                   <span style={{textTransform:'capitalize'}}>{`${day?.messages[0]?.created_by?.first_name} ${day?.messages[0]?.created_by?.last_name}`}</span> • <Moment format="LT" tz={profile.timezone}>{day.messages[0].created_at}</Moment> — 
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} children={day.messages[0].message} /></div>
           </div>
        </Link>)}
        </>
        
   )}
        
    </div>
    </div>
    )
}
export default MessageBoard;