import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from "react-router-dom";
import reviewFileIcon from "../../assets/icons/review-file.svg";
import likeIcon from "../../assets/icons/like.svg";
import messageIcon from "../../assets/icons/message.svg";
import defaultFileIcon from "../../assets/icons/folder.svg"
import taskIcon from "../../assets/icons/task.svg";
import plus from "../../assets/icons/plus.svg";
import user1 from "../../assets/images/user-1.png";
import user2 from "../../assets/images/user-2.png";
import user3 from "../../assets/images/user-3.png";
import EllipseDark from "../../assets/icons/ellipse-dark.svg";
import EllipseLight from "../../assets/icons/ellipse-light.svg";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import InviteUser from './inviteUser';
import Moment from 'react-moment';
import "../../assets/css/activity.css"
import { getUserProject, getAdminProject, getInvitePeopleList, getProjectActivity, getVisitStatus, setVisitStatus } from '../../actions';
import ProjectInvolved from './ProjectInvolved';
import { set } from 'date-fns';
const DashboardProject = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const project = useSelector(state => state.project)
    const profile = useSelector(state => state?.profile)
    const notificationSettings = useSelector(
        (state) => state.notificationSettings
    );
    const prodTeam = sessionStorage.getItem("prodTeam")
    const isLoading = useSelector(state => state.isLoading)
    const activity = useSelector(state => state.activity)
    console.log("activity", activity)
    const show_project_tutorial = useSelector(state => state.show_project_tutorial)
    const first_project_visit = useSelector(state => state.first_project_visit)
    console.log('show_project_tutorial', show_project_tutorial)
    const [showUserList, setShowUserList] = useState(false)
    const [showProjectInvolvedModal, setShowProjectInvolvedModal] = useState(false)
    const [serial, setSerial] = useState(1);
    const [isDescriptionModal, setIsDescriptionModal] = useState(false);
    const handleCountIncrease = () => {
        if (serial < 5) {
            setSerial(serial + 1);
        }
    };
    const handleCountDecrease = () => {
        if (serial > 1) {
            setSerial(serial - 1);
        }
    };
    const skipTutorial = () => {
        dispatch({ type: "SET_PROJECT_TUTORIAL", payload: false });
        if (first_project_visit) {
            dispatch(setVisitStatus(profile.id))
        }
    }
    const handleClick = (location) => {
        skipTutorial();
        navigate(`/${location}/${id}`);
    }
    useEffect(() => {
        if (profile?.user_type === 'client') {
            dispatch(getVisitStatus(profile.id))
        }
    }, [])
    useEffect(() => {
        if (id) {
            if (profile?.user_type === 'superadmin')
                dispatch(getAdminProject(id))
            else
                dispatch(getUserProject(id))
            dispatch(getInvitePeopleList(id))
            dispatch(getProjectActivity(id))
        }

    }, [dispatch, id])
    console.log(profile.user_type)
    if (!isLoading && project)
        return (<div className={`${profile?.user_type==='superadmin'||profile?.user_type==='pm'||profile?.user_type==='accountant'?'admin-project-dashboard':''}`}>
            {showUserList && <InviteUser projectID={id} setShowUserList={setShowUserList} />}
            {showProjectInvolvedModal && <ProjectInvolved isDescriptionModal={isDescriptionModal} setIsDescriptionModal={setIsDescriptionModal} projectID={id} setShowProjectInvolvedModal={setShowProjectInvolvedModal} />}
            <div className='main-dashboard-project-header'>
                <div className='dashboard-project-header-content'>
                    <div className='dashboard-project-content-1'>{project.project_name}</div>
                    <div className='dashboard-project-status-container'>
                        <div className='dashboard-project-status'>Status: {project.project_status}</div>
                    </div>
                    <div className='project-desc'>
                        <div onClick={() => { setIsDescriptionModal(true); setShowProjectInvolvedModal(true) }} className="dashboard-project-content-3 project-desc-content">
                            <ReactMarkdown rehypePlugins={[rehypeRaw]} disallowedElements={['li']} children={project.project_description.length >= 50 ? `${project.project_description.substring(0, 100)}...` : project.project_description} />
                        </div>
                    </div>
                    {/* <div className='dashboard-project-content-3'>Input File | Date/Time | Version(Cad, RVD)</div> */}
                    {project.assigned_pm.length > 0 &&
                        <div className='dashboard-project-content-2'>Managed By {project.assigned_pm.join(",")}</div>}
                    {project.invited_peoples.length > 0 &&
                        <div className='dashboard-project-content-3'>
                            <div onClick={() => setShowProjectInvolvedModal(true)} className='project-involved'>
                                <span>Number of people involved in the project</span>
                                <div className='project-user-photo-grid'>
                                    {project?.invited_peoples.slice(0, 3).map(item => <div className='project-user-photo-grid-profile'>
                                        {item.avatar ? <img src={item.avatar} alt="user1" /> :
                                            <div className='project-user-photo-grid-profile-default'>{item.fullName.split(" ")[0].charAt(0)} {item.fullName.split(" ")[1].charAt(0)}</div>
                                        }

                                    </div>)}
                                    {project?.invited_peoples.length > 3 && <small className='extra-people-count'>+ {project?.invited_peoples.length - 3} more</small>}
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <div className='dashboard-header-secondary-section'>

                    <button className={`btn btn-green-fill invite-btn ${prodTeam && 'btn-hide'}`} onClick={() => setShowUserList(true)}>Invite People <img src={plus} alt="invite people" /></button>
                </div>
            </div>
            {show_project_tutorial && <div className="tutorial-page"></div>}
            <div className="board-grid">
                {
                    !prodTeam &&
                    <>
                        <div onClick={() => { if (!show_project_tutorial) { handleClick('message-board') } }} className={`project-board ${show_project_tutorial && serial === 1 && 'show-tutorial'}`}>
                            {notificationSettings?.numUnread && project.unread_messages !== 0 &&
                                <div className='board-count'>{project.unread_messages}</div>}
                            <div className='board-heading'>Message Board</div>
                            <div className='board-icon'><img src={messageIcon} alt="messages" /></div>
                            <div className='board-description'>
                                Post announcements, pitch ideas, group discussions, feedback and updates
                            </div>
                        <div style={{ visibility: `${show_project_tutorial && serial === 1 ? 'visible' : 'hidden'}` }} className={`tutorial-text tutorial10-text tutorial10-temp-text ${show_project_tutorial && serial === 1 && 'show-tutorial'}`}>
                            <div>
                                <span className='tutorial-text-heading'><img src={messageIcon} alt='messages' /> Message Board</span>
                                <span>Post announcements, pitch ideas, group discussions, feedback and updates</span>
                                <div className='pagination-ellipse'>
                                    <img src={EllipseLight} alt="" />
                                    <img src={EllipseDark} alt="" />
                                    <img src={EllipseDark} alt="" />
                                    <img src={EllipseDark} alt="" />
                                    <img src={EllipseDark} alt="" />
                                </div>
                                <div className='tutorial-text-footer'>
                                    <div onClick={skipTutorial}>Skip</div>
                                    <div className='pagination-btn'>
                                        <div onClick={handleCountDecrease} className={`prev-btn ${serial === 1 && 'pagination-grey'}`}>
                                            <span>{"<"}</span>
                                            <span>Prev</span>
                                        </div>
                                        <div onClick={handleCountIncrease} className={`next-btn ${serial === 5 && 'pagination-grey'}`}>
                                            <span>Next</span>
                                            <span>{">"}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </>
                }
                <div onClick={() => { if (!show_project_tutorial) { handleClick('documents-board') } }} className={`project-board ${show_project_tutorial && serial === 2 && 'show-tutorial'}`}>
                    {/* <div className='board-count'>3</div> */}
                    <div className='board-heading'>Docs and Files</div>
                    <div className='board-icon'><img src={defaultFileIcon} alt="messages" /></div>
                    <div className='board-description'>
                        Files and documents submitted by clients related to the project are stored here
                    </div>
                    <div style={{ visibility: `${show_project_tutorial && serial === 2 ? 'visible' : 'hidden'}` }} className={`tutorial-text tutorial11-text tutorial11-temp-text ${show_project_tutorial && serial === 2 && 'show-tutorial'}`}>
                        <div>
                            <span className='tutorial-text-heading'><img src={messageIcon} alt='messages' /> Docs</span>
                            <span>Files and documents submitted by clients related to the project are stored here</span>
                            <div className='pagination-ellipse'>
                                <img src={EllipseDark} alt="" />
                                <img src={EllipseLight} alt="" />
                                <img src={EllipseDark} alt="" />
                                <img src={EllipseDark} alt="" />
                                <img src={EllipseDark} alt="" />
                            </div>
                            <div className='tutorial-text-footer'>
                                <div onClick={skipTutorial}>Skip</div>
                                <div className='pagination-btn'>
                                    <div onClick={handleCountDecrease} className={`prev-btn ${serial === 1 && 'pagination-grey'}`}>
                                        <span>{"<"}</span>
                                        <span>Prev</span>
                                    </div>
                                    <div onClick={handleCountIncrease} className={`next-btn ${serial === 5 && 'pagination-grey'}`}>
                                        <span>Next</span>
                                        <span>{">"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div onClick={() => { if (!show_project_tutorial) { handleClick('tasks-to-do') } }} className={`project-board ${show_project_tutorial && serial === 3 && 'show-tutorial'}`}>
                    {notificationSettings?.numUnread && project.unread_todo !== 0 &&
                        <div className='board-count'>{project.unread_todo}</div>}
                    <div className='board-heading'>Tasks to do</div>
                    <div className='board-icon'><img src={taskIcon} alt="tasks" /></div>
                    <div className='board-description'>
                        List of assignments that need to be completed will be shown here
                    </div>
                    <div style={{ visibility: `${show_project_tutorial && serial === 3 ? 'visible' : 'hidden'}` }} className={`tutorial-text tutorial12-text tutorial12-temp-text ${show_project_tutorial && serial === 3 && 'show-tutorial'}`}>
                        <div>
                            <span className='tutorial-text-heading'><img src={taskIcon} alt='tasks' /> Tasks to do</span>
                            <span>List of assignments that need to be completed will be shown here</span>
                            <div className='pagination-ellipse'>
                                <img src={EllipseDark} alt="" />
                                <img src={EllipseDark} alt="" />
                                <img src={EllipseLight} alt="" />
                                <img src={EllipseDark} alt="" />
                                <img src={EllipseDark} alt="" />
                            </div>
                            <div className='tutorial-text-footer'>
                                <div onClick={skipTutorial}>Skip</div>
                                <div className='pagination-btn'>
                                    <div onClick={handleCountDecrease} className={`prev-btn ${serial === 1 && 'pagination-grey'}`}>
                                        <span>{"<"}</span>
                                        <span>Prev</span>
                                    </div>
                                    <div onClick={handleCountIncrease} className={`next-btn ${serial === 5 && 'pagination-grey'}`}>
                                        <span>Next</span>
                                        <span>{">"}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div onClick={() => {if (!show_project_tutorial){handleClick('review-file')}}} className={`project-board ${show_project_tutorial && serial === 4 && 'show-tutorial'}`}>
                    <div className='board-heading'>Review Files</div>
                    <div className='board-icon'><img src={reviewFileIcon} alt="review" /></div>
                    <div className='board-description'>
                        Get updated about the the current activities on the project & leave feedback reviews for improvement
                    </div>
                <div style={{ visibility: `${show_project_tutorial && serial === 4 ? 'visible' : 'hidden'}` }} className={`tutorial-text tutorial13-text tutorial13-temp-text ${show_project_tutorial && serial === 4 && 'show-tutorial'}`}>
                    <div>
                        <span className='tutorial-text-heading'><img src={reviewFileIcon} alt='review' /> Review File</span>
                        <span>Get updated about the the current activities on the project & leave feedback reviews for improvement</span>
                        <div className='pagination-ellipse'>
                            <img src={EllipseDark} alt="" />
                            <img src={EllipseDark} alt="" />
                            <img src={EllipseDark} alt="" />
                            <img src={EllipseLight} alt="" />
                            <img src={EllipseDark} alt="" />
                        </div>
                        <div className='tutorial-text-footer'>
                            <div onClick={skipTutorial}>Skip</div>
                            <div className='pagination-btn'>
                                <div onClick={handleCountDecrease} className={`prev-btn ${serial === 1 && 'pagination-grey'}`}>
                                    <span>{"<"}</span>
                                    <span>Prev</span>
                                </div>
                                <div onClick={handleCountIncrease} className={`next-btn ${serial === 5 && 'pagination-grey'}`}>
                                    <span>Next</span>
                                    <span>{">"}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <div onClick={() => {if(!show_project_tutorial){handleClick('completed-file')}}} className={`project-board ${show_project_tutorial && serial === 5 && 'show-tutorial'}`}>
                    <div className='board-heading'>Completed Files</div>
                    <div className='board-icon'><img src={likeIcon} alt="completed" /></div>
                    <div className='board-description'>
                        Store finalized project file after reviewing and suggesting changes
                    </div>
                <div style={{ visibility: `${show_project_tutorial && serial === 5 ? 'visible' : 'hidden'}` }} className={`tutorial-text tutorial14-text tutorial14-temp-text ${show_project_tutorial && serial === 5 && 'show-tutorial'}`}>
                    <div>
                        <span className='tutorial-text-heading'><img src={likeIcon} alt='completed' /> Completed file</span>
                        <span>Post announcements, pitch ideas, group discussions, feedback and updates</span>
                        <div className='pagination-ellipse'>
                            <img src={EllipseDark} alt="" />
                            <img src={EllipseDark} alt="" />
                            <img src={EllipseDark} alt="" />
                            <img src={EllipseDark} alt="" />
                            <img src={EllipseLight} alt="" />
                        </div>
                        <div className='tutorial-text-footer'>
                            <div onClick={skipTutorial}>Skip</div>
                            <div className='pagination-btn'>
                                <div onClick={handleCountDecrease} className={`prev-btn ${serial === 1 && 'pagination-grey'}`}>
                                    <span>{"<"}</span>
                                    <span>Prev</span>
                                </div>
                                <div onClick={handleCountIncrease} className={`next-btn ${serial === 5 && 'pagination-grey'}`}>
                                    <span>Next</span>
                                    <span>{">"}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            {activity?.length > 0 &&
                <div className='activity-board'>
                    <div className='activity-board-header'>Project Activity</div>
                    <div className='activity-blocks'>
                        {activity?.map((item, index) =>
                            <div className={`activity-block ${index % 2 == 0 ? '' : 'activity-block-right'}`}>
                                <div className='activity-date'>
                                    {item.date.split("-")[1]}/{item.date.split("-")[2]}/{item.date.split("-")[0]}
                                </div>
                                <div className={`activity-list activity-list-${index % 2 == 0 ? 'left' : 'right'}`}>
                                    {item.data.map(act =>
                                        <div className='activity-item'>
                                            <div className="person-img">
                                                {act.user.avatar ? <img src={act.user.avatar} alt="avatar" /> :
                                                    <span className="person-img-default">{`${act.user.fullName.split(" ")[0].charAt(0)} ${act.user.fullName.split(" ")[1].charAt(0)}`} </span>
                                                }
                                            </div>
                                            <div className='activity-content'>
                                                <div className='activity-item-header'>
                                                    <div className='activity-item-profile'>
                                                        <div className='activity-item-name'>{act.user.fullName},</div>
                                                        <div className='activity-item-role'>{act.user.user_type}</div>
                                                    </div>
                                                    <Moment format="LT" tz={profile.timezone} className='activity-item-time'>{act.created_at}</Moment>
                                                </div>
                                                <div className='activity-text'>
                                                <ReactMarkdown rehypePlugins={[rehypeRaw]} disallowedElements={['li']} children={act?.content} />
                                                </div>
                                                {act.image_url && <img className='activity-image' src={act.image_url} alt="" />}
                                            </div>
                                        </div>)}
                                </div>
                            </div>)}

                    </div>
                </div>
            }
        </div>)
}
export default DashboardProject;