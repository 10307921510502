import React,{useEffect, useState} from "react";
import { useDispatch,useSelector } from "react-redux";
import { useNavigate,useParams } from "react-router-dom";
import arrowDown from "../../../assets/icons/arrow-down.svg";
import linkIcon from "../../../assets/icons/link.svg";
import copyIcon from "../../../assets/icons/copy.svg";
import { updateProjectStatus } from "../../../actions";
import { all_project_status } from "../../../helpers/constants";
const ClientTab=({project})=>{
    const dispatch=useDispatch()
    const [descriptionModal,setDescriptionModal]=useState(false)
    const [showStatusDropdown,setShowStatusDropdown]=useState(false);
    const handleUpdateStatus=(value)=>{
        dispatch(updateProjectStatus(project.id,{"project_status":value}))
        setShowStatusDropdown(false)
    }
    const DescriptionModal=()=><div className='modal-popup'>
<div className='modal-popup-content'>
    <div className='modal-popup-content-title'>Confirm</div>
    <div className='modal-popup-content-body modal-popup-content-highlight'>{project?.project_description}</div>
    <div className='modal-popup-content-actions'>
        <button className='btn btn-green-fill' onClick={()=>setDescriptionModal(false)}>Close</button>
    </div>
</div>
</div>
    return (
        <div className='project-body'>
            {descriptionModal && <DescriptionModal/>}
<div className='project-title'>
    <div className='project-name'>{project?.project_name}</div>
</div>
<div className='project-details'>
    <div>
        <div className='detail'>
            Client name :
            <span style={{textTransform:"capitalize"}} className='info'>{project?.your_name}</span>
        </div>
        <div className='detail'>
            Email Address :
            <span className='info'>{project?.email}</span>
        </div>
        <div className='detail'>
            Company name :
            <span style={{textTransform:"capitalize"}} className='info'>{project?.company_name}</span>
        </div>
        <div className='detail'>
            Project description :
            <span className='info'>
                {project?.project_description?.split(/\s+/)?.slice(0, 10)?.join(" ")}
                <span className='view-more' onClick={()=>setDescriptionModal(true)}>view more</span>
            </span>
        </div>
    </div>
    <div>
        <div className='detail'>
            Service type :
            <span className='info'>{project?.service_type}</span>
        </div>
        <div className='detail'>
            Project type :
            <span className='info'>{project?.project_type}</span>
        </div>
        <div className='detail'>
            Quantity of service :
            <span className='info'>{project?.quantity}</span>
        </div>
        
        <div className='detail'>
            Project deadline :
            <span className='info'>{project?.project_deadline?.split("-")[1]}/{project?.project_deadline?.split("-")[2]}/{project?.project_deadline?.split("-")[0]}</span>
        </div>
    </div>
</div>
{(project?.file?.length>0 || project?.link?.length>0 ) && <><div className='documents detail'>
                  Client documents :
              </div>
              <div className='files'>
                  {
                      project?.file?.length > 0 && (
                          project?.file?.map((file, index) => 
                              (
                                  <a href={file.link} key={file.id} className='file-item'>
                                      <img src={file.icon} alt="file" />
                                      <div className='file-item-description'>
                                          <div>{file.name?.split(".")[0]}</div>
                                          <div>{file.name?.split(".")[1]}</div>
                                      </div>
                                  </a>
                              )
                          )
                      )
                  }
              </div>
              {project?.link &&
              <div className='document-link'>
                 <a href={project?.link}>{project?.link} <img src={linkIcon} alt="link"/></a>
                 <div className='document-link-copy'><img src={copyIcon} alt="copy"/></div>
                 
              </div>}</>}
<div className="ppl-status-dropdown">
    <div className="ppl-status">
        <span>Status</span>:<span>{project?.project_status}</span>
        <img style={{cursor:'pointer'}} onClick={()=>setShowStatusDropdown(p=>!p)} className={showStatusDropdown?'rotate':''} src={arrowDown} alt="status"/>
    </div>
    {showStatusDropdown && <div className="ppl-status-options">
        {all_project_status?.map((item,index)=> <div className="ppl-status-option" onClick={()=>handleUpdateStatus(item)}>{item}</div>)}
    </div>}
    
</div>
</div>
    )
}
export default ClientTab;