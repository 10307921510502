import React, { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SearchIcon from "../../assets/icons/search-icon";
import sortIcon from "../../assets/icons/sort.svg"
import PinIcon from "../../assets/icons/pin-icon";
import plus from "../../assets/icons/plus.svg"
import CreateProjectModal from '../CreateProjectModal';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import emptyBox from '../../assets/images/empty-box.svg';
import { getAdminProjects,getAdminCancelledProjects,getAdminCompletedProjects,getAdminOngoingProjects,pinProject, searchAdminProject } from "../../actions";
import Moment from 'react-moment';
const AdminHome=()=>{
    const dispatch=useDispatch();
    const isAuth=useSelector(state=>state.isAuth);
    const profile=useSelector(state=>state.profile)
    const projects=useSelector(state=>state.projects)
    const isLoading=useSelector(state=>state.isLoading)
    const [active,setActive]=useState('all');
    const [activeSearch,setActiveSearch]=useState(false)
    const [showSort,setShowSort]=useState(false)
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {setShow(false);console.log("closed")}
    
    const closeSortMenu=()=>{
        setShowSort(false);
    }
    const selectSort=(type)=>{
        console.log(type)
    if(active==='incomplete')
    dispatch(getAdminOngoingProjects(type))
    else if(active==='complete')
    dispatch(getAdminCompletedProjects(type))
    else if(active==='cancelled')
    dispatch(getAdminCancelledProjects(type))
    else
     dispatch(getAdminProjects(type))
      closeSortMenu()
    }
    document.addEventListener('click',(e)=>{
        if(!e.target.closest('.sort-options') && !e.target.closest('.sort-action'))
        closeSortMenu()
    })
    const handlePinProject=(id,val)=>{
        dispatch(pinProject({"user_id":profile.id,"project_id":id,"is_pinned":val?0:1}))
    }
   useEffect(()=>{
     if(active==='incomplete')
       dispatch(getAdminOngoingProjects())
    else if(active==='complete')
    dispatch(getAdminCompletedProjects())
    else if(active==='cancelled')
    dispatch(getAdminCancelledProjects())
    else
     dispatch(getAdminProjects())
   },[dispatch,active])
    return <>
     <CreateProjectModal show={show} handleClose={handleClose} />
     <div className="admin-home">
    <div className="admin-home-header">
        <form className="search-form">
            <SearchIcon fill={activeSearch?'#D6D6D6':'#909090'}/>
            <input onFocus={()=>setActiveSearch(true)} onBlur={()=>setActiveSearch(false)} placeholder="Search for projects" onChange={(e)=>{dispatch(searchAdminProject(e?.target.value))}}/>
        </form>
        <div className="admin-home-title">
        <div className="admin-page-title">
            <div>Project Files</div>
        </div>
        </div>
        {
            profile?.user_type === "superadmin" ? 
        <div className="btn-new-project">
            <button style={{margin:'0',marginLeft:'auto'}} onClick={handleShow} className='btn btn-green-fill'>New Project <img src={plus} alt="new project"/></button>
        </div> : ""
        }
    </div>
    <div className="home-actions">
        <div className="home-tabs">
            <div  onClick={() => {setActive('all')}} className={`tab ${active === 'all'?'tab-active':''}`}>All projects</div>
            <div  onClick={() => {setActive('incomplete')}} className={`tab ${active === 'incomplete'?'tab-active':''}`}>Work in progress</div>
            <div  onClick={() => {setActive('complete')}} className={`tab ${active === 'complete'?'tab-active':''}`}>Completed projects</div>
            <div  onClick={() => {setActive('cancelled')}} className={`tab ${active === 'cancelled'?'tab-active':''}`}>Cancelled projects</div>
        </div>
        <div className="sort" style={{marginLeft : "auto"}}>
            <div className="sort-action" onClick={()=>setShowSort(p=>!p)}>
                <img src={sortIcon} alt="sort"/>
                <span>Sort</span>
            </div>
            <div className={`sort-options ${showSort?'':'hide'}`} style={{top : "135px",right : "35px"}}>
                <div className="sort-option" onClick={()=>selectSort('created_at')}>Sort by created</div>
            </div>
        </div>
    </div>
    <div className="card-grid">
    {!isLoading && projects && projects.map((project,index)=>
    <div className="project-grid-item">
        <div className="project-pin"  onClick={()=>handlePinProject(project.id,project.is_pinned==1)}> <PinIcon fill={`${project.is_pinned?'#40BAFF':'#D6D6D6'}`}/></div>
        
    <Link to={`/project/${project.id}`} key={`project-${index}`} className="project-card">
        <div className="project-card-top">
            <div className="project-card-name">{project.project_name}</div>
            <div className="project-card-client">{project.your_name}</div>
            <div className="project-card-description"><ReactMarkdown rehypePlugins={[rehypeRaw]} children={project.project_description.length >= 100 ? `${project.project_description.substring(0,60)}...` : project.project_description} /></div>
        </div>
        <div className="project-card-bottom">
            {project.pm_name && <div><span>PM - </span> <span className="project-card-info">{project.pm_name}</span></div>}
            
            <div><span>Status - </span><span className="project-card-info">{project.project_status}</span></div>
            <div><span>Last edited - </span><span className="project-card-info"><Moment fromNow ago>{project.updated_at}</Moment></span></div>
        </div>
    </Link>
    </div>)}
    </div>
    {!isLoading && projects?.length==0 &&
    <>
        <div className='bg-dashboard-home'>
            <h2 style={{marginTop: '0px'}}>Welcome <span style={{textTransform:'capitalize'}}>{profile?.fullName}</span></h2>
            <p><small>Looks like you have no projects yet!</small></p>
            
            <div className='empty-box'>
                <img src={emptyBox} alt="empty" />
            </div>
            
        </div>
    </>
    }
</div></>
}
export default AdminHome;