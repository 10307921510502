import React, { useState } from 'react';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import dropdown from "../../../assets/icons/dropdown.svg";
import moment from 'moment';
import { components } from 'react-select';
import { useSelector,useDispatch } from 'react-redux';
import { createToDo } from '../../../actions';

const TaskToDoModal = ({openModal, handleCloseModal,projectID}) => {
    const initialForm={
        "assigned_ids": [],
        "notify_ids": [],  
        "due_date": moment(new Date()).format('YYYY-MM-DD'),
        "Notes": "",  
        "project_id": projectID
    }
    const dispatch=useDispatch()
    const startDate = new Date();
    const users=useSelector(state=>state.all_users)
    const [selectedDate,setSelectedDate]=useState(new Date())
    const [form,setForm]=useState(initialForm)
    const verify=()=>{
        return form.assigned_ids.length>0 && form.notify_ids.length>0 && form.due_date!=null && form.Notes!=""
    }
    const handleAddTask=()=>{
        console.log(form)
        if(verify()){
            dispatch(createToDo(form))
            setForm(initialForm)
            setSelectedDate(new Date())
            handleCloseModal()
        }
    }
    const CustomOption = (props) =>
    { 
        const { innerProps, innerRef } = props
       return <div ref={innerRef} {...innerProps} className="todo-auto-search-result auto-search-result">
       <div className="todo-search-profile search-profile">{props.data.full_name.split(" ")[0].charAt(0)}{props.data.full_name.split(" ")[1]?props.data.full_name.split(" ")[1].charAt(0):props.data.full_name.split(" ")[0].charAt(1)}</div>
       <div className="todo-search-name search-name">{props.data.full_name}</div>
     </div>

    }
    return (
        <>
            {
                openModal && (
                    <div className='modal'>
                        <div className='modal-content task-details-modal'>
                            <div className='modal-title task-details-title'>New to-do task</div>
                            
                            <div className='task-details-form'>
                        
                                <div className="new-task-detail">
                                    <div className="task-detail-label">Assigned to</div>
                                    <Select
            isClearable
            isSearchable
            isMulti
            placeholder="Type names to assign"
            getOptionLabel={option => option.full_name}
            theme={(theme) => ({
                ...theme,
                color:"white"
            })}
            styles={{
                control: (base,state) => ({
                    ...base,
                    backgroundColor: "#4B4B4B",
                    border: "1px solid rgba(214, 214, 214, 0.25)",
                    borderRadius: "3px",
                    boxShadow: "none",
                    minHeight: "29px",
                    width: "280px",
                  }),
                  input: (base,state)=>({
                    ...base,
                    color:'white'
                   }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    display: "none",
                  }),
                  indicatorSeparator: (provided, state) => ({
                    ...provided,
                    display: "none",
                }),
                  menu: base => ({
                    ...base,
                    marginTop:0,
                    borderRadius: 0,
                    background:'transparent'
                  }),
            }}
            onChange={(e)=>{
                console.log(e)
                setForm({...form,assigned_ids:e.map(i=>i.id)})
            }}
            
            options={users}
            getOptionValue={option=>option.id}
                components={{Control: (props) => (
                    <components.Control {...props}>
                         {props.children}
                    </components.Control>
                  ), Option: CustomOption }}
            />
                                </div>
                                <div className="new-task-detail">
                                    <div className="task-detail-label">When done notify</div>
                                    <Select
            isClearable
            isSearchable
            isMulti
            placeholder="Type names to notify"
            getOptionLabel={option => option.full_name}
            theme={(theme) => ({
                ...theme,
                color:"white"
            })}
            styles={{
                control: (base,state) => ({
                    ...base,
                    backgroundColor: "#4B4B4B",
                    border: "1px solid rgba(214, 214, 214, 0.25)",
                    borderRadius: "3px",
                    boxShadow: "none",
                    minHeight: "29px",
                    width: "280px",
                  }),
                  input: (base,state)=>({
                    ...base,
                    color:'white'
                   }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    display: "none",
                  }),
                  indicatorSeparator: (provided, state) => ({
                    ...provided,
                    display: "none",
                }),
                  menu: base => ({
                    ...base,
                    marginTop:0,
                    borderRadius: 0,
                    background:'transparent'
                  }),
            }}
            onChange={(e)=>{
                console.log(e)
                setForm({...form,notify_ids:e.map(i=>i.id)})
            }}
            
            options={users}
            getOptionValue={option=>option.id}
                components={{Control: (props) => (
                    <components.Control {...props}>
                         {props.children}
                    </components.Control>
                  ), Option: CustomOption }}
            />
                                </div>
                                <div className="new-task-detail">
                                    <div className="task-detail-label">Due on</div>
                                    <div>
                                        <DatePicker
                                            className="form-input task-detail-input" 
                                            placeholderText="Select a date"
                                            minDate={startDate}
                                            selected={selectedDate}
                                            onChange={
                                                    (date) => {
                                                         setSelectedDate(date);
                                                        setForm({...form,due_date:moment(date).format('YYYY-MM-DD')})
                                                    }
                                                } 
                                        />
                                    </div>
                                </div>
                                <div className="new-task-detail">
                                    <div className="task-detail-label">Notes</div>
                                    <div className='task-detail-label'>
                                        <textarea className="task-textarea" placeholder="Add extra notes" value={form.Notes} onChange={(e)=>setForm({...form,Notes:e.target.value})} />
                                    </div>
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button className={`btn btn-${verify()? 'green-fill':'grey'}`} onClick={handleAddTask}>Add To-do task</button>
                                <button className='btn btn-green-outline' onClick={handleCloseModal}>Cancel</button>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
};

export default TaskToDoModal;