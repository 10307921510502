import React, { useEffect, useState } from 'react';
import './../../assets/css/Profile.css';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import DownloadIcon from '../../assets/icons/download-icon';
import { Link,useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProjects,getProposalSignUrl,createPaymentOrder,checkPaypalStatus } from '../../actions';
import Moment from 'react-moment';

const ProjectStatus = () => {
    const navigate=useNavigate();
    const dispatch=useDispatch();
    const isAuth=useSelector(state=>state.isAuth);
    const projects=useSelector(state=>state.projects)
    const isLoading=useSelector(state=>state.isLoading)
   
    
    useEffect(() => {
      dispatch(getUserProjects())
    }, [])

    return (
        <div className='profile-page'>
            <div className='profile-content'>
                <div className='project-history'>
                    <table className='project-table'>
                        <thead>
                            <tr>
                                <th className='first-column'>Projects</th>
                                <th>Created On</th>
                                <th>Proposal</th>
                                <th>Invoice(s)</th>
                                <th>Status</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { !isLoading &&
                                projects?.map((project, index) => {
                                    console.log(project.signed_doc_url)
                                    return (
                                        <tr key={index}>
                                            <td className='first-column'>{project.project_name}</td>
                                            <td><Moment format="MM/DD/YYYY">{project.created_at}</Moment></td>
                                            
                                            <td>
                                                {project.signed_doc_url? 
                                                <a href={project.signed_doc_url} target="_blank" className='view-file'>
                                                <p>
                                                    Project proposal 
                                                </p>
                                                <DownloadIcon fill="#40BAFF"/>
                                            </a> 
                                                :
                                            <div
                                            className={`tag ppl-tag pending-bg`}>
                                                pending
                                            </div>
                                            }
                                                
                                            </td>
                                            <td>
                                                {project.invoice_doc ?
                                                <a href={project.invoice_doc} target="_blank" className='view-file'>
                                                <p>
                                                    Project Invoice 
                                                </p>
                                                <DownloadIcon fill="#40BAFF"/></a>
                                                :
                                                <div
                                                className={`tag ppl-tag pending-bg`}>
                                                    pending
                                                </div>}
                                                
                                            </td>
                                            <td >
                                            <div
                                              className={`tag ppl-tag ${project?.project_status}-bg`}>
                                                  {project?.project_status}
                                          </div>
                                            </td>
                                            
                                            <td className="ppl-actions">
                                                {project.is_payment_done ? <Link className='view' to={`/project-payment/${project.id}`}>View</Link>:<div></div>}
                                                {!project.signed_doc_url ? <div onClick={()=>{project.proposal_status!=='pending'&&navigate(`/project/sign-proposal/${project.id}`)}} className={`sign ${project.proposal_status==='pending'?'sign-inactive':''}`} disabled={project.proposal_status==='pending'? true:false} >Sign</div>:<div></div>}
                                                {/* {checkout  && }     */}
                                                <button className={`pay ${(project.signed_doc_url!==null && project.amount_to_be_paid!==0 && project.amount_to_be_paid!==null)?'':'pay-inactive'}`} disabled={(project.signed_doc_url!==null && project.amount_to_be_paid!==0 && project.amount_to_be_paid!==null)? false:true} onClick={()=>navigate(`/project-payment/${project.id}`)}>Pay</button>
                                                
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
       
    );
};

export default ProjectStatus;