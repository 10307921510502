import React,{useState,useEffect} from "react";
import { useParams,useNavigate } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import plus from "../../../assets/icons/plus.svg";
import sortIcon from "../../../assets/icons/sort.svg";
import arrowLeft from "../../../assets/icons/arrow-left.svg";
import defaultFile from "../../../assets/icons/default-file.svg"
import { getUserProject,getAdminProject } from '../../../actions';
import DocumentInputModal from "./DocumentInputModal"
const DocumentsBoard=()=>{
    const {id}=useParams();
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const [showSort,setShowSort]=useState(false)
    const [showInputModal,setShowInputModal]=useState(false)
    const project=useSelector(state=>state.project)
    const profile=useSelector(state=>state.profile)
    const prodTeam=sessionStorage.getItem("prodTeam")
    const isLoading=useSelector(state=>state.isLoading)
    useEffect(()=>{
       if(id)
       {
        if(profile.user_type==='superadmin')
         dispatch(getAdminProject(id))
         else
        dispatch(getUserProject(id))
       }
        
    },[dispatch,id])
    const getFileName=(link)=>{
    let temp=link.split("/")
    return temp[temp.length-1]
    }
    const closeSortMenu=()=>{
        setShowSort(false);
    }
    const selectSort=(type)=>{
      closeSortMenu()
    }
    document.addEventListener('click',(e)=>{
        if(!e.target.closest('.sort-options') && !e.target.closest('.sort-action'))
        closeSortMenu()
    })
    if(!isLoading && project)
    return (
    <div className={`${profile?.user_type==='superadmin'||profile?.user_type==='pm'||profile?.user_type==='accountant'&&!prodTeam?'admin-project-dashboard':''}`}>
         {showInputModal && <DocumentInputModal showInputModal={showInputModal} setShowInputModal={setShowInputModal} projectID={id}/>}
    <div className='dashboard-project-header'>
    <button style={{justifySelf:'flex-start',marginRight : "auto"}} onClick={()=>navigate(-1)} className='btn btn-green-outline' >
        <img src={arrowLeft} alt="back"/>
        <span>Back</span>
    </button>
    <div className='dashboard-project-header-content'>
        <div className='dashboard-project-content-1'>{project.project_name}</div>
    </div>
    <div></div>
    {/* <button className='btn btn-green-fill invite-btn-center'>Invite People <img src={plus} alt="invite people"/></button> */}
    </div>  
    <div className="document-board">
        <div className="board-header">
            <button className='btn btn-green-fill sm-add-btn' onClick={()=>setShowInputModal(true)}>New <img src={plus} alt="invite people"/></button>
            <div className="board-title">
                <div className="board-title-main">Docs & files</div>
                <div className="board-title-sub">This is a folder to store and organize assets and reference material</div>
            </div>
            <div className="sort sm-sort-btn">
                <div className="sort-action" onClick={()=>setShowSort(p=>!p)}>
                    <img src={sortIcon} alt="sort"/>
                    <span style={{display:"block"}}>Sort</span>
                </div>
                <div className={`sort-options ${showSort?'':'hide'}`}>
                    <div className="sort-option" onClick={()=>selectSort('created')}>Sort by created</div>
                </div>
            </div>
        </div>
        <div className="document-board-grid">
            {project.file.length===0 && project.File_docs.length==0 && <h2 style={{color:'white'}}>No files to show</h2>}
            {project.File_docs.map((item,index)=><Link to={item.File} className="document-board-file">
                <img src={item.icon?item.icon:defaultFile} alt="empty"/>
                <div className="document-board-filename">{getFileName(item.File)}</div></Link>)}
            {project.file.map((item,index)=><Link to={item.link} className="document-board-file"><img src={item.icon} alt="empty"/><div className="document-board-filename">{item.name}</div></Link>)}
        </div>
    </div>
    </div>
    )
}
export default DocumentsBoard;