import React,{useEffect, useState} from "react";
import logo from "../../assets/images/logo.png";
import { useSelector,useDispatch } from "react-redux";
import { Link,useLocation,useNavigate } from "react-router-dom";
import "../../assets/css/header.css";
import HomeIcon from "../../assets/icons/home-icon";
import NotificationIcon from "../../assets/icons/notification-icon";
import MagnifyIcon from "../../assets/icons/magnify-icon";
import FolderIcon from "../../assets/icons/folder-icon";
import GridIcon from "../../assets/icons/grid-icon";
import ProfileIcon from "../../assets/icons/profile-icon";
import ActivityIcon from "../../assets/icons/activity-icon";
import menu from "../../assets/icons/menu.svg";
import LogoutIcon from '../../assets/icons/logout-icon';
import profilePic from "../../assets/images/profile-pic.jpg";
import arrowDown from "../../assets/icons/arrow-down.svg";
import MailIcon from "../../assets/icons/mail-icon";
import Search from "./Search";
import { getNotificationSettings, getUserNotificationCount, logout } from "../../actions";
const Header=({handleShowMailModal,handleShow,handleOpenNotificationSettings,handleShowTutorial,handleShowProjectTutorial,setShowMenu,showMenu})=>{
  const profile=useSelector(state=>state.profile);
  const notificationSettings = useSelector(
    (state) => state.notificationSettings
  );
  const isAuth=useSelector(state=>state.isAuth);
  const unreadAct=useSelector(state=>state.unread_activity);
  const unreadNot=useSelector(state=>state.unread_client_notification);
  console.log("unreadNotification",unreadNot)
 const [showDropdown,setShowDropdown]=useState(false)
  const dispatch=useDispatch();
  let location=useLocation();
  const navigate=useNavigate();
  // const [showSearch,setShowSearch]=useState(false)
  let pathname=location.pathname.split("/")[1];
  
  const closeMenu=(e)=>{
    console.log(e?.target.innerHTML)
    if(e?.target.innerHTML === "Mail us"){
      handleShowMailModal(true);
    }
    else{
      setShowMenu(false)
    }
  }
  const closeDropdown=(e)=>{
    setShowDropdown(false)
    if(e?.target.innerHTML === "Profile"){
      handleShow(true);
    }
    console.log(e?.target.innerHTML)
    if(e?.target.innerHTML === "Mail us"){
      handleShowMailModal(true);
    }
  }
  const handleLogout=()=>{
    dispatch(logout());
    closeMenu();
    navigate("/");
  }
  const showMail=()=>{
    handleShowMailModal(true);
  }
  const showProfileEdit = ()=>{
    setShowMenu(false)
      handleShow(true);
  }
  document.addEventListener('click',(e=>{ 
    //console.log(el,menudrop)
      // if(!e.target.closest('.navbar-small')&& !e.target.closest('.menu'))
      //  { setShowMenu(false);
      // }
      //console.log(e.target.classList.contains("drop-arrow"))
      if(!e.target.classList.contains("drop-arrow"))
       { 
      setShowDropdown(false);}
      // if(!e.target.closest(".search-bar") && !e.target.closest(".search-open"))
      // {
      //   setShowSearch(false)
      // }
   }));

   const handleTutorial=()=>{
    console.log(window.location.href.split("/")[3])
    if(window.location.href.split("/")[3] === "project"){
      navigate(`/project/${window.location.href.split("/")[4]}`)
      handleShowProjectTutorial();
    }
    else{
      navigate("/")
      handleShowTutorial();
    }
   }
   
   useEffect(()=>{
    dispatch(getNotificationSettings());
    dispatch(getUserNotificationCount())
   },[])

  return (
    <>
    <header>
      {isAuth && 
      <div className="menu-box">
         <img onClick={()=>setShowMenu(p=>!p)} className="menu" src={menu} alt="mobile menu"/>
         {showMenu && <div className='navbar-small'>
                    <Link onClick={closeMenu} className={`navbar-item ${pathname.length===0?'selected':''}`} to='/'>
                        <HomeIcon fill={pathname.length===0?'black':'currentColor'}/>
                        <span>Home</span>
                    </Link>
                    <Link onClick={closeMenu} className={`navbar-item ${pathname==='notifications'?'selected':''}`} to='/notifications'>
                        <NotificationIcon fill={pathname==='notifications'?'black':'currentColor'}/>
                        <span>Notifications</span>
                        {notificationSettings?.numUnread &&
                          unreadNot !== 0 && <span className="board-count-act">{unreadNot}</span>
                        }
                    </Link>
                    <Link onClick={closeMenu} className={`navbar-item ${pathname==='activity'?'selected':''}`} to='/activity'>
                        <ActivityIcon fill={pathname==='activity'?'black':'currentColor'}/>
                        <span>Activity</span>
                        {notificationSettings?.numUnread &&
                          unreadAct !== 0 && <span className="board-count-act">{unreadAct}</span>
                        }
                    </Link>
                    <Link className={`navbar-item`} to='/search'>
                        <MagnifyIcon fill={pathname==='search'?'black':'currentColor'}/>
                        <span>Search</span>
                    </Link>
                    <Link className={`navbar-item`} to='/project-status'>
                        <FolderIcon fill={pathname==='project-status'?'black':'currentColor'}/>
                        <span>Project Status</span>
                    </Link>
           </div>}
      </div>
    }
      <div className="logo-div">
        <Link className="logo" to="/"><img src={logo} alt="logo"/></Link>
        <div className="portal-type">MEMBERS PORTAL</div>
      </div>
      {isAuth && <>
        <div className='navbar'>
                    <Link className={`navbar-item ${pathname.length===0?'selected':''}`} to='/'>
                        <HomeIcon fill="white"/>
                        <span>Home</span>
                    </Link>
                    <Link className={`navbar-item ${pathname==='notifications'?'selected':''}`} to='/notifications'>
                        <NotificationIcon fill="white"/>
                        <span>Notifications</span>
                        {notificationSettings?.numUnread &&
                          unreadNot !== 0 && <span className="board-count-act">{unreadNot}</span>
                        }
                    </Link>
                    {/* <div onClick={handleTutorial} className={`navbar-item`}>
                        <GridIcon fill="white"/>
                        <span>Tutorial</span>
                    </div> */}
                    <Link className={`navbar-item ${pathname==='activity'?'selected':''}`} to='/activity'>
                        <ActivityIcon fill="white"/>  
                        <span>Activity</span>
                        {notificationSettings?.numUnread &&
                          unreadAct !== 0 && <span className="board-count-act">{unreadAct}</span>
                        }
                    </Link>
                    <Link  className={`search-open navbar-item ${pathname==='search'?'selected':''} `} to="/search">
                        <MagnifyIcon fill="white"/>
                        <span>Search</span>
                    </Link>
                    <Link className={`navbar-item ${pathname==='project-status'?'selected':''}`} to='/project-status'>
                        <FolderIcon fill="white"/>
                        <span>Project status</span>
                    </Link>
        </div>
        <div className="user-header user-header-resp">
          <div className="user-header-profile" >
            <img className="user-header-pic" src={profile?.avatar ? `${profile.avatar}` : profilePic} alt="profile"/>
            <div className="user-profile-details">
              <div className="user-profile-subheading">
                <div className="user-profile-name">{profile?.fullName}</div>
                <div className="user-profile-title">{profile?.title_atcompany}</div>
              </div>
              <img className="drop-arrow" onClick={()=>setShowDropdown(true)} src={arrowDown} alt="profile"/>
            </div>
          </div>
          <div className={`user-header-dropdown ${showDropdown?'':"hide"}`}>
            <div onClick={closeDropdown} className={`navbar-item `}>
              <ProfileIcon fill="white"/>
              <span>Profile</span>
            </div> 
            <div onClick={()=>{closeDropdown();handleOpenNotificationSettings();}} className={`navbar-item`}>
              <NotificationIcon fill="white"/>
              <span>Notifications settings</span>
            </div>
            <div onClick={handleTutorial} className={`navbar-item`}>
              <GridIcon fill="white"/>
              <span>Tutorial</span>
            </div>
            <div  className='navbar-item logout' onClick={handleLogout}>
            <LogoutIcon fill="white"/>
            <span>Sign out</span>
        </div>
        <div onClick={showMail} className={`navbar-item`}>
              <MailIcon fill="white"/>
              <span>Mail Us</span>
            </div>
          </div>
          
        </div>
        </>}
        
        {isAuth && showMenu && <>
          
        
       </>}
       {/* {showSearch && <Search/>} */}
    </header>
    
    </>
  )
}
export default Header;