import React,{useState,useEffect,useRef} from "react";
import { useDispatch,useSelector } from "react-redux";
import downloadIcon from "../../../assets/icons/download-icon"
import plusIcon from "../../../assets/icons/plus.svg"
import dropboxIcon from "../../../assets/icons/dropbox.svg"
import crossIcon from "../../../assets/icons/cross.svg"
import RemoveIcon from '../../../assets/icons/remove-icon';
import notify from "../../../helpers/notify";
import DropboxChooser from "react-dropbox-chooser";
import { updateDocs } from "../../../actions";
const InputReviewModal=({showInputModal,setShowInputModal,projectID})=>{
    console.log(showInputModal)
    const dispatch=useDispatch()
    const reviewInput=useRef();
    const [fileType,setFileType]=useState(null)
    const [hoverRemove,setHoverRemove]=useState('');
    const [form,setForm]=useState({
        "local":[],
        "dropbox":[]
    })
    const [reviewInputFiles,setReviewInputFiles]=useState([])
    const verify=()=>{
       return form.local.length!=0 || form.dropbox.length!=0
    }
    const handleSuccess=(files)=>{
       // console.log(files)
        //   setFiles(files);
        //   console.log(files)
        setFileType("dropbox")
          setForm({...form,dropbox:[...files]})
      }
    const handleRemoveFile=(id)=>{
        if(fileType=='local')
        setForm({...form,"local":[...form.local].filter((item,index)=>id!==index)})
        else if(fileType=='dropbox')
        setForm({...form,"dropbox":[...form.dropbox].filter((item,index)=>id!==index)})
      }
    const handleInputReviewFiles=(e)=>{
       let f=e.target.files,files=[]
       for(let i=0;i<f.length;i++)
         files.push(f[i])
       // console.log(files)
       setForm({...form,local:files})
    }
    const fileName=(t)=>{
        const i=t.lastIndexOf(".");
        return <>
        <p>{t.substring(0,i)}</p>
        <p>{t.substring(i+1)}</p>
        </>
       }
    const handleUploadFiles=(e)=>{
        e.preventDefault()
        console.log(form)
        if(fileType=='dropbox'){
            dispatch(updateDocs(projectID,{"json_data":form.dropbox}))
        }
        else{
        let newForm=new FormData()
        for(let i=0;i<form.local.length;i++)
           newForm.append("File_docs",form.local[i])
        dispatch(updateDocs(projectID,newForm))
        }
        
        setShowInputModal(null);
    }
    console.log(form)
    return(
        <div className="modal-popup">
            <input ref={reviewInput} className="review-input" multiple hidden type="file" onChange={handleInputReviewFiles}/>
            {(form.local.length==0&&form.dropbox.length==0)?
            <div className="modal-popup-content review-input-modal">
            <input className="review-input" multiple hidden type="file" accept="image/png, image/jpeg" onChange={handleInputReviewFiles}/>
                <img className="close-input-review-modal" src={crossIcon} alt="close" onClick={()=>setShowInputModal(false)}/>
                <div className="input-review-heading">
                    <div className="input-review-heading-main">Upload Documents</div>
                    {/* <div className="input-review-heading-sub">Allowed types: Jpg, Jpeg</div> */}
                </div>
                <button className="btn btn-grey" onClick={()=>{setFileType("local");reviewInput.current.click()}}>
                <img src={plusIcon} alt="add"/><span>Upload from computer</span>
                
            </button>
            <DropboxChooser
                appKey={process.env.REACT_APP_DROP_KEY}
                success={handleSuccess}
                linkType="direct"
                folderselect={true}
                multiselect={true}
                
              >
            <button className="btn btn-grey">
            
                <img src={dropboxIcon} alt="add"/><span>Upload through dropbox</span>
             
            </button>
            </DropboxChooser>
            </div>
             :<div className="modal-popup-content document-input-modal" style={{transform : "translate(-50%,10%)"}}>
             {form.local.length>0 &&  <div className='files-list'>
                                {
                                    form.local.length > 0 && (
                                        form.local.map((file, index) => 
                                        // {
                                        //     console.log(files[file].name);
                                        // }
                                        (     <div className='file-box' key={index}>
                                          <div className="file-remove" onMouseEnter={()=>setHoverRemove(index)} onMouseLeave={()=>setHoverRemove('')} onClick={()=>handleRemoveFile(index)} ><RemoveIcon opacity={hoverRemove===index?0.57:0.12}  fill={hoverRemove===index?'#333333':'white'}/></div>
                                          <div className='file-item-description'>
                                                       {file.name}
                                                    </div>
                                                {/* <a href={file.link}  className='file-item'>
                                                    <img src={file.icon} alt="file item" />
                                                    <div className='file-item-description'>
                                                       {fileName(file.name)}
                                                    </div>
                                                </a> */}
                                                </div>
                                            )
                                        )
                                    )
                                }
                            </div>}
            {form.dropbox.length>0 &&  <div className='files-list'>
                {
                    form.dropbox.length > 0 && (
                        form.dropbox.map((file, index) => 
                        // {
                        //     console.log(files[file].name);
                         // }
                         (     <div className='file-box' key={index}>
                          <div className="file-remove" onMouseEnter={()=>setHoverRemove(index)} onMouseLeave={()=>setHoverRemove('')} onClick={()=>handleRemoveFile(index)} ><RemoveIcon opacity={hoverRemove===index?0.57:0.12}  fill={hoverRemove===index?'#333333':'white'}/></div>
                          <div className='file-item-description'>
                                       {file.name}
                                    </div>
                                <a href={file.link}  className='file-item'>
                                    <img src={file.icon} alt="file item" />
                                    <div className='file-item-description'>
                                       {fileName(file.name)}
                                    </div>
                                </a>
                                </div>
                            )
                        )
                    )
                }
            </div>}
                            <div className="review-input-modal-actions">
                   <button onClick={handleUploadFiles} className={`btn btn-${verify()?"green-fill":"grey"}`} >
                    Upload
                    </button>
                    <button className="btn btn-green-outline" onClick={()=>setShowInputModal(false)}>
                    Cancel
                    </button>
                </div>
            </div>}
        </div>
    )
}
export default InputReviewModal