import React,{useState,useEffect} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import MagnifyIcon from '../../../assets/icons/magnify-icon';
import dropdown from "../../../assets/icons/dropdown.svg";
import defaultFile from "../../../assets/icons/default-file.svg"
import { Link } from 'react-router-dom';
import "../../../assets/css/Dashboard/search.css"
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import Moment from 'react-moment';
import * as actionType from "../../../helpers/constants"
import { getSearchList,getSearchResult,markTaskDone } from '../../../actions';
import notify from '../../../helpers/notify';
const SearchComponent=()=>{
    const dispatch=useDispatch()
    const [showComponents,setShowComponents]=useState(false)
    const [showProjects,setShowProjects]=useState(false)
    const [showUsers,setShowUsers]=useState(false)
    const searchList=useSelector(state=>state.searchList)
    console.log("SearchList",searchList)
    const searchResult=useSelector(state=>state.searchResult)
    console.log(searchResult)
    const profile=useSelector(state=>state.profile)
    const initialForm={
        "query":"",
        "component":"",
        "user":"",
        "project":""
    }
    const [form,setForm]=useState(initialForm)
    const getFileName=(link)=>{
        let temp=link?.split("/")
        return temp[temp.length-1]
        }
 const handleSelect=(type,val)=>{
   console.log(type,val)
   console.log("Form",form)
    if(type=='component')
       setForm({...form,"component":val})
    if(type=='user')
       setForm({...form,"user":val})
    if(type=='project')
       setForm({...form,"project":val})
    setShowComponents(false)
    setShowProjects(false)
    setShowUsers(false)
    
}
const handleSearch=(e)=>{
    e.preventDefault();
    if(form?.query === "")
        notify("warning","Please give some input")
    if(form?.query)
    dispatch(getSearchResult(form?.query,{"name":form?.component ? form?.component?.toLowerCase()?.split(" ").join("") : "none","user_id":form?.user?.id ? form?.user?.id : "none","project_id":form?.project?.id ? form?.project?.id : "none"}))
}
useEffect(()=>{
  dispatch(getSearchList())
},[dispatch])
    return(
        <div className='search-page'>
              <div className='search-bar search-bar-resp'>
                <div className='search-input-box'>
                <div className='search-box'>
                <div className="search-input-icon"><MagnifyIcon fill="#D6D6D6"/></div>
              <input className='search-input' value={form?.query} placeholder='Search for...' onChange={(e)=>setForm({...form,"query":e.target.value})}/>
            </div>
                <button class="btn btn-green-fill" onClick={handleSearch}><MagnifyIcon fill="#D6D6D6"/> Search</button>
                </div>
           
            <div className='search-filters'>
                <div className='search-filter search-first'>
                    <div className='search-filter-input' onClick={()=>setShowComponents(p=>!p)}>
                        <div>{form?.component?form.component:`Search EveryThing`}</div>
                        <img src={dropdown} alt=""/>
                    </div>
                    {showComponents && 
                    <div className='search-filter-dropdown'>
                    <div className='search-filter-dropdown-item' onClick={()=>handleSelect("component","")}>None</div>
                        {searchList?.category?.map(item=><div className='search-filter-dropdown-item' onClick={()=>handleSelect("component",item)}>{item}</div>)}
                       
                    </div>
                   }
                </div>
                <div className='search-filter search-second'>
                    <div className='search-filter-input' onClick={()=>setShowUsers(p=>!p)}>
                        <div>{form?.user?form.user.fullname:`By Anyone`}</div>
                        <img src={dropdown} alt=""/>
                    </div>
                    {showUsers && 
                    <div className='search-filter-dropdown'>
                    <div className='search-filter-dropdown-item' onClick={()=>handleSelect("user","")}>None</div>
                        {searchList?.users?.map(item=><div className='search-filter-dropdown-item' onClick={()=>handleSelect("user",item)}>{item?.fullname}</div>)}                       
                    </div>
                   }
                </div>
           
                {window.innerWidth>800 && <div className='search-filter search-thirda' style={{marginTop: '12px'}}>
                    <div className='search-filter-input' onClick={()=>setShowProjects(p=>!p)}>
                        <div>{form?.project?form.project.project_name:`Everywhere`}</div>
                        <img src={dropdown} alt=""/>
                    </div>
                    {showProjects && 
                    <div className='search-filter-dropdown'>
                        <div className='search-filter-dropdown-item' onClick={()=>handleSelect("project","")}>None</div>
                        {searchList?.projects?.map(item=><div className='search-filter-dropdown-item' onClick={()=>handleSelect("project",item)}>{item?.project_name}</div>)}
                       
                    </div>
                   }
                </div>}
            </div>
            {window.innerWidth<=800 && <div className='search-filter search-thirdb'>
                    <div className='search-filter-input' onClick={()=>setShowProjects(p=>!p)}>
                        <div>{form?.project?form.project.project_name:`Search project`}</div>
                        <img src={dropdown} alt=""/>
                    </div>
                    {showProjects && 
                    <div className='search-filter-dropdown'>
                        <div className='search-filter-dropdown-item' onClick={()=>handleSelect("project",null)}>None</div>
                        {searchList?.projects?.map(item=><div className='search-filter-dropdown-item' onClick={()=>handleSelect("project",item)}>{item.project_name}</div>)}
                       
                    </div>
                   }
                </div>}
        </div>
        <div className='search-result-resp'>
        {searchResult?.component=='none'  &&
        <>
        <div style={{padding:'25px'}}>
            <div className='search-result-heading'>Review files:</div>
        <div className='search-reviewfiles'>
        {searchResult?.data?.reviewfiles?.length==0 && <div className='search-result-heading'>No results found</div>}
            {searchResult?.data?.reviewfile?.map(item=>
            <Link to={`/review-file/${item?.imageparent}`} className='search-reviewfile'>
                <img src={item?.file} alt="review"/>
                <div className='search-reviewfile-name'>Name :{item?.name}</div>
                <div className='search-reviewfile-version'>Version {item?.versionnum}</div>
            </Link>)}
        </div>
        </div>
        <div style={{padding:'25px'}}>
            <div className='search-result-heading'>Completed files:</div>
            {searchResult?.data?.completedfiles?.length==0 && <div className='search-result-heading'>No results found</div>}
        <div className='search-reviewfiles'>
            {searchResult?.data?.completedfiles?.map(item=>
            <Link to={`/completed-file/${item?.imageparent}`} className='search-reviewfile'>
                <img src={item?.file} alt="review"/>
                <div className='search-reviewfile-name'>{item?.name}</div>
                <div className='search-reviewfile-version'>Version {item?.versionnum}</div>
            </Link>)}
        </div>
        </div>
        <div style={{padding:'25px'}}>
            <div className='search-result-heading'>Docs & Files:</div>
        <div className='search-reviewfiles'>
            {searchResult?.data?.docs?.dropbox?.length==0 && searchResult?.data?.docs?.local_file?.length==0 && <div className='search-result-heading'>No results found</div>}
        {searchResult?.data?.docs?.dropbox?.map((item,index)=><Link to={item?.link} className="document-board-file">
                <img src={item?.icon?item.icon:defaultFile} alt="empty"/>
                <div className="document-board-filename">{item?.name?item.name:getFileName(item?.link)}</div></Link>)}
        {searchResult?.data?.docs?.local_file?.map((item,index)=><Link to={item?.File} className="document-board-file">
                <img src={item?.icon?item.icon:defaultFile} alt="empty"/>
                <div className="document-board-filename">{item?.name?item.name:getFileName(item?.File)}</div></Link>)}
        </div>
        </div>
        <div style={{padding:'25px'}}>
            <div className='search-result-heading'>Tasks to do:</div>
            {searchResult?.data?.todo?.length==0 && <div className='search-result-heading'>No results found</div>}
         {searchResult?.data?.todo?.map(item=> <div className="task">
         <div className="task-content">
                        <div className={`${item.is_done?'task-content-done':''}`}>
                            <Moment format="ddd , MMM DD">{item?.due_date}</Moment>
                            <div>{item?.Notes}</div>
                        </div>
                    </div>
                    <input type="checkbox" checked={item?.is_done} onClick={()=>dispatch(markTaskDone(item?.id,{"is_done":!item.is_done},true))}/>
         </div>)}
        </div>
        <div style={{padding:'25px'}}>
            <div className='search-result-heading'>Message Board:</div>
        <div className='search-messages'>
        {searchResult?.data?.messageboard?.length==0 && <div className='search-result-heading'>No results found</div>}
            {searchResult?.data?.messageboard?.map((item,index)=><div className="message-block-item message-title-block" key={`message-block-${index}`}>
        <div className="person-img">
            {item?.created_by?.avatar?<img src={item?.created_by.avatar} alt="profile-img"/>:
            <span className="person-img-default">{`${item?.created_by?.fullName?.split(" ")[0].charAt(0)} ${item?.created_by?.fullName?.split(" ")[1].charAt(0)}`} </span>
            }
         </div>
           <div className="message-block-item-content">
           <div className="message-block-item-content-header">
                <div className="message-block-item-title">{`${item?.category?`${item?.category} of `:''}`} {item?.project?.project_name}</div>
                {/* <div className="message-block-item-time"><Moment format="LT">{item.created_at}</Moment></div> */}
                </div>
                <div className="message-block-item-message">
                <span style={{textTransform:'capitalize'}}>{item?.title} by </span>
                   <span style={{textTransform:'capitalize'}}>{`${item?.created_by?.fullName}`}</span> • <Moment format="h:mma , MMM DD" tz={profile?.timezone}>{item?.created_at}</Moment> — 
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} children={item?.message} /></div>
           </div>
        </div>)}
        </div>
        </div>
        </>}
        {searchResult?.component=='taskstodo'  &&
        <div style={{padding:'25px'}}>
            <div className='search-result-heading'>Tasks to do:</div>
            {searchResult?.data?.length==0 && <div className='search-result-heading'>No results found</div>}
         {searchResult?.data?.map(item=> <div className="task">
         <div className="task-content">
                        <div className={`${item.is_done?'task-content-done':''}`}>
                            <Moment format="ddd , MMM DD">{item?.due_date}</Moment>
                            <div>{item?.Notes}</div>
                        </div>
                    </div>
                    <input type="checkbox" checked={item?.is_done} onClick={()=>dispatch(markTaskDone(item?.id,{"is_done":!item.is_done},true))}/>
         </div>)}
        </div>}
        {searchResult?.component=='reviewfile'  &&
        <div style={{padding:'25px'}}>
            <div className='search-result-heading'>Review files:</div>
        <div className='search-reviewfiles'>
        {searchResult?.data?.length==0 && <div className='search-result-heading'>No results found</div>}
            {searchResult?.data?.map(item=>
            <Link to={`/review-file/${item?.imageparent}`} className='search-reviewfile'>
                <img src={item.file} alt="review"/>
                <div className='search-reviewfile-name'>Name :{item?.name}</div>
                <div className='search-reviewfile-version'>Version {item?.versionnum}</div>
            </Link>)}
        </div>
        </div>}
        {searchResult?.component=='completedfile'  &&
        <div style={{padding:'25px'}}>
            <div className='search-result-heading'>Completed files:</div>
            {searchResult?.data?.length==0 && <div className='search-result-heading'>No results found</div>}
        <div className='search-reviewfiles'>
            {searchResult?.data?.map(item=>
            <Link to={`/completed-file/${item?.imageparent}`} className='search-reviewfile'>
                <img src={item?.file} alt="review"/>
                <div className='search-reviewfile-name'>{item?.name}</div>
                <div className='search-reviewfile-version'>Version {item?.versionnum}</div>
            </Link>)}
        </div>
        </div>}
        {searchResult?.component=='docs'&&
        <div style={{padding:'25px'}}>
            <div className='search-result-heading'>Docs & Files:</div>
        <div className='search-reviewfiles'>
            {searchResult?.data?.dropbox?.length==0 && searchResult?.data?.local_file?.length==0 && <div className='search-result-heading'>No results found</div>}
        {searchResult?.data?.dropbox?.map((item,index)=><Link to={item?.link} className="document-board-file">
                <img src={item?.icon?item.icon:defaultFile} alt="empty"/>
                <div className="document-board-filename">{item?.name?item.name:getFileName(item?.link)}</div></Link>)}
        {searchResult?.data?.local_file?.map((item,index)=><Link to={item?.File} className="document-board-file">
                <img src={item?.icon?item.icon:defaultFile} alt="empty"/>
                <div className="document-board-filename">{item?.name?item.name:getFileName(item?.File)}</div></Link>)}
        </div>
        </div>}
        {searchResult?.component=='messageboard' &&
        <div style={{padding:'25px'}}>
            <div className='search-result-heading'>Message Board:</div>
        <div className='search-messages'>
        {searchResult?.data?.length==0 && <div className='search-result-heading'>No results found</div>}
            {searchResult?.data?.map((item,index)=><div className="message-block-item message-title-block" key={`message-block-${index}`}>
        <div className="person-img">
            {item?.created_by?.avatar?<img src={item?.created_by.avatar} alt="profile-img"/>:
            <span className="person-img-default">{`${item?.created_by.fullName?.split(" ")[0].charAt(0)} ${item?.created_by.fullName?.split(" ")[1].charAt(0)}`} </span>
            }
         </div>
           <div className="message-block-item-content">
           <div className="message-block-item-content-header">
                <div className="message-block-item-title">{`${item?.category?`${item?.category} of `:''}`} {item?.project?.project_name}</div>
                {/* <div className="message-block-item-time"><Moment format="LT">{item.created_at}</Moment></div> */}
                </div>
                <div className="message-block-item-message">
                <span style={{textTransform:'capitalize'}}>{item?.title} by </span>
                   <span style={{textTransform:'capitalize'}}>{`${item?.created_by?.fullName}`}</span> • <Moment format="h:mma , MMM DD" tz={profile?.timezone}>{item?.created_at}</Moment> — 
                    <ReactMarkdown rehypePlugins={[rehypeRaw]} children={item?.message} /></div>
           </div>
        </div>)}
        </div>
        </div>}
        </div>
        </div>
    )
}
export default SearchComponent