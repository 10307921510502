import React,{useEffect,useState} from "react";
import { useParams,useNavigate } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import plus from "../../../assets/icons/plus.svg";
import sortIcon from "../../../assets/icons/sort.svg";
import Calendar from 'react-calendar';
import moment from "moment";
import arrowLeft from "../../../assets/icons/arrow-left.svg";
import * as actionType from "../../../helpers/constants"
import { getUserProject,getAdminProject,getToDoPeople,getToDo,getToDoDates,markTaskDone} from "../../../actions"
import TaskToDoModal from "./TaskToDoModal";

const TasksToDo=()=>{
    const {id}=useParams();
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const profile=useSelector(state=>state.profile)
    const project=useSelector(state=>state.project)
    const prodTeam=sessionStorage.getItem("prodTeam")
    const tasks=useSelector(state=>state.todo)
    const todo_dates=useSelector(state=>state.todo_dates)
    const isLoading=useSelector(state=>state.isLoading)
    const [showSort,setShowSort]=useState(false)
    const [date,setDate] = useState(new Date());
    const [openModal,setOpenModal]=useState(false)
    const closeSortMenu=()=>{
        setShowSort(false);
    }
    const selectSort=(type)=>{
        dispatch(getToDo(id,moment(date).format('YYYY-MM-DD'),type))
      closeSortMenu()
    }
    document.addEventListener('click',(e)=>{
        if(!e.target.closest('.sort-options') && !e.target.closest('.sort-action'))
        closeSortMenu()
    })
    const handleOpenModal=()=>setOpenModal(true)
    const handleCloseModal=()=>setOpenModal(false)
    useEffect(()=>{
        if(id)
        {
            dispatch(getToDoDates(id))
         if(profile.user_type==='superadmin')
          dispatch(getAdminProject(id))
          else
         dispatch(getUserProject(id))
         dispatch(getToDoPeople(id))
         dispatch({type:actionType.SET_CURRENT_TODO_DATE,payload:moment(date).format('YYYY-MM-DD')})
         dispatch(getToDo(id,moment(date).format('YYYY-MM-DD')))
        }
         
     },[dispatch,id])
     if(!isLoading && project)
    return (
    <div className={`${profile?.user_type==='superadmin'||profile?.user_type==='pm'||profile?.user_type==='accountant'&&!prodTeam?'admin-project-dashboard':''}`}>
    <div className='dashboard-project-header'>
    <button style={{justifySelf:'flex-start',marginRight : 'auto'}} onClick={()=>navigate(-1)} className='btn btn-green-outline' >
        <img src={arrowLeft} alt="back"/>
        <span>Back</span>
    </button>
    <div className='dashboard-project-header-content'>
        <div className='dashboard-project-content-1'>{project.project_name}</div>
    </div>
    <div></div>
    </div> 
    <div className="task-board">
       <div className="board-header">
       <button onClick={handleOpenModal} className={`btn btn-green-fill ${prodTeam && 'btn-hide'}`} >
         Add    
        </button>
        <TaskToDoModal projectID={id} openModal={openModal} handleCloseModal={handleCloseModal} />
        <div className="board-title">
            <div className="board-title-main">To-Dos</div>
            <div className="board-title-sub">Make lists of work that needs to get done, assign items, set due dates.</div>
        </div>
        <div className="sort">
            <div className="sort-action" onClick={()=>setShowSort(p=>!p)}>
                <img src={sortIcon} alt="sort"/>
                <span>Sort</span>
            </div>
            <div className={`sort-options ${showSort?'':'hide'}`}>
                <div className="sort-option" onClick={()=>selectSort('created_at')}>Sort by created</div>
            </div>
        </div>
       </div>
       <div className="task-board-content">
       <Calendar 
       onChange={(d)=>{
        setDate(d);
        dispatch({type:actionType.SET_CURRENT_TODO_DATE,payload:moment(d).format('YYYY-MM-DD')})
        dispatch(getToDo(id,moment(d).format('YYYY-MM-DD')))
       }} 
       value={date} 
       defaultActiveStartDate={new Date()}
       tileClassName={({ date, view }) => {
        if(todo_dates.find(x=>x===moment(date).format("YYYY-MM-DD"))){
         return  'highlight-date'
        }
      }}
       />
       <div className="task-schedule">
         <div className="task-schedule-header">{tasks.length>0?'List of assingnments and scheduled tasks':'Nothing’s on the schedule'}</div>
         <div className="task-schedule-content">
            {tasks.length==0? <div className="task-empty-schedule">Select a date and add task</div>:
            <div className="task-schedule-list">
                {tasks.map(item=><div className="task">
                    <div className="task-content">
                        <div className={`${item.is_done?'task-content-done':''}`}>{item.Notes}</div>
                    </div>
                    <input type="checkbox" checked={item.is_done} onClick={()=>dispatch(markTaskDone(item.id,{"is_done":!item.is_done}))}/>
                </div>)}
            </div>
            }
           
         </div>
       </div>
       </div>
    </div> 
    </div>
    )
}
export default TasksToDo;