import React from 'react';
import { useState } from 'react';
import crossClose from '../assets/icons/cross-close.svg';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect } from 'react';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const DescriptionModal = ({showDescriptionModal, handleCloseDescriptionModal, handleSubmitDescription}) => {
    let editorState = EditorState.createEmpty();
    const [description, setDescription] = useState(editorState);
    const onEditorStateChange = (editorState) => {
        setDescription(editorState);
    }
    const handleSubmitDesc = () => {
        const desc = document.getElementsByTagName('textarea')[0].value;
        handleSubmitDescription(desc)
        localStorage.removeItem('description')
        handleCloseDescriptionModal()
    }
    const handleSaveDraft = () => {
        localStorage.setItem('description', JSON.stringify(convertToRaw(description.getCurrentContent())))
        handleCloseDescriptionModal()
    }
    useEffect(() => {
        console.log('editorState', description.getCurrentContent())
        const desc = localStorage.getItem('description')
        if(desc) {
            setDescription(EditorState.createWithContent(convertFromRaw(JSON.parse(desc))))
        }
    }, [])
    return (
        <>
            {
                showDescriptionModal && (
                <div className='modal'>
                    <div className='modal-content modal-content-description'>
                        <img src={crossClose} alt="close" onClick={()=>handleCloseDescriptionModal()} className="modal-close" />
                       
                            <div className='modal-link-header'>Description</div>
                        
                        <div className='modal-link-body'>
                            <div className='modal-description'>
                                <Editor
                                    editorState={description}
                                    toolbarClassName="toolbarClassName"
                                    wrapperClassName="wrapperClassName"
                                    editorClassName="editorClassName"
                                    onEditorStateChange={onEditorStateChange}
                                    toolbarStyle={{ background: "rgba(255, 255, 255, 0.14)", border: "none" }}
                                    placeholder="type your description here..."
                                    toolbar={{
                                        link: {options: ['link', 'unlink']}
                                    }}
                                />
                                <textarea className='asd' style={{display:'none'}} disabled value={draftToHtml(convertToRaw(description.getCurrentContent())) } />
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button onClick={handleSubmitDesc} className='btn btn-green-fill'>Add Description</button>
                            <button onClick={handleSaveDraft} className='btn btn-green-outline'>Save as draft</button>
                        </div>
                    </div>
                </div>
                )
            }
        </>
    );
};

export default DescriptionModal;