import React,{useState,useEffect} from 'react';
import { useNavigate,useParams } from "react-router-dom";
import arrowLeft from "../../assets/icons/arrow-left.svg";
import DownloadIcon from '../../assets/icons/download-icon';
import arrowDownGreen from '../../assets/icons/arrow-down-green.svg';
import arrowUpGreen from '../../assets/icons/arrow-up-green.svg';
import Payment from './Payment/payment';
import Invoice from './Invoice';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useSelector,useDispatch } from 'react-redux';
import { getUserProject } from '../../actions';
import { Document, Page,pdfjs } from "react-pdf";
import ChequePayment from './Payment/cheque';
pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const PaymentPage=()=>{
    const navigate=useNavigate();
    const dispatch=useDispatch()
    const { id } = useParams();
    console.log(id)
    const [showInvoice,setShowInvoice]=useState(false);
    const [showPaymentModal,setShowPaymentModal]=useState(false);
    const [numInvoicePages, setNumInvoicePages] = useState(null);
    const [showChequeModal, setShowChequeModal] = useState(false);
    const project=useSelector(state=>state.project);
    const isLoading=useSelector(state=>state.isLoading)
    
    document.addEventListener('click',(e)=>{
        if(e.target.classList.contains("payment-modal-bg"))
           setShowPaymentModal(false)
    })
    useEffect(()=>{
     if(id){
        
        dispatch(getUserProject(id))
     }
    },[dispatch,id])
    
    return(
        <div>
            {!isLoading && project && <>
                {showPaymentModal && <Payment project={project} setShowPaymentModal={setShowPaymentModal} setShowChequeModal={setShowChequeModal}/>}
            <div className='payment-page-header'>
            <button style={{justifySelf:'flex-start'}} onClick={()=>navigate(-1)} className='btn btn-green-outline' >
                    <img src={arrowLeft} alt="back"/>
                            <span>Back</span>
                </button>
                <div className='dashboard-page-title'>Project Details</div>
                <div></div>
                {/* <button className='btn btn-green-fill'>Assign PM <DownloadIcon fill="white"/></button> */}
            </div>
            <div className='payment-history'>
                <div className='payment-history-item'>
                    <div>Project name</div>
                    <div>{project.project_name}</div>
                </div>
                <div className='payment-history-item'>
                    <div>Company name</div>
                    <div>{project.company_name}</div>
                </div>
                <div className='payment-history-item'>
                    <div>Client name</div>
                    <div>Priya Soni</div>
                </div>
                <div className='payment-history-item'>
                    <div>Email address</div>
                    <div>{project.email}</div>
                </div>
                <div className='payment-history-item'>
                    <div>Mobile number</div>
                    <div>{project.mobile_number}</div>
                </div>
                <div className='payment-history-item'>
                    <div>Project ID</div>
                    <div>{project.id}</div>
                </div>
                <div className='payment-history-item'>
                    <div>Service type</div>
                    <div>{project.service_type}</div>
                </div>
                <div className='payment-history-item'>
                    <div>Project type</div>
                    <div>{project.project_type}</div>
                </div>
                <div className='payment-history-item'>
                    <div>Quantity of service</div>
                    <div>{project.quantity}</div>
                </div>
                <div className='payment-history-item'>
                    <div>Payment segment</div>
                    <div>${project.amount_to_be_paid}</div>
                </div>
                <button className='btn btn-green-fill' onClick={()=>setShowPaymentModal(true)}>Continue and pay</button>
                {project.invoice_doc && 
                <div className='show-invoice'>
                    <div className='show-invoice-header'>
                        <div>Invoice</div>
                        {
                            showInvoice ? <img src={arrowUpGreen} alt="invoice" onClick={()=>setShowInvoice(false)}/> : <img src={arrowDownGreen} alt="invoice" onClick={()=>setShowInvoice(true)}/>
                        }
                    </div>
                    <div>
                        {
                            showInvoice && <Document
                            file={project.invoice_doc}
                            onLoadSuccess={({ numPages })=>setNumInvoicePages(numPages)}
                        >
                            {Array.apply(null, Array(numInvoicePages))
                            .map((x, i)=>i+1)
                            .map(page => <Page pageNumber={page}/>)}
                        </Document>
                        }
                       
                    </div>
                </div>
                 }
            </div>
            </>}
            
            {
                showChequeModal && <ChequePayment setShowChequeModal={setShowChequeModal} showChequeModal={setShowChequeModal} />
            }

        </div>
    )
}
export default PaymentPage;