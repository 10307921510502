import React,{useState} from "react";
import { useDispatch,useSelector } from "react-redux";
import { Link,useNavigate } from "react-router-dom";
import OTPInput from "otp-input-react";
import bg4 from "../../../assets/images/bg-4.png";
import arrowRight from "../../../assets/icons/arrow-right.svg";
import warning from "../../../assets/icons/warning.svg";
import logo from "../../../assets/images/logo.png";
import bgLogo from "../../../assets/icons/bg-logo.svg";
import { verifyOtp,sendOtp } from "../../../actions";
import * as actionType from "../../../helpers/constants";
import Notification from "../../notification";
import { Carousel } from "react-responsive-carousel";

const SignUpOtp=({next,back,handleFormData,form})=>{
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const images=useSelector(state=>state.auth_images)
  const filteredImageUrls = Object.entries(images)
  .filter(([key, value]) => value !== null)
  .map(([key, value]) => ({ [key]: value }));
    const otp_token=useSelector(state=>state.otp_token)
    const message=useSelector(state=>state.message)
    const [OTP, setOTP] = useState("");
    const [err,setErr]=useState(null);
    const handleSubmit=(e)=>{
        e.preventDefault();
        if(OTP.length==6)
        {
            const formData={"country_code":"+91",
            "isLogin":false,
            "mobile_no": form.mobile_number,
            "secret": otp_token,
            "otp": OTP};
            
           dispatch(verifyOtp(formData,next,4));
          
         
        }else{
            setErr("Please fill 6 digits OTP")
        }
    }
    const handleSendOtp=()=>{
        //console.log(form)
        dispatch(sendOtp({
                "isLogin":false,
                "country_code":form.country_code,
                "mobile_no":form.mobile_number
        }))
    }
    return (<div className="auth-page">
    <div className="auth-background">
        <div className="auth-overlay">
        <div className="auth-content">
                    <div>
                        <Link to="/" className="logo-link"><img src={bgLogo} alt="logo"/><p className="logo-cgi">CGI</p></Link>
                    </div>
                    <div className="auth-content-text">MEMBERS PORTAL</div>
                    {/* <div className="overlay-highlight">JOIN MEDIUM</div>
                    <div className="overlay-text">ARCHITECTURE +<br/>INTERIOR DESIGN</div> */}
                </div>
        </div>
        {filteredImageUrls?.length>0 &&
        <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true}  showArrows={false} showStatus={false}>
            {
               filteredImageUrls.map((e,i)=>(    
                <div key={`image-${i}`}>
                    <img src={Object.values(e)} className="bg-img" alt="carousel"/>
                </div>
               ))
            }
            </Carousel>}
    </div>
    <div className="form-bg">
    <div className="default-header">
            <Link to="/" className="logo-link">
                <img className="bgLogo" src={bgLogo} alt="logo"/>
                <p className="logo-cgi">CGI</p>
                </Link>
              <div className="members-portal">MEMBERS PORTAL</div>
            </div>
        <form className="auth-form">
            <div className="form-heading" style={{alignSelf: 'start'}}>OTP Verification</div>
            <div className="form-subheading">Enter OTP sent to your number</div>
            <div className="auth-form-content">
            <div className="form-input-box">
              <div className="form-label">OTP</div>
              <OTPInput className="otp-input" value={OTP} onChange={setOTP} autoFocus OTPLength={6} otpType="number" disabled={false}  />
              {err && <div className="input-error">
                    <img src={warning} alt="opt warning"/>
                    <span>{err}</span>
                    </div>}
            </div>
            
            <div className="form-toggle" style={{marginBottom : "35px"}}> 
                <span style={{color:'#D6D6D6'}}>Didn’t receive the OTP? </span>
                <span className="highlight" onClick={handleSendOtp}>Resend</span>
            </div>
            <button className={`btn btn-${OTP.length!==6?'grey':'green-fill'} login-btn-resp`} onClick={handleSubmit}> <span>Continue</span><img src={arrowRight} alt="next"/></button>
            </div>
            {message && <Notification type={message.type} message={message.message}/>}
        </form>
    </div>
       
    
</div>)
}
export default SignUpOtp;