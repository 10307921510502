import React,{useEffect,useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getQuotes } from "../../actions";
import SearchIcon from "../../assets/icons/search-icon";
import sortIcon from "../../assets/icons/sort.svg";
import Moment from 'react-moment';
import "../../assets/css/Admin/quotes.css"
const Quotes=()=>{
    const dispatch=useDispatch();
    const isAuth=useSelector(state=>state.isAuth)
    const projects=useSelector(state=>state.quotes)
    const profile=useSelector(state=>state.profile)
    const [activeSearch,setActiveSearch]=useState(false)
    const [showSort,setShowSort]=useState(false)
    const closeSortMenu=()=>{
        setShowSort(false);
    }
    const selectSort=(type)=>{
        dispatch(getQuotes(type))
      closeSortMenu()
    }
    document.addEventListener('click',(e)=>{
        if(!e.target.closest('.sort-options') && !e.target.closest('.sort-action'))
        closeSortMenu()
    })
  useEffect(()=>{
    dispatch(getQuotes())
  },[dispatch])
  
    return (
        <div className="admin-quotes">
            <div className="admin-quotes-header">
            <form className="search-form">
            <SearchIcon fill={activeSearch?'#D6D6D6':'#909090'}/>
            <input onFocus={()=>setActiveSearch(true)} onBlur={()=>setActiveSearch(false)} placeholder="Search"/>
            </form>
            <div className="quotes-title">
                <div className="admin-page-title">
                    <div>Quotes</div>
                </div>
            </div>
            <div className="sort sorting-section" style={{marginLeft : "auto"}}>
                <div className="sort-action" onClick={()=>setShowSort(p=>!p)}>
                <img src={sortIcon} alt="sort"/>
                <span>Sort</span>
                </div>
                <div className={`sort-options ${showSort?'':'hide'}`}>
                    <div className="sort-option" onClick={()=>selectSort('project_name')}>Sort by name</div>
                    <div className="sort-option" onClick={()=>selectSort('created_at')}>Sort by date</div>
                </div>
            </div>
        </div>
            
            <div className="quotes-content">
                <table className="quotes-table">
                    <tr>
                        <td>Created on</td>
                        <td >Projects</td>
                        <td>Company name</td>
                        <td>Client name</td>
                        <td>Details</td>
                        <td style={{borderBottom:'1px solid transparent'}}></td>
                    </tr>
                    {projects?.map((project,index)=><tr key={`q-${index}`}>
                        <td><Moment format="MMMM DD, YYYY">{project.created_at}</Moment> | <Moment format="LT" tz={profile.timezone}>{project.created_at}</Moment></td>
                        <td>{project?.project_name}</td>
                        <td>{project.company_name? project.company_name:'--'}</td>
                        <td>{project.your_name}</td>
                        <td><Link to={`${project?.id}`} className="quote-project-view">View</Link></td>
                        <td>{project.newtag &&<span className="quotes-new">New</span>}</td>
                    </tr>)}
                </table>
            </div>
        </div>
    )
}
export default Quotes