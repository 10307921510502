import React,{useState} from 'react'
import Moment from 'react-moment';
const CommentsBar=({markers})=>{
    const [showReply,setShowReply]=useState(null)
    return (
<div className="version-comment-list">
    {markers.map((marker,index)=>
        <div className="review-message">
            <div className="review-message-header">
            <div className="comment-message-block-profile-img">
                    {marker.user.avatar?<img src={marker.user.avatar} alt="avatar"/>:
            <span className="comment-message-block-profile-img-default">{`${marker.user.fullName.split(" ")[0].charAt(0)} ${marker.user.fullName.split(" ")[1].charAt(0)}`} </span>
}
                    </div>
                    <div className="review-message-block-profile">
                        <div className="review-message-block-main">{marker.user.fullName}</div>
                        <Moment className="review-message-block-sub"format='h:mm a,MMMM DD YYYY'>{marker.created_at}</Moment>
                     </div>
                     <div className="comment-pin" >
                <div className="comment-pin-inner comment-pin-active">{index+1}</div>
            </div>
            </div>
            <div className='review-message-text'>{marker.text}</div>
            {marker.replies.length>0 &&
            <div className='review-reply-show-text' onClick={()=>setShowReply(p=>{
                if(p==marker.id)
                return null
                else
                return marker.id
            })}>{marker.replies.length} {marker.replies.length==1?'reply':'replies'}</div>}
            <div className='review-message-replies'>
            {showReply==marker.id && marker.replies.map(item=>
                <div className='review-message-reply'>
                    <div className="review-message-header">
                <div className="comment-message-block-profile-img">
                        {item.user.avatar?<img src={item.user.avatar} alt="avatar"/>:
                <span className="comment-message-block-profile-img-default">{`${item.user.fullName.split(" ")[0].charAt(0)} ${item.user.fullName.split(" ")[1].charAt(0)}`} </span>
    }
                        </div>
                        <div className="review-message-block-profile">
                            <div className="review-message-block-main">{item.user.fullName}</div>
                            <Moment className="review-message-block-sub"format='h:mm a,MMMM DD YYYY'>{item.created_at}</Moment>
                         </div>
                </div>
                <div className='review-message-text'>{item.text}</div>
                
                    </div>)}
            </div>
        </div>)}
</div>
    )
}
export default CommentsBar