import React,{useEffect,useRef,useState} from 'react'
import { useDispatch,useSelector } from "react-redux"
import arrowLeft from "../../assets/icons/arrow-left.svg";
import { useNavigate, useParams } from "react-router-dom"
import WebViewer from '@pdftron/webviewer'
import { updateProposal,getUserProject } from '../../actions';
import notify from "../../helpers/notify"
const SignProposal=()=>{
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const {id}=useParams()
    const project=useSelector(state=>state.project)
    const profile=useSelector(state=>state.profile)
    
    const [instance, setInstance] = useState(null);
    const [documentViewer, setDocumentViewer] = useState(null);
    const [annotationManager, setAnnotationManager] = useState(null);
    const [Annotations,setAnnotations]=useState(null)
    const [annotPosition, setAnnotPosition] = useState(0);
    const viewer = useRef(null);
    if(project)
      {
        if(project.signed_doc_url)
        documentViewer?.loadDocument(project.signed_doc_url)
        else
        documentViewer?.loadDocument(project.proposal_doc)
      }
      
    const nextField =async () => {
        let annots = annotationManager.getAnnotationsList().filter(
          (annot) => annot instanceof Annotations.WidgetAnnotation,
      );
      let c=0,m=0
      annotationManager.getAnnotationsList().forEach(i=>{
        let t={...i}
        if(t.Subject=='Widget')
          c++
          if(t.Subject=='Signature')
          { console.log(t.iT)
            m++}
      })
      //console.log(c,m)
        if (annots[annotPosition] ) {
          //console.log(annots[annotPosition])
          annotationManager.jumpToAnnotation(annots[annotPosition]);
          if (annots[annotPosition+1]) {
            setAnnotPosition(annotPosition+1);
          }
        }
      }
      const prevField = () => {
        let annots = annotationManager.getAnnotationsList().filter(
          (annot) => annot instanceof Annotations.SignatureWidgetAnnotation,
      );
        if (annots[annotPosition]) {
          annotationManager.jumpToAnnotation(annots[annotPosition]);
          if (annots[annotPosition-1]) {
            setAnnotPosition(annotPosition-1);
          }
        }
      }
      const completeSigning = async () => {
        //instance.UI.downloadPdf(true);
        //annotationManager.getAnnotationsList().forEach(i=>{console.log(i)})
        let totalFields=0
        annotationManager.getAnnotationsList().forEach(i=>{
          if(i.Subject==='Widget')
             totalFields++
        })
        annotationManager.getAnnotationsList().forEach(i=>{
          if(i.Subject==='Signature' || (i.Jm=='date' && i.ud!=='m-d-yyyy') || i.ud)
             totalFields--
        })
        if(totalFields==0)
          {
            const doc = documentViewer.getDocument();
            const xfdfString = await annotationManager.exportAnnotations({ widgets: true, fields: true,generateInlineAppearances:true });
            const data = await doc.getFileData({ xfdfString });
            const arr = new Uint8Array(data);
            const blob = new Blob([arr], { type: 'application/pdf' });
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              var base64String = reader.result;
            dispatch(updateProposal({"project_id":project.id,"pdf_blob":base64String.split(",")[1]},navigate))
            
            }
           }
        else
          notify("error","Please fill all fields")
       
      //  console.log(blob)

      }
      
    useEffect(() => {
      dispatch(getUserProject(id))
        WebViewer(
            {
              path: '../../../lib',
              disabledElements: [
                'ribbons',
                'toggleNotesButton',
                'searchButton',
                'menuButton',
                'rubberStampToolGroupButton',
                'stampToolGroupButton',
                'fileAttachmentToolGroupButton',
                'calloutToolGroupButton',
                'undo',
                'redo',
                'eraserToolButton'
              ],
            },
            viewer.current,
          ).then(async instance => {
            setInstance(instance);
            const { documentViewer, annotationManager, Annotations,PDFNet } = instance.Core;
           setDocumentViewer(documentViewer)
            //console.log(Annotations.getAssociatedSignatureAnnotation())
            setAnnotationManager(annotationManager);
             setAnnotations(Annotations)
            // select only the insert group
            instance.UI.setToolbarGroup('toolbarGroup-Insert');
      
            // load document
            //const storageRef = storage.ref();
            //const URL = await storageRef.child(docRef).getDownloadURL();
           
            //documentViewer.loadDocument(projects[index].proposal_doc);
            
            const normalStyles = (widget) => {
              if (widget instanceof Annotations.TextWidgetAnnotation) {
                return {
                  'background-color': '#a5c7ff',
                  'font-size':'22px',
                  'width':'150px',
                  'height':'30px',
                  'text-align':'center',
                  'padding':'5px',
                  color: 'white',
                };
              } else if (widget instanceof Annotations.SignatureWidgetAnnotation) {
               
                return {
                  border: 'none',
                };
              }
            };
            // annotationManager.addEventListener('annotationChanged',async ()=>{
            
            // })
            annotationManager.addEventListener('annotationChanged',async (annotations, action, { imported }) => {
              
                if (imported && action === 'add') {
                 
                  annotations.forEach(function(annot) {
                    if (annot instanceof Annotations.WidgetAnnotation) {
                      Annotations.WidgetAnnotation.getCustomStyles = normalStyles;
                    //   if (!annot.fieldName.startsWith(email)) {
                    //     annot.Hidden = true;
                    //     annot.Listable = false;
                    //   }
                    }
                  });
                }
              });
              
            });      
    },[dispatch])
  return (
    <div>
         <button style={{justifySelf:'flex-start'}} onClick={()=>navigate(-1)} className='btn btn-green-outline' >
        <img src={arrowLeft} alt="back"/>
        <span>Back</span>
    </button>
   <div style={{display:'flex',gap:'15px',justifyContent:'center',margin:'10px 0'}}>
   {/* <button className="btn btn-green-fill" onClick={nextField} >Next field</button>
    <button className="btn btn-green-fill" onClick={prevField}>Previous field</button> */}
    <button className="btn btn-green-fill" onClick={completeSigning}>Complete signing</button>
   </div>
    <div className="ppl-proposal ">
    
    <div className="webviewer" ref={viewer} style={{height:'100vh'}}></div>   
        </div>
        </div>
  )
}
export default SignProposal