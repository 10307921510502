import React from "react";
import "../../../assets/css/auth.css";
import logo from "../../../assets/images/logo.png";
import { useState } from "react";

const EndModal = ({ setEndModal }) => {
  return (
    <div className="privacyModal">
      <div className="privacyDiv">
        <div className="privacyCross" onClick={() => setEndModal(false)}>
          <p>x</p>
        </div>
        <div className="privacyContent">
          <div className="privacyLogo">
            <img src={logo} alt="logo" />
            <p>VISUALIZING UNBUILT ARCHITECTURE + DESIGN</p>
          </div>
          <div className="privacyHeading">
            END USER AGREEMENT AND PRIVACY POLICY
          </div>
          <div className="privacySubHeading">
            This End User Agreement ("Agreement") is entered into between
            PanoramCGI ("Company," "we," "our," or "us") and you ("User" or
            "you"). <br /> <br /> By using PanoramCGI's portal app ("App"), you
            agree to the terms and conditions of this Agreement. If you do not
            agree with these terms, please do not use the App.
          </div>
          <div className="privacyPoint">
            <div className="privacyPointHeading">1. License and Use</div>
            <div className="privacyPointText">
              1.1. License Grant: We grant you a non-exclusive,
              non-transferable, revocable license to usethe App for your
              personal or business purposes, subject to the terms of this
              Agreement.
              <br />
              <br />
              1.2. Restrictions: You may not:
              <li>Copy, modify, distribute, or reverse engineer the App;</li>
              <li>Use the App for illegal or unauthorized purposes;</li>
              <li>Use the App to infringe upon intellectual property rights</li>
              <li>
                Remove or obscure any copyright, trademark, or other proprietary
                notices fromthe App;
              </li>
              <li>Share your account credentials with others.</li>
            </div>
          </div>
          <div className="privacyPoint">
            <div className="privacyPointHeading">2. Privacy Policy</div>
            <div className="privacyPointText">
              2.1. Collection of Personal Information: We may collect personal
              information as described inour Privacy Policy. By using the App,
              you consent to our collection and use of your personal information
              as outlined in the Privacy Policy.
              <br />
              <br />
              2.2. Privacy Policy: Our Privacy Policy, which is an integral part
              of this Agreement. Please review it to understand how we collect,
              use, and protect your information.
            </div>
          </div>
          <div className="privacyPoint">
            <div className="privacyPointHeading">3. Termination</div>
            <div className="privacyPointText">
              3.1. Termination by Us: We reserve the right to terminate your
              access to the App at our solediscretion, with or without notice,
              for any reason, including but not limited to a violation of this
              Agreement.
              <br />
              <br />
              3.2. Termination by You: You may terminate this Agreement by
              discontinuing your use of theApp.
            </div>
          </div>
          <div className="privacyPoint">
            <div className="privacyPointHeading">
              4. Disclaimer of Warranties
            </div>
            <div className="privacyPointText">
              4.1. No Warranty: The App is provided "as-is" and "as available"
              without any warranties, whether expressed or implied. We do not
              guarantee the accuracy, completeness, or reliabilityof the App.
            </div>
          </div>
          <div className="privacyPoint">
            <div className="privacyPointHeading">
              5. Limitation of Liability
            </div>
            <div className="privacyPointText">
              5.1. Limitation of Liability: To the extent permitted by law, we
              shall not be liable for any direct, indirect, incidental, special,
              or consequential damages resulting from your use or inability
              touse the App.
            </div>
          </div>
          <div className="privacyPoint">
            <div className="privacyPointHeading">6. Changes to Agreement</div>
            <div className="privacyPointText">
              6.1. Amendment: We reserve the right to modify this Agreement at
              any time. Changes will beeffective upon posting on the App. Your
              continued use of the App after such changes constitutes your
              acceptance of the modified Agreement.
            </div>
          </div>
          <div className="privacyPoint">
            <div className="privacyPointHeading">7. Governing Law</div>
            <div className="privacyPointText">
              7.1. Governing Law: This Agreement is governed by and construed in
              accordance with the laws of the United States of America.
            </div>
          </div>
          <div className="privacyPoint">
            <div className="privacyPointHeading">8. Contact Information</div>
            <div className="privacyPointText">
              8.1. Contact: If you have any questions or concerns about this
              Agreement or the App, pleaseemail us at{" "}
              <span style={{ fontWeight: "bolder" }}>info@panoramcgi.com</span>.
            </div>
          </div>
          <div className="privacyHeading">Privacy Policy</div>
          <div className="privacyPoint">
            <div className="privacyPointHeading">1. Introduction</div>
            <div className="privacyPointText">
              PanoramCGI ("Company," "we," "our," or "us") is committed to
              protecting the privacy of its users ("User" or "you"). This
              Privacy Policy describes how we collect, use, disclose, and
              safeguard your personal information when you use our portal app
              ("App").
            </div>
          </div>
          <div className="privacyPoint">
            <div className="privacyPointHeading">2. Information We Collect</div>
            <div className="privacyPointText">
              2.1. Information You Provide: We may collect personal information
              that you voluntarily provide when using the App, including but not
              limited to:
              <br />
              <br />
              <li>
                Contact information (e.g., name, email address, phone number)
              </li>
              <li>User-generated content (e.g., comments</li>
              <li>Account credentials (e.g., username, password)</li>
              <br />
              2.2. Automatically Collected Information: When you use the App, we
              may automatically collect certain information, including:
              <li>
                Device information (e.g., IP address, device type, operating
                system)
              </li>
              <li>Usage data (e.g., App usage patterns, interactions)</li>
              <li>Location data (if you grant us permission)</li>
            </div>
          </div>
          <div className="privacyPoint">
            <div className="privacyPointHeading">
              3. How We Use Your Information
            </div>
            <div className="privacyPointText">
              We may use your personal information for various purposes,
              including but not limited to:
              <br />
              <br />
              <li>Providing and improving the App</li>
              <li>Communicating with you</li>
              <li>Responding to your inquiries and requests</li>
              <li>Analyzing usage patterns and trends</li>
              <li>Complying with legal obligations</li>
            </div>
          </div>
          <div className="privacyPoint">
            <div className="privacyPointHeading">
              4. Cookies and Similar Technologies
            </div>
            <div className="privacyPointText">
              We may use cookies and similar technologies to collect information
              and enhance your experience. You can manage your cookie
              preferences through your browser settings.
            </div>
          </div>
          <div className="privacyPoint">
            <div className="privacyPointHeading">
              5. Disclosure of Your Information
            </div>
            <div className="privacyPointText">
              We may disclose your personal information to third parties in the
              following circumstances:
              <br />
              <br />
              <li>With your consent</li>
              <li>To service providers and vendors assisting us</li>
              <li>To comply with legal obligations</li>
              <li>In connection with a merger, sale, or asset transfer</li>
            </div>
          </div>
          <div className="privacyPoint">
            <div className="privacyPointHeading">6. Data Security</div>
            <div className="privacyPointText">
              We implement reasonable security measures to protect your personal
              information fromunauthorized access, disclosure, or alteration.
              However, no data transmission over the internet is entirely
              secure, and we cannot guarantee the security of your information.
            </div>
          </div>
          <div className="privacyPoint">
            <div className="privacyPointHeading">7. Your Choices</div>
            <div className="privacyPointText">
              You may have certain rights regarding your personal information,
              including:
              <br />
              <br />
              <li>Accessing, correcting, or deleting your data</li>
              <li>Opting out of certain communications</li>
            </div>
          </div>
          <div className="privacyPoint">
            <div className="privacyPointHeading">
              8. Changes to this Privacy Policy
            </div>
            <div className="privacyPointText">
              We may update this Privacy Policy from time to time. Any changes
              will be posted on the App. Your continued use of the App after
              such changes constitutes your acceptance of the revisedPrivacy
              Policy.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EndModal;
