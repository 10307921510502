import React,{useEffect, useState,useRef} from "react";
import { useDispatch,useSelector } from "react-redux";
import { useNavigate,useParams } from "react-router-dom";
import arrowLeft from "../../../assets/icons/arrow-left.svg";
import arrowDown from "../../../assets/icons/arrow-down.svg";
import plus from "../../../assets/icons/plus.svg";
import uploadIcon from "../../../assets/icons/upload-icon.svg";
import { getAdminProject,downloadPdf } from "../../../actions";
import ClientTab from "./client";
import PaymentTab from "./payment";
import ProposalTab from "./proposal";
import DownloadIcon from "../../../assets/icons/download-icon";
import AssignUser from "./assignUser";
import { uploadProjectProposal,getAllUsers } from "../../../actions";

const ProjectionList=()=>{
    const proposalInput=useRef()
    const navigate=useNavigate();
    const dispatch=useDispatch()
    const { id } = useParams();
    const [tabSelected,setTabSelected]=useState('client')
    const [assignUser,setAssignUser]=useState()
    const [reupload,setReupload]=useState(false)
    const isAuth=useSelector(state=>state.isAuth)
    const project=useSelector(state=>state.project)
    const isLoading=useSelector(state=>state.isLoading)
    const filePicker = useRef(null);
    const openFileDialog=() =>{
        proposalInput.current.click()
      }
    const handleUpload=(form)=>{
        console.log(form)
         dispatch(uploadProjectProposal(form))
    }
    useEffect(()=>{
        if(id)
        {
         dispatch(getAdminProject(id,isAuth.access))
         dispatch(getAllUsers())
        }
     },[dispatch,id])
    return(<div className="project-ppl">
        {assignUser && <AssignUser assignUser={assignUser} setAssignUser={setAssignUser} projectId={project.id}/>}
        <div className='project-projection-header admin-quotes-header'>
            <button style={{justifySelf:'flex-start'}} onClick={()=>navigate(-1)} className='back-btn btn btn-green-outline' >
                <img src={arrowLeft} alt="back"/>
                        <span>Back</span>
                    </button>
                    <div className='admin-title'>
                    <div className='admin-page-title project-projection-title'>Project Projection List</div>
                    <div className={`status ${project?.project_status}-status project-projection-status`}>
                    <span>Status</span>
                    <span>:</span>
                    <span style={{textTransform:'capitalize'}}>{project?.project_status}</span>
                </div>
                    </div>
                    <div className='quote-top-buttons'>
                        {tabSelected==='client' && <>
                        <button className='btn btn-green-fill' onClick={()=>setAssignUser('pm')}>Assign PM<img src={plus} alt="add"/></button>
                        <button className='btn btn-green-fill' onClick={()=>setAssignUser('admin')}>Assign Administrator <img src={plus} alt="add"/></button>
                        </>}
                        {tabSelected==='proposal' && project.proposal_doc && <>
                    <input ref={proposalInput} className="proposal-input-file" type='file' hidden onChange={handleUpload}/>
                    <button className='btn btn-green-fill' onClick={()=>dispatch(downloadPdf(project.proposal_doc))}>Download PDF <DownloadIcon fill="white"/></button>
                    {/* <button className='btn btn-green-fill' onClick={()=>filePicker.current.click()}>Re-upload <img src={uploadIcon} alt="add"/></button> */}
                    </>}
                    {tabSelected==='payment' && <div></div>}
            </div>
                    
            </div>
            <div className="ppl-tabs">
                <div className={`tab ${tabSelected==='client'?'tab-active':''}`} onClick={()=>setTabSelected('client')}>Client details</div>
                <div className={`tab ${tabSelected==='proposal'?'tab-active':''}`}  onClick={()=>setTabSelected('proposal')}>Proposal</div>
                <div className={`tab ${tabSelected==='payment'?'tab-active':''}`}  onClick={()=>setTabSelected('payment')}>Payment and invoice</div>
            </div>
            <input ref={filePicker} className="proposal-input-file" type='file' hidden/>
            {!isLoading && tabSelected==='client' && project && <ClientTab project={project}/>}
            {!isLoading && tabSelected==='proposal' && <ProposalTab project={project} handleUpload={handleUpload} filePicker={filePicker} getAdminProject={getAdminProject} projectId={id}/>}
            {!isLoading && tabSelected==='payment' && project && <PaymentTab project={project}/>}
    </div>)
}
export default ProjectionList;