import React,{useState} from "react";
import paypalImg from "../../../assets/images/paypal-img.png";
import chequeImg from "../../../assets/images/cheque-img.png";
import cardImg from "../../../assets/images/card-img.png";
import quickbookImg from "../../../assets/images/quickbook-img.png";
import PaypalPayment from "./paypal";
import QuickbookPayment from "./quickbook";
import CardPayment from "./card";
import ChequePayment from "./cheque";
const Payment=({project,setShowPaymentModal,setShowChequeModal})=>{
    const [paymentMode,setPaymentMode]=useState('paypal')
    const [checkoutType,setCheckoutType]=useState(null);
    const handlePaymentMode=(e)=>{
        setPaymentMode(e.target.value)
    }
    const handlePayment=(e)=>{
     e.preventDefault()
     setCheckoutType(paymentMode)
     console.log(paymentMode)
    }
    return (
    <div className="payment-modal-bg">
        <div className="payment-modal">
           <div className="payment-modal-header">Payment</div>
           
           {!checkoutType && 
           <div className="payment-content">
           <div className="payment-summary">
             <div className="payment-summary-main">
                <div className="payment-title">Project total amount</div>
                <div className="payment-amount">${project.total_amount}</div>
             </div>
             <div className="payment-summary-sub">
             <div className="payment-title">Amount to be paid</div>
                <div className="payment-amount">${project.amount_to_be_paid}</div>
             </div>
           </div>
           <div className="payment-options-box">
            <div className="payment-options-heading">How would you like to pay?</div>
            <div className="payment-options">
              <div className="payment-option">
                <input type="radio" id="paypal-otpion" name="payment-mode" value="paypal" onChange={handlePaymentMode} checked={paymentMode==='paypal'}/>
                <label htmlFor="paypal-otpion">
                   <span>PayPal</span>
                   <img src={paypalImg} alt="paypal"/>
                </label>
              </div>
              <div className="payment-option">
                <input type="radio" id="quickbook-otpion" name="payment-mode" value="quickbook"  onChange={handlePaymentMode} checked={paymentMode==='quickbook'}/>
                <label htmlFor="quickbook-otpion">
                   <span>QuickBooks</span>
                   <img src={quickbookImg} alt="quickbook"/>
                </label>
              </div>
              <div className="payment-option">
                <input type="radio" id="card-otpion" name="payment-mode" value="card"  onChange={handlePaymentMode} checked={paymentMode==='card'}/>
                <label htmlFor="card-otpion">
                   <span>Bankwire</span>
                   <img src={cardImg} alt="card"/>
                </label>
              </div>
              <div className="payment-option">
                <input type="radio" id="cheque-otpion" name="payment-mode" value="cheque"  onChange={handlePaymentMode} checked={paymentMode==='cheque'}/>
                <label htmlFor="cheque-otpion">
                   <span>Mail Check</span>
                   <img src={chequeImg} alt="card"/>
                </label>
              </div>
            </div>

           </div>
           <button className="btn btn-green-fill" onClick={handlePayment}>Pay now</button>
           </div>
           }
           {checkoutType==='paypal' && <PaypalPayment project={project} setShowPaymentModal={setShowPaymentModal}/>}
           {checkoutType==='quickbook' && <QuickbookPayment project={project} setShowPaymentModal={setShowPaymentModal}/>}
           {checkoutType==='cheque' && <>
            {setShowPaymentModal(false)}
            {setShowChequeModal(true)}
           <ChequePayment/></> }
           {checkoutType==='card' && <CardPayment setShowPaymentModal={setShowPaymentModal}/>}
        </div>
    </div>)
}
export default Payment;