import React,{useEffect} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { getUserActivity } from '../../actions';
import emptyBox from '../../assets/images/empty-box.svg';
import Moment from 'react-moment';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import defaultFile from "../../assets/icons/default-file.svg"
import rehypeRaw from 'rehype-raw';
const Activity = () => {
    const dispatch=useDispatch()
    const activity=useSelector(state=>state.activity)
    const isLoading=useSelector(state=>state.isLoading)
    const profile=useSelector(state=>state.profile)
    const getFileName=(link)=>{
        let temp=link.split("/")
        return temp[temp.length-1]
    }
    useEffect(()=>{
      dispatch(getUserActivity())
    },dispatch)
    if(!isLoading)
    return (
        <div>
          
    <div className='activity-board'>
        <div className='activity-board-header'>Your Activity</div>
        
        {activity.length>0 ?
        <div className='activity-blocks'>
            {activity.map((item,index)=>
                 <div key={`activity-${index}`} className={`activity-block ${index%2==0?'':'activity-block-right'}`}>
                 <div className='activity-date'>
                         {item.date.split("-")[1]}/{item.date.split("-")[2]}/{item.date.split("-")[0]}
                 </div>
                 <div className={`activity-list activity-list-${index%2==0?'left':'right'}`}>
                    {item.data.map(act=>
                        <div className='activity-item'>
                        <div className="person-img">
        {act.user.avatar?<img src={act.user.avatar} alt="avatar"/>:
            <span className="person-img-default">{`${act.user.fullName.split(" ")[0].charAt(0)} ${act.user.fullName.split(" ")[1].charAt(0)}`} </span>
}
        </div>
                        <div className='activity-content'>
                        <div className='activity-item-header'>
                                <div  className='activity-item-profile'>
                                    <div className='activity-item-name'>{act.user.fullName} ,</div>
                                    <div className='activity-item-role'>{act.project.project_name}</div>
                                </div>
                                <Moment format="LT" tz={profile.timezone} className='activity-item-time'>{act.created_at}</Moment>
                            </div>
                            <div className='activity-text'>
                            <ReactMarkdown rehypePlugins={[rehypeRaw]} disallowedElements={['li']} children={act?.content} />
                            </div>
                            {act?.image_url && <img className='activity-image' src={act?.image_url} alt=""/>}
                            {act?.doc_url && <a className='activity-doc-image' href={act?.doc_url} target='__blank'>
                                <img className='' src={defaultFile} alt="defaultFileImage" />
                                <p>{getFileName(act?.doc_url)}</p>
                            </a> }
                        </div>
                        </div>)}
                 </div>
             </div>)}
       
        </div>:
        <div className='empty-box'>
        <div className="dialogue-box-activity">
            <div className="dialogue-box-activity-heading">
            Nothing's happened yet!
            </div>
            <div className="dialogue-box-activity-text">
            But as soon as your project gets accepted, every activity that's happening in your project will be summarized for you right here.
            </div>
        </div>
        <img src={emptyBox} alt="empty" />
    </div>
      }
    </div>
    

        </div>
    );
};

export default Activity;