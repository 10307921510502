import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import edit from "../../../assets/icons/edit.svg";
import share from "../../../assets/icons/share.svg";
import deleteIcon from "../../../assets/icons/delete.svg";
import saveIcon from "../../../assets/icons/save-icon.svg";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Select from "react-select";
import { serviceOptions } from "../../../helpers/constants";
import "../../../assets/css/Admin/ppl.css";
import dropdown from "../../../assets/icons/dropdown.svg";
import backArrow from "../../../assets/icons/back-arrow.svg";
import shareIcon from "../../../assets/icons/share.svg";
import {
  getAdminProjects,
  editAdminPPL,
  getAdminCancelledProjects,
  getAdminPendingProjects,
  getAdminCompletedProjects,
  getAdminOngoingProjects,
  getNotes,
  getAllUsers,
} from "../../../actions";
import ConfirmDeleteProjectModal from "./confirmDeleteProject";
import GeneralNotesModal from "./generalNotesModal";
const initialForm = {
  project_name: "",
  quantity: null,
  assigned_pm: "edited new pm",
  assigned_admin: "edited admin",
  service_type: "new one",
  assign_artist: "new artist",
  email: "",
  your_name: "",
};
const Ppl = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuth = useSelector((state) => state.isAuth);
  const projects = useSelector((state) => state.projects);
  const all_users = useSelector((state) => state.all_users);
  const [active, setActive] = useState("all");
  const [form, setForm] = useState(initialForm);
  const [editMode, setEditMode] = useState(false);
  const [editID, setEditID] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);
  const [generalNotes, setGeneralNotes] = useState(null);
  const handleEditMode = () => {
    if (editMode) {
      setEditID(null);
      setEditMode(false);
    } else {
      setEditMode(true);
    }
  };
  const handleEditTable = (project) => {
    setEditID(project.id);
    setForm({
      project_name: project.project_name,
      quantity: project.quantity,
      assigned_pm: project.assigned_pm,
      assigned_admin: project.assigned_admin,
      service_type: project.service_type,
      assign_artist: project.artist,
      email: project.email,
      your_name: project.your_name,
    });
    document.querySelectorAll(".edit-ppl-icon").forEach((item) => {
      item.classList.add("hide");
    });
  };
  const saveEditTable = () => {
    document.querySelectorAll(".edit-ppl-icon").forEach((item) => {
      item.classList.remove("hide");
    });
    dispatch(
      editAdminPPL(
        {
          ...form,
          assigned_pm: form.assigned_pm.map((i) => i.id),
          assigned_admin: form.assigned_admin.map((i) => i.id),
        },
        editID,
        isAuth.access
      )
    );
    setEditID(null);
  };
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "quantity") setForm({ ...form, [name]: Number(value) });
    else setForm({ ...form, [name]: value });
  };
  const handleGeneralNotes = (id) => {
    dispatch(getNotes(id));
    setGeneralNotes(id);
  };

  useEffect(() => {
    if (active === "incomplete") dispatch(getAdminOngoingProjects());
    else if (active === "complete") dispatch(getAdminCompletedProjects());
    else if (active === "rejected") dispatch(getAdminCancelledProjects());
    else if (active === "pending") dispatch(getAdminPendingProjects());
    else dispatch(getAdminProjects());
    dispatch(getAllUsers());
  }, [dispatch, active]);
  return (
    <div className="ppl-page">
      {deleteItem !== null && (
        <ConfirmDeleteProjectModal
          deleteItem={deleteItem}
          setDeleteItem={setDeleteItem}
        />
      )}
      {generalNotes !== null && (
        <GeneralNotesModal
          generalNotes={generalNotes}
          setGeneralNotes={setGeneralNotes}
        />
      )}
      <div className="admin-ppl-header">
       {window.innerWidth<=1000 && <button style={{ justifySelf: 'flex-start' }} onClick={() => navigate(-1)} className='back-btn btn btn-green-outline btn-resp' >
          <img src={backArrow} alt="back" />
          <span>Back</span>
        </button>}
        <div className="ppl-title">
          <div className="admin-page-title">Project Projection List</div>
        </div>

        <div className="ppl-top-buttons">
          <button
            className={`btn btn-${editMode ? "grey" : "green-fill"}`}
            onClick={handleEditMode}
          >
            Edit <img src={edit} alt="edit" />
          </button>
          <button className="btn btn-green-fill">{window.innerWidth<=1000?<>Share<img src={shareIcon} alt="share_icon"/></>:'Export excel'}</button>
        </div>
      </div>
      <div className="home-actions">
        <div className="home-tabs">
          <div
            onClick={() => {
              setActive("all");
            }}
            className={`tab ${active === "all" ? "tab-active" : ""}`}
          >
            All projects
          </div>
          <div
            onClick={() => {
              setActive("incomplete");
            }}
            className={`tab ${active === "incomplete" ? "tab-active" : ""}`}
          >
            Work in progress
          </div>
          <div
            onClick={() => {
              setActive("complete");
            }}
            className={`tab ${active === "complete" ? "tab-active" : ""}`}
          >
            Completed projects
          </div>
          <div
            onClick={() => {
              setActive("pending");
            }}
            className={`tab ${active === "pending" ? "tab-active" : ""}`}
          >
            Pending projects
          </div>
          <div
            onClick={() => {
              setActive("rejected");
            }}
            className={`tab ${active === "rejected" ? "tab-active" : ""}`}
          >
            Rejected projects
          </div>
        </div>
      </div>

      <div className="ppl-body">
        <div className="ppl-content">
          <table className="ppl-table">
            <thead>
              <tr>
                <th className="first-column-ppl">Projects</th>
                <th>Status</th>
                <th>Type of services</th>
                <th>Client contact info</th>
                <th>3D artist assigned</th>
                <th>Quantity of visuals</th>
                <th>Assigned PM</th>
                <th>Assigned administrator</th>
                <th>General notes</th>
                {editMode && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {projects?.map((project, idx) => {
                if (editID === project.id)
                  return (
                    <tr key={idx} className={`project-data-${project.id}`}>
                      <td>
                        <div className="first-column">
                          <input
                            name="project_name"
                            onChange={handleChange}
                            className="form-input"
                            value={form.project_name}
                          />
                          {editID === project.id && (
                            <img
                              style={{ marginLeft: "20px" }}
                              onClick={saveEditTable}
                              src={saveIcon}
                              alt="save edit"
                            />
                          )}
                        </div>
                      </td>
                      <td>
                        <div
                          className={`ppl-tag ${project?.project_status}-bg`}
                        >
                          {project?.project_status}
                        </div>
                      </td>
                      <td style={{ maxWidth: "330px" }}>
                        <Select
                          name="service_type"
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: "rgba(217, 217, 217, 0.12)",
                              border: "none",
                              borderRadius: "0px",
                              boxShadow: "none",
                              height: "43px",
                            }),
                            dropdownIndicator: (provided, state) => ({
                              ...provided,
                              display: "none",
                            }),
                            option: (styles, { data }) => ({
                              ...styles,
                              color: "black",
                            }),
                            indicatorSeparator: (provided, state) => ({
                              ...provided,
                              display: "none",
                            }),
                            valueContainer: (provided, state) => ({
                              ...provided,
                              overflowY: "scroll",
                              maxHeight: "43px",
                            }),
                          }}
                          components={{
                            DropdownIndicator: () => {
                              return (
                                <img
                                  style={{ paddingRight: "18px" }}
                                  src={dropdown}
                                  alt="service type"
                                />
                              );
                            },
                          }}
                          placeholder="Select service type"
                          isMulti
                          defaultValue={form.service_type
                            ?.split(",")
                            .map((i) => {
                              return { value: i, label: i };
                            })}
                          onChange={(e) => {
                            let v = e.map((i) => i.value).join(",");
                            setForm({ ...form, service_type: v });
                          }}
                          options={serviceOptions}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </td>
                      <td>
                        <input
                          name="your_name"
                          onChange={handleChange}
                          className="form-input"
                          value={form.your_name}
                        />
                        <br />
                        <input
                          name="email"
                          type="email"
                          onChange={handleChange}
                          className="form-input"
                          value={form.email}
                        />
                      </td>
                      <td>
                        <input
                          name="assign_artist"
                          onChange={handleChange}
                          className="form-input"
                          value={form.assign_artist}
                        />
                      </td>
                      <td>
                        <input
                          name="quantity"
                          onChange={handleChange}
                          className="form-input"
                          value={form.quantity}
                        />
                      </td>
                      <td>
                        <Select
                          name="assigned_pm"
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: "rgba(217, 217, 217, 0.12)",
                              border: "none",
                              borderRadius: "0px",
                              boxShadow: "none",
                              height: "43px",
                            }),
                            dropdownIndicator: (provided, state) => ({
                              ...provided,
                              display: "none",
                            }),
                            option: (styles, { data }) => ({
                              ...styles,
                              color: "black",
                            }),
                            indicatorSeparator: (provided, state) => ({
                              ...provided,
                              display: "none",
                            }),
                            valueContainer: (provided, state) => ({
                              ...provided,
                              overflowY: "scroll",
                              maxHeight: "43px",
                            }),
                          }}
                          components={{
                            DropdownIndicator: () => {
                              return (
                                <img
                                  style={{ padding: "0 18px" }}
                                  src={dropdown}
                                  alt="arrow-down"
                                />
                              );
                            },
                          }}
                          placeholder="Select PM"
                          isMulti
                          defaultValue={form.assigned_pm.map((i) => {
                            return { value: i, label: i.fullName };
                          })}
                          onChange={(e) => {
                            let v = e.map((i) => i.value);
                            setForm({ ...form, assigned_pm: v });
                          }}
                          options={all_users.map((i) => {
                            return { label: i.fullName, value: i };
                          })}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </td>
                      <td>
                        <Select
                          name="assigned_admin"
                          styles={{
                            control: (provided, state) => ({
                              ...provided,
                              backgroundColor: "rgba(217, 217, 217, 0.12)",
                              border: "none",
                              borderRadius: "0px",
                              boxShadow: "none",
                              height: "43px",
                            }),
                            dropdownIndicator: (provided, state) => ({
                              ...provided,
                              display: "none",
                            }),
                            option: (styles, { data }) => ({
                              ...styles,
                              color: "black",
                            }),
                            indicatorSeparator: (provided, state) => ({
                              ...provided,
                              display: "none",
                            }),
                            valueContainer: (provided, state) => ({
                              ...provided,
                              overflowY: "scroll",
                              maxHeight: "43px",
                            }),
                          }}
                          components={{
                            DropdownIndicator: () => {
                              return (
                                <img
                                  style={{ padding: "0 18px" }}
                                  src={dropdown}
                                  alt="arrow-down"
                                />
                              );
                            },
                          }}
                          placeholder="Select Admin"
                          isMulti
                          defaultValue={form.assigned_admin.map((i) => {
                            return { value: i, label: i.fullName };
                          })}
                          onChange={(e) => {
                            let v = e.map((i) => i.value);
                            setForm({ ...form, assigned_admin: v });
                          }}
                          options={all_users.map((i) => {
                            return { label: i.fullName, value: i };
                          })}
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      </td>
                      {/* <td><input name="assigned_pm" onChange={handleChange} className="form-input" value={form.assigned_pm}  /></td> */}
                      {/* <td><input name="assigned_admin" onChange={handleChange} className="form-input" value={form.assigned_admin}  /></td> */}
                      <td>
                        <span
                          className="view"
                          onClick={() => handleGeneralNotes(project.id)}
                        >
                          Open
                        </span>
                      </td>
                    </tr>
                  );
                else
                  return (
                    <tr key={idx} className={`project-data-${project.id}`}>
                      <td>
                        <div className="first-column">
                          <Link
                            to={`/ppl-project/${project.id}`}
                            className="select-project"
                          >
                            {project?.project_name}
                          </Link>
                          {editMode && (
                            <img
                              className="edit-ppl-icon"
                              onClick={() => handleEditTable(project)}
                              src={edit}
                              alt="edit"
                            />
                          )}
                          {editID === project.id && (
                            <img
                              onClick={saveEditTable}
                              src={saveIcon}
                              alt="edit"
                            />
                          )}
                        </div>
                      </td>
                      <td>
                        <div
                          className={`tag ppl-tag ${project?.project_status}-bg`}
                          style={{ marginLeft: "0px" }}
                        >
                          {project?.project_status}
                        </div>
                      </td>
                      <td style={{ maxWidth: "150px", wordBreak: "break-all" }}>
                        {project?.service_type ? project.service_type : "--"}
                      </td>
                      <td>
                        <span>{project?.your_name}</span>
                        <br />
                        <span>{project?.email}</span>
                      </td>
                      <td>
                        {project.artist
                          ? project.artist
                          : "--"}
                      </td>
                      <td>{project.quantity}</td>
                      <td className="ppl-col-max">
                        {project.assigned_pm
                          ? project.assigned_pm.map((i) => i.fullName).join(",")
                          : "--"}
                      </td>
                      <td className="ppl-col-max">
                        {project.assigned_admin
                          ? project.assigned_admin
                            .map((i) => i.fullName)
                            .join(",")
                          : "--"}
                      </td>
                      <td>
                        <span
                          className="view"
                          onClick={() => handleGeneralNotes(project.id)}
                        >
                          Open
                        </span>
                      </td>
                      {editMode && (
                        <td>
                          <img
                            style={{ cursor: "pointer" }}
                            src={deleteIcon}
                            onClick={() => setDeleteItem(project)}
                            alt="delete"
                          />
                        </td>
                      )}
                    </tr>
                  );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Ppl;
