import React,{useEffect,useState,useRef,useMemo,useCallback} from "react"
import plus from '../../../assets/icons/plus.svg';
import RemoveIcon from '../../../assets/icons/remove-icon';
import SendIcon from "../../../assets/icons/send-icon"
import { sendReply } from "../../../actions";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useDispatch, useSelector } from "react-redux";

const MessageReply=({messageID})=>{
    const inputFile=useRef()
    const all_users=useSelector(state=>state.all_users)
    let editorState = EditorState.createEmpty();
    const [description, setDescription] = useState(editorState);
    const dispatch=useDispatch()
    const [tagged,setTagged] = useState([])
    const initialForm={
        "message_ref":messageID,
        "reply":"",
        "attachment":[], 
        "tagged_users":tagged
    }
    const [form,setForm]=useState(initialForm)
    const handleRemoveFile=(id)=>{
        let f=form?.attachment?.filter((f,index)=>index!==id)
        setForm({...form,attachment:[...f]})
      }
    const handleInputFiles=(e)=>{
        console.log(e.target.files)
        setForm({...form,attachment:[...form?.attachment,...e.target.files]})
    }
    const submitReply=(e)=>{
        console.log(form)
        if(form?.reply!="")
        {
            let newForm=new FormData()
            newForm.append("message_ref",form?.message_ref)
            newForm.append("reply",form?.reply)
            for(let i=0;i<form?.attachment?.length;i++) 
                newForm.append("attachment",form?.attachment[i])
              
            newForm.append("tagged_users",form?.tagged_users?.join(","))
            dispatch(sendReply(form?.message_ref,newForm))
            setForm(initialForm)
            setDescription(EditorState.createEmpty())
        }
        

    }
    
   
    const onEditorStateChange = (editorState) => {
        
        setDescription(editorState);
        setForm({...form,reply:draftToHtml(convertToRaw(description.getCurrentContent()))})
    }
    const [hoverRemove,setHoverRemove]=useState('');
    
    return (
        <div className="reply-box">
            <Editor
                    editorState={description}
                    toolbarClassName="reply-toolbar"
                    wrapperClassName="wrapperClassName"
                    editorClassName="reply-editor"
                    onEditorStateChange={onEditorStateChange}
                    toolbarStyle={{ background: "rgba(255, 255, 255, 0.14)", border: "none" }}
                    
                    mention={{
                      separator: ' ',
                      trigger: '@',
                      suggestions:all_users?.map(item=>{
                        return {
                            text:<div onClick={()=>setForm({...form,tagged_users:form?.tagged_users?.push(item?.id)})} style={{display:'flex',alignItems:'center',gap:'10px',cursor : "pointer"}}>
                            <div className="todo-search-profile search-profile">{item?.fullName?.split(" ")[0]?.charAt(0)}{item?.fullName?.split(" ")[1]?item?.fullName?.split(" ")[1]?.charAt(0):item?.fullName?.split(" ")[0]?.charAt(1)}</div>
                            <div >{item?.fullName}</div>
                          </div>,
                            value:item?.fullName,
                            url:item?.fullName
                          }
                      })
                     
                    }}
                />
            {/* <input className="reply-box-input" placeholder="Reply . . ." value={form.reply} onChange={(e)=>setForm({...form,"reply":e.target.value})}/> */}
            {form?.attachment?.length>0 &&  <div className='files-list'>
                                {
                                    form?.attachment?.length > 0 && (
                                        form?.attachment?.map((file, index) => 
                                        (     <div className='file-box' key={index}>
                                          <div className="file-remove" onMouseEnter={()=>setHoverRemove(index)} onMouseLeave={()=>setHoverRemove('')} onClick={()=>handleRemoveFile(index)} ><RemoveIcon opacity={hoverRemove===index?0.57:0.12}  fill={hoverRemove===index?'#333333':'white'}/></div>
                                                
                                                    <div className='file-item-description'>
                                                       {file?.name}
                                                    </div>
                                                
                                                </div>
                                            )
                                        )
                                    )
                                }
                            </div>}
            <div className="message-file-block">
                <div className="message-add-file" onClick={()=>inputFile?.current?.click()}>
                    <span>Add files</span>
                    <img className='circle-bg' src={plus} alt="upload file" />
                </div>
                <div onClick={submitReply} style={{cursor:'pointer'}}><SendIcon fill="white"/></div>
                
                
                <input ref={inputFile} className="message-add-computer-file" type="file" multiple hidden onChange={handleInputFiles}/>
            </div>
        </div>
    )
}
export default MessageReply