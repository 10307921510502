import React from 'react'
import "../../../assets/css/auth.css"
import logo from "../../../assets/images/logo.png"
import { useState } from 'react';

const PrivacyModal = ({setModal}) => {
    
  return (
    <div className='privacyModal'>
        <div className="privacyDiv">
        <div className="privacyCross">
            <p onClick={()=>setModal(false)}>x</p>
        </div>
          <div className="privacyContent">
            <div className="privacyLogo">
              <img src={logo} alt="logo" />
              <p>VISUALIZING UNBUILT ARCHITECTURE + DESIGN</p>
            </div>
            <div className="privacyHeading">
              Privacy Policy
            </div>
            <div className="privacyPoint">
              <div className="privacyPointHeading">
                1. Introduction
              </div>
              <div className="privacyPointText">
              PanoramCGI ("Company," "we," "our," or "us") is committed to protecting the privacy of its
users ("User" or "you"). This Privacy Policy describes how we collect, use, disclose, and
safeguard your personal information when you use our portal app ("App").
              </div>
            </div>
            <div className="privacyPoint">
              <div className="privacyPointHeading">
                2. Information We Collect
              </div>
              <div className="privacyPointText">
              2.1. Information You Provide: We may collect personal information that you voluntarily provide when using the App, including but not limited to:
              <br />
              <br />
              <li>Contact information (e.g., name, email address, phone number)</li>
              <li>User-generated content (e.g., comments</li>
              <li>Account credentials (e.g., username, password)</li>
              <br />
              2.2. Automatically Collected Information: When you use the App, we may automatically collect
              certain information, including:
              <li>Device information (e.g., IP address, device type, operating system)</li>
              <li>Usage data (e.g., App usage patterns, interactions)</li>
              <li>Location data (if you grant us permission)</li>
              </div>
            </div>
            <div className="privacyPoint">
              <div className="privacyPointHeading">
              3. How We Use Your Information
              </div>
              <div className="privacyPointText">
              We may use your personal information for various purposes, including but not limited to:
              <br />
              <br />
              <li>Providing and improving the App</li>
              <li>Communicating with you</li>
              <li>Responding to your inquiries and requests</li>
              <li>Analyzing usage patterns and trends</li>
              <li>Complying with legal obligations</li>
              </div>
            </div>
            <div className="privacyPoint">
              <div className="privacyPointHeading">
              4. Cookies and Similar Technologies
              </div>
              <div className="privacyPointText">
              We may use cookies and similar technologies to collect information and enhance your
              experience. You can manage your cookie preferences through your browser settings.
              </div>
            </div>
            <div className="privacyPoint">
              <div className="privacyPointHeading">
              5. Disclosure of Your Information
              </div>
              <div className="privacyPointText">
              We may disclose your personal information to third parties in the following circumstances:
              <br />
              <br />
              <li>With your consent</li>
              <li>To service providers and vendors assisting us</li>
              <li>To comply with legal obligations</li>
              <li>In connection with a merger, sale, or asset transfer</li>
              </div>
            </div>
            <div className="privacyPoint">
              <div className="privacyPointHeading">
              6. Data Security
              </div>
              <div className="privacyPointText">
              We implement reasonable security measures to protect your personal information fromunauthorized access, disclosure, or alteration. However, no data transmission over the internet is entirely secure, and we cannot guarantee the security of your information.
              </div>
            </div>
            <div className="privacyPoint">
              <div className="privacyPointHeading">
              7. Your Choices
              </div>
              <div className="privacyPointText">
              You may have certain rights regarding your personal information, including: 
              <br />
              <br />
              <li>Accessing, correcting, or deleting your data</li>
              <li>Opting out of certain communications</li>
              </div>
            </div>
            <div className="privacyPoint">
              <div className="privacyPointHeading">
              8. Changes to this Privacy Policy
              </div>
              <div className="privacyPointText">
              We may update this Privacy Policy from time to time. Any changes will be posted on the App. Your continued use of the App after such changes constitutes your acceptance of the revisedPrivacy Policy.
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default PrivacyModal