import React from 'react';

const MailInfoModal = ({showMailModal, setShowMailModal}) => {
    
    const handleClick = (e) => {
        if(e.target.classList.contains('modal') || e.target.classList.contains('modal-close')) {
            setShowMailModal(false);
        }
    }

    return (
        <>
            {
                showMailModal &&
                <div onClick={handleClick} className='modal'>
                    <div className='modal-content'>
                    <div className="modal-title">Mail Us</div>
                        <div className="modal-close" onClick={handleClick}>X</div>
                        <div className='mail-info' style={{lineHeight : "40px",fontSize : "20px"}}>
                            Please see the proposal that shows the address location to mail your check. <br />Please send an email to <a href="mailto:billing@panoramcgi.com">billing@panoramcgi.com</a>
                        </div>    
                    </div>
                </div>
            }
        </>
    );
};

export default MailInfoModal;