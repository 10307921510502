import React,{useEffect,useState} from "react"
import { useDispatch, useSelector } from "react-redux"
import editIcon from "../../../assets/icons/edit.svg"
import deleteIcon from "../../../assets/icons/delete.svg"
import plus from "../../../assets/icons/plus.svg"
import CrossIcon from "../../../assets/icons/cross-icon";
import saveIcon from "../../../assets/icons/save-icon.svg";
import { createMessageCategory,deleteMessageCategory,editMessageCategory} from "../../../actions"
const initialForm={
  "name":""
}
const CategoryList=({setShowCategoryList})=>{
  const dispatch=useDispatch()
  const categories=useSelector(state=>state.message_categories)
  console.log(categories)
  const [showAddCategory,setShowAddCategory]=useState(false)
  const [editId,setEditId]=useState(null)
  const [formCategory,setFormCategory]=useState(initialForm)
 
  const handleSaveCategory=()=>{
    console.log(formCategory,editId)
    if(formCategory.name!=""){
      if(editId)
       {dispatch(editMessageCategory(editId,formCategory));setEditId(null)}
      else
       dispatch(createMessageCategory(formCategory))
      setShowAddCategory(false)
      setFormCategory(initialForm)
    }
  }
  const handleCloseEditCategory=()=>{
    setShowAddCategory(false)
  }
  const handleEditCategory=(item)=>{
   setFormCategory({...formCategory,name:item.name})
   setShowAddCategory(true)
   setEditId(item.id)
  }
  const handleDeleteCategory=(id)=>{
   dispatch(deleteMessageCategory(id))
  }
 
  return (
    <div className='modal-popup title-list-modal'>
    <div className='modal-popup-content'>
        <div className="modal-header">
        <div className='modal-popup-content-title'>Message Board Categories</div>
        </div>
        {categories.map(item=><div className="message-title-item">
          <div className="message-title-item-name" >{item.name}</div>
          <img style={{cursor:'pointer'}} onClick={()=>handleEditCategory(item)} src={editIcon} alt="edit"/>
          <img style={{cursor:'pointer'}} onClick={()=>handleDeleteCategory(item.id)} src={deleteIcon} alt="delete"/>
        </div>)}
        <div className="add-title-box">
           
           {showAddCategory ? <div className="add-title-input">
            <input placeholder="Add title" value={formCategory.name} onChange={(e)=>setFormCategory({...formCategory,"name":e.target.value})}/>
            <img style={{cursor:'pointer'}} onClick={handleSaveCategory} src={saveIcon} alt="save"/>
            <div style={{cursor:'pointer'}} onClick={handleCloseEditCategory}><CrossIcon fill="white" height="15" /></div>
           </div>:
           <div className="add-title" onClick={()=>setShowAddCategory(true)}>
           <img src={plus} alt="add-title"/>
           <span>Add category</span>
        </div>}
           
        </div>
        <div className='modal-popup-content-actions'>
                
                <button className='btn btn-green-outline' onClick={()=>setShowCategoryList(false)}>Cancel</button>
            </div>
        
    </div>
</div>
  )
}
export default CategoryList;