import React, { useEffect, useState } from 'react';
import './../../assets/css/ProfileDetailsModal.css';
import logo from "../../assets/images/logo.png";
import profilePic from "../../assets/images/profile-pic.jpg";
import { useDispatch, useSelector } from 'react-redux';
import { getTimezones, updateProfile } from '../../actions';
import { API } from '../../helpers/constants';
import { getTime } from 'date-fns';

const ProfileDetailsModal = ({show, handleClose}) => {
    const isAuth = useSelector(state => state.isAuth);
    const profile=useSelector(state=>state.profile);
    const [fewTimeZones,setFewTimeZones] = useState([])
    console.log('profile: ', profile)
    const timezones = useSelector(state => state.timezones);
    console.log('timezones: ', timezones)
    const [initialForm, setInitialForm] = useState({
        fullName: profile?.fullName ? profile?.fullName : '',
        title_atcompany: profile?.title_atcompany ? profile?.title_atcompany : '',
        email: profile?.email ? profile?.email : '',
        mobile_number: profile?.mobile_number ? profile?.mobile_number : '',
        location: profile?.location ? profile?.location : '',
        bio: profile?.bio ? profile?.bio : '',
        timezone: profile?.timezone ? profile?.timezone : 'EST',
        first_day_week: profile?.first_day_week ? profile?.first_day_week : ''
    });
    const [avatar, setAvatar] = useState(profile?.avatar ? `${API}${profile.avatar}` : profilePic);
    const [image, setImage] = useState(null);
    const dispatch = useDispatch();

    const handleClick = (e) => {
        if(e.target.classList.contains('modal') || e.target.classList.contains('cross-btn')) {
            handleClose();
        }
    }

    useEffect(()=>{
        const result = timezones.filter(elem=>{
            if(!elem.startsWith("Africa") && !elem.startsWith("America") && !elem.startsWith("Antarctica") && !elem.startsWith("Australia")&& !elem.startsWith("Brazil")&& !elem.startsWith("Asia")&& !elem.startsWith("Atlantic")&& !elem.startsWith("Canada")&& !elem.startsWith("Europe")&& !elem.startsWith("Indian")&& !elem.startsWith("Pacific")){
                return elem
            }
        })

        setFewTimeZones(result)
    },[timezones])


    useEffect(() => {
        dispatch(getTimezones());
    }, [])

    useEffect(() => {
        setAvatar(profile?.avatar ? profile?.avatar : profilePic);
    }, [profile])

    const handleAvatarChange = () => {
        document.getElementById('changeAvatar').click();
    }

    const handleSetAvatar = (e) => {
        setImage(e.target.files[0]);
        setAvatar(URL.createObjectURL(e.target.files[0]));
    }

    const handleChange = (e) => {
        setInitialForm({...initialForm, [e.target.name]: e.target.value})
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        console.log('form: ', initialForm)
        const formData = new FormData();
        for (var key in initialForm) {
            formData.append(key, initialForm[key]);
        }
        if(image) {
            formData.append('avatar', image);
        }
        dispatch(updateProfile(profile.id, formData, isAuth.access));
        handleClose();
    }

    return (
        <>
            {
                show &&
                <div onClick={handleClick} className='modal'>
                <form onSubmit={handleFormSubmit} className='modal-content profile-content'>
                    <div>
                        {/* <img className='logo' src={logo} alt="logo" /> */}
                        <div className='avatar-top'>
                            <img className='avatar' src={avatar} alt="user" />
                            <div onClick={handleAvatarChange} className='change-avatar'>Change avatar</div>
                            <input onChange={(e)=>handleSetAvatar(e)} style={{display: 'none'}} id='changeAvatar' type="file" accept='image/*' />
                        <div className="cross-btn" onClick={handleClick}>
                            X
                        </div>
                        </div>
                    </div>
                    <div className='profile-modal'>
                        <div className='form-input-box'>
                            <label className='form-label' htmlFor='fullName'>Name</label>
                            <input onChange={handleChange} className='form-input profile-input' defaultValue={profile?.fullName} type='text' name='fullName' id='fullName'/>
                        </div>
                        <div className='form-input-box'>
                            <label className='form-label' htmlFor='title_atcompany'>Title at user's company</label>
                            <input onChange={handleChange} className='form-input profile-input' defaultValue={profile?.title_atcompany} placeholder='title' type='text' name='title_atcompany' id='title_atcompany'/>
                        </div>
                        <div className='form-input-box'>
                            <label className='form-label' htmlFor='email'>Email Address</label>
                            <input className='default-form-input profile-input' value={profile?.email} placeholder='email address' type='email' name='email' id='email'/>
                        </div>
                        <div className='form-input-box'>
                            <label className='form-label' htmlFor='mobile_number'>Mobile Number</label>
                            <input onChange={handleChange} className='form-input profile-input' defaultValue={profile?.mobile_number} placeholder='mobile number' type='tel' name='mobile_number' id='mobile_number'/>
                        </div>
                        <div className='form-input-box'>
                            <label className='form-label' htmlFor='location'>Location</label>
                            <input onChange={handleChange} className='form-input profile-input' defaultValue={profile?.location} placeholder='location' type='text' name='location' id='location'/>
                        </div>
                        <div className='form-input-box'>
                            <label className='form-label' htmlFor='bio'>Short bio/current status</label>
                            <input onChange={handleChange} className='form-input profile-input short-bio' defaultValue={profile?.bio} placeholder='short bio' name='bio' id='bio'/>
                        </div>
                        <div className='form-input-box'>
                            <label className='form-label' htmlFor='timezone'>Time zone</label>
                            <p className='input-info'>
                                We use your time zone setting for email notifications, event reminders, your profile, and your notification settings.
                            </p>
                            <select onChange={handleChange} className='form-input profile-input' defaultValue={profile?.timezone} name='timezone' id='timezone'>
                                <option value=''>Choose Time zone</option>
                                {
                                    fewTimeZones?.map((timezone, index) => (
                                        <option key={index} value={timezone}>{timezone}</option>
                                    ))
                                }
                                {/* <option value='(GMT-05:00) Eastern Time'>(GMT-05:00) Eastern Time</option> */}
                            </select>
                        </div>
                        <div className='form-input-box'>
                            <label className='form-label' htmlFor='first_day_week'>First day of week</label>
                            {/* <p className='input-info'>
                                Basecamp uses this when presenting calendars.
                            </p> */}
                            <select onChange={handleChange} className='form-input profile-input' defaultValue={profile?.first_day_week} name='first_day_week' id='first_day_week'>
                                <option value=''>Choose first day of week</option>
                                <option value='Monday'>Monday</option>
                                <option value='Tuesday'>Tuesday</option>
                                <option value='Wednesday'>Wednesday</option>
                                <option value='Thursday'>Thursday</option>
                                <option value='Friday'>Friday</option>
                                <option value='Saturday'>Saturday</option>
                                <option value='Sunday'>Sunday</option>
                            </select>
                        </div>
                    </div>
                    <div className='modal-footer'>
                    <button className='btn btn-green-fill' type='submit' >
                        Save Changes
                    </button>
                    </div>
                </form>
            </div>
            }
        </>
    );
};

export default ProfileDetailsModal;