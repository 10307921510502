import React,{useState} from "react";
import { Link } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux"
import * as validate from "../../../helpers/validation";
import notify from "../../../helpers/notify";
import * as actionType from "../../../helpers/constants"
import bg3 from "../../../assets/images/bg-3.png";
import phoneDown from "../../../assets/icons/phone-down.svg"
import arrowRight from "../../../assets/icons/arrow-right.svg";
import warning from "../../../assets/icons/warning.svg";
import logo from "../../../assets/images/logo.png";
import bgLogo from "../../../assets/icons/bg-logo.svg";
import {sendOtp} from "../../../actions";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { isValidPhoneNumber,parsePhoneNumber } from 'react-phone-number-input'
import { Carousel } from "react-responsive-carousel";

const SignUpPhone=({next,back,handleFormData,form})=>{
    const dispatch=useDispatch();
    const [mobileErr,setMobileErr]=useState(null);
    const [fromErr,setFromErr]=useState(null);
    const images=useSelector(state=>state.auth_images)
  const filteredImageUrls = Object.entries(images)
  .filter(([key, value]) => value !== null)
  .map(([key, value]) => ({ [key]: value }));
    const [mobile,setMobile]=useState('');
    // if(mobile)
    // console.log(mobile)
    const verify=()=>{
        return !mobileErr && !validate.validateEmpty(form.heard_from);
    }
    const handleChange=(e)=>{
        const name=e.target.name;
        const value=e.target.value;
        handleFormData(name,value);
        if(name=='heard_from' && validate.validateEmpty(value))
         setFromErr("Cannot be empty")
       else
        setFromErr(null)
    }
    const handleMobileNumber=(e)=>{
       setMobile(e);
       
       //console.log(e)
       if(e!==undefined)
       {
        const parse=parsePhoneNumber(e);
        if(isValidPhoneNumber(e))
           {setMobileErr(null);handleFormData('mobile_number',parse.nationalNumber);handleFormData('country_code',"+"+parse.countryCallingCode);}
           else
           setMobileErr("Not a valid phone number")
       }else{
        setMobileErr("Cannot be empty")
       }
        
    }
    const handleSubmit=(e)=>{
        e.preventDefault();
        if(verify())
        {
            
                dispatch(sendOtp({
                    "isLogin":false,
                    "country_code":form.country_code,
                    "mobile_no":form.mobile_number
                },next,3));
                
           
            //console.log(form)
        }
        
    }
    
    return (<div className="auth-page">
        <div className="auth-background">
            <div className="auth-overlay">
            <div className="auth-content">
                    <div>
                        <Link to="/" className="logo-link"><img src={bgLogo} alt="logo"/><p className="logo-cgi">CGI</p></Link>
                    </div>
                    <div className="auth-content-text">MEMBERS PORTAL</div>
                    {/* <div className="overlay-highlight">JOIN MEDIUM</div>
                    <div className="overlay-text">ARCHITECTURE +<br/>INTERIOR DESIGN</div> */}
                </div>
            </div>
            {filteredImageUrls?.length>0 &&
            <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true}  showArrows={false} showStatus={false}>
            {
               filteredImageUrls?.map((e,i)=>(    
                <div key={`image-${i}`}>
                    <img src={Object?.values(e)} className="bg-img" alt="carousel"/>
                </div>
               ))
            }
            </Carousel>}
        </div>
        <div className="form-bg">
        <div className="default-header">
            <Link to="/" className="logo-link">
                <img className="bgLogo" src={bgLogo} alt="logo"/>
                <p className="logo-cgi">CGI</p>
                </Link>
              <div className="members-portal">MEMBERS PORTAL</div>
            </div>
        <form className="auth-form">
                {window.innerWidth>=800 && <div className="auth-skip" onClick={()=>next(4)}>Skip</div>}
                <div className="form-heading">Welcome To Panoram CGI</div>
                <div className="form-subheading">Create your account</div>
                <div className="auth-form-content">
                <div className="form-input-box">
                <div className="form-label">Phone number</div>
                <div className={`phone-box ${mobileErr && 'input-invalid'}`}>
                   
                 <PhoneInput
  style={{width:'100%'}}
  defaultCountry="US"
  value={mobile}
  onChange={handleMobileNumber}/>
                 
                </div>
                {mobileErr && <div className="input-error">
                    <img src={warning} alt="mobile warning"/>
                    <span>{mobileErr}</span>
                    </div>}
                </div>
                <div className="form-input-box">
                  <div className="form-label">How did you hear about us?</div>
                  <input className={`form-input ${fromErr && 'input-invalid'}`}  name="heard_from" onChange={handleChange}/>
                  {fromErr && <div className="input-error">
                    <img src={warning} alt="warning"/>
                    <span>{fromErr}</span>
                    </div>}
                </div>
                <button className={`btn btn-${verify()?'green-fill':'grey'}`} onClick={handleSubmit} > <span>Continue</span><img src={arrowRight} alt="next"/></button>
                </div>
                {window.innerWidth<800 && <div className="auth-skip auth-skip-resp" onClick={()=>next(4)}>Skip</div>}
            </form>
        </div>
           
        
    </div>
    )
}
export default SignUpPhone;