import React,{useState,useEffect} from "react"
import { useParams,useNavigate } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import ScrollContainer from "react-indiana-drag-scroll";
import arrowLeft from "../../../assets/icons/arrow-left.svg";
import plus from "../../../assets/icons/plus.svg";
import menuGridIcon from "../../../assets/icons/menu-grid.svg"
import commentIcon from "../../../assets/icons/comment.svg"
import DownloadIcon from "../../../assets/icons/download-icon";
import InputReviewModal from "./InputReviewModal";
import fullscreenIcon from "../../../assets/icons/fullscreen.svg";
import NextIcon from "../../../assets/icons/next-icon"
import PrevIcon from "../../../assets/icons/prev-icon"
import ImageMarker, { Marker } from 'react-image-marker';
import editImageIcon from "../../../assets/icons/edit-image.svg"
import deleteIcon from "../../../assets/icons/delete-image.svg"
import * as actionType from "../../../helpers/constants"
import CommentsBar from "./CommentBar";
import Comment from "./Comment";
import { getUserProject,getAdminProject,getReviewVersions,approveImage, deleteReviewImage, showReviewTutorialFunc, putReviewTutorialFunc } from '../../../actions';
import {saveAs} from "file-saver";
import Draggable from "react-draggable";
import ReviewTutorial from "./ReviewTutorial";

const ReviewFile=()=>{
    const {id}=useParams();
    const navigate=useNavigate()
    const dispatch=useDispatch();
    const [reviewItemIndex,setReviewItemIndex]=useState(0)
    const initialTutorialState = useSelector(state=>state.show_review_file_tutorial)
    const [showReviewTutorial,setShowReviewTutorial] = useState(initialTutorialState)
    console.log(showReviewTutorial)
    const [reviewItem,setReviewItem]=useState(0)
    const [wipIndex,setWipIndex]=useState(0)
    const [showUploadModal,setShowUploadModal]=useState(null)
    const {project,profile,review_files,isLoading,markers}=useSelector(state=>state)
    console.log(review_files)
    const skipReviewTutorial = ()=>{
        setShowReviewTutorial(false)
        dispatch(putReviewTutorialFunc())
    }
    const prodTeam=sessionStorage.getItem("prodTeam")
    
    const [zoom,setZoom]=useState(100);
    const [viewFull,setViewFull]=useState("full")
    console.log(markers,reviewItemIndex,wipIndex)
    // const [markers, setMarkers] =useState([])
    console.log(isLoading,project)
    const createPin=(marker)=>{
        const newPinObj={
            "pinNumber":markers.length+1,
            "user": profile,
            "replies": [],
            "text": "",
            "top": marker.top,
            "left": marker.left,
            "imageId":review_files[reviewItemIndex].wip[review_files[reviewItemIndex].wip.length-1-wipIndex].id
        }
       dispatch({type:actionType.SET_MARKERS,payload:[...markers,newPinObj]})
       
    }
    const handleSetReviewItemMarkers=(review_item=reviewItemIndex,wip_item=wipIndex)=>{
        console.log("setting marker",review_item,wip_item)
        if(review_files[review_item]?.wip[review_files[review_item]?.wip?.length-1-wip_item]?.comments)
        dispatch({type:actionType.SET_MARKERS,payload:review_files[review_item]?.wip[review_files[review_item]?.wip?.length-1-wip_item]?.comments})
        else
        dispatch({type:actionType.SET_MARKERS,payload:[]})
    }
    const zoomfn=()=>{
        let id='review-wrapper';
        let value=+zoom
        value=Number(value)/100
        //console.log(id,value)
        var outerDiv = document.getElementById('img-wrapper');
        var imgEle = document.getElementById(id);
        if(outerDiv && imgEle)
        {
            var scale = "scale(" + value + ");"
            var origin = "center";
            var translateX = '';
            //console.log("in",outerDiv.clientWidth,outerDiv.scrollWidth)
            if (outerDiv.clientWidth !== outerDiv.scrollWidth) {
                console.log("in")
                origin = "top left";
                translateX = ' translateX(' + (-imgEle.offsetLeft) + 'px) ';
            }
    
            var style = "-ms-transform:" + translateX + scale + "-webkit-transform:" + translateX + scale + "transform:" + translateX + scale + "transform-origin:" + origin + ";";
            document.getElementById(id).setAttribute("style", style);
    
            outerDiv.scrollTop = outerDiv.scrollHeight / 2 - outerDiv.clientHeight / 2;
            outerDiv.scrollLeft = outerDiv.scrollWidth / 2 - outerDiv.clientWidth / 2;
        }
    }

    const handleDownloadImg=(url)=>{
        console.log(url)
        saveAs(url, "Download-Image");
    }
    
    useEffect(()=>{
        if(profile?.user_type==='admin' || profile?.user_type==='pm')
        dispatch(showReviewTutorialFunc())
    },[])

    useEffect(()=>{
       if(id)
       {
        if(profile?.user_type==='superadmin')
         dispatch(getAdminProject(id))
         else
        dispatch(getUserProject(id))
        dispatch(getReviewVersions(id,setShowUploadModal))
       }
       
    },[dispatch,id])
    zoomfn()
    if(!isLoading && project)
    return (
        <>
            {
                showReviewTutorial && <ReviewTutorial skipReviewTutorial={skipReviewTutorial}/> 
            }
        <div className={`${profile?.user_type==='superadmin'||profile?.user_type==='pm'||profile?.user_type==='accountant'&&!prodTeam?'admin-project-dashboard':''}`}>
            {showUploadModal && !prodTeam && profile?.user_type!=='client' && <InputReviewModal showUploadModal={showUploadModal} setShowUploadModal={setShowUploadModal} projectID={id}/>}
        <div className="board-header">
           <button style={{justifySelf:'flex-start'}} onClick={()=>navigate(-1)} className='btn btn-green-outline' >
                <img src={arrowLeft} alt="back"/>
                <span>Back</span>
            </button>
            <div className={`${profile.user_type=='client' && 'board-title'}`}>
            <div className="board-title-main">{project.project_name}</div>
            </div>
            <div className="board-header-btn-block">
            <button className={`btn btn-green-fill ${(prodTeam || profile.user_type=='client') && 'btn-hide'}`} onClick={()=>setShowUploadModal("add")}>Add images <img src={plus} alt="Add images" /></button>
            </div>
        </div>
        <div className="review-board">
            <div className="review-board-header">
                <div className="review-header-actions">
                    <img onClick={()=>setViewFull(p=>
                        {
                            if(p=='menu')
                              return 'full';
                            else
                              return "menu"
                        })} src={menuGridIcon} alt="menu"/>
                    <div className="review-comment-icon" onClick={()=>setViewFull(p=>
                        {
                            if(p=='comments')
                              return 'full';
                            else
                              return "comments"
                        })}>
                        <img src={commentIcon} alt="review-comment"/>
                        <div className="review-comment-new"></div>
                    </div>
                    <div>
                    {
                        review_files.length>0 &&
                        <button className="markup-edit-btn" onClick={()=>navigate(`/review-file-edit/${project.id}/${review_files[reviewItemIndex].wip[review_files[reviewItemIndex].wip.length-1-wipIndex].id}`)}>
                            <img src={editImageIcon} alt="edit-img"/>
                        </button>
                    }
                    </div>
                    <button className="markup-edit-btn" onClick={()=>{dispatch(deleteReviewImage(review_files[reviewItemIndex]?.id))
                    setReviewItemIndex(p=>p-1)
                    }}>
                            <img src={deleteIcon} alt="edit-img" width={30} height={30} style={{fill : "white"}}/>
                        </button>
                    <div className="review-header-filename"><span style={{color:'white'}}>{review_files[reviewItemIndex]?.Title}</span> | <span style={{color:'#D6D6D6'}}>Revision {review_files[reviewItemIndex]?.wip[review_files[reviewItemIndex]?.wip.length-1]?.versionnum}</span></div>
                </div>
                <div className="review-actions">
                    <div className="review-action">
                    { review_files.length>0 && <div className="review-action-pagecount">
                   
                        <div className="review-action-pagecount-input">
                            <input value={reviewItemIndex+1} onChange={(e)=>{setReviewItem(e.target.value);console.log(e.target.value)}}/>
                        </div>
                        <span> /{review_files.length}</span>
                        
                    </div>}
                        
                    </div>
                    <div className="review-action-border member-review"></div>
                    <div className="review-action">
                        <button className="review-action-btn" onClick={()=>{setZoom(p=>p-1)}}>-</button><input  className="review-action-zoom-input" value={zoom} onChange={(e)=>setZoom(+e.target.value)}/><button className="review-action-btn" onClick={()=>setZoom(p=>p+1)}>+</button>
                    </div>
                    <div className="review-action-border"></div>
                    <div className="review-action">
                        <img className="review-action-btn" onClick={()=>setViewFull(p=>
                        {
                            if(p=='full')
                              return 'menu';
                            else
                              return "full"
                        })} src={fullscreenIcon} alt="fullscreen"/>
                    </div>
                    
                </div>
                <div className={`review-header-actions ${(prodTeam || profile.user_type=='client') && 'btn-hide member-hide'}`}>
                <button className={`btn btn-${review_files.length>0?'green-outline':'grey-outline'}`} disabled={review_files.length==0} onClick={()=>setShowUploadModal(review_files[reviewItemIndex])}>Add New Version</button>
                    
                    <button className={`btn btn-green-outline ${(prodTeam || profile.user_type=='client') && 'btn-hide'}`} onClick={()=>{
                        if(review_files[reviewItemIndex])
                        dispatch(approveImage(review_files[reviewItemIndex].wip[review_files[reviewItemIndex].wip.length-1-wipIndex].id))}}>Approve Version</button>
                    <button className="download-img-btn" onClick={()=>{handleDownloadImg(review_files[reviewItemIndex].wip[review_files[reviewItemIndex].wip.length-1-wipIndex].file)}}><DownloadIcon fill="white"/></button>
                </div>
            </div>        
            <div className="review-playground">
                {viewFull=='comments' && <CommentsBar markers={markers}/>}
            { viewFull=='menu' && <div className="version-list">
                {review_files[reviewItemIndex] && review_files[reviewItemIndex].wip.map((item,index)=>
                <div className={ `review-sidebar-box`}
                   onClick={()=>{handleSetReviewItemMarkers(reviewItemIndex,index);setWipIndex(index); }}
                   // handleSetReviewItemMarkers()
                >
                    <div className={`review-sidebar-item ${index==wipIndex?'review-sidebar-item-active':''}`}>
                    <img  src={review_files[reviewItemIndex].wip[review_files[reviewItemIndex].wip.length-1-index].file} alt="file"/>
                    
                    </div>
                    <div className="review-sidebar-version">Version {review_files[reviewItemIndex].wip[review_files[reviewItemIndex].wip.length-1-index].versionnum}</div>
                    </div>
           )}
                 </div>}
            <div className="review">
                {review_files[reviewItemIndex] && review_files[reviewItemIndex].wip.length>0 &&
            <div class="img-wrapper" id="img-wrapper">
                <div id="review-wrapper" class="my-image">
                <ImageMarker 
             src={review_files[reviewItemIndex].wip[review_files[reviewItemIndex].wip.length-1-wipIndex].file}
             markers={markers.map((mp,index)=>{return{...mp,"pinNumber":index+1,"imageId":review_files[reviewItemIndex].wip[review_files[reviewItemIndex].wip.length-1-wipIndex].id}})}
             onAddMarker={(marker: Marker) => createPin(marker)}
             markerComponent={Comment}
         /> 
         <img src="https://i.ibb.co/1sZv64L/light-watermark-png.png" alt="light-watermark-png" class='review-markup'/>
                </div>
         
            
            </div>
               }
               
                {review_files.length>0 &&
                <div className="review-image-scroll">
                <button onClick={()=>{
                    setReviewItemIndex(p=>{
                    if(p>0) {
                        if(review_files[p-1].wip.length==0)
                          setShowUploadModal(true)
                        handleSetReviewItemMarkers(p-1,0)  
                        return p-1}
                   else {
                    if(review_files[p].wip.length==0)
                          setShowUploadModal(true)
                    handleSetReviewItemMarkers(p,0)  
                    return p}});
                 
                 setWipIndex(0)
                }
                    }>
                        <PrevIcon height="20px" width="20px" fill={reviewItemIndex==0?'grey':'white'}/>
                    </button> <div>|</div> 
                    <button onClick={()=>
                    {setReviewItemIndex(p=>{
                    if(p<review_files.length-1)
                    {
                        if(review_files[p+1].wip.length==0)
                          setShowUploadModal(true)
                        handleSetReviewItemMarkers(p+1,0)  
                        return p+1}
                    else
                     {
                        if(review_files[p].wip.length==0)
                          setShowUploadModal(true)
                        handleSetReviewItemMarkers(p,0)
                        return p}})
                     setWipIndex(0)
                     
                    }
                     }>
                        <NextIcon height="20px" width="20px" fill={reviewItemIndex==(review_files.length-1)?'grey':'white'}/>
                     </button>
                </div>   
            }
             {/* {review_files.length>0 &&
                <button className="btn btn-green-fill btn-review-image-edit" onClick={()=>navigate(`/review-file-edit/${project.id}/${review_files[reviewItemIndex].wip[review_files[reviewItemIndex].wip.length-1-wipIndex].id}`)}>
                    <img src={editImageIcon} alt="edit-img"/>
                </button>
} */}
            </div>
            </div>
            
        </div>
    </div>
    </>
    )
}
export default ReviewFile