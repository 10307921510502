import React,{useEffect, useState,useRef} from 'react';
import { useDispatch,useSelector } from 'react-redux';
import * as validate from "../helpers/validation";
import linkBtn from '../assets/icons/link-btn.svg';
import plus from '../assets/icons/plus.svg';
import laptopIcon from '../assets/icons/laptop.svg';
import dropbox from "../assets/icons/dropbox.svg";
import defaultFile from "../assets/icons/default-file.svg"
import infoIcon from "../assets/icons/info.svg"
import dropdown from "../assets/icons/dropdown.svg";

import DropboxChooser from "react-dropbox-chooser";
import { addNewProject } from '../actions';
import DatePicker from "react-datepicker";
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";
import warning from "../assets/icons/warning.svg";
import RemoveIcon from '../assets/icons/remove-icon';
import Select from 'react-select';
import { serviceOptions } from '../helpers/constants';
import LinkModal from './LinkModal';
import DescriptionModal from './DescriptionModal';
import notify from '../helpers/notify';
// import { format } from 'date-fns';


const CreateProjectModal=({show, handleClose})=>{
    const dispatch=useDispatch();
    const profile = useSelector(state => state.profile);
    // const message=useSelector(state=>state.message);

    const initialForm={
      your_name:profile?.fullName,
      email:profile?.email,
      company_name:profile?.title_atcompany,
      project_name:"",
      project_description:"",
      project_purpose:"",
      service_type:"",
      project_type:"",
      quantity:null,
      project_deadline:moment(new Date()).format('YYYY-MM-DD'),
      project_location:"",
      project_address:"",
      link:"",
      local:[],
      file:[],
  }

    const localInput=useRef();
    const isAuth=useSelector(state=>state.isAuth)
    const [form, setForm] = useState(initialForm);
    const [emailErr,setEmailErr]=useState(null);
    const [companyNameErr,setCompanyNameErr]=useState(null);
    const [serviceTypeErr,setServiceTypeErr]=useState(null);
    const [projectTypeErr,setProjectTypeErr]=useState(null);
    const [quantityErr,setQuantityErr]=useState(null);
    const [nameErr,setNameErr]=useState(null)
    const [projectNameErr,setProjectNameErr]=useState(null);
    const [projectDesErr,setProjectDesErr]=useState(null);
    const [projectPurposeErr,setProjectPurposeErr]=useState(null);
    const [projectLocationErr,setProjectLocationErr]=useState(null);
    const [addressErr,setAddressErr]=useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [selectedDate,setSelectedDate]=useState(new Date());
    const [hoverRemove,setHoverRemove]=useState({});
    const [showLinkModal,setShowLinkModal]=useState(false);
    const [showDescriptionModal,setShowDescriptionModal]=useState(false);

    const handleCloseLinkModal=()=>{setShowLinkModal(false)}
    const handleCloseDescriptionModal=()=>{setShowDescriptionModal(false)}
    


    const handleSuccess=(files)=>{
      console.log(files)
        setForm({...form,file:[...files]})
    }
   
    const handleFiles = (e) => {
      let f=e.target.files,files=[]
       for(let i=0;i<f.length;i++)
         files.push(f[i])
      setForm({...form,local:files})
      // console.log(e.target.files);
      // initialForm.project_files.push(e.target.files);
  }
   const handleRemoveFile=(type,id)=>{
    if(type=='dropbox')
    {
      let f=form.file.filter(f=>f.id!==id)
      setForm({...form,file:[...f]})
    }
    if(type=='local')
    {
      let f=form.local.filter((f,index)=>index!==id)
      setForm({...form,local:[...f]})
    }
   }
   
    const verify=()=>{
        return !nameErr && !emailErr && !companyNameErr && !projectNameErr && !projectDesErr && !projectPurposeErr && !projectTypeErr && !serviceTypeErr && !quantityErr && !projectLocationErr && !addressErr && !validate.validateEmpty(form.service_type) && !validate.validateEmpty(form.project_type) && !validate.validateEmpty(form.your_name) && !validate.validateEmpty(form.email) && !validate.validateEmpty(form.company_name) && !validate.validateEmpty(form.project_name) && !validate.validateEmpty(form.project_purpose) && !validate.validateEmpty(form.service_type) && !validate.validateEmpty(form.project_type) && !validate.validateEmpty(form.quantity) && !validate.validateEmpty(form.project_deadline) && !validate.validateEmpty(form.project_location) && !validate.validateEmpty(form.project_address);
      //return (!nameErr && !emailErr && !projectNameErr && !projectDesErr && !projectTypeErr && !serviceTypeErr && !quantityErr)  
    }
        const handleSubmitLink=(value)=>{
          console.log(value);
          setForm({...form,link:value})
        }
        const handleSubmitDescription=(value)=>{
          console.log(value);
          setForm({...form,project_description:value})
        }
        const handleChange=(e)=>{
          const name=e.target.name;
          const value=e.target.value;
            if(name==='quantity')
             setForm({...form,[name]:Number(value)})
            else
            setForm({...form,[name]:value})
           // console.log(form)
          if(name==='email')
          {
            if(!validate.validateEmail(value))
              setEmailErr("Not a valid email id");
            else
             setEmailErr(null)
          }
          if(name==='company_name')
          {
            if(validate.validateEmpty(value))
              setCompanyNameErr("Cannot be empty");
            else if(value.length<3)
              setCompanyNameErr("Min 3 characters needed")
            else if(value.length>40)
              setCompanyNameErr("Exceeds max limit")
            else
             setCompanyNameErr(null)
          }
          if(name==='your_name')
          {
            
            if(validate.validateEmpty(value))
              setNameErr("Cannot be empty");
            else if(value.length<3)
             setNameErr("Min 3 characters needed")
            else if(value.length>40)
             setNameErr("Exceeds max limit")
            else if(!validate.validateAlphabets(value))
             setNameErr('Only alphabets and spaces')
            else
             setNameErr(null)
          }
          if(name==='quantity')
          {
            if(validate.validateEmpty(value))
              setNameErr("Cannot be empty");
            else
             setNameErr(null)
          }
          if(name==='project_name')
          {
            if(validate.validateEmpty(value))
              setProjectNameErr("Cannot be empty");
            else if(value.length<3)
             setProjectNameErr("Min 3 characters needed")
            else
             setProjectNameErr(null)
          }
          // if(name==='project_description')
          // {
          //   if(validate.validateEmpty(value))
          //     setProjectDesErr("Cannot be empty");
          //   else if(value.length<3)
          //     setProjectDesErr("Min 3 characters needed")
          //   else if(!validate.validateAlphaNumerics(value))
          //     setProjectDesErr("Only alphabets and numbers")
          //   else
          //    setProjectDesErr(null)
          // }
         if(name==='project_purpose')
          {
            if(validate.validateEmpty(value))
              setProjectPurposeErr("Cannot be empty");
            else
              setProjectPurposeErr(null)
          }
         if(name==='project_type')
         {
            if(value === 'none')
              setProjectTypeErr("Cannot be empty");
            else
             setProjectTypeErr(null)
         }
         if(name==='service_type')
         {
            if(value === 'none')
              setServiceTypeErr("Cannot be empty");
            else
            
             setServiceTypeErr(null)
         } 
         if(name==='quantity')
         {
            if(value)
            setQuantityErr(null);
            else
            setQuantityErr("Cannot be empty")
         }
          if(name==='project_location')
            {
              if(validate.validateEmpty(value))
                setProjectLocationErr("Cannot be empty");
              else if(value.length<3)
                setProjectLocationErr("Min 3 characters needed")
              else if(value.length>40)
                setProjectLocationErr("Exceeds max limit")
              else
                setProjectLocationErr(null)
            }
          if(name==='address')
            {
              if(validate.validateEmpty(value))
                setAddressErr("Cannot be empty");
              else if(value.length<3)
                setAddressErr("Min 3 characters needed")
              else if(value.length>40)
                setAddressErr("Exceeds max limit")
              else
                setAddressErr(null)
            }
        }
        const handleSubmit=(e)=>{
            e.preventDefault();
            
            if(verify())
            {
              let newForm=new FormData()
              newForm.append("your_name",form.your_name)
              newForm.append("email",form.email)
              newForm.append("company_name",form.company_name)
              newForm.append("project_name",form.project_name)
              newForm.append("project_description",form.project_description)
              newForm.append("project_purpose",form.project_purpose)
              newForm.append("service_type",form.service_type)
              newForm.append("project_type",form.project_type)
              newForm.append("quantity",form.quantity)
              newForm.append("project_deadline",form.project_deadline)
              newForm.append("project_location",form.project_location)
              newForm.append("project_address",form.project_address)
              newForm.append("link",form.link)
              newForm.append("file",JSON.stringify(form.file))
              for(let i=0;i<form.local.length;i++)
                 newForm.append("File_docs",form.local[i])
              dispatch(addNewProject(newForm))
              handleClose();
              setForm(initialForm);
              notify("success",`New project ${form.project_name} created successfully !`)
            }
            
        }
       const fileName=(t)=>{
        const i=t.lastIndexOf(".");
        return <>
        <p>{t.substring(0,i)}</p>
        <p>{t.substring(i+1)}</p>
        </>
       }

       useEffect(()=>{

        console.log(form)
        },[form]) 
      
  return (
    <>
      {
        show && (
            <div className='modal'>
                <div  className='modal-content'>
                   
                    <div className='modal-title'>Enter project details</div>
                    
                    <div className='modal-body'>
                        <div>
                            <div className="form-input-box">
                                <div className="form-label">Your Name</div>
                                <input className="form-input form-input-create-resp" name="your_name" minLength={3} maxLength={40} onChange={handleChange} required value={form.your_name}/>
                                {nameErr && <div className="input-error">
                    <img src={warning} alt="name warning"/>
                    <span>{nameErr}</span>
                    </div>}
                            </div>
                            <div className="form-input-box">
                                <div className='form-label'>Email Address</div>
                                <input className="form-input form-input-create-resp" type="email" name="email" onChange={handleChange} required value={form.email}/>
                                {emailErr && <div className="input-error">
                    <img src={warning} alt="email warning"/>
                    <span>{emailErr}</span>
                    </div>}
                            </div>
                            <div className="form-input-box">
                                <div className='form-label'>Company Name</div>
                                <input className="form-input form-input-create-resp" name="company_name" minLength={3} onChange={handleChange} required value={form.company_name}/>
                                {companyNameErr && <div className="input-error">
                     <img src={warning} alt="warning"/>
                    <span>{companyNameErr}</span>
                    </div>}
                            </div>
                            <div className="form-input-box">
                                <div className='form-label'>Project Name</div>
                                <input className="form-input form-input-create-resp" name="project_name" minLength={3} onChange={handleChange} required />
                                {projectNameErr && <div className="input-error">
                    <img src={warning} alt="project name warning"/>
                    <span>{projectNameErr}</span>
                    </div>}
                            </div>
                            <div className="form-input-box">
                                <div className='form-label'>Project Purpose</div>
                                <select className="form-input form-input-create-resp" name="project_purpose" onChange={handleChange} required>
                                    <option value='none'>Select project purpose</option>
                                    <option value='Marketing'>Marketing</option>
                                    <option value='City Planning'>City Planning</option>
                                    <option value='Design Development'>Design Development</option>
                                    <option value='Competition'>Competition</option>
                                    <option value='Leasing'>Leasing</option>
                                    <option value='Other'>Other</option>
                                </select>
                                {projectPurposeErr && <div className="input-error">
                     <img src={warning} alt="warning"/>
                    <span>{projectPurposeErr}</span>
                    </div>}
                            </div>
                            <div className="form-input-box">
                                <div className='form-label'>Project Description</div>
                                <div className="form-input form-input-create-resp add-description">
                                    <span>Add description</span>
                                    <span onClick={()=>{setShowDescriptionModal(true)}}><img className='circle-bg' src={plus} alt="upload file" /></span>
                                    <DescriptionModal showDescriptionModal={showDescriptionModal} handleCloseDescriptionModal={handleCloseDescriptionModal} handleSubmitDescription={handleSubmitDescription} />
                                </div>
                                {projectDesErr && <div className="input-error">
                    <img src={warning} alt="description warning"/>
                    <span>{projectDesErr}</span>
                    </div>}
                            </div>

                        </div>
                        <div>
                            <div style={{width: "320px"}} className="form-input-box">
                                <div className="form-label">Service Type</div>
                                <Select
                                  name="service_type"
                                  styles={{
                                    control: (provided, state) => ({
                                      ...provided,
                                      backgroundColor: "#4B4B4B",
                                      border: "none",
                                      borderRadius: "0px",
                                      boxShadow: "none",
                                      height: "43px",
                                    }),
                                    dropdownIndicator: (provided, state) => ({
                                      ...provided,
                                      display: "none",
                                    }),
                                    indicatorSeparator: (provided, state) => ({
                                      ...provided,
                                      display: "none",
                                    }),
                                    valueContainer: (provided, state) => ({
                                      ...provided,
                                      overflowY: "scroll",
                                      maxHeight: "43px",
                                    })
                                  }}
                                  components={{
                                    DropdownIndicator: () => {
                                      return <img style={{paddingRight: "18px"}} src={dropdown} alt="service type" />;
                                    },
                                  }}
                                  placeholder="Select service type"
                                  isMulti
                                  onChange={(e)=>{
                                    let v=e.map(i=>i.value).join(",")
                                    setForm({...form,'service_type':v})
                                    if(v === '')
                                    setServiceTypeErr("Cannot be empty");
                                  else
                                  
                                   setServiceTypeErr(null)
                                  }}
                                  options={serviceOptions}
                                  className="basic-multi-select form-input-create-resp"
                                  classNamePrefix="select"
                                />
                                {serviceTypeErr && <div className="input-error">
                                <img src={warning} alt="service warning"/>
                                <span>{serviceTypeErr}</span>
                                </div>}
                            </div>
                            <div className="form-input-box">
                                <div className='form-label'>Project Type</div>
                                <select className="form-input form-input-create-resp" name="project_type" onChange={handleChange} required>
                                    <option value='none'>Select project type</option>
                                    <option value="Architecture">Architecture</option>
                                    <option value="Interior">Interiors</option>
                                    <option value="Real Estate">Real Estate</option>
                                    <option value="Landscape">Landscape</option>
                                    <option value="Golf Course">Golf Course</option>
                                    <option value="Yacht/Boat">Yacht/Boat</option>
                                    <option value="Swimming Pools">Swimming Pools</option>
                                </select>
                                {projectTypeErr && <div className="input-error">
                    <img src={warning} alt="project type warning"/>
                    <span>{projectTypeErr}</span>
                    </div>}
                            </div>
                            <div className="form-input-box">
                                <div className='form-label'>Quantity of Service</div>
                                <input className="form-input form-input-create-resp" type="number" min={0} name="quantity" onChange={handleChange} required/>
                                {quantityErr && <div className="input-error">
                    <img src={warning} alt="uantity warning"/>
                    <span>{quantityErr}</span>
                    </div>}
                            </div>
                            <div className="form-input-box">
                                <div className='form-label'>Project Deadline</div>
                                <DatePicker className="form-input form-input-create-resp"   selected={selectedDate} minDate={startDate} onChange={(date) => {setSelectedDate(date);setForm({...form,project_deadline:moment(date).format('YYYY-MM-DD')})}} />
                                {/* <input className="form-input" name="project_deadline" pattern="[0-9]{1,2}/[0-9]{1,2}/[0-9]{4}" placeholder="DD/MM/YYYY" onChange={handleChange} required /> */}
                            </div>
                            <div className="form-input-box">
                                <div className='form-label'>Project Location</div>
                                <input className="form-input form-input-create-resp" name="project_location" minLength={3} onChange={handleChange} required />
                                {projectLocationErr && <div className="input-error">
                     <img src={warning} alt="warning"/>
                    <span>{projectLocationErr}</span>
                    </div>}
                            </div>
                            <div className="form-input-box">
                                <div className='form-label'>Address</div>
                                <input className="form-input form-input-create-resp" name="project_address" minLength={3} onChange={handleChange} required />
                                {addressErr && <div className="input-error">
                     <img src={warning} alt="warning"/>
                    <span>{addressErr}</span>
                    </div>}
                            </div>
                        </div>
                    </div>
                    <div className="form-input-box upload-field">
                        <div className='form-label'>Upload Files</div>
                        
                        <div className={`${form.file.length > 0 && form.local.length>0 && "files-box"}`}>
                            <div className='form-input form-input-create-resp upload-files-btn'>
                                <p>Project files</p>
                                <DropboxChooser
                appKey={process.env.REACT_APP_DROP_KEY}
                success={handleSuccess}
                linkType="direct"
                folderselect={true}
                multiselect={true}
              >
                <img className='dropbox-icon' src={dropbox} alt="upload file" />
              </DropboxChooser>
                <img onClick={()=>setShowLinkModal(true)} className='circle-bg' src={linkBtn} alt="include link" />
                <img onClick={()=>localInput.current.click()}src={laptopIcon} alt="laptop" />
                <LinkModal showLinkModal={showLinkModal} handleCloseLinkModal={handleCloseLinkModal} handleSubmitLink={handleSubmitLink} />
                               
                                
                            </div>
                            <input 
                                id='getFiles' 
                                ref={localInput}
                                onChange={handleFiles}
                                multiple={true} 
                                type="file" 
                                className="upload-files-input " 
                            />
                            {form.file.length>0 &&  <div className='files-list'>
                                {
                                    form.file.length > 0 && (
                                        form.file.map((file, index) => 
                                        // {
                                        //     console.log(files[file].name);
                                        // }
                                        (     <div className='file-box' key={index}>
                                          <div className="file-remove" onMouseEnter={()=>setHoverRemove({"type":"dropbox","index":index})} onMouseLeave={()=>setHoverRemove({})} onClick={()=>handleRemoveFile("dropbox",file.id)} ><RemoveIcon opacity={hoverRemove.index===index?0.57:0.12}  fill={hoverRemove.index===index?'#333333':'white'}/></div>
                                                
                                                <a href={file.link}  className='file-item'>
                                                    <img src={file.icon} alt="file item" />
                                                    <div className='file-item-description'>
                                                       {fileName(file.name)}
                                                    </div>
                                                </a>
                                                </div>
                                            )
                                        )
                                    )
                                }
                            </div>}
                            {form.local.length>0 &&  <div className='files-list'>
                                {
                                    form.local.length > 0 && (
                                        form.local.map((file, index) => 
                                        // {
                                        //     console.log(files[file].name);
                                        // }
                                        (     <div className='file-box' key={index}>
                                          <div className="file-remove" onMouseEnter={()=>setHoverRemove({"type":"dropbox","index":index})} onMouseLeave={()=>setHoverRemove({})} onClick={()=>handleRemoveFile("local",index)} ><RemoveIcon opacity={hoverRemove.index===index?0.57:0.12}  fill={hoverRemove.index===index?'#333333':'white'}/></div>
                                                
                                                <div href={file.link}  className='file-item'>
                                                    <img src={defaultFile} alt="file item" />
                                                    <div className='file-item-description'>
                                                       {file.name}
                                                    </div>
                                                </div>
                                                </div>
                                            )
                                        )
                                    )
                                }
                            </div>}
                        </div>
                       <div className='upload-text'>
                        <img src={infoIcon} alt="upload"/><span>maximum file size 20mb</span>
                       </div>
                    </div>
                    <div className='modal-footer'>
                    <button type='submit' onClick={handleSubmit} className={`btn btn-${verify()?'green-fill':'grey'}`}  >
                        Create Project
                    </button>
                    <button className='btn btn-green-outline' onClick={handleClose}>
                        Cancel
                    </button>
                    </div>
                </div>
            </div>
        )
      }
    </>
  );
}

export default CreateProjectModal;