import { Link, Outlet } from 'react-router-dom';
import logo from "./.././assets/images/logo.png"

const ProductionHome = () => {
    return (
        <>
            <div className='production-dashboard'>
                <Link className="logo" to="/"><img src={logo} alt="logo"/></Link>
            </div>
            <div className='production-body'> 
                <Outlet/>
            </div>
        </>    
    );
};

export default ProductionHome;