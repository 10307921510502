import React from 'react';
import menu from "../../assets/icons/menu.svg";
import logo from "../../assets/images/logo.png";
import HomeIcon from "../../assets/icons/home-icon";
import NotificationIcon from "../../assets/icons/notification-icon";
import QuotesIcon from "../../assets/icons/quotes-dark.svg";
import PplIcon from "../../assets/icons/ppl-dark.svg";
import AllocationIcon from "../../assets/icons/allocation-dark.svg";
import ProfileIcon from "../../assets/icons/profile-icon";
import LogoutIcon from '../../assets/icons/logout-icon';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { logout } from '../../actions';
import './../../assets/css/header.css'

const AdminHeader = () => {
    const isAuth=useSelector(state=>state.isAuth);
    const [showMenu,setShowMenu]=useState(false);
    const dispatch=useDispatch();
    const navigate=useNavigate();
    let location=useLocation();
    let pathname=location?.pathname.split("/")[1];

    const closeMenu=(e)=>{
        setShowMenu(false)
    }
    
    const handleLogout=()=>{
        dispatch(logout());
        closeMenu();
        navigate("/");
      }

    return (
        <div className="admin-sm-header">
            {isAuth && <img onClick={()=>setShowMenu(p=>!p)} className="menu" src={menu} alt="mobile-menu"/>}
            <img className='header-sm-logo' src={logo} alt="mobile-menu" />
            <span style={{width: '1rem'}}></span>
            {
                isAuth && showMenu && 
                <div className='navbar-small admin-navbar'>
                    <Link onClick={closeMenu} className={`navbar-item ${pathname.length===0?'selected-resp':''}`} to='/'>
                        <HomeIcon fill={pathname.length===0?'black':'currentColor'}/>
                        <span>Home</span>
                    </Link>
                    <Link onClick={closeMenu} className={`navbar-item ${pathname==='notifications'?'selected-resp':''}`} to='/notifications'>
                        <NotificationIcon fill={pathname==='notifications'?'black':'currentColor'}/>
                        <span>Notifications</span>
                    </Link>
                    <Link onClick={closeMenu} className={`navbar-item ${pathname==='quotes'?'selected-resp':''}`} to='/quotes'>
                        {/* <QuotesIcon fill={pathname==='quotes'?'black':'currentColor'}/> */}
                        <img src={QuotesIcon} alt="quotes" />
                        <span>Quotes</span>
                    </Link>
                    <Link onClick={closeMenu} className={`navbar-item ${pathname==='ppl'?'selected-resp':''}`} to='/ppl'>
                        {/* <PplIcon fill={pathname==='ppl'?'black':'currentColor'}/> */}
                        <img src={PplIcon} alt="ppl" />
                        <span>PPL</span>
                    </Link>
                    <Link onClick={closeMenu} className={`navbar-item ${pathname==='allocation'?'selected-resp':''}`} to='/allocation'>
                        {/* <AllocationIcon fill={pathname==='allocation'?'black':'currentColor'}/> */}
                        <img src={AllocationIcon} alt="allocation" />
                        <span>Allocation</span>
                    </Link>
                    <Link onClick={closeMenu} className={`navbar-item ${pathname==='profile'?'selected-resp':''}`} to='/profile'>
                        <ProfileIcon fill={pathname==='profile'?'black':'currentColor'}/>
                        <span>Profile</span>
                    </Link>
                    <div  className='navbar-item' onClick={handleLogout}>
                        <LogoutIcon fill='currentColor'/>
                        <span>Sign out</span>
                    </div>
                </div>
            }
        </div>
    );
};

export default AdminHeader;