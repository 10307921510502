import React,{useState,useEffect} from "react";
import { useParams,useNavigate,Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import plus from "../../../assets/icons/plus.svg";
import sortIcon from "../../../assets/icons/sort.svg";
import arrowLeft from "../../../assets/icons/arrow-left.svg";
import replyIcon from "../../../assets/icons/reply.svg"
import { getUserProject,getAdminProject,getProjectUserList, getMessages,getReplies, getConversation } from '../../../actions';
import WriteMessage from "./writeMessage";
import MessageReply from "./messageReply";
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import Moment from 'react-moment';
import notify from "../../../helpers/notify";
const Conversation=()=>{
    const {projectID,date,category}=useParams()
    const profile=useSelector(state=>state.profile)
    const project=useSelector(state=>state.project)
    const conversation=useSelector(state=>state.conversation)
    console.log("conversation",conversation)
    const isLoading=useSelector(state=>state.isLoading)
    const replies=useSelector(state=>state.replies)
    const navigate=useNavigate();
    const dispatch=useDispatch()
    const [showSort,setShowSort]=useState(false)
    const [showWriteModal,setWriteShowModal]=useState(false)
    const [showReply,setShowReply]=useState(null)
    const [viewReplyIds,setViewReplyIds]=useState([])
    const [highlightConversationIds,setHighlightConversationIds]=useState([])
    const getFileName=(link)=>{
        let temp=link.split("/")
        return temp[temp.length-1]
    }
    const closeSortMenu=()=>{
        setShowSort(false);
    }
    const selectSort=(type)=>{
      closeSortMenu()
    }
    document.addEventListener('click',(e)=>{
        if(!e.target.closest('.sort-options') && !e.target.closest('.sort-action'))
        closeSortMenu()
    })
    // const handleHighlightIds=()=>{
    //     setHighlightConversationIds(o=>o.filter(i=>i==showReply||i==viewReplyIds))
    // }
    const handleGetReplies=(id)=>{
        let t=highlightConversationIds
       if(viewReplyIds && viewReplyIds!=showReply)
          {
            t=t.filter(i=>i!=viewReplyIds)
            console.log("in",t)
            //setHighlightConversationIds(t)
          }
            setViewReplyIds(id)
            t.push(id)
        setHighlightConversationIds(t)
        notify("info","Getting replies")
        dispatch(getReplies(id))
        
       
    }
    const handleHideReply=(id)=>{
           if(showReply!=id)
             setHighlightConversationIds(o=>o.filter(i=>i!=id))
          setViewReplyIds(null)
          
    }
    useEffect(()=>{
        if(date && category)
        {
            if(profile.user_type==='superadmin')
          dispatch(getAdminProject(projectID))
          else
         dispatch(getUserProject(projectID))
         dispatch(getConversation(date,category))
         dispatch(getProjectUserList(projectID))
        }
          
    },[dispatch])
    //console.log(highlightConversationIds)
    if(!isLoading && project)
    return (
    <div className={`${profile?.user_type==='superadmin'||profile?.user_type==='pm'||profile?.user_type==='accountant'?'admin-project-dashboard':''}`}>
        {showWriteModal && <WriteMessage setWriteShowModal={setWriteShowModal} projectID={project.id}/>}
    <div className='dashboard-project-header'>
    <button style={{justifySelf:'flex-start',marginRight : "auto"}} onClick={()=>navigate(-1)} className='btn btn-green-outline' >
        <img src={arrowLeft} alt="back"/>
        <span>Back</span>
    </button>
    <div className='dashboard-project-header-content'>
        <div className='dashboard-project-content-1'>{project.project_name}</div>
    </div>
    <div></div>
    {/* <button className='btn btn-green-fill'>Invite People <img src={plus} alt="invite people"/></button> */}
    </div>  
    <div className="message-board">
        <div className="board-header">
        <button className='btn btn-green-fill' onClick={()=>setWriteShowModal(true)}>New message <img src={plus} alt="invite people"/></button>
            <div className="board-title">
              <div className="board-title-main">Message board</div>
            </div>
            <div className="sort">
            <div className="sort-action" onClick={()=>setShowSort(p=>!p)}>
                <img src={sortIcon} alt="sort"/>
                <span>Sort</span>
            </div>
            <div className={`sort-options ${showSort?'':'hide'}`}>
                <div className="sort-option" onClick={()=>selectSort('created')}>Sort by created</div>
            </div>
        </div>
        </div>
        {conversation && conversation?.length !== 0 ? conversation?.map((item,index)=>
        <div className={`message-block-item ${highlightConversationIds.includes(item.id)?'highlight-conversation':''}`} key={`message-block-${index}`}>
        <div className="person-img">
        {item?.created_by.avatar?<img src={item?.created_by?.avatar} alt="avatar"/>:
            <span className="person-img-default">{`${item?.created_by.first_name.charAt(0)} ${item?.created_by.last_name.charAt(0)}`} </span>
}
        </div>
        <div className="message-block-item-content">
           <div className="message-block-item-content-header">
             <div className="message-block-item-title">{`${item?.created_by.first_name} ${item?.created_by.last_name}`}</div>
             <div className="message-block-item-time"> <Moment format="LT , MM/DD/YYYY" tz={profile.timezone}>{item.created_at}</Moment></div>
           </div>
           <div className="message-block-item-message"> <ReactMarkdown rehypePlugins={[rehypeRaw]} children={item.message} /></div>
           {item.attachment.length>0 && <> <div className='documents detail'>
                  Documents :
              </div>
              <div className='files'>
                  {
                      
                          item.attachment.map((file, index) => 
                              (
                                  <a href={file.file} key={file.id} target="_blank" className='file-item' rel="noreferrer">
                                     
                                      <div className='file-item-description'>
                                          <div>{getFileName(file.file).split(".")[0]}</div>
                                          <div>{getFileName(file.file).split(".")[1]}</div>
                                      </div>
                                  </a>
                              )
                          )
                      
                  }
              </div>
                           </>}
           <div className="message-show-reply">
            <span className="message-show-reply" onClick={()=>{
                let t=highlightConversationIds
                if(showReply && showReply!=viewReplyIds)
                   t=t.filter(i=>i!=showReply)
                setShowReply(item.id);
                t.push(item.id)
                setHighlightConversationIds(t)
                
            }}><img src={replyIcon} alt="reply"/>
              <span>Add a comment here</span></span>
              
              {item.reply_count!==0 && 
              <span>
                { viewReplyIds==item.id?  
                <span className="reply-highlight" onClick={()=>handleHideReply(item.id)}>Hide replies</span>: 
                <span className="reply-highlight" onClick={()=>handleGetReplies(item.id)}>View {item.reply_count} {item.reply_count>1?'comments':'comment'}</span>}</span>}
             
           </div>
           {viewReplyIds===item.id &&<div className="message-replies">
           {replies.map((reply,index)=> 
           <div className={`message-block-item `} key={`message-block-${index}`}>
           <div className="person-img">
           {reply.created_by.avatar?<img src={reply.created_by.avatar} alt="avatar"/>:
            <span className="person-img-default">{`${reply.created_by.first_name.charAt(0)} ${reply.created_by.last_name.charAt(0)}`} </span>
}
           </div>
           <div className="message-block-item-content">
           <div className="message-block-item-content-header">
             <div className="message-block-item-title">{reply.created_by.first_name} {reply.created_by.last_name}</div>
             <div className="message-block-item-time"> <Moment format="LT , MM/DD/YYYY" tz={profile.timezone}>{reply.created_at}</Moment></div>
           </div>
           <div className="message-block-item-message"> <ReactMarkdown rehypePlugins={[rehypeRaw]} children={reply.reply} /></div>
           {reply.attachment.length>0 && <> <div className='documents detail'>
                  Documents :
              </div>
              <div className='files'>
                  {
                      
                          reply?.attachment?.map((file, index) => 
                              (
                                  <a href={file.file} key={file.id} target="_blank" className='file-item' rel="noreferrer">
                                     
                                      <div className='file-item-description'>
                                          <div>{getFileName(file.file).split(".")[0]}</div>
                                          <div>{getFileName(file.file).split(".")[1]}</div>
                                      </div>
                                  </a>
                              )
                          )
                      
                  }
              </div>
                           </>}
         </div>
         </div>)}
            </div>}
           {showReply && showReply===item.id && <MessageReply messageID={item.id}/>}
        </div>
    </div>)
    : <div className="noReplyMessage">No Replies Yet</div> }
        </div>
   
        </div>
    )
}
export default Conversation;