import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import '../../assets/css/Notifications.css';
import emptyBox from '../../assets/images/empty-box.svg';
import Moment from 'react-moment';
import { getNotifications } from '../../actions';
import { week, year } from "../../helpers/constants"
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';
const Notifications = () => {
    const dispatch = useDispatch();
    const notifications = useSelector(state => state.notifications)
    console.log("NNotifications", notifications)
    const isLoading = useSelector(state => state.isLoading)
    const profile = useSelector(state => state.profile)
    const formatDate = (d) => {
        let temp = d.split("-");
        let date = new Date(`${temp[1]}/${temp[2]}/${temp[0]}`);
        return `${week[date.getDay()]},${year[date.getMonth()]} ${date.getDate()}`
    }
    useEffect(() => {
        dispatch(getNotifications())
    }, [dispatch])
    if (!isLoading)
        return (
            <div className='notification-page'>
                {
                    notifications.length > 0 ? notifications.map((item, index) =>
                        <div className='notification-box admin-notification' key={`notif-day-${index}`}>
                            <div className='notification-day'>{formatDate(item.date)}</div>
                            {
                                item.notifications.map((notification, index) => {
                                    return (
                                        <div className='notification' key={`notif-${index}`}>
                                            <div className='person-img'>
                                                {notification.user_ref.avatar ? <img src={notification.user_ref.avatar} alt="avatar" /> :
                                                    <div className='person-img-default'>{notification.user_ref.fullName.split(" ")[0].charAt(0)}{notification.user_ref.fullName.split(" ")[1] ? notification.user_ref.fullName.split(" ")[1].charAt(0) : notification.user_ref.fullName.split(" ")[0].charAt(1)}</div>}
                                            </div>
                                            <div className='notification-content'>
                                                <div>
                                                    <div className='notification-line'>
                                                        <p>
                                                            <span className='person-name'>{notification.user_ref.fullName}</span>
                                                            <span> <ReactMarkdown rehypePlugins={[rehypeRaw]} disallowedElements={['li']} children={notification?.notif} /></span>
                                                            <span className='place'>"{notification.project_ref.project_name}"</span>
                                                        </p>
                                                        {!notification.viewed && <span className='tag view-request'>View request</span>}
                                                    </div>

                                                </div>
                                                <div className='notification-time'>
                                                    <Moment format="LT" tz={profile.timezone}>{notification.created_at}</Moment>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    )
                        :
                        (
                            <div className='empty-box'>
                                <div className='activity-board-header'>Your Notifications</div>
                                <div className="dialogue-box-activity">
                                    <div className="dialogue-box-activity-heading">
                                        Nothing's happened yet!
                                    </div>
                                    <div className="dialogue-box-activity-text">
                                        But as soon as your project gets accepted, every activity that's happening in your project will be summarized for you right here.
                                    </div>
                                </div>
                                <img src={emptyBox} alt="empty" />
                            </div>
                        )
                }
            </div>
        );
};

export default Notifications;