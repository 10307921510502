const RemoveIcon=(props)=>(
    <svg width="24" height="24" viewBox="0 0 24 24"  xmlns="http://www.w3.org/2000/svg">
<g  clip-path="url(#clip0_1662_3074)">
<circle cx="12" cy="12" r="12" fill="white" fill-opacity={props.opacity}/>
<path d="M8.42031 16.773L12 13.1932L15.5798 16.773C15.9088 17.102 16.444 17.102 16.773 16.773C17.1021 16.4439 17.1021 15.9088 16.773 15.5797L13.1933 12L16.773 8.42027C17.1021 8.09123 17.1021 7.55607 16.773 7.22703C16.444 6.89799 15.9088 6.89799 15.5798 7.22703L12 10.8068L8.42031 7.22703C8.09128 6.89799 7.55611 6.89799 7.22707 7.22703C6.89803 7.55607 6.89803 8.09123 7.22707 8.42027L10.8068 12L7.22707 15.5797C6.89803 15.9088 6.89803 16.4439 7.22707 16.773C7.55611 17.102 8.09128 17.102 8.42031 16.773Z" fill={props.fill}/>

</g>
<defs>
<clipPath id="clip0_1662_3074">
<rect width="24" height="24" />
</clipPath>
</defs>
</svg>

)
export default RemoveIcon;
