import React,{useState} from "react";
import { Link } from "react-router-dom";
import * as validate from "../../../helpers/validation";
import bg5 from "../../../assets/images/bg-5.png";
import arrowRight from "../../../assets/icons/arrow-right.svg";
import infoSuccess from "../../../assets/icons/info-success.svg";
import { useDispatch,useSelector } from "react-redux";
import { sendResetEmail } from "../../../actions";
import Notification from "../../notification";
import warning from "../../../assets/icons/warning.svg";
import logo from "../../../assets/images/logo.png";
import bgLogo from "../../../assets/icons/bg-logo.svg";
import { Carousel } from "react-responsive-carousel";
import { useLocation } from "react-router-dom";
const ForgotPassword=({back})=>{
    const dispatch=useDispatch();
    const location  = useLocation();
    const [form,setForm]=useState({email:""});
    const [emailErr,setEmailErr]=useState(null)
    const images=useSelector(state=>state.auth_images)
  const filteredImageUrls = Object.entries(images)
  .filter(([key, value]) => value !== null)
  .map(([key, value]) => ({ [key]: value }));
    const sentResetEmail=useSelector(state=>state.sentResetEmail)
    const verify=()=>{
        return validate.validateEmail(form.email);
     }
     const handleChange=(e)=>{
        if(validate.validateEmail(e.target.value))
          setEmailErr(null)
        else
         setEmailErr("Not a valid email id")
        setForm({...form,[e.target.name]:e.target.value})
     }
    const handleSubmit=(e)=>{
        e.preventDefault();
        dispatch(sendResetEmail(form))
    }
    console.log(sentResetEmail)
    return (<div className="auth-page">
        <div className="auth-background">
            <div className="auth-overlay">
            <div className="auth-content">
                    <div>
                    <Link to="/" className="logo-link">
                <img className="bgLogo" src={bgLogo} alt="logo"/>
                <p className="logo-cgi">CGI</p>
                </Link>
                    </div>
                    <div className="auth-content-text">MEMBERS PORTAL</div>
                    {/* <div className="overlay-highlight">JOIN MEDIUM</div>
                    <div className="overlay-text">ARCHITECTURE +<br/>INTERIOR DESIGN</div> */}
                </div>
            </div>
            {filteredImageUrls?.length>0 &&
            <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true}  showArrows={false} showStatus={false}>
            {
               filteredImageUrls.map((e,i)=>(    
                <div key={i}>
                    <img src={Object.values(e)} className="bg-img" alt="carousel"/>
                </div>
               ))
            }
            </Carousel>
           }
        </div>
        <div className="form-bg">
            <div className="default-header">
                <Link to="/"><img className="bgLogo" src={bgLogo} alt="logo"/></Link>
                <div className="members-portal">MEMBERS PORTAL</div>
            </div>
        <form className="auth-form">
                <div className="form-heading" style={{alignSelf: location.pathname.slice(1)==='login'?'start':'center'}}>Forgot Your Password?</div>
                <div className="form-subheading">Enter your email address below and we’ll send you password reset instructions.</div>
                <div className="auth-form-content">
                <div className="form-input-box">
              <div className="form-label">Email address</div>
              <input className={`form-input ${emailErr && 'input-invalid'}`} type="email" name="email"  onChange={handleChange}/>
              {emailErr && <div className="input-error">
                    <img src={warning} alt="email warning"/>
                    <span>{emailErr}</span>
                    </div>}
            </div>
                
                <button className={`btn btn-${verify()?"green-fill":"grey"}`} onClick={handleSubmit}> <span>Email me reset instructions</span><img src={arrowRight} alt="reset"/></button>
                {!sentResetEmail &&
                <div className="forgot-undo" onClick={()=>back(2)}>Never mind, go back</div>
                }
                </div>
                {sentResetEmail && <Notification type="success" message="Check your email for reset instructions"/>}
                
            </form>
        </div>
          
        
    </div>)
}
export default ForgotPassword;