import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link,useLocation,useNavigate } from "react-router-dom";
import "../../assets/css/Admin/sidebar.css";
import logo from "../../assets/images/logo.png";
import allocation from "../../assets/icons/allocation.svg";
import ppl from "../../assets/icons/ppl.svg";
import quotes from "../../assets/icons/quotes.svg";
import HomeIcon from "../../assets/icons/home-icon";
import NotificationIcon from "../../assets/icons/notification-icon";
import ProfileIcon from "../../assets/icons/profile-icon";
import LogoutIcon from "../../assets/icons/logout-icon";
import { getNotificationCount, logout } from "../../actions";
const SideBar=()=>{
    const dispatch=useDispatch();
  let location=useLocation();
  const navigate=useNavigate();
  const profile=useSelector(state=>state.profile);
  const adminNoti=useSelector(state=>state.unread_admin_notification);
  const unreadQuotes=useSelector(state=>state.unread_quotes);
  const [userType,setUserType] = useState("")
  const prodTeam=sessionStorage.getItem("prodTeam");
  const handleLogout=()=>{
    dispatch(logout());
   
    navigate("/");
  }

  useEffect(()=>{
    if(profile?.user_type === "pm"){
        setUserType("MANAGER")
    }
    else if(profile?.user_type === "admin"){
        setUserType("ACCOUNTANT")
    }
    else{
        setUserType("ADMIN")
    }
    dispatch(getNotificationCount())
  },[])

  let pathname=location.pathname.split("/");
    return <div className="sidebar" style={{display:`${prodTeam && "none"}`}}>
        <ul>
        <div className="logo-container">
            <Link className="logo" to="/"><img src={logo} alt="logo"/></Link>
        </div>
        <div className="portal-type" style={{marginBottom : "10px"}}>{userType} PORTAL</div>
        <div style={{textAlign : "center"}}>
        <img src={profile?.avatar ? profile?.avatar : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"} alt="profile pic" width={60} height={60} style={{borderRadius : "50px",textAlign : "center",objectFit : "cover"}}/>
        </div>
            
        <div className="sidebar-title">{profile?.fullName}</div>
        <Link className={`navbar-item ${pathname[1]?'':'selected'}`} to='/'>
            <HomeIcon fill="white"/>
            <span>Home</span>
        </Link>
        <Link className={`navbar-item ${pathname[1]==='notifications'?'selected':''}`} to='/notifications'>
            <NotificationIcon fill="white"/>
            <span>Notifications</span>
            {
                adminNoti !== 0 && <span className="board-count-act">{adminNoti}</span>
            }
        </Link>
        {
            profile.user_type === "superadmin" &&
            <Link className={`navbar-item ${pathname[1]==='quotes'?'selected':''}`} to='/quotes'>
                <img src={quotes} alt="quotes"/>
                <span>Quotes</span>
                {
                    unreadQuotes !== 0 && unreadQuotes !== undefined && <span className="board-count-act">{unreadQuotes}</span>
                }
                
            </Link>
        }
        <Link className={`navbar-item ${pathname[1]==='ppl'?'selected':''}`} to='/ppl'>
            <img src={ppl} alt="ppl"/>
            <span>PPL</span>
        </Link>
        {
            (profile.user_type === "superadmin") &&
            <Link className={`navbar-item ${pathname[1]==='allocation'?'selected':''}`} to='/allocation'>
                <img src={allocation} alt="allocation"/>
                <span>Allocation</span>
            </Link>
        }
        <Link className={`navbar-item ${pathname[1]==='profile'?'selected':''}`} to='/profile'>
            <ProfileIcon fill="white"/>
            <span>Profile</span>
        </Link>
        <div  className='navbar-item logout sidebar-logout' onClick={handleLogout} >
            <LogoutIcon fill="white"/>
            <span>Sign out</span>
        </div>
        </ul>
    </div>
}
export default SideBar