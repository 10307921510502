import React from "react";
import quickbook from "../assets/images/quickbook.png";
import backArrow from "../assets/icons/back-arrow.svg"
import "../assets/css/disconnect.css"
import { useNavigate } from "react-router-dom";

const Disconnect = () => {

  const navigate = useNavigate()
  return (
    <div className="disconnect">
      <img src={quickbook} alt="quickbook" />
      <p>
      You've Disconnected your account from Quick Book
      </p>
      <button className="btn btn-green-outline" onClick={()=>navigate("/")}> <img src={backArrow} alt="" /> Go back</button>
    </div>
  );
};

export default Disconnect;
