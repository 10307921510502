import React, { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import CreateProjectModal from './../CreateProjectModal';
import './../../assets/css/tutorial.css';
import arrowRight from './../../assets/icons/arrow-right.svg';
// import arrowLeft from './../../assets/icons/arrow-left.svg';
import plus from "./../../assets/icons/plus.svg";
import HomeIcon from "../../assets/icons/home-icon";
import NotificationIcon from "../../assets/icons/notification-icon";
import MagnifyIcon from "../../assets/icons/magnify-icon";
import FolderIcon from "../../assets/icons/folder-icon";
import GridIcon from "../../assets/icons/grid-icon";
import ProfileIcon from "../../assets/icons/profile-icon";
import ActivityIcon from "../../assets/icons/activity-icon";
import EllipseDark from "./../../assets/icons/ellipse-dark.svg";
import EllipseLight from "./../../assets/icons/ellipse-light.svg";
// import Search from './Search';
import LogoutIcon from '../../assets/icons/logout-icon';
import TutorialMobile from './TutorialMobile';

const Tutorial = ({showTutorial, skipTutorial, handleShow, handleOpenNotificationSettings,setShowMenu,showMenu}) => {
    const [serial, setSerial] = useState(1);
    const [show, setShow] = useState(false);
    const [tutorial,setTutorial] = useState(0)
    // const [showSearch,setShowSearch]=useState(false);
    const handleCountIncrease = () => {
        if (serial < 9) {
            setSerial(serial + 1);
        }
    };
    useEffect(()=>{
        if(window.innerWidth < 850){
            setTutorial(1)
        }
        else{
            setTutorial(0)
        }
    },[showTutorial])

    const handleCountDecrease = () => {
        if (serial > 1) {
            setSerial(serial - 1);
        }
    };
    const closeDropdown=()=>{
        skipTutorial();
        handleShow(true);
    }
    useEffect(() => {
        console.log(serial);
    }, [serial]);
    const handleShowModal = () => setShow(true);
    const handleClose = () => {setShow(false);}
    const handleSearchTabClick=()=>{
        skipTutorial();
        // setShowSearch(!showSearch)
    }
    return (
        <>
            {
                showTutorial && (tutorial === 0 ? (
                    <div className="tutorial-page">
                        {
                            serial === 1 && (
                                <div className="">
                                    <div className='tutorial-new-project-btn'>
                                        <button style={{marginLeft:'15px'}} onClick={()=>{skipTutorial();handleShowModal();}} className='btn btn-green-fill'>New Project <img src={plus} alt="new project"/></button>
                                    </div>
                                    <div className='tutorial-text tutorial1-text'>
                                        <div>
                                            <span className='tutorial-text-heading'><img src={plus} alt="new project"/> New Project</span>
                                            <span>Enter your project details here, and submit a project request to be accepted by the admin.</span>
                                            <div className='pagination-ellipse'>
                                                <img src={EllipseLight} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                            </div>
                                            <div className='tutorial-text-footer'>
                                                <div onClick={()=>skipTutorial()}>Skip</div>
                                                <div className='pagination-btn'>
                                                    <div onClick={handleCountDecrease} className={`prev-btn ${serial===1 && 'pagination-grey'}`}>
                                                        <span>{"<"}</span>
                                                        <span>Prev</span>
                                                    </div>
                                                    <div onClick={handleCountIncrease} className={`next-btn ${serial===9 && 'pagination-grey'}`}>
                                                        <span>Next</span>
                                                        <span>{">"}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        {
                            <div className='navbar tutorial-navbar'>
                                <div className='tutorial tut-resp-2' style={{visibility: serial===2 ? 'visible' : 'hidden', marginLeft: "6rem"}}>
                                    <Link onClick={()=>skipTutorial()} className={`navbar-item tutorial-navbar-item`} to='/'>
                                        <HomeIcon fill="black"/>
                                        <span>Home</span>
                                    </Link>
                                    {
                                        serial===2 && (
                                            <>
                                                <div className='tutorial-pointer'></div>
                                                <div className='tutorial-text'>
                                                    <div>
                                                        <span className='tutorial-text-heading'><HomeIcon fill="white"/> Home</span>
                                                        <span>All your projects will appear here</span>
                                                        <div className='pagination-ellipse'>
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseLight} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                        </div>
                                                        <div className='tutorial-text-footer'>
                                                            <div onClick={()=>skipTutorial()}>Skip</div>
                                                            <div className='pagination-btn'>
                                                                <div onClick={handleCountDecrease} className={`prev-btn ${serial===1 && 'pagination-grey'}`}>
                                                                    <span>{"<"}</span>
                                                                    <span>Prev</span>
                                                                </div>
                                                                <div onClick={handleCountIncrease} className={`next-btn ${serial===9 && 'pagination-grey'}`}>
                                                                    <span>Next</span>
                                                                    <span>{">"}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                                <div className='tutorial tut-resp-3' style={{visibility: serial===3 ? 'visible' : 'hidden'}}>
                                    <Link onClick={()=>skipTutorial()} className={`navbar-item tutorial-navbar-item`} to='/notifications'>
                                        <NotificationIcon fill="black"/>
                                        <span>Notifications</span>
                                    </Link>
                                    {
                                        serial===3 && (
                                            <>
                                                <div className='tutorial-pointer'></div>
                                                <div className='tutorial-text'>
                                                    <div>
                                                        <span className='tutorial-text-heading'><NotificationIcon fill="white"/> Notifications</span>
                                                        <span>Check all the notifications/alerts regarding your project here</span>
                                                        <div className='pagination-ellipse'>
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseLight} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                        </div>
                                                        <div className='tutorial-text-footer'>
                                                            <div onClick={()=>skipTutorial()}>Skip</div>
                                                            <div className='pagination-btn'>
                                                                <div onClick={handleCountDecrease} className={`prev-btn ${serial===1 && 'pagination-grey'}`}>
                                                                    <span>{"<"}</span>
                                                                    <span>Prev</span>
                                                                </div>
                                                                <div onClick={handleCountIncrease} className={`next-btn ${serial===9 && 'pagination-grey'}`}>
                                                                    <span>Next</span>
                                                                    <span>{">"}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                                <div className='tutorial tut-resp-4' style={{visibility: serial===4 ? 'visible' : 'hidden'}}>
                                    <Link onClick={()=>skipTutorial()} className={`navbar-item tutorial-navbar-item`} to='/activity'>
                                        <ActivityIcon fill="black"/>
                                        <span>Activity</span>
                                    </Link>
                                    {
                                        serial===4 && (
                                            <>
                                                <div className='tutorial-pointer'></div>
                                                <div className='tutorial-text'>
                                                    <div>
                                                        <span className='tutorial-text-heading'><ActivityIcon fill="white"/> Activity</span>
                                                        <span>Check detailed activity that’s happening in your projects</span>
                                                        <div className='pagination-ellipse'>
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseLight} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                        </div>
                                                        <div className='tutorial-text-footer'>
                                                            <div onClick={()=>skipTutorial()}>Skip</div>
                                                            <div className='pagination-btn'>
                                                                <div onClick={handleCountDecrease} className={`prev-btn ${serial===1 && 'pagination-grey'}`}>
                                                                    <span>{"<"}</span>
                                                                    <span>Prev</span>
                                                                </div>
                                                                <div onClick={handleCountIncrease} className={`next-btn ${serial===9 && 'pagination-grey'}`}>
                                                                    <span>Next</span>
                                                                    <span>{">"}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                                <div className='tutorial tut-resp-5' style={{visibility: serial===5 ? 'visible' : 'hidden'}}>
                                    <div onClick={handleSearchTabClick} className={`navbar-item tutorial-navbar-item`}>
                                        <MagnifyIcon fill="black"/>
                                        <span>Search</span>
                                    </div>
                                    {
                                        serial===5 && (
                                            <>
                                                <div className='tutorial-pointer'></div>
                                                <div className='tutorial-text'>
                                                    <div>
                                                        <span className='tutorial-text-heading'><MagnifyIcon fill="white"/> Search</span>
                                                        <span>Search for projects and people associated to your project</span>
                                                        <div className='pagination-ellipse'>
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseLight} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                        </div>
                                                        <div className='tutorial-text-footer'>
                                                            <div onClick={()=>skipTutorial()}>Skip</div>
                                                            <div className='pagination-btn'>
                                                                <div onClick={handleCountDecrease} className={`prev-btn ${serial===1 && 'pagination-grey'}`}>
                                                                    <span>{"<"}</span>
                                                                    <span>Prev</span>
                                                                </div>
                                                                <div onClick={handleCountIncrease} className={`next-btn ${serial===9 && 'pagination-grey'}`}>
                                                                    <span>Next</span>
                                                                    <span>{">"}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                                <div className='tutorial tut-resp-6' style={{visibility: serial===6 ? 'visible' : 'hidden'}}>
                                    <Link onClick={()=>skipTutorial()} className={`navbar-item tutorial-navbar-item`} to='/project-status'>
                                        <FolderIcon fill="black"/>
                                        <span>Project status</span>
                                    </Link>
                                    {
                                        serial===6 && (
                                            <>
                                                <div className='tutorial-pointer'></div>
                                                <div className='tutorial-text'>
                                                    <div>
                                                        <span className='tutorial-text-heading'><FolderIcon fill="white"/> Project Status</span>
                                                        <span>Check the status of your projects, proposals and payments here</span>
                                                        <div className='pagination-ellipse'>
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseLight} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                            <img src={EllipseDark} alt="" />
                                                        </div>
                                                        <div className='tutorial-text-footer'>
                                                            <div onClick={()=>skipTutorial()}>Skip</div>
                                                            <div className='pagination-btn'>
                                                                <div onClick={handleCountDecrease} className={`prev-btn ${serial===1 && 'pagination-grey'}`}>
                                                                    <span>{"<"}</span>
                                                                    <span>Prev</span>
                                                                </div>
                                                                <div onClick={handleCountIncrease} className={`next-btn ${serial===9 && 'pagination-grey'}`}>
                                                                    <span>Next</span>
                                                                    <span>{">"}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                </div>
                            </div>
                        }
                        {
                            serial === 7 && (
                                <div className="">
                                    <div className={`user-header-dropdown tutorial-user-header-dropdown`}>
                                        <div onClick={closeDropdown} className={`navbar-item `}>
                                            <ProfileIcon fill="white"/>
                                            <span>Profile</span>
                                        </div> 
                                        <div className={`navbar-item`}>
                                            <NotificationIcon fill="white"/>
                                            <span>Notification settings</span>
                                        </div>
                                        <div className={`navbar-item`}>
                                            <GridIcon fill="white"/>
                                            <span>Tutorial</span>
                                        </div>
                                        <div className='navbar-item logout'>
                                            <LogoutIcon fill="white"/>
                                            <span>Sign out</span>
                                        </div>
                                    </div>
                                    <div className='tutorial-text tutorial7-text'>
                                        <div>
                                            <span className='tutorial-text-heading'><ProfileIcon fill="white"/> Profile</span>
                                            <span>Update your profile settings here</span>
                                            <div className='pagination-ellipse'>
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseLight} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                            </div>
                                            <div className='tutorial-text-footer'>
                                                <div onClick={()=>skipTutorial()}>Skip</div>
                                                <div className='pagination-btn'>
                                                    <div onClick={handleCountDecrease} className={`prev-btn ${serial===1 && 'pagination-grey'}`}>
                                                        <span>{"<"}</span>
                                                        <span>Prev</span>
                                                    </div>
                                                    <div onClick={handleCountIncrease} className={`next-btn ${serial===9 && 'pagination-grey'}`}>
                                                        <span>Next</span>
                                                        <span>{">"}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>       
                                </div>
                            )
                        }
                        {
                            serial === 8 && (
                                <div className="">
                                    <div className={`user-header-dropdown tutorial-user-header-dropdown`}>
                                        <div className={`navbar-item `}>
                                            <ProfileIcon fill="white"/>
                                            <span>Profile</span>
                                        </div> 
                                        <div onClick={()=>{skipTutorial();handleOpenNotificationSettings();}} className={`navbar-item`}>
                                            <NotificationIcon fill="white"/>
                                            <span>Notification settings</span>
                                        </div>
                                        <div className={`navbar-item`}>
                                            <GridIcon fill="white"/>
                                            <span>Tutorial</span>
                                        </div>
                                        <div  className='navbar-item logout'>
                                            <LogoutIcon fill="white"/>
                                            <span>Sign out</span>
                                        </div>
                                    </div>
                                    <div className='tutorial-text tutorial8-text'>
                                        <div>
                                            <span className='tutorial-text-heading'><NotificationIcon fill="white"/> Notification Settings</span>
                                            <span>Setup and update you notification settings here</span>
                                            <div className='pagination-ellipse'>
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseLight} alt="" />
                                                <img src={EllipseDark} alt="" />
                                            </div>
                                            <div className='tutorial-text-footer'>
                                                <div onClick={()=>skipTutorial()}>Skip</div>
                                                <div className='pagination-btn'>
                                                    <div onClick={handleCountDecrease} className={`prev-btn ${serial===1 && 'pagination-grey'}`}>
                                                        <span>{"<"}</span>
                                                        <span>Prev</span>
                                                    </div>
                                                    <div onClick={handleCountIncrease} className={`next-btn ${serial===9 && 'pagination-grey'}`}>
                                                        <span>Next</span>
                                                        <span>{">"}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>       
                                </div>
                            )
                        }
                        {
                            serial === 9 && (
                                <div className="">
                                    <div className={`user-header-dropdown tutorial-user-header-dropdown`}>
                                        <div className={`navbar-item `}>
                                            <ProfileIcon fill="white"/>
                                            <span>Profile</span>
                                        </div> 
                                        <div className={`navbar-item`}>
                                            <NotificationIcon fill="white"/>
                                            <span>Notification settings</span>
                                        </div>
                                        <div className={`navbar-item`}>
                                            <GridIcon fill="white"/>
                                            <span>Tutorial</span>
                                        </div>
                                        <div className='navbar-item logout'>
                                            <LogoutIcon fill="white"/>
                                            <span>Sign out</span>
                                        </div>
                                    </div>
                                    <div className='tutorial-text tutorial9-text'>
                                        <div>
                                            <span className='tutorial-text-heading'><GridIcon fill="white"/> Tutorial</span>
                                            <span>Watch tutorials anytime you get lost</span>
                                            <div className='pagination-ellipse'>
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseDark} alt="" />
                                                <img src={EllipseLight} alt="" />
                                            </div>
                                            <div className='tutorial-text-footer'>
                                                <div onClick={()=>skipTutorial()}>Skip</div>
                                                <div className='pagination-btn'>
                                                    <div onClick={handleCountDecrease} className={`prev-btn ${serial===1 && 'pagination-grey'}`}>
                                                        <span>{"<"}</span>
                                                        <span>Prev</span>
                                                    </div>
                                                    <div onClick={handleCountIncrease} className={`next-btn ${serial===9 && 'pagination-grey'}`}>
                                                        <span>Next</span>
                                                        <span>{">"}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>       
                                </div>
                            )
                        }
                        {/* <div className="tutorials-pagination">
                            <div className='pagination-btn'>
                                <div onClick={handleCountDecrease} className={`${serial===1 && 'pagination-grey'}`}>{"<"}</div>
                                <div>|</div>
                                <div onClick={handleCountIncrease} className={`${serial===9 && 'pagination-grey'}`}>{">"}</div>
                            </div>
                        </div> */}
                    </div>
                ) : (
                    <TutorialMobile showTutorial={showTutorial} skipTutorial={skipTutorial} handleShow={handleShow} handleOpenNotificationSettings={handleOpenNotificationSettings} showMenu={showMenu} setShowMenu={setShowMenu}/>
                ))
            }
            {/* {showSearch && <Search/>} */}
            <CreateProjectModal show={show} handleClose={handleClose} />
        </>
    );
};

export default Tutorial;