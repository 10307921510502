import React, { useEffect, useState } from "react";
import "../../../assets/css/modal.css";

const ChequePayment = ({setShowChequeModal, showChequeModal}) => {

  const handleClick = (e) => {
    if (
      e.target.classList.contains("modal") ||
      e.target.classList.contains("modal-close")
    ) {
        setShowChequeModal(false);
    }
  };

  return (
    <>
      {showChequeModal && (
        <div onClick={handleClick} className="modal">
          <div className="modal-content">
          <div className="modal-title">Mail Us</div>
            <div className="modal-close" onClick={handleClick}>
              X
            </div>
            <div className="mail-info" style={{lineHeight : "40px",fontSize : "20px"}}>
            <p>
            Instructions to Mailing Checks:
            </p>
                <ol className="mail-list">
                  <li>Make Check payable to PANORAM CGI,LLC</li>
                  <li>Please send check to the address on the signed agreement.</li>
                  <li>Notify the billing department that your check has been mailed by sending an email to <a href="mailto:billing@panoramcgi.com">billing@panoramcgi.com</a></li>
                </ol>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChequePayment;
