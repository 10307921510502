import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import arrowLeft from "../../../assets/icons/arrow-left.svg";
import DownloadIcon from "../../../assets/icons/download-icon";
import { saveEditImage } from '../../../actions';
import { PinturaEditor } from "@pqina/react-pintura";
import { getEditorDefaults } from "@pqina/pintura";

import "@pqina/pintura/pintura.css";
import "../../.././assets/css/Dashboard/review-board.css";

import { getUserProject,getAdminProject, getEditImage } from '../../../actions';
import {saveAs} from "file-saver";
const MarkupTools=()=>{
   const {imageID,projectID}=useParams()
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const {edit_image,isLoading,project,profile}=useSelector(state=>state)
    console.log(edit_image)
   
    const openImgEditor = () => {
      //setIsImgEditorShown(true);
    };
  
    

    const handleSave=(editedImageObject)=>{
      console.log(editedImageObject)
      let formData=new FormData()
      formData.append("image",editedImageObject)
      dispatch(saveEditImage(imageID,formData))
      // const base64Response = await fetch(`${editedImageObject.imageBase64}`);
      // const blob = await base64Response.blob();
      // const link = document.createElement('a');
      //     link.href = window.URL.createObjectURL(blob);
      //     link.download = `${+new Date()}.${editedImageObject.extension}`;
      //     console.log(link)
      //     link.click();
    }

    const handleDownloadImg=(url)=>{
      console.log(url)
      saveAs(url, "Download-Image");
  }

    useEffect(()=>{
      if(projectID)
      {
        if(profile.user_type==='superadmin')
        dispatch(getAdminProject(projectID))
        else
       dispatch(getUserProject(projectID))
      }
     
      if(imageID)
      dispatch(getEditImage(imageID))
    },[dispatch])
    if(!isLoading && project)
 return (
    <div className={`${profile.user_type==='superadmin'?'admin-project-dashboard':''}`}>
  
<div className="board-header">
   <button style={{justifySelf:'flex-start'}} onClick={()=>navigate(-1)} className='btn btn-green-outline' >
        <img src={arrowLeft} alt="back"/>
        <span>Back</span>
    </button>
    <div className="board-title">
    <div className="board-title-main">{project.project_name}</div>
    </div>
    <div className="board-header-btn-block">
    </div>
</div>
<div className="review-board">
    <div className="review-board-header">
        <div className="review-header-actions">
            
            <div className="review-header-filename"><span style={{color:'white'}}>{edit_image?.name}</span> | <span style={{color:'#D6D6D6'}}>Version {edit_image?.versionnum}</span></div>
        </div>
        
        <div className="review-header-actions">
          <button className="download-img-btn" onClick={()=>{handleDownloadImg(edit_image.file)}}><DownloadIcon fill="white"/></button>  
        </div>
    </div>
    
    <div className="review">
    {edit_image && (
              <div style={{ height: "120%",width:"100%" }}>
                <PinturaEditor
                  {...getEditorDefaults()}
                  layoutVerticalControlGroupsPreference={"top"}
                  utils={['annotate']}
                  src={edit_image.file}
                  onProcess={(file) => {
                    navigate(-1)
                    console.log(file);
                    const { dest } = file;
                    const convertBase64 = (dest) => {
                      return new Promise((resolve, reject) => {
                        const fileReader = new FileReader();
                        fileReader.readAsDataURL(dest);

                        fileReader.onload = () => {
                          resolve(fileReader.result);
                          handleSave(fileReader.result);
                        };

                        fileReader.onerror = (error) => {
                          reject(error);
                        };
                      });
                    };
                    convertBase64(dest);
                  }}
                />
              </div>
            )}
    </div>
</div>
</div>
 )
}
export default MarkupTools