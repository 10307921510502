export const SET_LOADING="SET_LOADING";
export const SENT_RESET_EMAIL="SENT_RESET_EMAIL";
export const SET_AUTH="SET_AUTH";
export const AUTH_IMAGES="AUTH_IMAGES";
export const SET_ADMIN="SET_ADMIN";
export const SET_MESSAGE="SET_MESSAGE";
export const LOGOUT="LOGOUT";
export const SET_OTP_TOKEN="SET_OTP_TOKEN";
export const API=process.env.REACT_APP_API;
export const SET_PROFILE="SET_PROFILE";
export const SET_FIRST_LOGIN="SET_FIRST_LOGIN";
export const SET_CURRENT_PROJECT="SET_CURRENT_PROJECT";
export const GET_QUOTES="GET_QUOTES"
export const GET_PPL="GET_PPL";
export const GET_PROJECTS="GET_PROJECTS";
export const SEARCH_PROJECTS="SEARCH_PROJECTS";
export const UPDATE_PROJECTS="UPDATE_PROJECTS";
export const SET_NOTIFICATION_SETTINGS="SET_NOTIFICATION_SETTINGS";
export const GET_ALL_NOTIFICATIONS="GET_ALL_NOTIFICATIONS";
export const EDIT_PPL="EDIT_PPL";
export const week=['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
export const year=['January',' February',' March',' April',' May',' June',' July',' August',' September',' October',' November','December']
export const GET_PROJECT_STATUS="GET_PROJECT_STATUS";
export const ALL_USERS="ALL_USERS"
export const ALL_PROJECTS="ALL_PROJECTS"
export const all_project_status=["pending","active","completed","cancelled"]
export const SET_INVITATIONS="SET_INVITATIONS"
export const SET_UNREAD_MESSAGE_COUNT="SET_UNREAD_MESSAGE_COUNT"
export const SET_UNREAD_TODO_COUNT="SET_UNREAD_TODO_COUNT"
export const SET_UNREAD_ACTIVITY_COUNT="SET_UNREAD_ACTIVITY_COUNT"
export const SET_UNREAD_CLIENT_NOTIFICATION_COUNT="SET_UNREAD_CLIENT_NOTIFICATION_COUNT"
export const SET_UNREAD_ADMIN_NOTIFICATION_COUNT="SET_UNREAD_ADMIN_NOTIFICATION_COUNT"
export const SET_UNREAD_QUOTE_COUNT="SET_UNREAD_QUOTE_COUNT"
export const SET_MESSAGES="SET_MESSAGES"
export const SET_REPLIES="SET_REPLIES"
export const SET_MESSAGE_CATEGORIES="SET_MESSAGE_CATEGORIES"
export const CREATE_MESSAGE_CATEGORY="CREATE_MESSAGE_CATEGORY"
export const EDIT_MESSAGE_CATEGORY="EDIT_MESSAGE_CATEGORY"
export const SET_CONVERSATION="SET_CONVERSATION"
export const DELETE_MESSAGE_CATEGORY="DELETE_MESSAGE_CATEGORY"
export const SET_TODO="SET_TODO"
export const ADD_TODO="ADD_TODO"
export const UPDATE_TODO="UPDATE_TODO"
export const TODO_DATES="TODO_DATES"
export const SET_CURRENT_TODO_DATE="SET_CURRENT_TODO_DATE"
export const SET_REVIEW_FILES="SET_REVIEW_FILES"
export const DELETE_REVIEW_FILES="DELETE_REVIEW_FILES"
export const SET_MARKERS="SET_MARKERS"
export const ADD_MARKER="ADD_MARKER"
export const UPDATE_MARKER="UPDATE_MARKER"
export const DELETE_MARKER="DELETE_MARKER"
export const UPDATE_REVIEW_FILES="UPDATE_REVIEW_FILES"
export const SET_EDIT_IMAGE="SET_EDIT_IMAGE"
export const SAVE_EDIT_IMAGE="SAVE_EDIT_IMAGE"
export const SET_NOTES="SET_NOTES"
export const SET_COMPLETED_FILES="SET_COMPLETED_FILES"
export const SET_ACTIVITY="SET_ACTIVITY"
export const SET_SEARCH_LIST="SET_SEARCH_LIST"
export const SET_SEARCH_RESULT="SET_SEARCH_RESULT"
export const UPDATE_SEARCH_RESULT="UPDATE_SEARCH_RESULT"
export const SET_TIMEZONES="SET_TIMEZONES"
export const SET_PROJECT_TUTORIAL="SET_PROJECT_TUTORIAL"
export const SET_FIRST_PROJECT_VISIT="SET_FIRST_PROJECT_VISIT"
export const SHOW_REVIEW_FILE_TUTORIAL="SHOW_REVIEW_FILE_TUTORIAL"
export const serviceOptions = [
  { value: 'Photo-Realistic Rendering', label: 'Photo-Realistic Rendering' },
  { value: 'Conceptual Renderings', label: 'Conceptual Renderings' },
  { value: 'Walkthrough Animation', label: 'Walkthrough Animation' },
  { value: 'Flythrough Animation', label: 'Flythrough Animation' },
  { value: '360 Virtual Tour', label: '360 Virtual Tour' },
  { value: 'Virtual Staging', label: 'Virtual Staging' },
  { value: 'Interactive Imagery', label: 'Interactive Imagery' },
  { value: 'Colored Floor plans', label: 'Colored Floor plans' },
  { value: '3D Floor plans', label: '3D Floor plans' },
  { value: 'Drafting Services - BIM', label: 'Drafting Services - BIM' },
  { value: 'Drafting Services - CAD', label: 'Drafting Services - CAD' }
]
export const dayOptions = [
  { value:'Choose first day of week'},
  { value:'Monday'},
  { value:'Tuesday'},
  { value:'Wednesday'},
  { value:'Thursday'},
  { value:'Friday'},
  { value:'Saturday'},
  { value:'Sunday'}
]