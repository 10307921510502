import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationSettings,
  updateNotificationSettings,
} from "../../actions";
import { API } from "../../helpers/constants";
const NotificationSettings = ({
  handleCloseNotificationSettings
}) => {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.profile);
  const notificationSettings = useSelector(
    (state) => state.notificationSettings
  );
  const [defaultForm, setDefaultForm] = useState({});

  useEffect(() => {
    setDefaultForm(notificationSettings);
    console.log("notification settings:", notificationSettings);
  }, [notificationSettings]);

  useEffect(() => {
    dispatch(getNotificationSettings());
    setDefaultForm(notificationSettings);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = {
      notifyEverything: e.target.elements[0].checked,
      sendEmailNotifs: e.target.elements[2].checked,
      popUpNotif: e.target.elements[3].checked,
      numUnread: e.target.elements[4].checked,
      myDevices: e.target.elements[5].checked,
      alwaysAvailable: e.target.elements[6].checked,
    };

    console.log("finally:", form);

    dispatch(
      updateNotificationSettings(
        profile.id,
        form,
        JSON.parse(localStorage.getItem("isAuth")).access
      )
    );
    setDefaultForm(notificationSettings);
    handleCloseNotificationSettings();
  };
  return (
    <div className={`notification-settings-page`}>
      <form onSubmit={handleSubmit} className={`notification-settings`}>
        <div className="modal-title">Notification settings</div>
        <div className="notification-setting-block">
          <div className="notification-setting-heading">
            <div className="notification-setting-heading-name">What?</div>
            <div className="notification-setting-heading-bar"></div>
          </div>
          <div className="form-input-type">
            <input
              type="radio"
              id="what1"
              name="radioGroup1"
              defaultChecked={defaultForm?.notifyEverything}
            />
            <label htmlFor="what1" className="form-input-label-type">
              <div className="form-input-label-type-main">
                Notify me about everything
              </div>
              <div className="form-input-label-type-sub">
              This includes new messages and comments, to-dos assigned to you, and when someone @mentions you in chats and Pings.
              </div>
            </label>
          </div>
          <div className="form-input-type">
            <input
              type="radio"
              id="what2"
              name="radioGroup1"
              defaultChecked={!defaultForm?.notifyEverything}
            />
            <label htmlFor="what2" className="form-input-label-type">
              <div className="form-input-label-type-main">
                Only notify me when someone sends me a Ping or @mentions me
              </div>
              <div className="form-input-label-type-sub">
              We will only send you a notification when someone sends you a Ping or @mentions you anywhere. You can always check the Hey! menu manually to see what else is new for you.
              </div>
            </label>
          </div>
        </div>
        <div className="notification-setting-block">
          <div className="notification-setting-heading">
            <div className="notification-setting-heading-name">How?</div>
            <div className="notification-setting-heading-bar"></div>
          </div>
          <div className="form-input-type">
            <input
              type="checkbox"
              id="how1"
              defaultChecked={defaultForm?.sendEmailNotifs}
            />
            <label htmlFor="how1" className="form-input-label-type">
              <div className="form-input-label-type-main">
                Send me email notifications
              </div>
              <div className="form-input-label-type-sub">
              Note: To prevent your inbox from overflowing, we will bundle Pings together if they happen within a few minutes of each other. You won’t be emailed if you are actively participating in a Ping chat on your computer.
              </div>
            </label>
          </div>
          <div className="form-input-type">
            <input
              type="checkbox"
              id="how2"
              defaultChecked={defaultForm?.popUpNotif}
            />
            <label htmlFor="how2" className="form-input-label-type">
              <div className="form-input-label-type-main">
                Pop up notifications on my computer when Panoram is open
              </div>
              <div className="form-input-label-type-sub">
                Note: To prevent you from being annoyingly over notified, you
                won’t get pop-up alerts about a particular message, to-do, or
                chat if you’re looking right at it.
              </div>
            </label>
          </div>
          <div className="form-input-type">
            <input
              type="checkbox"
              id="how3"
              defaultChecked={defaultForm?.numUnread}
            />
            <label htmlFor="how3" className="form-input-label-type">
              <div className="form-input-label-type-main">
                Show the number of unread items
              </div>
              <div className="form-input-label-type-sub">
              You’ll see counts for each new Ping and Hey! notification when Panoram is open in your browser.
              </div>
            </label>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <input
            type="submit"
            className="btn btn-green-fill"
            value="Save Settings"
            style={{ marginRight: "15px" }}
          />
          <button
            className="btn btn-green-outline"
            onClick={handleCloseNotificationSettings}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};
export default NotificationSettings;
