import { useDispatch, useSelector } from "react-redux"
import { addNote } from "../../../actions"
import { useState } from "react"
import Moment from "react-moment"
const GeneralNotesModal=({generalNotes,setGeneralNotes})=>{
    const dispatch=useDispatch()
    const profile = useSelector(state => state.profile)
    const notes = useSelector(state => state.notes)
    const date = new Date();
    console.log(date)
    console.log(profile)
    const [newNote,setNewNote]=useState(false)
    const [note,setNote]=useState('')
    console.log(generalNotes)
    const handleAddNote=()=>{
        setNewNote(true)
    }
    const handleSaveNote=()=>{
        dispatch(addNote({
            "project_id": generalNotes,
            "notes": note,
        }))
        setNewNote(false)
    }
    const handleNotes=(e)=>{
        setNote(e.target.value)
    }
    return (
        <div className='modal-popup'>
        <div className='modal-popup-content'>
            <div className='modal-popup-content-title'>General Notes</div>
            <div className='modal-popup-content-body notes-container'>
                {
                    notes && notes.map((note,idx)=>(
                        <div className='note-container' key={idx}>
                            <div className="person-img">
                                {
                                    note?.createdBy?.avatar ?
                                    <img src={note?.createdBy?.avatar ? note?.createdBy?.avatar : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png"} alt="profile-img"/>
                                    :
                                    <span className="person-img-default">
                                        {`${note.createdBy?.first_name.charAt(0)} ${note.createdBy?.last_name.charAt(0)}`}
                                    </span>
                                }
                            </div>
                            <div className='notes-content'>
                                <div className='notes-desc'>
                                    <p className='writer-name'>{`${note?.createdBy?.first_name}. ${note?.createdBy?.last_name.charAt(0)}`}</p>
                                    <p className='date-time'>
                                        <Moment format="h:mma, dddd">{note?.created_at}</Moment>
                                    </p>
                                </div>
                                <div className='note'>
                                    <span>{note?.notes}</span>
                                </div>
                            </div>
                        </div>
                    ))
                }
                {
                    newNote &&
                    <div className='note-container'>
                         <div className="person-img">
                            {
                                profile?.avatar ?
                                <img src={profile.avatar} alt="profile-img"/>
                                :
                                <span className="person-img-default">
                                    {`${profile?.fullName.charAt(0)} ${profile?.fullName.charAt(0)}`}
                                </span>
                            }
                        </div>
                        <div className='notes-content'>
                            <div className='notes-desc'>
                                <p className='writer-name'>{profile?.fullName}</p>
                                <p className='date-time'>
                                    <Moment format="h:mma, dddd">{date}</Moment>
                                </p>
                            </div>
                            <input onChange={handleNotes} type="text" placeholder="type something.." />
                        </div>
                    </div>
                }
            </div>
            <div className='modal-popup-content-actions'>
                {
                    newNote ?
                    <button className='btn btn-green-fill' onClick={handleSaveNote}>Save</button>
                    :
                    <button className='btn btn-green-fill' onClick={handleAddNote}>Add note</button>
                }
                {
                    newNote ?
                    <button className='btn btn-green-outline' onClick={()=>setNewNote(false)}>Cancel</button>
                    :
                    <button className='btn btn-green-outline' onClick={()=>setGeneralNotes(null)}>Close</button>
                }
            </div>
        </div>
</div>
    )
}
export default GeneralNotesModal;