import React,{useState,useEffect} from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import * as actionType from "../../helpers/constants";
import notify from "../../helpers/notify";
import bg1 from "./../../assets/images/bg-1.jpg";
import arrowRight from "./../../assets/icons/arrow-right.svg";
import logo from "../../assets/images/logo.png";
import bgLogo from "../../assets/icons/bg-logo.svg";
import Notification from "../notification";
import visibilityOff from "../../assets/icons/visibility_off.svg";
import visibility from "../../assets/icons/visibility.svg";
import 'react-phone-number-input/style.css'
import { setPassword } from "../../actions";
const VerifyEmail=()=>{
    const {id,token}=useParams()
    const [showPassword,setShowPassword]=useState(false);
    const navigate=useNavigate()
    const [form,setForm]=useState({
        new_password:"",
        confirm_password:""
    })
    const dispatch=useDispatch();
    
    const handleChange=(e)=>{
       setForm({...form,[e.target.name]:e.target.value})
        
    }
    const verify=()=>{
        return form.new_password!=="" && form.confirm_password!==""
    }
    const handleSubmit=async(e)=>{
        e.preventDefault();
        console.log(form)
        if(verify()){
            dispatch(setPassword(id,token,form,navigate))
        }
    }
    const handleShowPassword=()=>{
        setShowPassword(!showPassword)
      }
    return (<div className="auth-page">
        <div className="auth-background">
            <div className="auth-overlay">
                <div className="auth-content">
                    <div>
                        <Link to="/" className="logo-link"><img src={bgLogo} alt="logo"/><p className="logo-cgi">CGI</p></Link>
                    </div>
                    <div className="auth-content-text">MEMBERS PORTAL</div>
                    
                </div>
            </div>
            <img className="bg-img" src={bg1} alt="cover"/>
        </div>
        <div className="form-bg">
            <div className="default-header">
                <Link to="/"><img className="bgLogo" src={bgLogo} alt="logo"/></Link>
                <div className="members-portal">MEMBERS PORTAL</div>
            </div>
        <form className="auth-form">
                <div className="form-heading">Welcome To Panoram CGI</div>
                <div className="form-subheading">Create your account</div>
                <div className="auth-form-content">
                <div className="form-input-box">
                <div className="form-label">Enter Password</div>
                <div className="password-box">
                    <input name="new_password" className={`form-input`} type={showPassword?'text':'password'} autoFocus onChange={handleChange} />
                    <img onClick={handleShowPassword} src={showPassword?visibility:visibilityOff} alt="show password"/>
                  </div>
                </div>
                <div className="form-input-box">
                <div className="form-label">Confirm Password</div>
                <div className="password-box">
                    <input name="confirm_password" className={`form-input`} type={showPassword?'text':'password'} autoFocus onChange={handleChange} />
                    <img onClick={handleShowPassword} src={showPassword?visibility:visibilityOff} alt="show password"/>
                  </div>
                </div>
                <button className={`btn btn-${verify()? 'green-fill':'grey'} login-btn`} onClick={handleSubmit}> <span>Continue</span><img src={arrowRight} alt="next"/></button>
                </div>
                
            </form>
        </div>
            
        
    </div>)
}
export default VerifyEmail;