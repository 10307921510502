import React,{useEffect,useState} from "react"
import dropdown from "../../../assets/icons/dropdown.svg";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import Select from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import { addUser } from "../../../actions";
const AddMember=({setShowAddMember})=>{
    const dispatch=useDispatch()
    const projects=useSelector(state=>state.all_projects)
    const projectsSelect=projects.map(item=>{return {"value":item.id,"label":item.project_name}})
    //console.log("allo",projects)
    const [showUserRoleSelect,setShowUserRoleSelect]=useState(false)
    const [form,setForm]=useState({
        "first_name":"",
        "last_name":"",
        "projects":"",
        "user_type":"client"
    })
    const handleAddRole=(val)=>{
        setForm({...form,user_type:val})
        setShowUserRoleSelect(false)
      }
    const verify=()=>{
        return form.first_name!="" && form.last_name!="" && form.user_type!=""
    }
    const handleAddMember=()=>{
        console.log("added",form)
        if(verify()){
            dispatch(addUser(form))
            setShowAddMember(false)
        }
        
    }
    const handleChange = (e) => {
        // the item selected
       setForm({...form,[e.target.name]:e.target.value})
      }
     
    return (
        <div className='modal-popup'>
        <div className='modal-popup-content modal-popup-content-resp'>
            <div className='modal-popup-content-title'>Add user</div>
            
            <div className="form-input-box">
                <div className='form-label'>User name</div>
                 <input className="form-input" name="first_name" value={form.first_name} onChange={handleChange} />
          </div>
          {/* <div className="form-input-box">
                <div className='form-label'>Last name</div>
                 <input className="form-input" name="last_name" value={form.last_name} onChange={handleChange} />
          </div> */}
          <div className="form-input-box">
                <div className='form-label'>Email address</div>
                <input className="form-input" name="email" value={form.email} onChange={handleChange} />
          </div>
          <div className="form-input-box">
                <div className='form-label'>User role</div>
            <div  className="form-input-select modal-allocation-input">
                <div className='form-input-selected' onClick={()=>setShowUserRoleSelect(p=>!p)}>
                    <div className='form-input-select-option'>{form.user_type==='superadmin'?'Bill administrator':form.user_type}</div>
                    <img src={dropdown} alt="drop"/>
                </div>
                {(showUserRoleSelect===true) && <div className='form-input-select-dropdown'>
                    <div className='form-input-select-option' onClick={()=>handleAddRole('client')}>Client</div>
                    <div className='form-input-select-option' onClick={()=>handleAddRole('pm')}>PM</div>
                    <div className='form-input-select-option' onClick={()=>handleAddRole('admin')}>Admin</div>
                    <div className='form-input-select-option' onClick={()=>handleAddRole('superadmin')}>Bill administrator</div>
                </div>}
            </div>
            </div>
            <div className="form-input-box">
                <div className='form-label'>Assign project</div>
                <Select
                                  name="projects"
                                  styles={{
                                    control: (provided, state) => ({
                                      ...provided,
                                      backgroundColor: "#4B4B4B",
                                      border: "none",
                                      borderRadius: "0px",
                                      boxShadow: "none",
                                      height: "43px",
                                    }),
                                    dropdownIndicator: (provided, state) => ({
                                      ...provided,
                                      display: "none",
                                    }),
                                    indicatorSeparator: (provided, state) => ({
                                      ...provided,
                                      display: "none",
                                    }),
                                    valueContainer: (provided, state) => ({
                                      ...provided,
                                      overflowY: "scroll",
                                      maxHeight: "43px",
                                    })
                                  }}
                                  components={{
                                    DropdownIndicator: () => {
                                      return <img style={{paddingRight: "18px"}} src={dropdown} alt="project" />;
                                    },
                                  }}
                                  placeholder="Select Projects"
                                  isMulti
                                  onChange={(e)=>{
                                    setForm({...form,"projects":e.map(i=>i.value).join(",")})
                                  }}
                                  options={projectsSelect}
                                  className="basic-multi-select"
                                  classNamePrefix="select"
                                />
          </div>
          <div className='modal-popup-content-actions'>
                <button className={`btn btn-${verify()? 'green-fill':'grey'}`} onClick={handleAddMember}>Add user</button>
                <button className='btn btn-green-outline' onClick={()=>setShowAddMember(false)}>Cancel</button>
            </div>
        </div>
</div>
    )
}
export default AddMember