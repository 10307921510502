import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch,useSelector } from "react-redux";
import bg2 from "../../../assets/images/bg-2.png";
import * as actionType from "../../../helpers/constants";
import * as validate from "../../../helpers/validation";
import arrowRight from "../../../assets/icons/arrow-right.svg";
import visibilityOff from "../../../assets/icons/visibility_off.svg";
import visibility from "../../../assets/icons/visibility.svg";
import warning from "../../../assets/icons/warning.svg";
import logo from "../../../assets/images/logo.png";
import bgLogo from "../../../assets/icons/bg-logo.svg";
import OTPInput from "otp-input-react";
import {login,verifyOtp,sendOtp} from "../../../actions";
import Notification from "../../notification";
import arrowLeft from "../../../assets/icons/arrow-left.svg";
import { Carousel } from "react-responsive-carousel";
import { useLocation } from "react-router-dom";

const LoginOtpPassword=({next,back,handleFormData,form})=>{
  const location = useLocation();
    const dispatch=useDispatch();
    const message=useSelector(state=>state.message);
    //console.log(message)
    const images=useSelector(state=>state.auth_images)
    const filteredImageUrls = Object.entries(images)
  .filter(([key, value]) => value !== null)
  .map(([key, value]) => ({ [key]: value }));
    const navigate=useNavigate();
    const [OTP, setOTP] = useState("");
    const [err,setErr]=useState(null)
    const [showPassword,setShowPassword]=useState(false);
    const otp_secret=useSelector(state=>state.otp_token)
    const verify=()=>{
         return (!validate.validateEmpty(form.password)||!validate.validateEmpty(form.otp))
    }
    const handleCredentials=(e)=>{
      if(!validate.validateEmpty(form.password)||!validate.validateEmpty(form.otp))
      setErr("Cannot be empty");
      else
      setErr(null);
      if(form.verification_type==='email')
         {handleFormData("password",e.target.value);
          if(e.target.value=='')
           setErr("Cannot be empty");
          else
          setErr(null)
        }
      else
        {
         setOTP(e)
         
          //console.log(e)
          //handleFormData("otp",e.target.value);
        }
    }
    const handleResendOtp=()=>{
      dispatch(sendOtp({
        "isLogin":true,
          "country_code":"+91",
       "mobile_no": form.mobile_no}))
    }
    const handleShowPassword=()=>{
      setShowPassword(!showPassword)
    }
    const handleSubmit=(e)=>{
       e.preventDefault();
       if(form.verification_type==="email")
       {
         dispatch(login({email:form.email,password:form.password},next,4))
       }else{
        if(OTP.length!=6)
         setErr("Please fill 6 digits OTP");
        else
         {setErr(null);
        const formData={"country_code":"+91",
        "mobile_no": form.mobile_no,
        "isLogin":true,
        "secret": otp_secret,
        "otp": OTP};
        console.log(formData)
        dispatch(verifyOtp(formData,next,4))
        
      }
       }
       
    }
    return (<div className="auth-page">
        <div className="auth-background">
            <div className="auth-overlay">
                <div className="auth-content">
                    <div>
                    <Link to="/" className="logo-link">
                <img className="bgLogo" src={bgLogo} alt="logo"/>
                <p className="logo-cgi">CGI</p>
                </Link>
                    </div>
                    <div className="auth-content-text">MEMBERS PORTAL</div>
                    
                </div>
            </div>
            {filteredImageUrls?.length>0 &&
            <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true}  showArrows={false} showStatus={false}>
            {
               filteredImageUrls.map((e,i)=>(    
                <div key={`image-${i}`}>
                    <img src={Object.values(e)} className="bg-img" alt="carousel"/>
                </div>
               ))
            }
            </Carousel>
         }
        </div>
        <div className="form-bg">
        <div className="default-header">
                <Link to="/" className="logo-link">
                <img className="bgLogo" src={bgLogo} alt="logo"/>
                <p className="logo-cgi">CGI</p>
                </Link>
                <div className="members-portal">MEMBERS PORTAL</div>
            </div>
          <form className="auth-form">
                <div className="form-heading" style={{alignSelf: location.pathname.slice(1)==='login'?'start':'center'}}>Welcome Back !</div>
                <div className="form-subheading">Login to your account</div>
                <div className="auth-form-content">
                <div className="form-input-box">
                  <div className="form-label">Enter {form.verification_type==='email'?'password':'OTP'}</div>
                  {form.verification_type==='email'?
                  <div className="password-box">
                    <input className={`form-input ${err && 'input-invalid'}`} type={showPassword?'text':'password'} autoFocus onChange={handleCredentials} />
                    <img onClick={handleShowPassword} src={showPassword?visibility:visibilityOff} alt="show password"/>
                  </div>:
                  <OTPInput className="otp-input"autoFocus value={OTP} OTPLength={6} otpType="number" disabled={false} onChange={handleCredentials}/>}
                  {err && <div className="input-error">
                    <img src={warning} alt="otp warning"/>
                    <span>{err}</span>
                    </div>}
                </div>
                <button className={`login-btn btn btn-${verify()||OTP?.length===6?'green-fill':'grey'}`} onClick={handleSubmit}> <span>Log In</span><img src={arrowRight} alt="next"/></button>
                {form.verification_type==='email'?<div className="highlight" style={{alignSelf:'flex-end'}} onClick={()=>next(3)}>Forgot password ?</div>:<div className="highlight" style={{alignSelf:'flex-end'}} onClick={handleResendOtp} >Resend OTP ?</div>}
                
                </div>
                <button style={{alignSelf:'start', width:'auto', display: 'none'}} onClick={()=>navigate(-1)} className='btn btn-green-outline' >
                  <img src={arrowLeft} alt="back"/>
                  <span>Back</span>
                </button>
                {message && <Notification type={message.type} message={message.message}/>}
            </form>
        </div>
            
        
    </div>)
}
export default LoginOtpPassword;