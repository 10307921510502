import React, { useState,useEffect } from 'react';
import emptyBox from '../../assets/images/empty-box.svg';
import plus from "./../../assets/icons/plus.svg";
import CreateProjectModal from '../CreateProjectModal';
import sortIcon from "../../assets/icons/sort.svg"
import PinIcon from "../../assets/icons/pin-icon";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Notification from '../notification';
import Moment from 'react-moment';
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import { getUserProjects,getUserOngoingProjects,getUserCancelledProjects,getUserCompletedProjects,pinProject } from '../../actions';
import { getTokenFunc } from '../../firebase';
const DashboardHome = () => {
    const [isTokenFound, setTokenFound] = useState(false);
    const dispatch=useDispatch();
    const [show, setShow] = useState(false);
    const [showSort,setShowSort]=useState(false)
    const profile=useSelector(state=>state.profile)
    const handleShow = () => setShow(true);
    const handleClose = () => {setShow(false);}
    const [active,setActive]=useState('all');
    const message=useSelector(state=>state.message)
    const isAuth=useSelector(state=>state.isAuth)
    const projects=useSelector(state=>state.projects);
    console.log(projects)
    console.log(active)
    const isLoading=useSelector(state=>state.isLoading);
    const closeSortMenu=()=>{
        setShowSort(false);
    }
    const selectSort=(type)=>{
        if(active==='incomplete')
        dispatch(getUserOngoingProjects(type))
     else if(active==='complete')
     dispatch(getUserCompletedProjects(type))
     else if(active==='cancelled')
     dispatch(getUserCancelledProjects(type))
     else
      dispatch(getUserProjects())
      closeSortMenu()
    }
    const handlePinProject=(id,val)=>{
        dispatch(pinProject({"user_id":profile.id,"project_id":id,"is_pinned":val?0:1}))
    }
    useEffect(()=>{
       
            if(active==='incomplete')
            dispatch(getUserOngoingProjects())
         else if(active==='complete')
         dispatch(getUserCompletedProjects())
         else if(active==='cancelled')
         dispatch(getUserCancelledProjects())
         else
          dispatch(getUserProjects())
        
        
      },[dispatch,active])

      useEffect(()=>{
        getTokenFunc(setTokenFound);
      },[])
     return (
        <>
        <div className="home-actions">
        {
            <div className="home-tabs">
            <div  onClick={() => {setActive('all')}} className={`tab ${active === 'all'?'tab-active':''} tab-resp`}>All projects</div>
            <div  onClick={() => {setActive('incomplete')}} className={`tab ${active === 'incomplete'?'tab-active':''} tab-resp`}>Work in progress</div>
            <div  onClick={() => {setActive('complete')}} className={`tab ${active === 'complete'?'tab-active':''} tab-resp`}>Completed projects</div>
            <div  onClick={() => {setActive('cancelled')}} className={`tab ${active === 'cancelled'?'tab-active':''} tab-resp`}>Cancelled projects</div>
            <div className="sort" style={{marginLeft : "auto"}}>
            {window.innerWidth<=450 && <div><div className="sort-action" onClick={()=>setShowSort(p=>!p)}>
                <img src={sortIcon} alt="sort"/>
                <span>Sort</span>
            </div>
            <div className={`sort-options ${showSort?'':'hide'}`}>
                <div className="sort-option" onClick={()=>selectSort('created_at')}>Sort by created</div>
            </div></div>}
        </div>
        </div>
        }
       
        {projects?.length>0 && <div className="sortNew">
            {window.innerWidth>450 && <div><div className="sort" style={{marginLeft : "auto"}}>
            <div className="sort-action" onClick={()=>setShowSort(p=>!p)}>
                <img src={sortIcon} alt="sort"/>
                <span>Sort</span>
            </div>
            <div className={`sort-options ${showSort?'':'hide'}`}>
                <div className="sort-option" onClick={()=>selectSort('created_at')}>Sort by created</div>
            </div>
        </div></div>}
        
       {window.innerWidth>680 && <div className='new-project-btn'>
           <button style={{marginLeft:'15px'}} onClick={handleShow} className='btn btn-green-fill'>New Project <img src={plus} alt="new project"/></button>
        </div>}
        </div>}
        {projects?.length===0 && active !== "all" && <div className="sortNew">
            {window.innerWidth>450 && <div><div className="sort" style={{marginLeft : "auto"}}>
            <div className="sort-action" onClick={()=>setShowSort(p=>!p)}>
                <img src={sortIcon} alt="sort"/>
                <span>Sort</span>
            </div>
            <div className={`sort-options ${showSort?'':'hide'}`}>
                <div className="sort-option" onClick={()=>selectSort('created_at')}>Sort by created</div>
            </div>
        </div></div>}
        
        <div className='new-project-btn'>
            <button style={{marginLeft:'15px'}} onClick={handleShow} className='btn btn-green-fill'>New Project <img src={plus} alt="new project"/></button>
        </div>
        </div>}
        </div>
        {!isLoading && projects?.length>0 && window.innerWidth<=680 && <div className='new-project-btn new-project-btn-resp'>
            <button style={{marginLeft:'15px'}} onClick={handleShow} className='btn btn-green-fill'>New Project <img src={plus} alt="new project"/></button>
        </div>}
        {!isLoading && projects?.length>0 && <div className="card-grid">
        {projects.map((project,index)=>
         <div className="project-grid-item">
         <div className="project-pin"  onClick={()=>handlePinProject(project.id,project.is_pinned==1)}> <PinIcon fill={`${project.is_pinned?'#40BAFF':'#D6D6D6'}`}/></div> 
        <Link to={`/project/${project.id}`} key={`project-${index}`} className="project-card">
       
        <div className="project-card-top">
            <div className="project-card-name">{project.project_name}</div>
            <div className="project-card-client">{project.your_name}</div>
            <div className="project-card-description"><ReactMarkdown rehypePlugins={[rehypeRaw]} children={project.project_description.length >= 100 ? `${project.project_description.substring(0,60)}...` : project.project_description} /></div>
        </div>
        <div className="project-card-bottom">
            {project.pm_name && <div><span>PM - </span> <span className="project-card-info">{project.pm_name}</span></div>}
            
            <div><span>Status - </span><span className="project-card-info">{project.project_status}</span></div>
            <div><span>Last edited - </span><span className="project-card-info"><Moment fromNow ago>{project.updated_at}</Moment></span></div>
        </div>
    </Link></div>)}
            </div>}
        
    {!isLoading && projects?.length===0 && active === "all" &&
    <>
        <div className='bg-dashboard-home'>
            <h2>Welcome <span style={{textTransform:'capitalize'}}>{profile?.fullName}</span></h2>
            <p><small>Looks like you have no projects yet!</small></p>
            <div className='new-project-btn'>
            <button style={{marginLeft:'15px', marginTop: '1.3rem', marginBottom: '1.3rem'}} onClick={handleShow} className='btn btn-green-fill'>New Project <img src={plus} alt="new project"/></button>
        </div>
            <div className='empty-box'>
                <img src={emptyBox} alt="empty" />
            </div>
            
        </div>
    </>
    }

    {!isLoading && projects?.length===0 && active === "incomplete" &&
    <>
        <div className='bg-dashboard-home'>
            <h2>Welcome <span style={{textTransform:'capitalize'}}>{profile?.fullName}</span></h2>
            <p><small>Looks like you have no Incomplete projects yet!</small></p>
            <div className='new-project-btn'>
        </div>
            <div className='empty-box'>
                <img src={emptyBox} alt="empty" />
            </div>
            
        </div>
    </>
    }
    {!isLoading && projects?.length===0 && active === "complete" &&
    <>
        <div className='bg-dashboard-home'>
            <h2>Welcome <span style={{textTransform:'capitalize'}}>{profile?.fullName}</span></h2>
            <p><small>Looks like you have no Active projects yet!</small></p>
            <div className='new-project-btn'>
        </div>
            <div className='empty-box'>
                <img src={emptyBox} alt="empty" />
            </div>
            
        </div>
    </>
    }
    {!isLoading && projects?.length===0 && active === "cancelled" &&
    <>
        <div className='bg-dashboard-home'>
            <h2>Welcome <span style={{textTransform:'capitalize'}}>{profile?.fullName}</span></h2>
            <p><small>Looks like you have no Cancelled projects yet!</small></p>
            <div className='new-project-btn'>
        </div>
            <div className='empty-box'>
                <img src={emptyBox} alt="empty" />
            </div>
            
        </div>
    </>
    }
    <CreateProjectModal show={show} handleClose={handleClose} />
    {message && <Notification type={message.type} message={message.message}/>}
     </>)
};

export default DashboardHome;