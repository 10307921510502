import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import * as validate from "../../../helpers/validation";
import bg5 from "../../../assets/images/bg-5.png";
import arrowRight from "../../../assets/icons/arrow-right.svg";
import warning from "../../../assets/icons/warning.svg";
import logo from "../../../assets/images/logo.png";
import bgLogo from "../../../assets/icons/bg-logo.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { AuthImages } from "../../../actions";
import PrivacyModal from "./PrivacyModal";
import EndModal from "./EndModal";
import notify from "../../../helpers/notify";
const SignUpBase=({next,back,handleFormData,form})=>{
  const [modal,setModal] = useState(false)
  const [endmodal,setEndModal] = useState(false)
  const [checked,setChecked] = useState(false)
  const dispatch=useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [fNameErr,setFNameErr]=useState(null);
  const [lNameErr,setLNameErr]=useState(null);
  const [cNameErr,setCNameErr]=useState(null);
  const images=useSelector(state=>state.auth_images)
  const filteredImageUrls = Object.entries(images)
  .filter(([key, value]) => value !== null)
  .map(([key, value]) => ({ [key]: value }));
  const verify=()=>{
    return !fNameErr&&!lNameErr&&validate.validateAlphabets(form.first_name)&&validate.validateAlphabets(form.last_name)&&!validate.validateEmpty(form.first_name)&&!validate.validateEmpty(form.last_name)&&!validate.validateEmpty(form.company_name);
  }
  const handleChange=(e)=>{
    const name=e.target.name;
    const value=e.target.value;
    handleFormData(name,value)
    //console.log(validate.validateAlphabets(value))
    if(validate.validateEmpty(value))
    {
      if(name==='first_name')
      setFNameErr('Cannot be empty');
    
    if(name==='last_name')
     setLNameErr('Cannot be empty');
     
    if(name==='company_name')
     setCNameErr('Cannot be empty');
    }
    else if(!validate.validateAlphabets(value))
    {
      if(name==='first_name')
        setFNameErr('Accepts only alphabets');
      
      if(name==='last_name')
       setLNameErr('Accepts only alphabets');
      
       if(name==='company_name')
       setCNameErr(null); 
       
    }
    else if(value.length<3){
      if(name==='first_name')
        setFNameErr('Minimum 3 characters');
      if(name==='last_name')
       setLNameErr('Minimum 3 characters');
    }else{
      if(name==='first_name')
        setFNameErr(null);
      
      if(name==='last_name')
       setLNameErr(null);
       
      if(name==='company_name')
       setCNameErr(null); 
    }
  }
  const handleSubmit=(e)=>{
    e.preventDefault();
    //console.log(form)
    if(checked === false){
      notify("error","Please accept the terms and conditions")
    }
    if(verify() && checked)
    {
      
        next(2);
    }
}

useEffect(()=>{
  dispatch(AuthImages())
  if(searchParams.size!==0){
    handleFormData("first_name",searchParams.get("first_name"))
    handleFormData("last_name",searchParams.get("last_name"))
  }
},[])

    return (<div className="auth-page">
        <div className="auth-background">
            <div className="auth-overlay">
            <div className="auth-content">
                    <div> 
                    <Link to="/" className="logo-link">
                <img className="bgLogo" src={bgLogo} alt="logo"/>
                <p className="logo-cgi">CGI</p>
                </Link>
                    </div>
                    <div className="auth-content-text">MEMBERS PORTAL</div>
                    {/* <div className="overlay-highlight">JOIN MEDIUM</div>
                    <div className="overlay-text">ARCHITECTURE +<br/>INTERIOR DESIGN</div> */}
                </div>
            </div>
            {filteredImageUrls?.length>0 &&
            <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true}  showArrows={false} showStatus={false}>
            {
               filteredImageUrls.map((e,i)=>(    
                <div key={`image-${i}`}>
                    <img src={Object.values(e)} className="bg-img" alt="carousel"/>
                </div>
               ))
            }
            </Carousel>}
        </div>
        <div className="form-bg">
            <div className="default-header">
            <Link to="/" className="logo-link">
                <img className="bgLogo" src={bgLogo} alt="logo"/>
                <p className="logo-cgi">CGI</p>
                </Link>
              <div className="members-portal">MEMBERS PORTAL</div>
            </div>
            <form className="auth-form">
                <div className="form-heading" style={{whiteSpace : "nowrap"}}>Welcome To Panoram CGI</div>
                <div className="form-subheading">Create your account</div>
                <div className="auth-form-content">
                <div className="auth-form-content">
                  <div className="form-input-box">
                    <div className="form-label">First name</div>
                    <input className={`form-input ${fNameErr && 'input-invalid'}`} name="first_name" maxLength={30} onChange={handleChange} value={form.first_name}/>
                    {fNameErr && <div className="input-error">
                      <img src={warning} alt="name error"/>
                      <span>{fNameErr}</span>
                      </div>}
                  </div>
                  <div className="form-input-box">
                    <div className="form-label">Last name</div>
                    <input className={`form-input ${lNameErr && 'input-invalid'}`} name="last_name" maxLength={30} onChange={handleChange} value={form.last_name}/>
                    {lNameErr && <div className="input-error">
                      <img src={warning} alt="name warning"/>
                      <span>{lNameErr}</span>
                      </div>}
                  </div>
                  <div className="form-input-box last-form-input-box">
                    <div className="form-label">Company name</div>
                    <input className={`form-input ${cNameErr && 'input-invalid'}`} name="company_name"maxLength={30} onChange={(e)=>{
                      handleChange(e)
                      }}/>
                    {cNameErr && <div className="input-error">
                      <img src={warning} alt="name error"/>
                      <span>{cNameErr}</span>
                      </div>}
                  </div>
                </div>
                <div className="form-toggle"> 
                 
                    <span style={{color:'#D6D6D6'}}>Already have an account ? </span>
                    <Link to="/" className="highlight">Log In</Link> <br />
                </div>
                <div className="privacy-policy-text">
                <input type="checkbox" name="" id="" onChange={e=>setChecked(e.target.checked)} style={{cursor : "pointer"}}/>
                <span>
                <span style={{marginLeft : "10px",color : "white"}}>
                    I confirm that I have read and agree to 
                </span> <br />
                    <span style={{color:'#01D9AB',marginTop : "10px",cursor : "pointer",marginLeft : "22px"}} onClick={()=>setModal(true)}> Privacy Policy</span> 
                    <span style={{color : "white",margin : "0 0px"}}> & </span>
                    <span style={{color:'#01D9AB',marginTop : "10px",cursor : "pointer"}} onClick={()=>setEndModal(true)}>End User Agreement</span>
                </span>
                    </div>
                <button className={`btn btn-${verify() && checked?'green-fill':'grey'} login-btn login-btn-resp`} onClick={handleSubmit}> <span>Continue</span><img src={arrowRight} alt="submit"/></button>
                </div>
            </form>
        </div>
           {
            modal ? <PrivacyModal setModal={setModal}/> : ""
           }
           {
            endmodal ? <EndModal setEndModal={setEndModal}/> : ""
           }
        
    </div>)
}
export default SignUpBase;