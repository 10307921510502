import React, { useState } from "react";
import "../../../assets/css/tutorial.css";
import EllipseDark from "../../../assets/icons/ellipse-dark.svg";
import EllipseLight from "../../../assets/icons/ellipse-light.svg";
import plus from "../../../assets/icons/plus.svg";
import commentIcon from "../../../assets/icons/comment.svg";
import NextIcon from "../../../assets/icons/next-icon";
import PrevIcon from "../../../assets/icons/prev-icon";
import editImageIcon from "../../../assets/icons/edit-image.svg"

const ReviewTutorial = ({ skipReviewTutorial }) => {
  const [serial, setSerial] = useState(1);

  const handleCountIncrease = () => {
    if (serial < 6) {
      setSerial(serial + 1);
    }
  };
  const handleCountDecrease = () => {
    if (serial > 1) {
      setSerial(serial - 1);
    }
  };

  return (
    <div className="review-tutorial">
      {serial === 1 && (
        <div className="">
          <div className="tutorial-new-project-btn review-btn">
            <button
              style={{ marginLeft: "15px" }}
              onClick={() => {
                skipReviewTutorial();
              }}
              className="btn btn-green-fill"
            >
              Add images <img src={plus} alt="new project" />
            </button>
          </div>
          <div className="tutorial-text tutorial1-text review-tut1-text">
            <div>
              <span className="tutorial-text-heading">
                <img src={plus} alt="new project" />
                Add images{" "}
              </span>
              <span>
                For adding multiple images. Note: Each image can have multiple
                version
              </span>
              <div className="pagination-ellipse">
                <img src={EllipseLight} alt="" />
                <img src={EllipseDark} alt="" />
                <img src={EllipseDark} alt="" />
                <img src={EllipseDark} alt="" />
                <img src={EllipseDark} alt="" />
                <img src={EllipseDark} alt="" />
              </div>
              <div className="tutorial-text-footer">
                <div onClick={() => skipReviewTutorial()}>Skip</div>
                <div className="pagination-btn">
                  <div
                    onClick={handleCountDecrease}
                    className={`prev-btn ${serial === 1 && "pagination-grey"}`}
                  >
                    <span>{"<"}</span>
                    <span>Prev</span>
                  </div>
                  <div
                    onClick={handleCountIncrease}
                    className={`next-btn ${serial === 9 && "pagination-grey"}`}
                  >
                    <span>Next</span>
                    <span>{">"}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {serial === 2 && (
        <div className="review-tut-comment">
          <div className="review-comment">
            <img src={commentIcon} alt="" />
          </div>
          <div className="tutorial-text review-tut2 review-tut-common">
            <div>
              <span className="tutorial-text-heading">
                <img src={commentIcon} alt="new project" />
                Commentary{" "}
              </span>
              <span>To view all previous comments</span>
              <div className="pagination-ellipse">
                <img src={EllipseDark} alt="" />
                <img src={EllipseLight} alt="" />
                <img src={EllipseDark} alt="" />
                <img src={EllipseDark} alt="" />
                <img src={EllipseDark} alt="" />
                <img src={EllipseDark} alt="" />
              </div>
              <div className="tutorial-text-footer">
                <div onClick={() => skipReviewTutorial()}>Skip</div>
                <div className="pagination-btn">
                  <div
                    onClick={handleCountDecrease}
                    className={`prev-btn ${serial === 1 && "pagination-grey"}`}
                  >
                    <span>{"<"}</span>
                    <span>Prev</span>
                  </div>
                  <div
                    onClick={handleCountIncrease}
                    className={`next-btn ${serial === 9 && "pagination-grey"}`}
                  >
                    <span>Next</span>
                    <span>{">"}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {serial === 3 && (
        <div className="review-tut-toggle">
          <div className="review-toggle review-image-scroll ">
            <button>
              <PrevIcon height="20px" width="20px" fill={"white"} />
            </button>{" "}
            <div>|</div>
            <button>
              <NextIcon height="20px" width="20px" fill={"white"} />
            </button>
          </div>
          <div className="tutorial-text review-tut3">
        <div className="review-tut3-pointer"></div>
            <div className="review-tut3-text">
              <span className="tutorial-text-heading">
              <PrevIcon height="10px" width="10px" fill={"white"} />
            {" "}
            <div style={{fontSize : "15px"}}>|</div>
              <NextIcon height="10px" width="10px" fill={"white"} />
            
                Toggle
              </span>
              <span>To view multiple images</span>
              <div className="pagination-ellipse">
                <img src={EllipseDark} alt="" />
                <img src={EllipseDark} alt="" />
                <img src={EllipseLight} alt="" />
                <img src={EllipseDark} alt="" />
                <img src={EllipseDark} alt="" />
                <img src={EllipseDark} alt="" />
              </div>
              <div className="tutorial-text-footer">
                <div onClick={() => skipReviewTutorial()}>Skip</div>
                <div className="pagination-btn">
                  <div
                    onClick={handleCountDecrease}
                    className={`prev-btn ${serial === 1 && "pagination-grey"}`}
                  >
                    <span>{"<"}</span>
                    <span>Prev</span>
                  </div>
                  <div
                    onClick={handleCountIncrease}
                    className={`next-btn ${serial === 9 && "pagination-grey"}`}
                  >
                    <span>Next</span>
                    <span>{">"}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {serial === 4 && (
        <div className="review-tut-comment">
          <div className="review-edit">
            <img src={editImageIcon} alt="" />
          </div>
          <div className="tutorial-text review-tut4 review-tut-common">
            <div>
              <span className="tutorial-text-heading">
                <img src={editImageIcon} alt="new project" />
                Mark Up Tool  
              </span>
              <span>For highlighting certain key features within the design. </span>
              <div className="pagination-ellipse">
                <img src={EllipseDark} alt="" />
                <img src={EllipseDark} alt="" />
                <img src={EllipseDark} alt="" />
                <img src={EllipseLight} alt="" />
                <img src={EllipseDark} alt="" />
                <img src={EllipseDark} alt="" />
              </div>
              <div className="tutorial-text-footer">
                <div onClick={() => skipReviewTutorial()}>Skip</div>
                <div className="pagination-btn">
                  <div
                    onClick={handleCountDecrease}
                    className={`prev-btn ${serial === 1 && "pagination-grey"}`}
                  >
                    <span>{"<"}</span>
                    <span>Prev</span>
                  </div>
                  <div
                    onClick={handleCountIncrease}
                    className={`next-btn ${serial === 9 && "pagination-grey"}`}
                  >
                    <span>Next</span>
                    <span>{">"}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {serial === 5 && (
        <div className="">
          <div className="review-tut-version">
          <button
              onClick={() => {
                skipReviewTutorial();
              }}
              className="btn btn-green-outline tutorial-text-heading-resp"
            >
              Add New Version
            </button>
          </div>
          <div className="tutorial-text review-tut5 review-tut-common">
            <div>
              <span className="tutorial-text-heading">
                Add New Version
              </span>
              <span>For Adding new versions of designs.</span>
              <div className="pagination-ellipse">
                <img src={EllipseDark} alt="" />
                <img src={EllipseDark} alt="" />
                <img src={EllipseDark} alt="" />
                <img src={EllipseDark} alt="" />
                <img src={EllipseLight} alt="" />
                <img src={EllipseDark} alt="" />
              </div>
              <div className="tutorial-text-footer">
                <div onClick={() => skipReviewTutorial()}>Skip</div>
                <div className="pagination-btn">
                  <div
                    onClick={handleCountDecrease}
                    className={`prev-btn ${serial === 1 && "pagination-grey"}`}
                  >
                    <span>{"<"}</span>
                    <span>Prev</span>
                  </div>
                  <div
                    onClick={handleCountIncrease}
                    className={`next-btn ${serial === 9 && "pagination-grey"}`}
                  >
                    <span>Next</span>
                    <span>{">"}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {serial === 6 && (
        <div className="">
          <div className="review-tut-approve">
          <button
              onClick={() => {
                skipReviewTutorial();
              }}
              className="btn btn-green-outline tutorial-text-heading-resp2"
            >
              Approve Version
            </button>
          </div>
          <div className="tutorial-text review-tut-common review-tut6">
            <div>
              <span className="tutorial-text-heading">
                Approve Version
              </span>
              <span>For Adding new versions of designs.</span>
              <div className="pagination-ellipse">
                <img src={EllipseDark} alt="" />
                <img src={EllipseDark} alt="" />
                <img src={EllipseDark} alt="" />
                <img src={EllipseDark} alt="" />
                <img src={EllipseDark} alt="" />
                <img src={EllipseLight} alt="" />
              </div>
              <div className="tutorial-text-footer">
                <div onClick={() => skipReviewTutorial()}>Skip</div>
                <div className="pagination-btn">
                  <div
                    onClick={handleCountDecrease}
                    className={`prev-btn ${serial === 1 && "pagination-grey"}`}
                  >
                    <span>{"<"}</span>
                    <span>Prev</span>
                  </div>
                  <div
                    onClick={handleCountIncrease}
                    className={`next-btn ${serial === 6 && "pagination-grey"}`}
                  >
                    <span>Next</span>
                    <span>{">"}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewTutorial;
