import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {  createPaypalPaymentOrder,capturePaypalOrder } from "../../../actions";
const PaypalPayment=({project,setShowPaymentModal})=>{
    const token=useSelector(state=>state.isAuth.access)
    const dispatch=useDispatch()
    return (
        <PayPalScriptProvider
        options={{
          "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
          components: "buttons",
          currency: "USD"
        }}
      >
    <PayPalButtons
       createOrder={()=>createPaypalPaymentOrder(project.id,project.amount_to_be_paid)}
       onApprove={(data)=>{console.log(data);dispatch(capturePaypalOrder(data.orderID,project.id));setShowPaymentModal(false)}}
        />
        </PayPalScriptProvider>)
   
}
export default PaypalPayment;