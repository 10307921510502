import React,{useState,useEffect} from 'react';
import '../../assets/css/Notifications.css'
import { useDispatch,useSelector } from 'react-redux';
import SearchIcon from "../../assets/icons/search-icon";
import sortIcon from "../../assets/icons/sort.svg";
import emptyBox from '../../assets/images/empty-box.svg';
import plus from "../../assets/icons/plus.svg"
import { Link } from 'react-router-dom';
import { getAdminNotifications } from '../../actions';
import Moment from 'react-moment';
import {week,year} from "../../helpers/constants"
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import rehypeRaw from 'rehype-raw';
const AdminNotifications = () => {
    const dispatch=useDispatch();
    const isAuth=useSelector(state=>state.isAuth)
    const notifications=useSelector(state=>state.notifications)
    const isLoading=useSelector(state=>state.isLoading)
    const profile=useSelector(state=>state.profile)
    const [activeSearch,setActiveSearch]=useState(false)
   
    const [showSort,setShowSort]=useState(false)
    const closeSortMenu=()=>{
        setShowSort(false);
    }
    const selectSort=(type)=>{
        dispatch(getAdminNotifications(type))
      closeSortMenu()
    }
    const formatDate=(d)=>{
      let temp=d.split("-");
      let date=new Date(`${temp[1]}/${temp[2]}/${temp[0]}`);
      return `${week[date.getDay()]},${year[date.getMonth()]} ${date.getDate()}`
    }
    document.addEventListener('click',(e)=>{
        if(!e.target.closest('.sort-options') && !e.target.closest('.sort-action'))
        closeSortMenu()
    })
    useEffect(()=>{
     dispatch(getAdminNotifications())
    },[dispatch,isAuth.access])
    return (
        <div className='admin-notifications'>
            <div className="admin-notifications-header">
                <form className="search-form">
                    <SearchIcon fill={activeSearch?'#D6D6D6':'#909090'}/>
                    <input onFocus={()=>setActiveSearch(true)} onBlur={()=>setActiveSearch(false)} placeholder="Search"/>
                </form>
                <div className="notifications-title">
                    <div className="admin-page-title">Notifications</div>
                </div>
                <div className="sort sorting-section" style={{justifySelf:'flex-end'}}>
                    <div className="sort-action" onClick={()=>setShowSort(p=>!p)}>
                    <img src={sortIcon} alt="sort"/>
                    <span>Sort</span>
                    </div>
                    <div className={`sort-options ${showSort?'':'hide'}`}>
                        {/* <div className="sort-option" onClick={()=>selectSort('project_name')}>Sort by name</div> */}
                        <div className="sort-option" onClick={()=>selectSort('updated_at')}>Sort by modified</div>
                        <div className="sort-option" onClick={()=>selectSort('created_at')}>Sort by created</div>
                    </div>
                </div>
            </div>
           
            <div className='notifications-page'>
                {!isLoading && notifications?.length>0 && notifications.map((item,index)=>
                    <div className='notification-box admin-notification' key={`notif-day-${index}`}>
                        <div className='notification-day'>{formatDate(item.date)}</div>
                        {
                            item.notifications.map((notification,index) => {
                                return (
                                    <div className='notification' key={`notif-${index}`}>
                                        <div className='person-img'>
                                        {notification.user_ref.avatar?<img src={notification.user_ref.avatar} alt="avatar"/>:
                                            <div className='person-img-default'>{notification.user_ref.fullName.split(" ")[0].charAt(0)}{notification.user_ref.fullName.split(" ")[1]?notification.user_ref.fullName.split(" ")[1].charAt(0):notification.user_ref.fullName.split(" ")[0].charAt(1)}</div>}
                                        </div>
                                        <div className='notification-content'>
                                            <div>
                                                <div className='notification-line'>
                                                    <p>
                                                        <span className='person-name'>{notification.user_ref.fullName}</span>
                                                        <span><ReactMarkdown rehypePlugins={[rehypeRaw]} disallowedElements={['li']} children={notification?.notif} /></span>
                                                        <span className='place'>"{notification.project_ref.project_name}"</span>
                                                    </p>
                                                    {!notification.viewed && <span className='tag view-request'>View request</span>}
                                                </div>
                                               
                                            </div>
                                            <div className='notification-time'>
                                                <Moment format="LT" tz={profile.timezone}>{notification.created_at}</Moment>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    )}
            { !isLoading && notifications?.length==0 &&
                    <div className='no-notification'>
                        <div className='no-notification-content'>
                            <p className='heading'>Nothing's happened yet!</p>
                            <p>
                                But as soon as your project gets accepted, everything that’s happening in your project will be summarized for you right here.
                            </p>
                        </div>
                        <img src={emptyBox} alt="nothing" />
                    </div>
                
            }
        </div>
        </div>
    );
};

export default AdminNotifications;       