import React,{useEffect,useState} from "react"
import { useDispatch, useSelector } from "react-redux"
import "../../../assets/css/customAutoSearch.css"
import Select from "react-select"
import { components } from 'react-select';
const UserList=({form,setForm,setShowUserList})=>{
    const dispatch=useDispatch()
    const users=useSelector(state=>state.all_users)
    const [searchResults,setSearchResults]=useState(users)
    const [query,setQuery]=useState("")
 
    const CustomOption = (props) =>
    { 
        const { innerProps, innerRef } = props
       return <div ref={innerRef} {...innerProps} className="auto-search-result">
       <div className="search-profile">{props.data.fullName.split(" ")[0].charAt(0)}{props.data.fullName.split(" ")[1]?props.data.fullName.split(" ")[1].charAt(0):props.data.fullName.split(" ")[0].charAt(1)}</div>
       <div className="search-name">{props.data.fullName}</div>
     </div>

    }
    return (
        <div className='modal-popup user-list-modal'>
        <div className='modal-popup-content'>
            <div className="modal-header">
                <div></div>
            <div className='modal-popup-content-title'>Search people</div>
            <button className='btn btn-green-outline' onClick={()=>{setForm({...form,user_ids:""});setShowUserList(false)}}>Cancel</button>
            </div>
           <div className="auto-search">
           <div className="auto-search-input-label">User name</div>
           
            <Select
            isClearable
            isSearchable
            isMulti
            menuIsOpen={true}
            getOptionLabel={option => option.fullName}
            theme={(theme) => ({
                ...theme,
                color:"white"
            })}
            styles={{
                control: (base,state) => ({
                    ...base,
                    backgroundColor: "#4B4B4B",
                    borderRadius: "4px",
                    border:0,
                    boxShadow: 'none',
                    outline: `1.5px solid ${state.isFocused ?'#01D9AB':'white'}`,
                  }),
                  input: (base,state)=>({
                   color:'white'
                  }),
                  placeholder:(base,state)=>({
                    ...base,
                    display:"none"
                  }),
                  dropdownIndicator: (provided, state) => ({
                    ...provided,
                    display: "none",
                  }),
                  menu: base => ({
                    ...base,
                   
                    borderRadius: 0,
                    background:'transparent'
                  }),
            }}
            onChange={(e)=>{
                console.log(e)
                setForm({...form,user_ids:e.map(i=>i.id).join(",")})
            }}
            
            options={users}
            getOptionValue={option=>option.id}
                components={{Control: (props) => (
                    <components.Control {...props}>
                         {props.children}
                         <button className={`btn btn-green-fill`} onClick={()=>setShowUserList(false)}>Done</button>
                    </components.Control>
                  ), Option: CustomOption }}
            />
            
           </div>
        </div>
</div>
    )
}
export default UserList