import React,{useState,useEffect,useRef} from "react";
import { useDispatch,useSelector } from "react-redux";
import downloadIcon from "../../../assets/icons/download-icon"
import plusIcon from "../../../assets/icons/plus.svg"
import dropboxIcon from "../../../assets/icons/dropbox.svg"
import crossIcon from "../../../assets/icons/cross.svg"
import notify from "../../../helpers/notify";
import { addReviewFiles,addReviewVersion } from "../../../actions";
const InputReviewModal=({showUploadModal,setShowUploadModal,projectID})=>{
    console.log(showUploadModal)
    const dispatch=useDispatch()
    const reviewInput=useRef();
    const [form,setForm]=useState({
        "title":"",
        "files":[],
        "project_id":projectID,
        "is_new":true
    })
    const [reviewInputFiles,setReviewInputFiles]=useState([])
    const verify=()=>{
        if(showUploadModal=='add')
       return form.title!="" && form.files.length>0
       else
        return form.files.length>0
    }
    const removeReviewInputFile=(id)=>{
        setForm({...form,"files":[...form.files].filter((item,index)=>id!==index)})
        setReviewInputFiles(reviewInputFiles.filter((iitem,index)=>id!==index))
    }
    const handleInputReviewFiles=(e)=>{
        let ar=[]
        console.log(e.target.files)
        for(let i=0;i<e.target.files.length;i++)
        {
            ar.push({"image":URL.createObjectURL(e.target.files[i]),"name":e.target.files[i].name})
        }
        if(reviewInputFiles.length==0)
          {
            setForm({...form,"files":e.target.files})
            setReviewInputFiles(ar)
        }
        else
         {
            setReviewInputFiles(reviewInputFiles.concat(ar))
            setForm({...form,"files":[...form.files,...e.target.files]})
         }
    }
    const handleUploadFiles=()=>{
        console.log(form)
        if(showUploadModal=='add')
        {
            let newForm=new FormData()
        newForm.append("title",form.title)
        newForm.append("is_new",form.is_new)
        newForm.append("project_id",form.project_id)
        for(let i=0;i<form.files.length;i++)
             newForm.append("image",form.files[i])
        dispatch(addReviewFiles(newForm))
        }else
        {
            let newForm=new FormData()
            newForm.append("is_new",false)
            newForm.append("project_id",form.project_id)
            newForm.append("image_id",showUploadModal.id)
            for(let i=0;i<form.files.length;i++)
                 newForm.append("image",form.files[i])
            dispatch(addReviewVersion(newForm))
        }
        setShowUploadModal(null);
    }
   // console.log(reviewInputFiles)
    return(
        <div className="modal-popup">
            <input ref={reviewInput} className="review-input" multiple={showUploadModal=='add'} hidden type="file" accept="image/png, image/jpeg" onChange={handleInputReviewFiles}/>
            {reviewInputFiles.length>0 ?
            <div className="modal-popup-content review-input-modal">
                
                <img className="close-input-review-modal" src={crossIcon} alt="close" onClick={()=>setShowUploadModal(null)}/>
                <div className="input-review-heading">
                    <div className="input-review-heading-main">Upload review files</div>
                </div>
                <div className="review-input-playground">
                    <div className="review-input-playground-heading">
                        {showUploadModal=='add' && 
                        <input className="review-input-title" placeholder="Title"  onChange={(e)=>setForm({...form,"title":e.target.value})}/>}
                        <div className="review-input-version">
                            <span>Define version :</span>
                            <div className="review-input-version-input">{showUploadModal.versioncount?showUploadModal.versioncount+1:1}</div>
                        </div>
                    </div>
                    <div className="review-input-grid">
                        {showUploadModal=='add' &&
                        <div className="review-playground-input">
                            <div className="review-playground-input-item" onClick={()=>{reviewInput.current.click()}} >
                                <img src={plusIcon} alt="add"/>
                            </div>
                            <div className="review-input-caption">Add</div>
                        </div>}
                        {reviewInputFiles.map((item,index)=>
                        <div className="review-input-item">
                            <div className="review-input-remove" onClick={()=>removeReviewInputFile(index)}>
                                <img src={crossIcon} alt="close"/></div>
                            <img className="review-input-item-img" src={item.image} alt="add"/>
                            <div className="review-input-caption">{item.name}</div>
                        </div>)}
                    </div>
                </div>
                <div className="review-input-modal-actions">
                   <button className={`btn btn-${verify()?"green-fill":"grey"}`} onClick={handleUploadFiles}>
                    Upload
                    </button>
                    <button className="btn btn-green-outline" onClick={()=>setShowUploadModal(false)}>
                    Cancel
                    </button>
                </div>
            </div>:
            <div className="modal-popup-content review-input-modal">
            <input className="review-input" multiple hidden type="file" accept="image/png, image/jpeg" onChange={handleInputReviewFiles}/>
                <img className="close-input-review-modal" src={crossIcon} alt="close" onClick={()=>setShowUploadModal(false)}/>
                <div className="input-review-heading">
                    <div className="input-review-heading-main">Upload review files</div>
                    <div className="input-review-heading-sub">Allowed types: Jpg, Jpeg</div>
                </div>
                <button className="btn btn-grey" onClick={()=>{reviewInput.current.click()}}>
                <img src={plusIcon} alt="add"/><span>Upload from computer</span>
                
            </button>
            <button className="btn btn-grey">
                <img src={dropboxIcon} alt="add"/><span>Upload through dropbox</span>
            </button>
            </div>}
            
        </div>
    )
}
export default InputReviewModal