import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from './Dashboard/Header';
import { Outlet } from 'react-router-dom';
import ProfileDetailsModal from './Dashboard/ProfileDetailsModal';
import { NotificationSettings, Tutorial } from './Dashboard';
import SideBar from './Admin/sidebar';
import AdminHeader from './Admin/adminHeader';
import ProductionHome from './ProductionHome';
import MailInfoModal from './Dashboard/MailInfoModal';

const Home = () => {
    const profile = useSelector(state => state.profile);
    const first_login = useSelector(state => state.first_login);
    const prodTeam = sessionStorage.getItem("prodTeam");
    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const [showMenu,setShowMenu]=useState(false);
    const [showNotificationSettings,setShowNotificationSettings]=useState(false);
    const [showTutorial,setShowTutorial]=useState(first_login);
    const [showMailModal,setShowMailModal]=useState(false);
    // const [showProjectTutorial,setShowProjectTutorial]=useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleShowTutorial = () => setShowTutorial(true);
    const handleShowProjectTutorial = () => dispatch({type:"SET_PROJECT_TUTORIAL",payload:true});
    const handleShowMailModal = () => {setShowMailModal(true)};
    // const skipTutorial = () => {setShowTutorial(false);setShowProjectTutorial(false);}
    const skipTutorial = () => {setShowTutorial(false)}
    const handleCloseNotificationSettings=()=>{setShowNotificationSettings(false)}
    const handleOpenNotificationSettings=()=>{setShowNotificationSettings(true)};
    useEffect(()=>{
        console.log(showNotificationSettings)
    },[showNotificationSettings])
    return (
        <>
            {
                profile.user_type === "client" ?
                <>
                    {/* <Header handleShow={handleShow} handleOpenNotificationSettings={handleOpenNotificationSettings} handleShowTutorial={handleShowTutorial}/> */}
                    <Header handleShowMailModal={handleShowMailModal} handleShow={handleShow} handleOpenNotificationSettings={handleOpenNotificationSettings} handleShowTutorial={handleShowTutorial} handleShowProjectTutorial={handleShowProjectTutorial} setShowMenu={setShowMenu} showMenu={showMenu}/>
                    <div className='dashboard-page'> 
                        <Outlet/>
                    </div>
                        <ProfileDetailsModal show={show} handleClose={handleClose} />
                        <MailInfoModal showMailModal={showMailModal} setShowMailModal={setShowMailModal} />
                        {showNotificationSettings && <NotificationSettings handleCloseNotificationSettings={handleCloseNotificationSettings}/>}
                        <Tutorial showTutorial={showTutorial} skipTutorial={skipTutorial} handleShow={handleShow} handleOpenNotificationSettings={handleOpenNotificationSettings} showMenu={showMenu} setShowMenu={setShowMenu}/>
                        {/* <ProjectTutorial showProjectTutorial={showProjectTutorial} skipTutorial={skipTutorial} handleShowProjectTutorial={handleShowProjectTutorial} /> */}
                </>
                :
                <>
                    {
                        prodTeam ? 
                        <ProductionHome/>
                        :
                        <div className="admin-view">
                            <SideBar/>
                            <AdminHeader/>
                            <Outlet/>
                        </div>
                    }
                </>
            }
        </>
    );
};

export default Home;