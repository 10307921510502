import * as actionType from "./../helpers/constants";

const initialState = {
  isLoading:false,
  sentResetEmail:false,
  otp_token:null,
  isAuth:null,
  auth_images:[],
  profile:null,
  first_login:null,
  message:null,
  messages:[],
  conversation:[],
  replies:[],
  project:null,
  projects:[],
  all_users:[],
  all_projects:[],
  message_categories:[],
  todo:[],
  edit_image:null,
  todo_dates:[],
  notes:[],
  current_todo_date:null,
  notifications:[],
  notificationSettings:null,
  projectStatus:null,
  review_files:[],
  completed_files:[],
  activity:[],
  prodTeam:false,
  searchList:null,
  searchResult:{},
  timezones:[],
  markers:[],
  show_project_tutorial:false,
  first_project_visit:null,
  unread_client_notification : "",
  unread_admin_notification : "",
  unread_quotes : "",
  show_review_file_tutorial : false
};
const reducer = (state = initialState, action) => {
  switch (action.type) 
  {
    case actionType.SET_LOADING:
      return {...state,isLoading:action.payload}
    case actionType.SET_AUTH:
      console.log("red",action.payload)
      localStorage.setItem("isAuth",JSON.stringify({ ...action?.payload }))
      return {...state,isAuth:action.payload};
    case actionType.AUTH_IMAGES:
      return {...state,auth_images:action.payload}
    case actionType.LOGOUT:
      localStorage.clear();
      return{
        ...state,isAuth:null,message:null
      }
    case actionType.SET_MESSAGE:
      console.log(action.payload)
       return{...state,message:action.payload}
    case actionType.SET_OTP_TOKEN:
      return {...state,otp_token:action.payload}
    case actionType.SENT_RESET_EMAIL: 
      return { ...state, sentResetEmail:action.payload};
    case actionType.SET_PROFILE:
      localStorage.setItem("profile",JSON.stringify(action.payload))
      return { ...state,profile:action.payload,message:null};
    case actionType.SET_FIRST_LOGIN:
      return {...state,first_login:action.payload}
    case actionType.SET_CURRENT_PROJECT:
      return {...state,project:action.payload}
    case actionType.SHOW_REVIEW_FILE_TUTORIAL:
      return {...state,show_review_file_tutorial:action.payload}
    case actionType.GET_QUOTES:
      return {...state,quotes:action.payload}
    case actionType.EDIT_PPL:
      const {id,item}=action.payload;
      return {...state,
        projects: state.projects.map((project) =>
        project.id === id
          ? item
          : project
      ),}
    case actionType.GET_PROJECTS:
      return {...state,projects:action.payload,message:null}
    case actionType.SEARCH_PROJECTS:
      return {...state,projects:action.payload,message:null}
    case actionType.UPDATE_PROJECTS:
      return {...state,projects:state.projects.map(item=>item.id==action.payload.id?action.payload:item)}
    case actionType.GET_ALL_NOTIFICATIONS:
      return {...state,notifications:action.payload,message:null}
    case actionType.SET_NOTIFICATION_SETTINGS:
      return {...state,notificationSettings:action.payload,message:null}
    case actionType.GET_PROJECT_STATUS:
      return {...state,projectStatus:action.payload,message:null}
    case actionType.ALL_USERS:
      return {...state,all_users:action.payload}
    case actionType.ALL_PROJECTS:
      return {...state,all_projects:action.payload}
    case actionType.SET_INVITATIONS:
      return {...state,project:{...state.project,invited_peoples:[...action.payload]}}
    case actionType.SET_UNREAD_QUOTE_COUNT:
      console.log(action.payload)
      return {...state,unread_quotes:action.payload}
    case actionType.SET_UNREAD_MESSAGE_COUNT:
      console.log(action.payload)
      return {...state,project:{...state.project,unread_messages:action.payload}}
    case actionType.SET_UNREAD_TODO_COUNT:
      console.log(action.payload)
      return {...state,project:{...state.project,unread_todo:action.payload}}
    case actionType.SET_UNREAD_ACTIVITY_COUNT:
      console.log(action.payload)
      return {...state,unread_activity:action.payload}
    case actionType.SET_UNREAD_CLIENT_NOTIFICATION_COUNT:
      console.log(action.payload)
      return {...state,unread_client_notification:action.payload}
    case actionType.SET_UNREAD_ADMIN_NOTIFICATION_COUNT:
      console.log(action.payload)
      return {...state,unread_admin_notification:action.payload}
    case actionType.SET_MESSAGES:
      return {...state,messages:action.payload}
    case actionType.SET_REPLIES:
      let conversation=state.conversation
      if(conversation)
      conversation.forEach(item=>{if(item.id==action.payload.message_ref) item.reply_count=action.payload.replies.length})
      return {...state,conversation,replies:action.payload.replies} 
    case actionType.SET_MESSAGE_CATEGORIES:
      return {...state,message_categories:action.payload}
    case actionType.CREATE_MESSAGE_CATEGORY:
      return {...state,message_categories:[action.payload,...state.message_categories]}
    case actionType.EDIT_MESSAGE_CATEGORY:
       return {...state,message_categories:state.message_categories.map(item=>item.id==action.payload.id?action.payload:item)}
    case actionType.DELETE_MESSAGE_CATEGORY:
      return {...state,message_categories:state.message_categories.filter(item=>item.id!==action.payload)}
    case actionType.SET_CONVERSATION:
      return {...state,conversation:action.payload}
    case actionType.SET_TODO:
      return{...state,todo:action.payload}
    case actionType.ADD_TODO:
      return {
      ...state,
      todo: (action.payload.due_date==state.current_todo_date)? [action.payload,...state.todo]:[...state.todo],
      todo_dates:(!state.todo_dates.includes(action.payload.due_date))?[action.payload.due_date,...state.todo_dates]:[...state.todo_dates]
    }
    case actionType.TODO_DATES:
      return {...state,todo_dates:action.payload}
    case actionType.SET_CURRENT_TODO_DATE:
      return {...state,current_todo_date:action.payload}
    case actionType.UPDATE_TODO:
      let all_done=true,todo_dates=state.todo_dates
      let todo=state.todo.map(item=>item.id==action.payload.id?{...item,is_done:action.payload.is_done}:item)
      for(let i=0;i<todo.length;i++)
        if(!todo[i].is_done)
          {
            all_done=false
            break;
          }
      if(all_done){
        todo_dates=todo_dates.filter(i=>i!=state.todo[0].due_date)
      }
      return {...state,todo:todo,todo_dates:todo_dates}
    case actionType.SET_REVIEW_FILES:
      if(state.review_files?.length==0 || action.payload?.length==0)
        return {...state,review_files:action.payload}
      else
        return {...state,review_files:[...state.review_files,...action.payload]}
    case actionType.DELETE_REVIEW_FILES:
      // if(state.review_files?.length==0 || action.payload?.length==0)
      //   return {...state,review_files:action.payload}
      // else
      //   return {...state,review_files:[...state.review_files]}
      return {...state,review_files:state.review_files.filter(i=>i.id !== action.payload)}
    case actionType.UPDATE_REVIEW_FILES:
      return {...state,review_files:state.review_files.map(item=>item.id==action.payload.id?action.payload:item)}
    case actionType.SAVE_EDIT_IMAGE:
      let images;
      for(let i=0;i<state.review_files.length;i++)
         if(state.review_files[i].id==action.payload.imageparent)
           images=state.review_files[i]
      images.wip=images.wip.map(item=>item.id==action.payload.id?action.payload:item)
      console.log(images)
        return {...state,review_files:state.review_files.map(item=>{
          if(action.payload.imageparent==item.id)
             {
               return images
             }else
             return item
        })}
    case actionType.SET_EDIT_IMAGE:
      return {...state,edit_image:action.payload}
    case actionType.SET_MARKERS:
      return {...state,markers:action.payload}
    case actionType.ADD_MARKER:
     { 
      let updatedMarkers=state.markers.map(item=>item.id!=null?item:action.payload.comment)
      let updatedReviewFiles=state.review_files.map(reviewItem=>{
        reviewItem.wip=reviewItem.wip.map(item=>{
        if(item.id==action.payload.imageID)
          return {...item,comments:updatedMarkers}
        else
         return item
        })
        return reviewItem
      })
      return {...state,review_files:updatedReviewFiles,markers:updatedMarkers}
    }
    case actionType.UPDATE_MARKER:
      {
        let updatedMarkers=state.markers.map(item=>item.id==action.payload.comment.id?action.payload.comment:item)
        let updatedReviewFiles=state.review_files.map(reviewItem=>{
          reviewItem.wip=reviewItem.wip.map(item=>{
          if(item.id==action.payload.imageID)
            return {...item,comments:updatedMarkers}
          else
           return item
          })
          return reviewItem
        })
      return {...state,review_files:updatedReviewFiles,markers:updatedMarkers}
      }
    case actionType.DELETE_MARKER:
      {
        let updatedMarkers=state.markers.filter(item=>item.id!==action.payload.id)
        let updatedReviewFiles=state.review_files.map(reviewItem=>{
          reviewItem.wip=reviewItem.wip.map(item=>{
          if(item.id==action.payload.imageID)
            return {...item,comments:updatedMarkers}
          else
           return item
          })
          return reviewItem
        })
        return {...state,review_files:updatedReviewFiles,markers:updatedMarkers}
      }
    case actionType.SET_COMPLETED_FILES:
      return {...state,completed_files:action.payload}
    case actionType.SET_NOTES:
      return {...state,notes:action.payload}
    case actionType.SET_ACTIVITY:
      return {...state,activity:action.payload}
    case actionType.SET_SEARCH_LIST:
      return {...state,searchList:action.payload}
    case actionType.SET_SEARCH_RESULT:
        return {...state,searchResult:action.payload}
    case actionType.UPDATE_SEARCH_RESULT:
      return {...state,searchResult:state.searchResult.map(item=>item.id==action.payload.id?{...item,is_done:action.payload.is_done}:item)}
    case actionType.SET_TIMEZONES:
      return {...state,timezones:action.payload}
    case actionType.SET_PROJECT_TUTORIAL:
      return {...state,show_project_tutorial:action.payload}
    case actionType.SET_FIRST_PROJECT_VISIT:
      return {...state,first_project_visit:action.payload}
    default:
      return {
        ...state,
        searchResult:{},
        isAuth:JSON.parse(localStorage.getItem("isAuth")) || JSON.parse(sessionStorage.getItem("isAuth")),
        profile:JSON.parse(localStorage.getItem("profile")) || JSON.parse(sessionStorage.getItem("profile")),
        message:null
      };
  }
}
export default reducer;