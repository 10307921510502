import React,{useEffect, useState} from "react";
import { useDispatch,useSelector } from "react-redux";
import { useNavigate,useParams } from "react-router-dom";

import DatePicker from "react-datepicker";
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import { isValidPhoneNumber,parsePhoneNumber } from 'react-phone-number-input';
import { sendPaymentInvoice } from "../../../actions";

const PaymentTab=({project})=>{
    console.log(project)
    const dispatch=useDispatch();
    const [startDate, setStartDate] = useState(new Date());
    const [selectedDate,setSelectedDate]=useState(new Date());
    const [isChequePayment,setIsChequePayment]=useState(false)
    const [startChequeDate, setStartChequeDate] = useState(new Date());
    const [selectedChequeDate,setSelectedChequeDate]=useState(new Date());
    const [paymentForm,setPaymentForm]=useState({
        "remaining_amount": project?.remaining_amount,
        "amount_to_be_paid": project?.amount_to_be_paid,
        "total_amount":project?.total_amount,
        "due_date":selectedDate === new Date() ? moment(new Date()).format('YYYY-MM-DD') : selectedDate,
        "is_cheque":false,
        "cheque_no":null,
        "cheque_date":moment(new Date()).format('YYYY-MM-DD'),
    })
   
   const verify=()=>{
    const {total_amount,remaining_amount,amount_to_be_paid} = paymentForm
    console.log(total_amount,amount_to_be_paid)
    console.log(typeof(total_amount))
    if(isChequePayment && paymentForm.cheque_no && total_amount && remaining_amount && amount_to_be_paid){
        return true
    }
    if(paymentForm.amount_to_be_paid < 1){
        return false
    }
    if(project.total_amount && project.remaining_amount && project.amount_to_be_paid){
        return false
    }
    if((total_amount && amount_to_be_paid)){
        if(Number(total_amount) >= Number(amount_to_be_paid))
        return true
    }
    else{
        return false
    }
   }
   const sendInvoice=(e)=>{
    e.preventDefault();
    if(verify())
    {
       dispatch(sendPaymentInvoice(project.id,{...paymentForm,due_date : selectedDate.toISOString().slice(0,10),amount_to_be_paid : Number(paymentForm.amount_to_be_paid),total_amount : Number(paymentForm.total_amount),remaining_amount : Number(paymentForm.remaining_amount)}))
    }
    console.log(selectedDate)
   }
 const handleChange=(e)=>{
    const name=e.target.name;
    const value=e.target.value;
    setPaymentForm({...paymentForm,[name]:value})
 }

    return (
    <div className="ppl-payment">
    <div className="ppl-payment-header">Payment Details</div>
    <div className="ppl-payment-form ppl-payment-form-border">
    <div className="form-input-box">
        <div className='form-label'>Project Name</div>
        <input readOnly className="form-input" name="project_name"  minLength={3} maxLength={40} value={project.project_name}  />
    </div>
    <div className="form-input-box">
        <div className='form-label'>Project ID</div>
        <input readOnly className="form-input" name="project_id" value={project.id}  />
    </div>
    <div className="form-input-box">
        <div className='form-label'>Company Name</div>
        <input readOnly className="form-input"  minLength={3} maxLength={40} name="company_name" value={project.company_name}  />
    </div>
       <div className="form-input-box">
        <div className='form-label'>Project Type</div>
        <select className="form-input" name="project_type" value={project.project_type} disabled>
            <option value='none'>Select project type</option>
            <option value="Architecture">Architecture</option>
            <option value="Interior">Interiors</option>
            <option value="Real Estate">Real Estate</option>
            <option value="Landscape">Landscape</option>
            <option value="Golf Course">Golf Course</option>
            <option value="Yacht/Boat">Yacht/Boat</option>
            <option value="Swimming Pools">Swimming Pools</option>
        </select>
         </div>
                        <div className="form-input-box">
        <div className='form-label'>Client Name</div>
        <input readOnly className="form-input" name="client_name"  minLength={3} maxLength={40} value={project.client_name || project.your_name} />
    </div>
                        <div className="form-input-box">
                            <div className="form-label">Service Type</div>
                            <select className="form-input" name="service_type" value={project.service_type} disabled>
                                <option value='none'>Select service type</option>
                                <option value="Photo-Realistic Rendering">Photo-Realistic Rendering</option>
                                <option value="Conceptual Renderings">Conceptual Renderings</option>
                                <option value="Walkthrough Animation">Walkthrough Animation</option>
                                <option value="Flythrough Animation">Flythrough Animation</option>
                                <option value="360 Virtual Tour">360 Virtual Tour</option>
                                <option value="Virtual Staging">Virtual Staging</option>
                                <option value="Interactive Imagery">Interactive Imagery</option>
                                <option value="Colored Floor plans">Colored Floor plans</option>
                                <option value="3D Floor plans">3D Floor plans</option>
                                <option value="Drafting Services - BIM">Drafting Services - BIM</option>
                                <option value="Drafting Services - CAD">Drafting Services - CAD</option>
                            </select>
                            
                        </div>
                        <div className="form-input-box">
                            <div className='form-label'>Email Address</div>
                            <input readOnly className="form-input" type="email" name="email" value={project.email}  />
                        </div>
                        <div className="form-input-box">
                            <div className='form-label'>Quantity of service</div>
                            <input readOnly className="form-input" type="number" min={0} name="quantity" value={project.quantity}/>
                            
                        </div>
                        <div className="form-input-box" >
                         <div className="form-label">Mobile number</div>
                            <div className={`phone-box `}>
               
                             <PhoneInput
                                style={{width:'100%'}}
                                defaultCountry="US"
                                value={project.mobile_number}
                                />
                        </div>
                    </div>
                    <div className="form-input-box">
                            <div className='form-label'>Due date</div>
                            <DatePicker className="form-input"   selected={selectedDate} minDate={startDate} onChange={(date) => {setSelectedDate(moment(new Date(date)).format('YYYY-MM-DD'));}} />
                        </div>
                        
                
    </div>
    <div className="ppl-payment-form">  
    <div className="form-input-box">
        <div className='form-label'>Total amount </div>
        <input readOnly={project?.total_amount === null ? false : true} className="form-input" type="number" name="total_amount" value={project?.total_amount !== null ? project?.total_amount : paymentForm?.total_amount} onChange={handleChange}/>
    </div>     
    <div className="form-input-box">
        <div className='form-label'>Amount to be paid </div>
        <input className="form-input" type="number" name="amount_to_be_paid" readOnly={project?.amount_to_be_paid === null || "0" ? false : true} value={project?.amount_to_be_paid === null || "0" ? paymentForm.amount_to_be_paid : project?.amount_to_be_paid} onChange={handleChange}/>
    </div>
    <div className="form-input-box">
        <div className='form-label'>Remaining amount</div>
        <input readOnly className="form-input" type="number" name="remaining_amount" value={project?.remaining_amount === null ? paymentForm?.total_amount : project?.remaining_amount}/>  
     </div>
     <div></div>
     <div className="form-input-type" style={{marginLeft : "0px"}}>
        <input type="checkbox" id="cheque-pay" onChange={(e)=>{setIsChequePayment(e.target.checked);setPaymentForm({...paymentForm,"is_cheque":e.target.checked})}}/>
        <label className="form-input-label-type" for="cheque-pay">
            <div className="form-input-label-type-main">paid via check</div></label>
     </div>
     <div></div>
     {isChequePayment && <>
        <div className="form-input-box">
        <div className='form-label'>Check ID </div>
        <input className="form-input" name="cheque_no" value={paymentForm.cheque_no} onChange={handleChange}/>
    </div> 
    <div className="form-input-box">
     <div className='form-label'>Check Date</div>
    <DatePicker className="form-input" selected={selectedChequeDate} minDate={startChequeDate} onChange={(date) => {setSelectedChequeDate(date);setPaymentForm({...paymentForm,cheque_date:moment(date).format('YYYY-MM-DD')})}} />
     </div>
     </>}    
    </div>
    <div className="ppl-form-actions">
    <button onClick={sendInvoice} className={`btn btn-${verify()?'green-fill':'grey'}`} style={{marginBottom : "20px"}} >
    Send to client
                </button>
               
    </div>
</div>
    )
}

export default PaymentTab