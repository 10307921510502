import * as actionType from "./../helpers/constants";
import notify from "../helpers/notify";
import HelloSign from 'hellosign-embedded';
import axios from "axios";
const API=actionType.API;
const temp = axios.create({ baseURL: API });
temp.interceptors.request.use((req)=>{
  if(localStorage.getItem('profile'))
    {
      req.headers.Authorization=`Bearer ${JSON.parse(localStorage.getItem('isAuth')).access}`
    }
  else if(sessionStorage.getItem('profile'))
    {
      req.headers.Authorization=`Bearer ${JSON.parse(sessionStorage.getItem('isAuth')).access}`
    }
  else
    {
      console.log("no token")
    }
   return req;
})
async function postData(url = '', data={},token) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `${token?`Bearer ${token}`:''}`
    },
    body: JSON.stringify(data) // body data type must match "Content-Type" header

  });
  //console.log(response)
  return response.json(); // parses JSON response into native JavaScript objects
}
async function putFormData(url = '', formData={},token) {
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      // 'Content-Type': 'multipart/form-data',
      // 'Accept': 'multipart/form-data',
      'Authorization': `${token?`Bearer ${token}`:''}`
    },
    // body: JSON.stringify(formData)
    body: formData
  });
  return response.json();
}
async function putData(url='',data={},token){
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `${token?`Bearer ${token}`:''}`
    },
    body: JSON.stringify(data)
  });
  return response.json();
}
async function getData(url,token) {
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization' : `Bearer ${token}`
    }
  });
  return response.json();
}
export const register=(formData,navigate)=>async (dispatch)=>{
  try{
    console.log(formData)
    notify("info","please wait")
    await postData(`${API}/users/signup/`,formData)
    .then((data)=>{
     console.log(data);
     if(data.Error)
     {
      navigate("/sign-up")
      dispatch({type:actionType.SET_MESSAGE,payload:{type:'fail',message:data.Error}});
     }else{
      notify(data.success?'success':'error',data.message)
      //dispatch({type:actionType.SET_AUTH,payload:true})
      navigate("/login")
     }
    })
  }catch(error)
  {
    console.log(error)
  }
}
export const resetPassword=(formData,uid,token,navigate)=>async (dispatch)=>{
  try{
    console.log(formData)
    notify("info","please wait")
    await postData(`${API}/users/resetpass/${uid}/${token}/`,formData)
    .then((data)=>{
     console.log(data);
     if(data.Error)
     {
      dispatch({type:actionType.SET_MESSAGE,payload:{type:'fail',message:data.Error}});
     }else{
      notify("success",data.message)
      navigate("/login")
     }
    })
  }catch(error)
  {
    console.log(error)
  }
}
export const login=(formData,next,step)=>async(dispatch)=>{
  try{
    console.log(formData)
    notify("info","please wait")
    await postData(`${API}/users/login/`,formData)
    .then((data)=>{
     console.log('user details', data);
     
     if(data.Error)
     {
      
      dispatch({type:actionType.SET_MESSAGE,payload:{type:'fail',message:data.Error}});
     }else{
      dispatch({type:actionType.SET_AUTH,payload:{access:data.access,refresh:data.refresh}})
      dispatch({type:actionType.SET_PROFILE,payload:data.profile[0]})
      dispatch({type:actionType.SET_FIRST_LOGIN,payload:data.firstLogin})
      dispatch({type:actionType.SET_MESSAGE,payload:null});
      next(step)
     }
    })
  }catch(error)
  {
    console.log(error)
  }
}
export const logout=()=>(dispatch)=>{
  try{
    dispatch({type:actionType.LOGOUT});
  }catch(error)
  {
    console.log(error)
  }
}
export const sendOtp=(formData,next,step)=>async(dispatch)=>{
  try{
    console.log(formData)
   await postData(`${API}/users/sendotp/`,formData)
   .then((data)=>{
    console.log(data);
    if(data.success){
      notify("success",`your otp is ${data.otp}`)
      dispatch({type:actionType.SET_OTP_TOKEN,payload:data.secret});
      dispatch({type:actionType.SET_MESSAGE,payload:null});
      if(step)
      next(step)
    }else{
      dispatch({type:actionType.SET_MESSAGE,payload:{type:'fail',message:data.message}});
    }
   })
  }catch(error)
  {
    console.log(error)
  }
}
export const verifyOtp=(formData,next,step)=>async(dispatch)=>{
  try{
    console.log(formData)
    await postData(`${API}/users/verifyotp/`,formData)
    .then((data)=>{
     console.log(data);
     if(data.success)
     {
     
      dispatch({type:actionType.SET_MESSAGE,payload:null});
      if(formData.isLogin)
      {
        dispatch({type:actionType.SET_AUTH,payload:{access:data.access,refresh:data.refresh}});
        dispatch({type:actionType.SET_PROFILE,payload:data.profile[0]})
      }
      next(step);
    }
     else
     dispatch({type:actionType.SET_MESSAGE,payload:{type:'fail',message:data.message}});
    });
  }catch(error)
  {
    console.log(error)
  }
}
export const sendResetEmail=(form)=>(dispatch)=>{
 try{
   console.log(form);
   temp.post("/users/forgotpass/",form)
   .then(data=>{
    dispatch({type:actionType.SENT_RESET_EMAIL,payload:true});
    console.log(data)
   })
  
 }catch(error){
    console.log(error);
 }
}
export const AuthImages=()=>(dispatch)=>{
 try{
   temp.get("/users/loginsignupimages/")
   .then(data=>{
    dispatch({type:actionType.AUTH_IMAGES,payload : data.data});
    console.log(data)
   })
  
 }catch(error){
    console.log(error);
 }
}
export const addNewProject=(formData)=>async(dispatch)=>{
  try{
    temp.post(`/project/newproject/`,formData)
    .then((data)=>{
      console.log(data)
      dispatch({type:actionType.SET_MESSAGE,payload:{type:'success',message:'Your request has been submitted '}});
      if(data.status==200)
      notify("success","Project added")
    })
  }catch(e)
  {
    console.log(e)
  }
}
export const FcmToken=(token)=>{
  try{
    temp.post(`/users/fcmtoken/`,{
      fcm_token : token
    })
    .then((data)=>{
      console.log(data)
    }).catch((e)=>{
      console.log(e)
    })
  }catch(e)
  {
    console.log(e)
  }
}

export const updateProfile=(id, formData,token)=>async(dispatch)=>{
  try{
    console.log(formData, token)
    
    for (var entry of formData.entries()) {
      console.log(entry[0] + ', ' + entry[1]);
  }
    // const formData = new FormData();
    // if(form?.avatar){
    //   formData.append('avatar', form?.avatar);
    // }
    // formData.append('full_name', 'data.full_name');
    // formData.append('title_atcompany', 'data.title_atcompany');
    // formData.append('email', 'data.email');
    // formData.append('location', 'data.location');
    // formData.append('bio', 'data.bio');
    // formData.append('timezone', 'data.timezone');
    // formData.append('first_day_week', 'data.first_day_week');
    await putFormData(`${API}/project/profile/${id}/`,formData,token)
    .then((data)=>{
        console.log(data)
        if(!data.Error){
          dispatch({type:actionType.SET_MESSAGE,payload:{type:'success',message:'Profile updated successfully '}});
          dispatch({type:actionType.SET_PROFILE,payload: data["User Profile"][0]})
        }
      }
    )
  }catch(e)
  {
    console.log(e)
  }
}
export const getAdminProject=(id,token)=>async(dispatch)=>{
  try{
    dispatch({type:actionType.SET_LOADING,payload:true})
    temp.get(`/project/projects/${id}`)
    .then(data=>{
      console.log("get project",data.data.Project);
      dispatch({type:actionType.SET_LOADING,payload:false})
      dispatch({type:actionType.SET_CURRENT_PROJECT,payload:data.data.Project})
    })
    temp.get(`/message_board/getmsgticker/?project_id=${id}`)
    .then(data=>{
      console.log("unread",data)
      dispatch({type:actionType.SET_UNREAD_MESSAGE_COUNT,payload:data.data.unreadmsgcount})
    })
    temp.get(`/project/GetQuoteTikers/`)
    .then(data=>{
      console.log("unread Quotes",data)
      dispatch({type:actionType.SET_UNREAD_QUOTE_COUNT,payload:data.data.unreadtodocount})
    })
  }catch(error)
  {
    dispatch({type:actionType.SET_LOADING,payload:false})
    console.log(error)
  }
}
export const getUserProject=(id)=>async(dispatch)=>{
  try{
    dispatch({type:actionType.SET_LOADING,payload:true})
    temp.get(`/userproj/userppl/${id}`)
    .then(data=>{
      //console.log("get project",data.data.Project);
      dispatch({type:actionType.SET_CURRENT_PROJECT,payload:data.data.Project})
      dispatch({type:actionType.SET_LOADING,payload:false})
      temp.get(`/message_board/getmsgticker/?project_id=${id}`)
      .then(data=>{
        console.log("unread",data)
        dispatch({type:actionType.SET_UNREAD_MESSAGE_COUNT,payload:data.data.unreadmsgcount})
      })
      temp.get(`/project/gettodoticker/?project_id=${id}`)
      .then(data=>{
        console.log("unread",data)
        dispatch({type:actionType.SET_UNREAD_TODO_COUNT,payload:data.data.unreadtodocount})
      })
    })
  
  }catch(error)
  {
    dispatch({type:actionType.SET_LOADING,payload:false})
    console.log(error)
  }
}
export const getQuotes=(sortBy)=>async(dispatch)=>{
  try{
    dispatch({type:actionType.SET_LOADING,payload:true})
    temp.get(`/project/quotes${sortBy?`/?sort_by=${sortBy}`:''}`)
    .then(data=>{
      console.log("admin quotes",data.data.Projects);
      dispatch({type:actionType.SET_LOADING,payload:false})
      dispatch({type:actionType.GET_QUOTES,payload:data.data.Projects})
    })
    
  }catch(error){
    dispatch({type:actionType.SET_LOADING,payload:false})
    console.log(error)
  }
}
export const acceptProposal=async(id,token,navigate)=>{
  await temp.post(`/project/accept/`,{ "project_id":id})
  .then(data=>{
    console.log(data.data);
    navigate("/ppl")
   }).catch((error)=>{
    // handle error
    console.log(error);
  })
  
  
}

export const denyProposal=async(id,token,navigate)=>{
  await temp.post(`/project/reject/`,{ "project_id":id})
  .then(data=>{
    console.log(data.data);
    navigate("/ppl")
   }).catch((error)=>{
    // handle error
    console.log(error);
  })
}
export const editAdminPPL=(formdata,id,token)=>async(dispatch)=>{
  try{
    console.log(formdata)

    temp.put(`/project/ppledit/${id}/`,formdata).then(data=>{
      console.log(data);
      if(data.status==200){
        notify("success","PPL Edited")
        dispatch({type:actionType.EDIT_PPL,payload:{id,item:data.data.data}})
      }
     })
  }catch(error){
    console.log(error)
  }
}
export const getAdminProjects=(sortBy)=>async(dispatch)=>{
  try{
    dispatch({type:actionType.SET_LOADING,payload:true})
    temp.get(`/project/ppl${sortBy?`/?sort_by=${sortBy}`:''}`)
    .then(data=>{
      console.log(data)
      console.log("home admin projects",data.data.Projects);
      dispatch({type:actionType.SET_LOADING,payload:false})
      dispatch({type:actionType.GET_PROJECTS,payload:data.data.Projects})
    })
    
  }catch(error){
    dispatch({type:actionType.SET_LOADING,payload:false})
    console.log(error)
  }
}
export const getNotificationCount=()=>async(dispatch)=>{
  try {
    temp.get(`/project/getadminnotif/`)
      .then(data=>{
        console.log("unread",data)
        dispatch({type:actionType.SET_UNREAD_ADMIN_NOTIFICATION_COUNT,payload:data.data.unreadnotcountadmin})
      })
      temp.get(`/project/GetQuoteTikers/`)
    .then(data=>{
      console.log("unread Quotes",data)
      dispatch({type:actionType.SET_UNREAD_QUOTE_COUNT,payload:data.data.unreadquote})
    })
  } catch (error) {
    console.log(error)
  }
}
export const searchAdminProject=(search)=>async(dispatch)=>{
  try{
    dispatch({type:actionType.SET_LOADING,payload:true})
    if(search !== ''){
    temp.get(`/project/search_admin/?search=${search}`)
    .then(data=>{
      console.log(data)
      console.log("Search Projects",data.data.data);
      dispatch({type:actionType.SET_LOADING,payload:false})
      dispatch({type:actionType.GET_PROJECTS,payload:data.data.data})
    }).catch(()=>{
      console.log("Not Found")
      dispatch({type:actionType.SET_LOADING,payload:false})
    })
  }
  else{
    dispatch({type:actionType.SET_LOADING,payload:true})
    temp.get(`/project/ppl`)
    .then(data=>{
      console.log(data)
      console.log("home admin projects",data.data.Projects);
      dispatch({type:actionType.SET_LOADING,payload:false})
      dispatch({type:actionType.GET_PROJECTS,payload:data.data.Projects})
    })
  }
    
  }catch(error){
    dispatch({type:actionType.SET_LOADING,payload:false})
    console.log(error)
  }
}
export const getAdminOngoingProjects=(sortBy)=>async(dispatch)=>{
  try{
    dispatch({type:actionType.SET_LOADING,payload:true})
    temp.get(`/project/ongoing${sortBy?`/?sort_by=${sortBy}`:''}`)
    .then(data=>{
      console.log("admin ongoing projects",data.data.Projects);
      dispatch({type:actionType.SET_LOADING,payload:false})
      dispatch({type:actionType.GET_PROJECTS,payload:data.data.Projects})
    })
    
  }catch(error){
    dispatch({type:actionType.SET_LOADING,payload:false})
    console.log(error)
  }
}
export const getAdminCompletedProjects=(sortBy)=>async(dispatch)=>{
  try{
    dispatch({type:actionType.SET_LOADING,payload:true})
    temp.get(`/project/comp${sortBy?`/?sort_by=${sortBy}`:''}`)
    .then(data=>{
      console.log("admin completed projects",data.data.Projects);
      dispatch({type:actionType.SET_LOADING,payload:false})
      dispatch({type:actionType.GET_PROJECTS,payload:data.data.Projects})
    })
    
  }catch(error){
    dispatch({type:actionType.SET_LOADING,payload:false})
    console.log(error)
  }
}
export const getAdminCancelledProjects=(sortBy)=>async(dispatch)=>{
  try{
    dispatch({type:actionType.SET_LOADING,payload:true})
    temp.get(`/project/cancel${sortBy?`/?sort_by=${sortBy}`:''}`)
    .then(data=>{
      console.log("admin cancelled projects",data.data.Projects);
      dispatch({type:actionType.SET_LOADING,payload:false})
      dispatch({type:actionType.GET_PROJECTS,payload:data.data.Projects})
    })
    
  }catch(error){
    dispatch({type:actionType.SET_LOADING,payload:false})
    console.log(error)
  }
}
export const getAdminPendingProjects=(sortBy)=>async(dispatch)=>{
  try{
    dispatch({type:actionType.SET_LOADING,payload:true})
    temp.get(`/project/pending${sortBy?`/?sort_by=${sortBy}`:''}`)
    .then(data=>{
      console.log("admin pending projects",data.data.Projects);
      dispatch({type:actionType.SET_LOADING,payload:false})
      dispatch({type:actionType.GET_PROJECTS,payload:data.data.Projects})
    })
    
  }catch(error){
    dispatch({type:actionType.SET_LOADING,payload:false})
    console.log(error)
  }
}
export const getUserProjects=(sortBy)=>async(dispatch)=>{
  try{
    dispatch({type:actionType.SET_LOADING,payload:true})
    temp.get(`/userproj/userppl${sortBy?`/?sort_by=${sortBy}`:''}`)
    .then(data=>{
      console.log("home user projects",data.data.Projects);
      dispatch({type:actionType.SET_LOADING,payload:false})
      dispatch({type:actionType.GET_PROJECTS,payload:data.data.Projects})
      temp.get("/project/getclientnotiftick")
      .then(data=>{
        console.log("unread",data)
        dispatch({type:actionType.SET_UNREAD_CLIENT_NOTIFICATION_COUNT,payload:data.data.unreadnotcount})
    })
  })
  }catch(error){
    dispatch({type:actionType.SET_LOADING,payload:false})
    console.log(error)
  }
}

export const getUserNotificationCount=()=>async(dispatch)=>{
  try{
    temp.get("/project/getclientnotiftick")
      .then(data=>{
        console.log("unread",data)
        dispatch({type:actionType.SET_UNREAD_CLIENT_NOTIFICATION_COUNT,payload:data.data.unreadnotcount})
    })
      temp.get("/project/activity_tickers")
      .then(data=>{
        console.log("unread",data)
        dispatch({type:actionType.SET_UNREAD_ACTIVITY_COUNT,payload:data.data.unreadactcount})
    })
  }catch(error){
    console.log(error)
  }
}
export const getUserOngoingProjects=(sortBy)=>async(dispatch)=>{
  try{
    dispatch({type:actionType.SET_LOADING,payload:true})
    temp.get(`/userproj/ongoing${sortBy?`/?sort_by=${sortBy}`:''}`)
    .then(data=>{
      console.log("home user ongoing",data.data.Projects);
      dispatch({type:actionType.SET_LOADING,payload:false})
      dispatch({type:actionType.GET_PROJECTS,payload:data.data.Projects})
    })
  }catch(error){
    dispatch({type:actionType.SET_LOADING,payload:false})
    console.log(error)
  }
}
export const getUserCompletedProjects=(sortBy)=>async(dispatch)=>{
  try{
    dispatch({type:actionType.SET_LOADING,payload:true})
    temp.get(`/userproj/comp${sortBy?`/?sort_by=${sortBy}`:''}`)
    .then(data=>{
      console.log("home user completed",data.data.Projects);
      dispatch({type:actionType.SET_LOADING,payload:false})
      dispatch({type:actionType.GET_PROJECTS,payload:data.data.Projects})
    })
    
  }catch(error){
    dispatch({type:actionType.SET_LOADING,payload:false})
    console.log(error)
  }
}
export const getUserCancelledProjects=(sortBy)=>async(dispatch)=>{
  try{
    dispatch({type:actionType.SET_LOADING,payload:true})
    temp.get(`/userproj/cancel${sortBy?`/?sort_by=${sortBy}`:''}`)
    .then(data=>{
      console.log("home user cancelled",data.data.Projects);
      dispatch({type:actionType.SET_LOADING,payload:false})
      dispatch({type:actionType.GET_PROJECTS,payload:data.data.Projects})
    })
    
  }catch(error){
    dispatch({type:actionType.SET_LOADING,payload:false})
    console.log(error)
  }
}
export const getUserPendingProjects=(sortBy)=>async(dispatch)=>{
  try{
    dispatch({type:actionType.SET_LOADING,payload:true})
    temp.get(`/userproj/pending${sortBy?`/?sort_by=${sortBy}`:''}`)
    .then(data=>{
      console.log("home user pending",data.data.Projects);
      dispatch({type:actionType.SET_LOADING,payload:false})
      dispatch({type:actionType.GET_PROJECTS,payload:data.data.Projects})
    })
    
  }catch(error){
    dispatch({type:actionType.SET_LOADING,payload:false})
    console.log(error)
  }
}
export const getAdminNotifications=(sortBy)=>async(dispatch)=>{
  try{
    dispatch({type:actionType.SET_LOADING,payload:true})
    temp.get(`/project/notif${sortBy?`/?sort_by=${sortBy}`:''}`)
    .then(data=>{
      console.log("admin notif",data.data.Notifications);
      dispatch({type:actionType.SET_LOADING,payload:false})
      dispatch({type:actionType.GET_ALL_NOTIFICATIONS,payload:data.data.Notifications})
    })
    
  }catch(error){
    dispatch({type:actionType.SET_LOADING,payload:false})
    console.log(error)
  }
}
export const getNotifications=(sortBy)=>async(dispatch)=>{
  try{
    dispatch({type:actionType.SET_LOADING,payload:true})
    temp.get(`/project/getclientnotif${sortBy?`/?sort_by=${sortBy}`:''}`)
    .then(data=>{
      console.log("client notif",data.data.Notifications);
      dispatch({type:actionType.SET_LOADING,payload:false})
      dispatch({type:actionType.GET_ALL_NOTIFICATIONS,payload:data.data.Notifications})
    })
    
  }catch(error){
    dispatch({type:actionType.SET_LOADING,payload:false})
    console.log(error)
  }
}
export const getNotificationSettings=()=>async(dispatch)=>{
  try{
    temp.get(`/users/notif/`)
    .then(data=>{
      console.log("notification",data);
      dispatch({type:actionType.SET_NOTIFICATION_SETTINGS,payload:data.data.NotificationSettings[0]})
    })
  }catch(error){
    console.log(error)
  }
}
export const updateNotificationSettings=(id,formData,token)=>async(dispatch)=>{
  try{
    await putData(`${API}/users/notif/${id}/`,formData,token).then(data=>{
      console.log("notification",data);
      dispatch({type:actionType.SET_NOTIFICATION_SETTINGS,payload:data.NotificationSettings[0]});
      dispatch({type:actionType.SET_MESSAGE,payload:{type:'success',message:'Notification settings updated successfully '}});
     })
  }catch(error){
    console.log(error)
  }
}

export const getProjectStatus=()=>async(dispatch)=>{
  try{
    await getData(`${API}/project/projects?sort_by=project_name&order=asc`)
      .then(data=>{
        console.log("project_status",data.Projects);
        dispatch({type:actionType.GET_PROJECT_STATUS,payload:data.Projects})
      })
  }catch(error){
    console.log(error)
  }
}
export const updateProjectStatus=(id,form)=>async(dispatch)=>{
  try{
    console.log(form,id)
    temp.patch(`${API}/project/status/${id}/`,form)
      .then(data=>{
        console.log(" update project_status",data);
        dispatch({type:actionType.SET_CURRENT_PROJECT,payload:data.data.data})
        if(data.data.success)
        notify("success",data.data.message)
        else
        notify("error","Sorry could not update status")
      })
  }catch(error){
    console.log(error)
  }
}
export const uploadProjectProposal=(formData)=>async(dispatch)=>{
  try{
    console.log(formData)
    dispatch({type:actionType.SET_LOADING,payload:true})
    temp.post(`/project/reuploadproposaldoc/`,formData)
    .then((data)=>{
      console.log("upload proposal",data)
      if(data.status==200)
        notify("success","Proposal uploaded")
      // dispatch({type:actionType.SET_CURRENT_PROJECT,payload:data.data.data})
      dispatch({type:actionType.SET_LOADING,payload:false})
    })
  }catch(err){
    console.log(err)
  }
}
export const updateProposal=(formData,navigate)=>async(dispatch)=>{
  try{
    console.log(formData)
    dispatch({type:actionType.SET_LOADING,payload:true})
    temp.post(`/project/updateproposaldoc/`,formData)
    .then((data)=>{
      console.log("upload proposal",data)
      if(data.status==200)
        notify("success","Proposal signed")
      dispatch({type:actionType.SET_CURRENT_PROJECT,payload:data.data.data})
      dispatch({type:actionType.SET_LOADING,payload:false})
      navigate(-1)
    })
  }catch(err){
    console.log(err)
  }
}
export const getProposalSignUrl=(id,token)=>async(dispatch)=>{
  try{
    const client = new HelloSign({
      clientId: process.env.REACT_APP_HELLO_SIGN_CLIENT_ID
    });
     await temp.get(`/project/sign_url/${id}/`)
     .then(data=>{
      console.log(data.data)
      client.open(data.data.url, {
        testMode: true,
        skipDomainVerification: true,
    });
    client.on('sign', () => {
    alert('The document has been signed!');
  });
  })
}catch(err){
    console.log(err)
  }
}
export const sendPaymentInvoice=(id,formData)=>async(dispatch)=>{
  try{
    console.log(formData)
    temp.patch(`/project/payment_details/${id}/`,{...formData, "project_id":id})
    .then(data=>{
      notify("success","Sent to client")
      console.log("payment order",data.data);
    })
  }catch(error)
  {
    console.log(error)
  }
}
export const createPaypalPaymentOrder=async(id,amount)=>
 await temp.post(`/create-paypal-order/${id}/`, {
      purchase_units: [
  {
      amount: {
          value:amount
      },
  },
],
  }).then((data) => {
    console.log("create order",data)
  return data.data.data.id; 
})
    .catch((error) => {
     console.log(error)
    });
export const capturePaypalOrder=(order,projectId)=>async(dispatch)=>
 await temp.post('/capture-paypal-order/',{
  orderID: order,
 }).then((data) => {
  console.log("capture order",data)
  dispatch(getUserProject(projectId))
})
  .catch((error) => {
   console.log(error)
  });
  export const captureQuickbookPayment=(form,navigate)=>async(dispatch)=>{
    try{
      notify("info","Please wait")
      temp.post("/payment/",form)
      .then(data=>{
        console.log(data)
        if(data.data.charge_id)
        {
         
          verifyQuickbookPayment({"project_id":form.project_id,"charge_id":data.data.charge_id},navigate)
        }
        else
         notify("error",data.data.error)
      })
    }catch(error)
    {
      console.log(error)
      notify("error",error.message)
    }
  }
  const verifyQuickbookPayment=(form,navigate)=>{
    console.log(form)
    temp.post("/verify/",form)
    .then(data=>{
      console.log(data)
      if(data.data.success)
       { notify("success","Payment successful")
       navigate("/project-status")
      }
    })
  }
export const downloadPdf=(url)=>async(dispatch)=>{
  try{
    fetch(url).then(response => {
      response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = url.split("/").slice(-1);
          alink.click();
      })
  })
  }
  catch(err){
    console.log(err)
  }
}
export const getAllUsers=()=>async(dispatch)=>{
  try{
    dispatch({type:actionType.SET_LOADING,payload:true})
     await temp.get(`/users/`)
     .then(data=>{
        console.log("all users",data.data.Users)
        dispatch({type:actionType.SET_LOADING,payload:false})
        dispatch({type:actionType.ALL_USERS,payload:data.data.Users})
     })
  }catch(error){
    dispatch({type:actionType.SET_LOADING,payload:false})
    console.log(error)
  }
}
export const searchUsers=(query)=>async(dispatch)=>{
  try{
    dispatch({type:actionType.SET_LOADING,payload:true})
     await temp.get(`/project/searchusers/?search=${query}`)
     .then(data=>{
        //console.log("search users",data.data.data)
        dispatch({type:actionType.SET_LOADING,payload:false})
        dispatch({type:actionType.ALL_USERS,payload:data.data.data})
     })
  }catch(error){
    dispatch({type:actionType.SET_LOADING,payload:false})
    console.log(error)
  }
}
export const getAllProjects=(sortBy)=>async(dispatch)=>{
  try{
    dispatch({type:actionType.SET_LOADING,payload:true})
     await temp.get(`/project${sortBy?`/?sort_by=${sortBy}`:''}`)
     .then(data=>{
        //console.log("all projects",data.data.data)
        dispatch({type:actionType.SET_LOADING,payload:false})
        dispatch({type:actionType.ALL_PROJECTS,payload:data.data.data})
     })
  }catch(error){
    dispatch({type:actionType.SET_LOADING,payload:false})
    console.log(error)
  }
}
export const getProjectUserList=(id)=>async(dispatch)=>{
  try{
     await temp.get(`/message_board/userlist/?project_id=${id}`)
     .then(data=>{
        console.log("all users",data)
        dispatch({type:actionType.ALL_USERS,payload:data.data})
     })
  }catch(error){
    console.log(error)
  }
}
export const assignPmAdmin=(projectId,form)=>async(dispatch)=>{
  try{
    temp.patch(`/project/assign_user/${projectId}/`,form)
    .then(data=>{
      console.log("assign",data)
      if(data.data.success)
       notify("success",data.data.message)
      else
      notify("error",data.data.message)
    })
  }catch(error){
    console.log(error)
  }
}
export const addUser=(form)=>async(dispatch)=>{
  try{
    temp.post(`/users/add/`,form)
    .then(data=>{
      console.log("add user",data)
      if(data.data.success)
       notify("success",data.data.message)
      else
        notify("error",data.data.Error)
    })
  }catch(error){
    console.log(error)
  }
}
export const blockUser=(form)=>async(dispatch)=>{
  try{
    temp.post(`/userproj/blockuser/`,form)
    .then(data=>{
      console.log("block user",data)
      if(data.data.success)
       notify("success",data.data.Projects)
      else
        notify("error",data.data.Error)
    })
  }catch(error){
    console.log(error)
  }
}
export const deleteUser=(id)=>async(dispatch)=>{
  try{
     temp.delete(`/users/delete/${id}/`)
     .then(data=>{
      console.log(data)
      if(data.data.success)
       {
        notify("success",data.data.message);
        dispatch({type:actionType.ALL_USERS,payload:data.data.data}) 
      }
      else
       notify("error","Please try again")
     })
  }catch(error){
    console.log(error)
  }
}
export const setPassword=(id,token,form,navigate)=>async(dispatch)=>{
  try{
    temp.post(`/users/password/${id}/${token}/`,form)
    .then(data=>{
      console.log(data.data)
      if(data.data.success)
      notify("success",data.data.message)
      else
      notify("error",data.data.Error)
      navigate("/login")
    })
  }catch(error)
  {
    console.log(error)
  }
}
export const updateAllocation=(id,form)=>async(dispatch)=>{
  try{
     temp.patch(`/project/edit/allocation/${id}/`,form)
     .then(data=>{
      console.log(data)
      if(data.data.success)
       {
        notify("success",data.data.message);
        dispatch({type:actionType.ALL_USERS,payload:data.data.data}) 
      }
      else
       notify("error","Please try again")
     })
  }catch(error){
    console.log(error)
  }
}
export const getMessages=(id)=>async(dispatch)=>{
  try{
    dispatch({type:actionType.SET_MESSAGES,payload:[]})
  temp.get(`/message_board/?project_id=${id}`)
  .then(data=>{
    console.log("messages",data)
    if(data.data.length)
    dispatch({type:actionType.SET_MESSAGES,payload:data.data})
  })
  }catch(error){
    console.log(error)
  }
}
export const getConversation=(date,category)=>async(dispatch)=>{
  try{
    dispatch({type:actionType.SET_CONVERSATION,payload:null})
   temp.get(`/message_board/catdate/?category_id=${category}&date=${date}`)
   .then(data=>{
    console.log("convo",data)
    dispatch({type:actionType.SET_CONVERSATION,payload:data.data.messages})
   })
  }catch(error){
    console.log(error)
  }
}
export const sendMessage=(projectID,date,category,form)=>async(dispatch)=>{
  console.log(form)
  try{
  temp.post(`/message_board/`,form)
  .then(data=>{
    console.log(data)
    notify("success","Message added")
    dispatch(getMessages(projectID))
    if(date && category)
       dispatch(getConversation(date,category))
  })
  }catch(error){
    console.log(error)
  }
}
export const sendReply=(message_ref,form)=>async(dispatch)=>{
  try{
    notify("info","Sending reply")
  temp.post(`/message_board/reply/`,form)
  .then(data=>{
    console.log(data)
    notify("success","Reply sent")
    dispatch({type:actionType.SET_REPLIES,payload:{"message_ref":message_ref,"replies":data.data.MessageReply}})
  })
  }catch(error){
    console.log(error)
  }
}
export const getReplies=(id)=>async(dispatch)=>{
  try{
    dispatch({type:actionType.SET_REPLIES,payload:{"message_ref":id,replies:[]}})
  temp.get(`/message_board/reply/?message_id=${id}`)
  .then(data=>{
    console.log("replies",data)
    dispatch({type:actionType.SET_REPLIES,payload:{"message_ref":id,"replies":data.data.MessageReplies}})
  })
  }catch(error){
    console.log(error)
  }
}
export const getMessageCategories=()=>async(dispatch)=>{
  try{
   temp.get("/message_board/categories/")
   .then(data=>{
    console.log("titles",data)
    dispatch({type:actionType.SET_MESSAGE_CATEGORIES,payload:data.data.data})
   })
  }catch(error){
    console.log(error)
  }
}
export const createMessageCategory=(form)=>async(dispatch)=>{
  try{
   temp.post(`/message_board/categories/`,form)
   .then(data=>{
    console.log(data)
    if(data.data.success)
      {
        dispatch({type:actionType.CREATE_MESSAGE_CATEGORY,payload:data.data.data})
        notify('success',"Category created")}
    else
      notify('error',"try again")
   })
  }catch(error){
    console.log(error)
  }
}
export const deleteMessageCategory=(id)=>async(dispatch)=>{
  try{
   temp.delete(`/message_board/categories/${id}`)
   .then(data=>{
    console.log(data)
    if(data.data.success)
      {notify('success',data.data.message)
       dispatch({type:actionType.DELETE_MESSAGE_CATEGORY,payload:id})  
    }
    else
      notify('error',"try again")
   })
  }catch(error){
    console.log(error)
  }
}
export const editMessageCategory=(id,form)=>async(dispatch)=>{
  try{
   temp.patch(`/message_board/categories/${id}`,form)
   .then(data=>{
    console.log(data.data)
    if(data.data.success)
      {
        dispatch({type:actionType.EDIT_MESSAGE_CATEGORY,payload:data.data.data}) 
        notify('success',data.data.message) 
    }
    else
      notify('error',"try again")
   })
  }catch(error){
    console.log(error)
  }
}
export const getInvitePeopleList=(id)=>async(dispatch)=>{
  try{
    temp.get(`/project/listusers?project_id=${id}`)
    .then(data=>{
      console.log("invite list",data)
      dispatch({type:actionType.ALL_USERS,payload:data.data})
    })
  }catch(error){
    console.log(error)
  }
}
export const invitePeople=(form)=>async(dispatch)=>{
  try{
   temp.post(`/project/addpeople/`,form)
   .then(data=>{
    console.log(data)
    dispatch({type:actionType.SET_INVITATIONS,payload:data.data.invited_peoples})
    notify("success","Invitation sent")
   })
  }catch(error){
    console.log(error)
  }
}
export const getToDoPeople=(id)=>async(dispatch)=>{
  try{
    temp.get(`/project/listprojusers?project_id=${id}`)
    .then(data=>{
      console.log("todo people",data)
      dispatch({type:actionType.ALL_USERS,payload:data.data})
    })
  }catch(error){
      console.log(error)
  }
}
export const createToDo=(form)=>async(dispatch)=>{
  try{
    temp.post(`/project/create/`,form)
    .then(data=>{
      console.log("add task",data)
      if(data.data.message==='success')
         {notify("success","Task added")
         dispatch({type:actionType.ADD_TODO,payload:data.data.data})
    }
    })
  }catch(error)
  {
    console.log(error)
  }
  
}
export const getToDo=(id,date,sortBy)=>async(dispatch)=>{
  try{
    temp.get(`/project/create/?project_id=${id}&date=${date}${sortBy?`&sort_by=${sortBy}`:''}`)
    .then(data=>{
      console.log("task",data)
      dispatch({type:actionType.SET_TODO,payload:data.data})
    })
  }catch(error)
  {
    console.log(error)
  }
}
export const getToDoDates=(id)=>async(dispatch)=>{
  try{
   // notify("info","Fetching tasks")
    dispatch({type:actionType.TODO_DATES,payload:[]})
    temp.get(`/project/listdates?project_id=${id}`)
    .then(data=>{
      console.log("todo dates",data)
      dispatch({type:actionType.TODO_DATES,payload:data.data})
    })
  }catch(error){
      console.log(error)
  }
}
export const markTaskDone=(id,form,isSearch)=>async(dispatch)=>{
  try{
    //console.log(form)
    temp.put(`/project/create/?id=${id}`,form)
    .then(data=>{
      console.log("mark done",data)
      if(data.status==200)
       { dispatch({type:actionType.UPDATE_TODO,payload:{"id":id,"is_done":form.is_done}})
       if(isSearch)
       {
        dispatch({type:actionType.UPDATE_SEARCH_RESULT,payload:{"id":id,"is_done":form.is_done}})
       }
       notify("success","Task updated")
        }
    })
  }catch(error){
    console.log(error)
  }
}
export const addReviewFiles=(form)=>async(dispatch)=>{
  try{
   temp.post(`/reviewfile/addimage/`,form)
   .then(data=>{
    console.log(data)
    dispatch({type:actionType.SET_REVIEW_FILES,payload:data.data.data})
    notify("success",data.data.message)
   })
  }catch(error)
  {
    console.log(error)
  }
}
export const deleteReviewImage=(id)=>async(dispatch)=>{
  try{
   temp.delete(`/reviewfile/addimage/?image_id=${id}`)
   .then(data=>{
    console.log(data)
    dispatch({type:actionType.DELETE_REVIEW_FILES,payload:id})
    notify("success",data.data.message)
   })
  }catch(error)
  {
    console.log(error)
  }
}
export const getReviewVersions=(id,setShowUploadModal)=>async(dispatch)=>{
  try{
    dispatch({type:actionType.SET_REVIEW_FILES,payload:[]})
  temp.get(`/reviewfile/listimage/?project_id=${id}`)
  .then(data=>{
    console.log("versions",data.data)
    if(data?.data?.length==0)
       setShowUploadModal("add")
    dispatch({type:actionType.SET_REVIEW_FILES,payload:data.data.filter(e=>(e?.wip?.length !== 0))})
    if(data.data[0]?.wip[data.data[0]?.wip?.length-1]?.comments)
    dispatch({type:actionType.SET_MARKERS,payload:data.data[0]?.wip[data.data[0]?.wip?.length-1]?.comments})
    else
    dispatch({type:actionType.SET_MARKERS,payload:[]})
  })
  }catch(error){
    console.log(error)
  }
}
export const getCompletedReviews=(id)=>async(dispatch)=>{
  try{
    dispatch({type:actionType.SET_REVIEW_FILES,payload:[]})
    dispatch({type:actionType.SET_LOADING,payload:true})
  temp.get(`/reviewfile/sendcompletedimage/?project_id=${id}`)
  .then(data=>{
    console.log("completed",data)
    dispatch({type:actionType.SET_COMPLETED_FILES,payload:data.data.data})
    if(data.data.data[0]?.comments)
    dispatch({type:actionType.SET_MARKERS,payload:data.data.data[0]?.comments})
    else
    dispatch({type:actionType.SET_MARKERS,payload:[]})
    dispatch({type:actionType.SET_LOADING,payload:false})
  })
  }catch(error){
    console.log(error)
    dispatch({type:actionType.SET_LOADING,payload:false})
  }
}
export const addReviewVersion=(form)=>async(dispatch)=>{
  try{
    temp.post(`/reviewfile/addimage/`,form)
    .then(data=>{
      console.log(data)
      notify("success",data.data.message)
      dispatch({type:actionType.UPDATE_REVIEW_FILES,payload:data.data.data})
    })
  }catch(error){
    console.log(error)
  }
}
export const getEditImage=(id)=>async(dispatch)=>{
  try{
    temp.get(`/reviewfile/sendimage/?image_id=${id}`)
    .then(data=>{
      console.log(data)
      dispatch({type:actionType.SET_EDIT_IMAGE,payload:data.data.data})
    })
  }catch(error){
    console.log(error)
  }
}

export const saveEditImage=(id,form)=>async(dispatch)=>{
  try{
    temp.patch(`/reviewfile/approveimage/?image_id=${id}`,form)
    .then(data=>{
      console.log("save",data)
      dispatch({type:actionType.SAVE_EDIT_IMAGE,payload:data.data.data})
      notify("suceess",data.data.message)
    })
  }catch(error)
  {
    console.log(error)
  }
}
export const approveImage=(id)=>async(dispatch)=>{
  try{
    temp.put(`/reviewfile/approveimage/?image_id=${id}`)
    .then(data=>{
      console.log(data)
      if(data.status==200)
       notify("success","Image approved")
    })
  }catch(error){
    console.log(error)
  }
}
export const sendForReviewImage=(id)=>async(dispatch)=>{
  try{
    temp.put(`/reviewfile/sendreview/?image_id=${id}`)
    .then(data=>{
      console.log(data)
      if(data.status==200)
       notify("success","Image send for review")
    })
  }catch(error){
    console.log(error)
  }
}
export const deleteProject=(id)=>async(dispatch)=>{
  try{
     temp.delete(`/project/ppl/${id}/`)
     .then(data=>{
      console.log(data)
      console.log(data.status === 200)
      console.log(data.data.Projects)
      if(data.status === 200)
       {
        notify("success","deleted successfully");
        // dispatch({type:actionType.ALL_PROJECTS,payload:data.data})
        dispatch({type:actionType.GET_PROJECTS,payload:data.data.Projects})
      }
      else
       notify("error","Please try again")
     })
  }catch(error){
    console.log(error)
  }
}
export const getNotes=(id)=>async(dispatch)=>{
  try{
    temp.get(`/project/notes/?project_id=${id}`)
    .then(data=>{
      console.log("notes",data)
      dispatch({type:actionType.SET_NOTES,payload:data.data})
    })
  }catch(error){
      console.log(error)
  }
}
export const addNote=(note)=>async(dispatch)=>{
  console.log(note)
  try{
    temp.post(`/project/notes/`,note)
    .then(data=>{
      console.log("add notes",data)
      if(data.data.success)
         {notify("success",data.data.message)
         dispatch({type:actionType.SET_NOTES,payload:data.data.notes})
    }
    })
  }
  catch(error){
    console.log(error)
  }
}
export const getProjectActivity=(id)=>async(dispatch)=>{
  temp.get(`project/activities/?project_id=${id}`)
  .then(data=>{
    //console.log("activity",data)
    if(data.data.success)
    {
      dispatch({type:actionType.SET_ACTIVITY,payload:data.data.data})
    }else{
      dispatch({type:actionType.SET_ACTIVITY,payload:[]})
    }
  })
}
export const getUserActivity=()=>async(dispatch)=>{
  dispatch({type:actionType.SET_LOADING,payload:true})
  temp.get(`/project/activities_list/`)
  .then(data=>{
    console.log("activity",data)
    if(data.data.success)
    {
      dispatch({type:actionType.SET_ACTIVITY,payload:data.data.data})
    }else{
      dispatch({type:actionType.SET_ACTIVITY,payload:[]})
    }
    dispatch({type:actionType.SET_LOADING,payload:false})
  })
}
export const getSearchList=()=>async(dispatch)=>{
  temp.get(`/project/searchlistuser/`)
  .then(data=>{
    console.log("get search",data)
    dispatch({type:actionType.SET_SEARCH_LIST,payload:data.data})
  })
}
export const showReviewTutorialFunc=()=>async(dispatch)=>{
  temp.get(`/users/showreviewfiletutorial/`)
  .then(data=>{
    console.log("review tut",data)
    dispatch({type:actionType.SHOW_REVIEW_FILE_TUTORIAL,payload:data.data.tutorial})
  })
}
export const putReviewTutorialFunc=()=>async(dispatch)=>{
  temp.put(`/users/showreviewfiletutorial/`)
  .then(data=>{
    console.log("review tut put",data)
  })
}
export const getSearchResult=(query,form)=>async(dispatch)=>{
  console.log(form)
  temp.get(`/project/overall/?search=${query}&name=${form.name}&user_id=${form.user_id}&project_id=${form?.project_id}`)
  .then(data=>{
   // console.log("search result",data)
    dispatch({type:actionType.SET_SEARCH_RESULT,payload:{"component":form.name,"data":data.data.data}})
  })
}
export const addReviewMessage=(userID,imageID,form)=>async(dispatch)=>{
  temp.post(`/reviewfile/createcor/?image_id=${imageID}`,{"left":form.left,"top":form.top})
  .then(data=>{
    console.log("coord",data)
    if(data.status==200)
    {
      temp.post(`/reviewfile/addcomment/?image_id=${imageID}&user_id=${userID}`,{"text":form.text,"cor_id":data.data.data.id})
      .then(data=>{
        console.log("comment",data)
        dispatch({type:actionType.ADD_MARKER,payload:{"imageID":imageID,"comment":data.data.data}})
        if(data.status==200)
         notify("success","Message sent")
      })
    }
  })
}
export const editReviewMessage=(userID,imageID,messageID,form)=>async(dispatch)=>{
  temp.put(`/reviewfile/editmessage/?user_id=${userID}&message_id=${messageID}`,form)
  .then(data=>{
    console.log("edit message",data)
    if(data.status==200)
    {
      dispatch({type:actionType.UPDATE_MARKER,payload:{"imageID":imageID,"comment":data.data.data}})
      notify("success","message edited")
    }
  })
}
export const deleteReviewMessage=(userID,messageID,imageID)=>async(dispatch)=>{
  temp.delete(`/reviewfile/editmessage/?user_id=${userID}&message_id=${messageID}`)
  .then(data=>{
    console.log("delete comment",data)
    dispatch({type:actionType.DELETE_MARKER,payload:{"id":messageID,"imageID":imageID}})
    notify("sucess","Comment deleted")
  })
}
export const addReviewReply=(form)=>async(dispatch)=>{
  temp.post(`/reviewfile/addreplies/?image_id=${form.imageID}&user_id=${form.userID}&message_id=${form.messageID}`,{"text":form.text})
  .then(data=>{
    console.log(data)
    dispatch({type:actionType.UPDATE_MARKER,payload:{"imageID":form.imageID,"comment":data.data.data}})
    if(data.status==200)
         notify("success","Message sent")
  })
}
export const deleteReviewReply=(userID,replyID,imageID)=>async(dispatch)=>{
  temp.delete(`/reviewfile/addreplies/?user_id=${userID}&reply_id=${replyID}`)
  .then(data=>{
    console.log(data)
    dispatch({type:actionType.UPDATE_MARKER,payload:{"imageID":imageID,"comment":data.data.data}})
    if(data.status==200)
         notify("success","Message deleted")
  })
}
export const updateDocs=(id,form)=>async(dispatch)=>{
  try{
    temp.put(`/project/updatedocs/?project_id=${id}`,form)
    .then(data=>{
      console.log(data)
      if(data.status==200)
        {notify("success","Documents added")
         dispatch({type:actionType.SET_CURRENT_PROJECT,payload:data.data.data})
      }
    })
  }catch(err)
  {
    console.log(err)
  }
}
export const pinProject=(form)=>async(dispatch)=>{
  try{
    temp.post(`/userproj/pinorunpinproj/`,form)
    .then(data=>{
      console.log(data)
      notify("info",data.data.message)
      dispatch({type:actionType.UPDATE_PROJECTS,payload:data.data.data})
    })
  }catch(err){
    console.log(err)
  }
}
export const getTimezones=()=>async(dispatch)=>{
  temp.get(`/message_board/timezones/`)
  .then(data=>{
    console.log("get timezones",data)
    dispatch({type:actionType.SET_TIMEZONES,payload:data.data.timezones})
  })
}
export const getVisitStatus=(id)=>async(dispatch)=>{
  temp.get(`/project/seevisit/?user_id=${id}`)
  .then(data=>{
    console.log("visit status",data)
    dispatch({type:actionType.SET_PROJECT_TUTORIAL,payload:data.data.first_visit})
    dispatch({type:actionType.SET_FIRST_PROJECT_VISIT,payload:data.data.first_visit})
  })
}
export const setVisitStatus=(id)=>async(dispatch)=>{
  temp.post(`/project/updatevisit/?user_id=${id}`)
  .then(data=>{
    console.log("updated visit status",data)
    dispatch({type:actionType.SET_PROJECT_TUTORIAL,payload:data.data.first_project_visit})
  })
}