import React,{useState,useEffect} from "react"
import { useParams,useNavigate, useSearchParams } from "react-router-dom";
import { useSelector,useDispatch } from "react-redux";
import ScrollContainer from "react-indiana-drag-scroll";
import arrowLeft from "../../../assets/icons/arrow-left.svg";
import plus from "../../../assets/icons/plus.svg";
import fullscreenIcon from "../../../assets/icons/fullscreen.svg";
import menuGridIcon from "../../../assets/icons/menu-grid.svg"
import commentIcon from "../../../assets/icons/comment.svg"
import editImageIcon from "../../../assets/icons/edit-image.svg"
import DownloadIcon from "../../../assets/icons/download-icon";
import NextIcon from "../../../assets/icons/next-icon"
import PrevIcon from "../../../assets/icons/prev-icon"
import ImageMarker, { Marker } from 'react-image-marker';
import { getUserProject,getAdminProject,getCompletedReviews,sendForReviewImage } from '../../../actions';
import Comment from "./Comment";
import * as actionType from "../../../helpers/constants"
import CommentsBar from "./CommentBar";
const CompletedBoard=()=>{
    const navigate=useNavigate()
    const dispatch=useDispatch();
    const { id } = useParams();
    const [reviewItemIndex,setReviewItemIndex]=useState(0)
    const {project,profile,isLoading,completed_files}=useSelector(state=>state)
    
    const prodTeam=sessionStorage.getItem("prodTeam")
    
    
    const [zoom,setZoom]=useState(100);
    const markers=useSelector(state=>state.markers)
    const [viewFull,setViewFull]=useState("full")
    const handleSetReviewItemMarkers=(review_item=reviewItemIndex)=>{
        console.log("setting marker",review_item)
        if(completed_files[review_item]?.comments)
        dispatch({type:actionType.SET_MARKERS,payload:completed_files[review_item]?.comments})
        else
        dispatch({type:actionType.SET_MARKERS,payload:[]})
    }
    const zoomfn=()=>{
        let id='review-wrapper';
        let value=+zoom
        value=Number(value)/100
        //console.log(id,value)
        var outerDiv = document.getElementById('img-wrapper');
        var imgEle = document.getElementById(id);
        if(outerDiv && imgEle)
        {
            var scale = "scale(" + value + ");"
            var origin = "center";
            var translateX = '';
            //console.log("in",outerDiv.clientWidth,outerDiv.scrollWidth)
            if (outerDiv.clientWidth !== outerDiv.scrollWidth) {
                console.log("in")
                origin = "top left";
                translateX = ' translateX(' + (-imgEle.offsetLeft) + 'px) ';
            }
    
            var style = "-ms-transform:" + translateX + scale + "-webkit-transform:" + translateX + scale + "transform:" + translateX + scale + "transform-origin:" + origin + ";";
            document.getElementById(id).setAttribute("style", style);
    
            outerDiv.scrollTop = outerDiv.scrollHeight / 2 - outerDiv.clientHeight / 2;
            outerDiv.scrollLeft = outerDiv.scrollWidth / 2 - outerDiv.clientWidth / 2;
        }
    }
    useEffect(()=>{
       if(id)
       {
        if(profile.user_type==='superadmin')
         dispatch(getAdminProject(id))
         else
        dispatch(getUserProject(id))
        dispatch(getCompletedReviews(id))
       }
        
    },[dispatch,id])
    zoomfn()
    console.log(markers)
    if(!isLoading && project)
    return (
        <div className={`${profile?.user_type==='superadmin'||profile?.user_type==='pm'||profile?.user_type==='accountant'&&!prodTeam?'admin-project-dashboard':''}`}>
          
        <div className="board-header">
           <button style={{justifySelf:'flex-start'}} onClick={()=>navigate(-1)} className='btn btn-green-outline' >
                <img src={arrowLeft} alt="back"/>
                <span>Back</span>
            </button>
            <div className="board-title completed_title">
            <div className="board-title-main">{project.project_name}</div>
            </div>
            <div className="board-header-btn-block">
            <button className={`btn btn-green-fill ${prodTeam && 'btn-hide'}`}>Add images <img src={plus} alt="Add images" /></button>
            <button className='btn btn-green-fill'>Share</button>
            </div>
        </div>
        <div className="review-board">
            <div className="review-board-header">
                <div className="review-header-actions">
                    <img onClick={()=>setViewFull(p=>
                        {
                            if(p=='menu')
                              return 'full';
                            else
                              return "menu"
                        })} src={menuGridIcon} alt="menu"/>
                    <div className="review-comment-icon">
                        <img onClick={()=>setViewFull(p=>
                        {
                            if(p=='comments')
                              return 'full';
                            else
                              return "comments"
                        })} src={commentIcon} alt="review-comment" />
                        <div className="review-comment-new"></div>
                    </div>
                    <div className="review-header-filename"><span style={{color:'white'}}>{completed_files[reviewItemIndex]?.title}</span> | <span style={{color:'#D6D6D6'}}>Version {completed_files[reviewItemIndex]?.versionnum}</span></div>
                </div>
                <div className="review-actions">
                    <div className="review-action">
                    <div className="review-action-pagecount">
                        <div className="review-action-pagecount-input"><input value={completed_files.length>0? reviewItemIndex+1:'0'}/></div><span> / {completed_files.length}</span>
                    </div>
                    </div>
                    <div className="review-action-border"></div>
                    <div className="review-action">
                        <button className="review-action-btn" onClick={()=>setZoom(p=>p-1)}>-</button><input  className="review-action-zoom-input" value={zoom} onChange={(e)=>{setZoom(Number(e.target.value));}}/><button className="review-action-btn" onClick={()=>setZoom(p=>p+1)}>+</button>
                    </div>
                    <div className="review-action-border"></div>
                    <div className="review-action">
                        <img className="review-action-btn"  onClick={()=>setViewFull(p=>
                        {
                            if(p=='full')
                              return 'menu';
                            else
                              return "full"
                        })} src={fullscreenIcon} alt="fullscreen"/>
                    </div>
                    
                </div>
                <div className="review-header-actions">
                    
                    <button className={`btn btn-green-outline ${prodTeam && 'btn-hide'}`} onClick={()=>{dispatch(sendForReviewImage(completed_files[reviewItemIndex]?.id))}}>Send for Review</button>
                    <DownloadIcon fill="white"/>
                </div>
            </div>
            <div className="review-playground">
            {viewFull=='comments' && <CommentsBar markers={markers}/>}
            { viewFull=='menu' && 
            <div className="version-list">
            {completed_files.map((item,index)=>
                <div className={ `review-sidebar-box`}
                   onClick={()=>{setReviewItemIndex(index); handleSetReviewItemMarkers(index)  }}
                   // handleSetReviewItemMarkers()
                >
                    <img className={`review-sidebar-item ${index==reviewItemIndex?'review-sidebar-item-active':''}`} src={item.file} alt="file"/>
                    <div className="review-sidebar-version">Version {item.versionnum}</div>
                    </div>
           )}
            </div>}
            <div className="review">
            <div class="img-wrapper" id="img-wrapper">
                <div id="review-wrapper" class="my-image" >
                
               
             {completed_files[reviewItemIndex] &&<ImageMarker 
                    src={completed_files[reviewItemIndex]?.file}
                    markers={markers.map((mp,index)=>{return{...mp,"pinNumber":index+1,"imageId":completed_files[reviewItemIndex].id}})}
                    markerComponent={Comment}
                />
             }
             
                </div>
                </div>
                {completed_files.length>0 && 
                <div className="review-image-scroll">
                <button onClick={()=>{
                    setReviewItemIndex(p=>{
                    if(p>1){ 
                        handleSetReviewItemMarkers(p-1)  
                        return p-1}
                   else {
                    handleSetReviewItemMarkers(p-1)  
                    return p}});
                  
                }
                    }>
                        <PrevIcon height="20px" width="20px" fill={reviewItemIndex==0?'grey':'white'}/>
                    </button> <div>|</div> 
                    <button onClick={()=>
                    {setReviewItemIndex(p=>{
                    if(p<completed_files.length-1)
                    { handleSetReviewItemMarkers(p+1)  ; return p+1}
                    else
                     {
                        handleSetReviewItemMarkers(p)  
                        return p}})
                     
                    }
                     }>
                        <NextIcon height="20px" width="20px" fill={reviewItemIndex==(completed_files.length-1)?'grey':'white'}/>
                     </button>
                </div>
                }   
                <button className="btn btn-green-fill btn-review-image-edit" onClick={()=>navigate(`/review-file-edit/${id}`)}>
                    <img src={editImageIcon} alt="edit-img"/>
                </button>
            </div>
            </div>
            
        </div>
    </div>
    )
}
export default CompletedBoard