import React,{useState,useEffect, useRef} from "react"
import { useDispatch,useSelector } from "react-redux";
import uploadIcon from "../../../assets/icons/upload-icon.svg";
import WebViewer from '@pdftron/webviewer';
const ProposalTab=({project,handleUpload,filePicker,getAdminProject,projectId})=>{
    const proposalInput=useRef()
    const dispatch=useDispatch();
    const isAuth=useSelector(state=>state.isAuth);
    console.log(project.proposal_doc)
    const [hasFile,setHasFile]=useState(project.proposal_doc?true:false)
    const [instance, setInstance] = useState(null);
    const [upload,setUpload] = useState(false)
    const [dropPoint, setDropPoint] = useState(null);
    useEffect(()=>{
      getAdminProject(projectId)
    },[upload])
    const viewer = useRef(null);
    const dragOver = e => {
        e.preventDefault();
        console.log("drag over")
        return false;
      };
      const applyFields = async () => {
        const { Annotations, documentViewer } = instance.Core;
        const annotationManager = documentViewer.getAnnotationManager();
        const fieldManager = annotationManager.getFieldManager();
        const annotationsList = annotationManager.getAnnotationsList();
        
        const annotsToDelete = [];
        const annotsToDraw = [];
    
        await Promise.all(
          annotationsList.map(async (annot, index) => {
            let inputAnnot;
            let field;
            
            if (typeof annot.custom !== 'undefined') {
              // create a form field based on the type of annotation
              if (annot.custom.type === 'TEXT') {
                field = new Annotations.Forms.Field(
                  annot.getContents() + Date.now() + index,
                  {
                    type: 'Tx',
                    value: 'Name',
                  },
                );
                inputAnnot = new Annotations.TextWidgetAnnotation(field);
              } else if (annot.custom.type === 'SIGNATURE') {
                console.log("signature")
                field = new Annotations.Forms.Field(
                  annot.getContents() + Date.now() + index,
                  {
                    type: 'Sig',
                  },
                );
                inputAnnot = new Annotations.SignatureWidgetAnnotation(field, {
                  appearance: '_DEFAULT',
                  appearances: {
                    _DEFAULT: {
                      Normal: {
                        data:
                          'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAYdEVYdFNvZnR3YXJlAHBhaW50Lm5ldCA0LjEuMWMqnEsAAAANSURBVBhXY/j//z8DAAj8Av6IXwbgAAAAAElFTkSuQmCC',
                        offset: {
                          x: 100,
                          y: 100,
                        },
                      },
                    },
                  },
                });
              } else if (annot.custom.type === 'DATE') {
                console.log("date")
                field = new Annotations.Forms.Field(
                  annot.getContents() + Date.now() + index,
                  {
                    type: 'Tx',
                    value: 'MM/DD/YY',
                    // Actions need to be added for DatePickerWidgetAnnotation to recognize this field.
                    actions: {
                      F: [
                        {
                          name: 'JavaScript',
                          // You can customize the date format here between the two double-quotation marks
                          // or leave this blank to use the default format
                          javascript: 'AFDate_FormatEx("MM/DD/YY");',
                        },
                      ],
                      K: [
                        {
                          name: 'JavaScript',
                          // You can customize the date format here between the two double-quotation marks
                          // or leave this blank to use the default format
                          javascript: 'AFDate_FormatEx("MM/DD/YY");',
                        },
                      ],
                    },
                  },
                );
      
                inputAnnot = new Annotations.DatePickerWidgetAnnotation(field);
                
              } else {
                // exit early for other annotations
                annotationManager.deleteAnnotation(annot, false, true); // prevent duplicates when importing xfdf
                return;
              }
            } else {
              console.log("exit")
              // exit early for other annotations
              return;
            }
            console.log(inputAnnot)
            // set position
            inputAnnot.PageNumber = annot.getPageNumber();
            inputAnnot.X = annot.getX();
            inputAnnot.Y = annot.getY();
            inputAnnot.rotation = annot.Rotation;
            if (annot.Rotation === 0 || annot.Rotation === 180) {
              inputAnnot.Width = annot.getWidth();
              inputAnnot.Height = annot.getHeight();
            } else {
              inputAnnot.Width = annot.getHeight();
              inputAnnot.Height = annot.getWidth();
            }
            
            // delete original annotation
            annotsToDelete.push(annot);
    
            // customize styles of the form field
            Annotations.WidgetAnnotation.getCustomStyles = function (widget) {
              if (widget instanceof Annotations.SignatureWidgetAnnotation) {
                return {
                  border: '1px solid #a5c7ff',
                };
              }else{
                return{
                  'font-size':'22px',
                  'background-color':'#d2d2d2',
                  'padding':'5px',
                  'text-align':'center'
                }
              }
            };
            Annotations.WidgetAnnotation.getCustomStyles(inputAnnot);
    
            // draw the annotation the viewer
            annotationManager.addAnnotation(inputAnnot);
            fieldManager.addField(field);
            annotsToDraw.push(inputAnnot);
          }),
        );
    
        // delete old annotations
        annotationManager.deleteAnnotations(annotsToDelete, null, true);
    
        // refresh viewer
        await annotationManager.drawAnnotationsFromList(annotsToDraw);
        await uploadForSigning();
      };
    
      const addField = (type, point = {}, name = '', value = '', flag = {}) => {
        const { documentViewer, Annotations } = instance.Core;
        console.log(dropPoint,documentViewer,point)
        const annotationManager = documentViewer.getAnnotationManager();
        const doc = documentViewer.getDocument();
        const displayMode = documentViewer.getDisplayModeManager().getDisplayMode();
        const page = displayMode.getSelectedPages(point, point);
        if (!!point.x && page.first == null) {
          return; 
        }
        const page_idx =
          page.first !== null ? page.first : documentViewer.getCurrentPage();
        const page_info = doc.getPageInfo(page_idx);
        const page_point = displayMode.windowToPage(point, page_idx);
        //const zoom = documentViewer.getZoom();
    
        var textAnnot = new Annotations.FreeTextAnnotation();
        textAnnot.PageNumber = page_idx;
        const rotation = documentViewer.getCompleteRotation(page_idx) * 90;
        textAnnot.Rotation = rotation;
        if (rotation === 270 || rotation === 90) {
          textAnnot.Width = 50.0;
          textAnnot.Height = 250.0;
        } else {
          textAnnot.Width = 250.0;
          textAnnot.Height = 50.0;
        }
        // textAnnot.X = (page_point.x || page_info.width / 2) - textAnnot.Width / 2;
        // textAnnot.Y = (page_point.y || page_info.height / 2) - textAnnot.Height / 2;
        textAnnot.X = (page_info.width / 2) - textAnnot.Width / 2;
        textAnnot.Y = ( page_info.height / 2) - textAnnot.Height / 2;
        textAnnot.setPadding(new Annotations.Rect(0, 0, 0, 0));
        textAnnot.custom = {
          type,
          value,
          flag,
          name: `${type==='TEXT'?'Name':type}`,
        };
        textAnnot.setContents(textAnnot.custom.name);
        textAnnot.FontSize = '' + 10.0+ 'px';
        textAnnot.FillColor = new Annotations.Color(211, 211, 211, 0.5);
        textAnnot.TextColor = new Annotations.Color(0, 165, 228);
        textAnnot.StrokeThickness = 1;
        textAnnot.StrokeColor = new Annotations.Color(0, 165, 228);
        textAnnot.TextAlign = 'center';
    
        textAnnot.Author = annotationManager.getCurrentUser();
    
        annotationManager.deselectAllAnnotations();
        annotationManager.addAnnotation(textAnnot, true);
        annotationManager.redrawAnnotation(textAnnot);
        annotationManager.selectAnnotation(textAnnot);
      };
    const drop = (e, instance) => {
        console.log("drop",instance)
        const { documentViewer } = instance.Core;
        const scrollElement = documentViewer.getScrollViewElement();
        let scrollLeft = scrollElement.scrollLeft || 0;
        let scrollTop = scrollElement.scrollTop || 0;
        console.log({ x: e.pageX + scrollLeft, y: e.pageY + scrollTop })
        setDropPoint({ x: e.pageX + scrollLeft, y: e.pageY + scrollTop });
        e.preventDefault();
        return false;
      };
      const uploadForSigning = async () => {
        const referenceString = `docToSign/${Date.now()}.pdf`;
        //const docRef = storageRef.child(referenceString);
        const { documentViewer, annotationManager } = instance.Core;
        const doc = documentViewer.getDocument();
        const xfdfString = await annotationManager.exportAnnotations({ widgets: true, fields: true });
        const data = await doc.getFileData({ xfdfString });
        const arr = new Uint8Array(data);
        const blob = new Blob([arr], { type: 'application/pdf' });
        
//         console.log(blob)
        var reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
    var base64String = reader.result;
    //console.log('Base64 String - ', base64String);
    handleUpload({"project_id":project.id,"pdf_blob":base64String?.split(",")[1]})
    
    }
       
      }
    useEffect(() => {
        WebViewer(
          {
            path: '../../lib',
            disabledElements: [
              'ribbons',
              'toggleNotesbutton',
              'searchbutton',
              'menubutton',
            ],
          },
          viewer.current,
        ).then(instance => {
            const { documentViewer, annotationManager, Annotations } = instance.Core;
            const iframeWindow = instance.UI.iframeWindow;
        //   const { iframeWindow } = instance;
    
          
           instance.UI.setToolbarGroup('toolbarGroup-View');
    
           setInstance(instance);
    
           const iframeDoc = iframeWindow.document.body;
          
         iframeDoc.addEventListener('dragover', dragOver);
         iframeDoc.addEventListener('drop', e => {
            drop(e, instance);
          });
          if(hasFile)
          {
            instance.UI.loadDocument(project.proposal_doc)
          }
          filePicker.current.onchange = e => {
            const file = e.target.files[0];
            console.log(file)
            setHasFile(true)
            if (file) {
              instance.UI.loadDocument(file);
            }
          };
          Annotations.WidgetAnnotation.getCustomStyles = function (widget) {
            if (widget instanceof Annotations.SignatureWidgetAnnotation) {
              return {
                border: '1px solid #a5c7ff',
              };
            }else{
              return{
                'font-size':'22px',
                'background-color':'#d2d2d2',
                'padding':'5px',
                'text-align':'center'
              }
            }
          };
        });
      }, []);
return(
  <div>

<div className="ppl-proposal ">
{!hasFile && <div className="ppl-proposal-upload-bg">
  <div className="ppl-proposal-upload">
            <div className='modal-popup-content-title'>Upload Proposal</div>
            <div className='modal-popup-content-body'>Upload proposal file to the system</div>
            <button style={{marginTop:'20px'}} className='btn btn-green-fill' onClick={()=>filePicker.current.click()}>Upload</button>
    </div></div>}
    <div style={{display:'grid',gridTemplateColumns:'1fr 1fr 1fr'}}>
      <div></div>
      <div style={{display:'flex',gap:'15px',justifyContent:'center',margin:'10px 0'}}>
      <button className="btn btn-green-fill" onClick={(e)=>addField('SIGNATURE')}>Add Signature</button>
      <button className="btn btn-green-fill" onClick={(e)=>addField('TEXT')}>Print Name</button>
      <button className="btn btn-green-fill" onClick={(e)=>addField('DATE')}>Add Date</button>
      </div>
      <div style={{display:'flex',gap:'15px',justifyContent:'center',margin:'10px 0'}}>
      <button className='btn btn-green-fill' onClick={()=>filePicker.current.click()}>Re-upload <img src={uploadIcon} alt="add"/></button>
      <button className="btn btn-green-fill" onClick={()=>{applyFields()
      setUpload(true)}}>save</button>
      </div>
      
     
    </div>
<div className="webviewer" ref={viewer} style={{height:'100vh'}}></div>   
    </div>
    </div>)}
export default ProposalTab;