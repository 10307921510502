import React,{useState} from "react";
import SignUpBase from "./signupBase";
import SignUpOtp from "./signupOtp";
import SignUpPhone from "./signupPhone";
import SignUpEmail from "./signupEmail";
import { useNavigate } from "react-router-dom";
import "../../../assets/css/auth.css";
const initialForm={
    verification_type:"",
    first_name:"",
    last_name:"",
    company_name:"",
    email:"",
    password:"",
    country_code:"",
    mobile_number:"",
    heard_from:""
}
const SignUp=()=>{
    const navigate=useNavigate();
    const [step,setStep]=useState(1);
    const [form,setForm]=useState(initialForm);
    const next=(val)=>{
        setStep(val);
    }
    const back=(val)=>{
        setStep(val);
    }
    const handleInputData =(input,value)=> {
        setForm(prevState => ({
          ...prevState,
          [input]: value
      }));
      }
    switch(step)
    {
        case 1:
            // return <><Header/><SignUpBase next={next} back={back} handleFormData={handleInputData} form={form}/></>;
            return <><SignUpBase next={next} back={back} handleFormData={handleInputData} form={form}/></>;
        case 2:
            // return <><Header/><SignUpPhone next={next} back={back} handleFormData={handleInputData} form={form}/></>;
            return <><SignUpPhone next={next} back={back} handleFormData={handleInputData} form={form}/></>;
        case 3:
            // return <><Header/><SignUpOtp next={next} back={back} handleFormData={handleInputData} form={form}/></>;
            return <><SignUpOtp next={next} back={back} handleFormData={handleInputData} form={form}/></>;
        case 4:
            // return <><Header/><SignUpEmail next={next} back={back} handleFormData={handleInputData} form={form}/></>;
            return <><SignUpEmail next={next} back={back} handleFormData={handleInputData} form={form}/></>;
        
        default:
                    navigate("/login")
    }
}
export default SignUp;