import React,{useEffect,useState,useRef} from "react"
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import crossClose from "../../../assets/icons/cross-close.svg"
import ArrowDownIcon from "../../../assets/icons/arrow-down-icon";
import dropboxIcon from "../../../assets/icons/dropbox.svg";
import plus from '../../../assets/icons/plus.svg';
import RemoveIcon from '../../../assets/icons/remove-icon';
import laptopIcon from "../../../assets/icons/laptop-icon.svg";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import DropboxChooser from "react-dropbox-chooser";
import UserList from "./userList";
import CategoryList from "./categoryList";

import { sendMessage,getMessageCategories } from "../../../actions";
const WriteMessage=({setWriteShowModal,projectID})=>{
    const {date,category}=useParams()
    const dispatch=useDispatch()
    const message_categories=useSelector(state=>state.message_categories)
    const all_users=useSelector(state=>state.all_users)
    const inputFromComputer=useRef()
    const [form,setForm]=useState({
        "title":"",
        "message_category":null,
        "message":"",
        "project":projectID,
        "attachment":[],
        "notify":"all",
        "user_ids":"",
        "tagged_users":[]
    })
    
    const [showTitle,setShowTitle]=useState(false);
    const [showCategoryList,setShowCategoryList]=useState(false);
    const [selectedTitle,setSelectedTitle]=useState(null)
    const [showAddFile,setShowAddFile]=useState(false)
    const [showUserList,setShowUserList]=useState(false)
    const [hoverRemove,setHoverRemove]=useState('');
    let editorState = EditorState.createEmpty();
    const [description, setDescription] = useState(editorState);
   
    const onEditorStateChange = (editorState) => {
        
        setDescription(editorState);
        setForm({...form,message:draftToHtml(convertToRaw(description.getCurrentContent()))})
    }
   const handleChange=(e)=>{
    if(e.target.name=='notify'){
        if(e.target.value=='selected')
          setShowUserList(true)
        else{
            setForm({...form,user_ids:""})
        }
    } 
    else 
    setForm({...form,[e.target.name]:e.target.value})
   }
    const handleTitleSelect=(item)=>{
        setSelectedTitle(item.name)
        setForm({...form,"message_category":item.id})
        setShowTitle(false)
      }
    const verify=()=>{
        if(form.notify=='selected' && form.user_ids==="")
          return false
      return form.message_category!=null && form.message!=""
    }
    const handleRemoveFile=(id)=>{
        let f=form.attachment.filter((f,index)=>index!==id)
        setForm({...form,attachment:[...f]})
      }
    const handleInputFiles=(e)=>{
        console.log(e.target.files)
        setForm({...form,attachment:[...form.attachment,...e.target.files]})
    }
    const saveMessage=()=>{
        console.log(form)
        if(verify())
        {
           let newForm=new FormData()
           newForm.append("title",form.title)
           newForm.append("message",form.message)
           newForm.append("project",form.project)
           newForm.append("notify",form.notify)
           newForm.append("user_ids",form.user_ids)
           for(let i=0;i<form.attachment.length;i++)
             newForm.append("attachment",form.attachment[i])
           newForm.append("message_category",form.message_category)
           newForm.append("tagged_users",form.tagged_users.join(","))
           dispatch(sendMessage(form.project,date,category,newForm))
           setWriteShowModal(false)
        }
        
    }
    document.addEventListener("click",(e)=>{
        if(!e.target.closest(".message-add-file"))
          setShowAddFile(false)
    })
    
   useEffect(()=>{
      dispatch(getMessageCategories())
   },[dispatch])
    return (
        <div className='modal-popup'>
          {showUserList && <UserList setShowUserList={setShowUserList} form={form} setForm={setForm}/>}
          {showCategoryList && <CategoryList setShowCategoryList={setShowCategoryList} form={form} setForm={setForm}/>}
        <div className='modal-popup-content'>
           <img src={crossClose} alt="close" onClick={()=>setWriteShowModal(false)} className="modal-close" />
            <div className='modal-popup-content-title'>New message</div>
            
            <div className="message-title-heading">Type a title</div>
            <input className="message-title-input" name="title" value={form.title} autoFocus onChange={handleChange}/>
            <div className="message-category-select">
                <div onClick={()=>setShowTitle(p=>!p)} className={`message-category-selected ${showTitle ? "message-category-active":""}`}>
                    <span>{selectedTitle ? selectedTitle:'Pick a category'}</span>
                    <ArrowDownIcon fill={showTitle?"#4B4B4B":"#D6D6D6"}/>
                </div>
                {showTitle && <div className="message-category-select-dropdown">
                    {message_categories.map((item,index)=><div className="message-category-item" onClick={()=>handleTitleSelect(item)}>{item.name}</div>)}

                    <div className="message-category-item" onClick={()=>setShowCategoryList(true)}>Edit category</div>
                </div>}
            </div>
            <div className="message-modal">
            <div className='modal-description '>
                <Editor
                    editorState={description}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="message-editor"
                    placeholder="type your message here..."
                    toolbar={{options: ['inline', 'blockType', 'colorPicker', 'link','history'],
                    inline: {
                    options: ['bold', 'italic', 'underline','monospace'],
                    },
                    link : {
                        options : ['link']
                    }
                    }}
                    onEditorStateChange={onEditorStateChange}
                    mention={{
                      separator: ' ',
                      trigger: '@',
                      suggestions:all_users.map(item=>{
                        return {
                            text:<div onClick={()=>setForm({...form,tagged_users:form.tagged_users.push(item.id)})} style={{display:'flex',alignItems:'center',gap:'10px'}}>
                            <div className="todo-search-profile search-profile">{item.fullName.split(" ")[0].charAt(0)}{item.fullName.split(" ")[1]?item.fullName.split(" ")[1].charAt(0):item.fullName.split(" ")[0].charAt(1)}</div>
                            <div >{item.fullName}</div>
                          </div>,
                            value:item.fullName,
                            url:item.fullName
                          }
                      })
                     
                    }}
                />
               
               
                <textarea style={{display:'none'}} disabled value={draftToHtml(convertToRaw(description.getCurrentContent())) } />
            </div>
            <div className="message-file-block">
                <div className="message-add-file" onClick={()=>inputFromComputer.current.click()}>
                    <span>Add files</span>
                    <img className='circle-bg' src={plus} alt="upload file" />
                </div>
                {form.attachment.length>0 &&  <div className='files-list'>
                                {
                                    form.attachment.length > 0 && (
                                        form.attachment.map((file, index) => 
                                        // {
                                        //     console.log(files[file].name);
                                        // }
                                        (     <div className='file-box' key={index}>
                                          <div className="file-remove" onMouseEnter={()=>setHoverRemove(index)} onMouseLeave={()=>setHoverRemove('')} onClick={()=>handleRemoveFile(index)} ><RemoveIcon opacity={hoverRemove===index?0.57:0.12}  fill={hoverRemove===index?'#333333':'white'}/></div>
                                                
                                                    <div className='file-item-description'>
                                                       {file.name}
                                                    </div>
                                                
                                                </div>
                                            )
                                        )
                                    )
                                }
                            </div>}
                {/* {showAddFile &&<div className="message-add-file-block">
                <DropboxChooser
                appKey={process.env.REACT_APP_DROP_KEY}
                success={handleSuccess}
                linkType="direct"
                folderselect={true}
                multiselect={true}
              >
                <div className="message-add-file-type"> <img src={dropboxIcon} alt=""/> <span>Dropbox</span></div>
                 
                </DropboxChooser>
                    
                    <input ref={inputFromComputer} className="message-add-computer-file" type="file" hidden />
                    <div className="message-add-file-type" onClick={()=>inputFromComputer.current.click()}>
                       <img src={laptopIcon} alt=""/> <span>Upload from computer</span>
                    </div>
                </div>} */}
                <input ref={inputFromComputer} className="message-add-computer-file" type="file" multiple hidden onChange={handleInputFiles}/>
            </div>
            </div>
           
            <div className="message-notify">
                  <div className="message-notify-heading">When I post this, notify…</div>
                  <div className="form-input-type">
                     <input type="radio" id="message-notify-all" name="notify" value="all" defaultChecked={form.notify==='all'} onChange={handleChange}/>
                     <label htmlFor="message-notify-all" className="form-input-label-type">
                     <div className="form-input-label-type-main">Everyone who can see this project</div>
                     </label>
                  </div>
                  <div className="form-input-type">
                     <input type="radio" id="message-notify-selected" name="notify" value="selected" defaultChecked={form.notify==='selected'} onChange={handleChange}/>
                     <label htmlFor="message-notify-selected" className="form-input-label-type">
                     <div className="form-input-label-type-main">Only notify selected people</div>
                     </label>
                  </div>
                  <div className="form-input-type">
                     <input type="radio" id="message-notify-none" name="notify" value="none" defaultChecked={form.notify==='none'} onChange={handleChange}/>
                     <label htmlFor="message-notify-none" className="form-input-label-type">
                     <div className="form-input-label-type-main">None</div>
                     </label>
                  </div>
            </div>
            <div className='modal-popup-content-actions'>
                <button className={`btn btn-${verify()? 'green-fill':'grey'}`} onClick={saveMessage}>Post message</button>
                {/* <button className='btn btn-green-outline' onClick={saveDraft}>Save as draft</button> */}
            </div>
        </div>
</div>
    )
}
export default WriteMessage