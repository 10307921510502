import React,{useEffect,useState} from 'react'
import notify from '../../../helpers/notify';
import { year } from "../../../helpers/constants";
import * as validate from '../../../helpers/validation';
import warning from "../../../assets/icons/warning.svg";
import { useNavigate } from 'react-router-dom';
import { captureQuickbookPayment } from '../../../actions';
import { useDispatch } from 'react-redux';
import ReCAPTCHA  from "react-google-recaptcha"
import { useRef } from 'react';
const QuickbookPayment=({project})=>{
    const navigate=useNavigate()
    const dispatch=useDispatch()
    const [token,setToken] = useState("")
    const recaptchaRef = useRef();
    let currentYear=new Date().getFullYear()
    let next10years=[]
    const initialForm={
        "project_id":project.id,
        "payment_method":"card",
        "amount":project.amount_to_be_paid,
        "currency":"USD",
        "card_name":"",
        "exp_year":currentYear+"",
        "exp_month": "01",
        "number": "",
        "cvc": "",
        "recaptcha_token" : ""
    }
    const [form,setForm]=useState(initialForm)
    const [cardError,setCardError]=useState(null)
    const [cardNameError,setCardNameError]=useState(null)
    const [cvcError,setCvcError]=useState(null)
    const onlyNumberKey=(evt)=>{
              
        // Only ASCII character in that range allowed
        var ASCIICode = (evt.which) ? evt.which : evt.keyCode
        if (ASCIICode > 31 && (ASCIICode < 48 || ASCIICode > 57))
            return false;
        return true;
    }
    const verify=()=>{
        return (form.amount!==null && form.exp_year!==null && form.exp_month!==null && cardNameError===null && cardError===null && cvcError===null)
    }
    const handleChange=(e)=>{
        const re = /^[0-9\b]+$/;
        const name=e.target.name
        let value=e.target.value
        if(name==='exp_year' || name==='exp_month')
        setForm({...form,[name]:value})
        else if(name==='card_name'){
            setForm({...form,[name]:value})
        if(validate.validateEmpty(value))
             setCardNameError("Cannot be empty");
        else
        {setCardNameError(null);}
        }
        else if(name==='number'){
            if(value.length!=16)
              setCardError('Has to be 16 characters')
            else 
              setCardError(null)
            if(validate.validateOnlyNumbers(value) || value==='')
              {setForm({...form,[name]:value});}
            
        }
        else if(name==='cvc'){
            if(validate.validateEmpty(value))
            setCvcError("Cannot be empty");
            else
            setCvcError(null)
            if(validate.validateOnlyNumbers(value) || value==='')
              setForm({...form,[name]:value})
            
            }
       
             
        }
        
    
    const handleQuickbookPayment=(e)=>{
        e.preventDefault();
        const finalForm = {
            ...form,recaptcha_token : token
        }
        console.log(finalForm)
        if(!verify())
         notify('error','Please fill all details')
         else
         dispatch(captureQuickbookPayment(finalForm,navigate))
    }
    for(let i=0;i<10;i++)
      next10years.push(currentYear++)
    return (
    <div className="quickbook">
        <div className="quickbook-heading">Enter card details</div>
        <div className="payment-form-input">
            <label for="payment-amount">Amount</label>
            <input readOnly id="payment-amount" name="amount" value={form.amount} />
        </div>
        <div className="payment-form-input">
            <label for="payment-currency">Currency</label>
            <input readOnly id="payment-currency" name="currency" value={form.currency} />
        </div>
        <div className="payment-form-input">
            <label for="payment-card-name">Card name</label>
            <input id="payment-card-name" name="card_name" value={form.card_name} onChange={handleChange}/>
            {cardNameError && <div className="input-error">
                    <img src={warning} alt="email warning"/>
                    <span>{cardNameError}</span>
                    </div>}
        </div>
        <div className="payment-form-input">
            <label for="payment-exp-year">Exp year</label>
            <select value={form.exp_year} name="exp_year" onChange={handleChange}>
                 {next10years.map((item,index)=><option value={item}>{item}</option>)}
            </select>
        </div>
        <div className="payment-form-input">
            <label for="payment-exp-month">Exp month</label>
            <select value={form.exp_month} name="exp_month" onChange={handleChange}>
                {year.map((item,index)=><option value={index<9?"0"+(index+1):index+1}>{item}</option>)}
            </select>
        </div>
        <div className="payment-form-input">
            <label for="payment-card-number">Card number</label>
            <input id="payment-card-number" name="number" value={form.number} onChange={handleChange}/>
            {cardError && <div className="input-error">
                    <img src={warning} alt="email warning"/>
                    <span>{cardError}</span>
                    </div>}
        </div>
        <div className="payment-form-input">
            <label for="payment-cvc">CVC</label>
            <input id="payment-cvc" name="cvc" value={form.cvc} onChange={handleChange}/>
            {cvcError && <div className="input-error">
                    <img src={warning} alt="email warning"/>
                    <span>{cvcError}</span>
                    </div>}
        </div>
        <ReCAPTCHA
        ref={recaptchaRef}
        sitekey="6Lc9UigpAAAAAPb2HMC3Za1RPurjC7Y4M_c_udi1"
        onChange={(response) => {
          setToken(response)
        }}
      />
        <button style={{marginTop : "20px"}} className={`btn btn-${verify()?'green-fill':'grey'}`} onClick={handleQuickbookPayment}>Continue</button>
    </div>)
}
export default QuickbookPayment;