import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { FcmToken } from "./actions";

const firebaseConfig = {
    apiKey: "AIzaSyBbLUkFzRCSL26WfYniqJHCHMXbPPE1eIk",
    authDomain: "panoramcgi-members-portal.firebaseapp.com",
    projectId: "panoramcgi-members-portal",
    storageBucket: "panoramcgi-members-portal.appspot.com",
    messagingSenderId: "331123799831",
    appId: "1:331123799831:web:b5c900fe8f9fb08a492952",
    measurementId: "G-EKJZRJHJMG"
  };

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);


export const getTokenFunc = (setTokenFound) => {
    return getToken(messaging, {vapidKey: 'BCWrbKqxNw8jZpusNdv5mtebCDIDebk4M86qiKqx4OhEBGKTWOY9lMgvhoUYtEf9Ofs0Pzv421xIIsiGdyDAbhY'}).then((currentToken) => {
      if (currentToken) {
        FcmToken(currentToken)
        console.log('current token for client: ', currentToken);
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log('No registration token available. Request permission to generate one.');
        setTokenFound(false);
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
}

onMessage(messaging, (payload) => {
  console.log("Message received. ", payload);
  // ...
});