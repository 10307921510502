import React from 'react';
import { useState } from 'react';

const LinkModal = ({showLinkModal, handleCloseLinkModal, handleSubmitLink}) => {
    const [link, setLink] = useState('')
    const handleLinkChange = (e) => {
        console.log(e.target.value)
        setLink(e.target.value)
    }
    const handleInsert = () => {
        handleSubmitLink(link)
        handleCloseLinkModal()
    }
    return (
        <>
            {
                showLinkModal && (
                <div className='modal'>
                    <div className='modal-content'>
                        
                            <div className='modal-link-header'>Insert a link</div>
                        
                        <div className='modal-link-body'>
                            <div className='modal-link'>
                                <label htmlFor="link">Link</label>
                                <input onChange={handleLinkChange} className="link-input form-input" type="text" name="link" id="link" />
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button onClick={handleInsert} className='btn btn-green-fill'>Insert</button>
                            <button onClick={()=>handleCloseLinkModal()} className='btn btn-green-outline'>Cancel</button>
                        </div>
                    </div>
                </div>
                )
            }
        </>
    );
};

export default LinkModal;