import React,{useEffect, useState} from "react";
import { Link,useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as validate from "../../../helpers/validation";
import bg6 from "../../../assets/images/bg-6.png";
import arrowRight from "../../../assets/icons/arrow-right.svg";
import visibilityOff from "../../../assets/icons/visibility_off.svg";
import visibility from "../../../assets/icons/visibility.svg";
import warning from "../../../assets/icons/warning.svg";
import logo from "../../../assets/images/logo.png";
import bgLogo from "../../../assets/icons/bg-logo.svg";
import { register } from "../../../actions";
import { Carousel } from "react-responsive-carousel";
import Notification from "../../notification";
const SignUpEmail=({next,back,handleFormData,form})=>{
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch=useDispatch();
  const navigate=useNavigate();
  const [showPassword,setShowPassword]=useState(false);
  const [emailErr,setEmailErr]=useState(null);
  const images=useSelector(state=>state.auth_images)
  const filteredImageUrls = Object.entries(images)
  .filter(([key, value]) => value !== null)
  .map(([key, value]) => ({ [key]: value }));
  const [passwordErr,setPasswordErr]=useState(null);
  const [emailVerify,setEmailVerify] = useState(false)
  const verify=()=>{
     return !validate.validateEmpty(form.email);
  }
  const handleChange=(e)=>{
    const name=e.target.name;
    const value=e.target.value;
    handleFormData(name,value);
    if(name=='email' && !validate.validateEmail(value))
      setEmailErr("Not a valid email id")
    else
     setEmailErr(null)
    if(name=='password' && validate.validateEmpty(value))
     setPasswordErr("Cannot be empty")
   else
    setPasswordErr(null)
}
  const handleSubmit=(e)=>{
    e.preventDefault();
    if(verify())
    {
      
      const formData= searchParams.get("uid") ? {
        "email": form.email,
        "first_name": form.first_name,
        "last_name": form.last_name,
        "company_name": form.company_name,
        "country_code":form.country_code,
        "mobile_number": form.mobile_number,
        "heard_from": form.heard_from,
        "password": "1gfh%hb&452*&@745jfaj",
        "uid" : searchParams.get("uid")
      } : {
        "email": form.email,
        "first_name": form.first_name,
        "last_name": form.last_name,
        "company_name": form.company_name,
        "country_code":form.country_code,
        "mobile_number": form.mobile_number,
        "heard_from": form.heard_from,
        "password": "1gfh%hb&452*&@745jfaj",
      }
      dispatch(register(formData,navigate))
      setEmailVerify(true)
    }
}

useEffect(()=>{
  if(searchParams.size!==0){
    handleFormData("email",searchParams.get("email"))
  }
},[])

const handleShowPassword=()=>{
  setShowPassword(!showPassword)
}
    return (<div className="auth-page">
    <div className="auth-background">
        <div className="auth-overlay">
        <div className="auth-content">
                    <div>
                        <Link to="/" className="logo-link"><img src={bgLogo} alt="logo"/><p className="logo-cgi">CGI</p></Link>
                    </div>
                    <div className="auth-content-text">MEMBERS PORTAL</div>
                    {/* <div className="overlay-highlight">JOIN MEDIUM</div>
                    <div className="overlay-text">ARCHITECTURE +<br/>INTERIOR DESIGN</div> */}
                </div>
        </div>
        {filteredImageUrls?.length>0 &&
        <Carousel showThumbs={false} infiniteLoop={true} autoPlay={true}  showArrows={false} showStatus={false}>
            {
               filteredImageUrls.map((e,i)=>(    
                <div key={`image-${i}`}>
                    <img src={Object.values(e)} className="bg-img" alt="carousel"/>
                </div>
               ))
            }
            </Carousel>}
    </div>
    <div className="form-bg">
    <div className="default-header">
            <Link to="/" className="logo-link">
                <img className="bgLogo" src={bgLogo} alt="logo"/>
                <p className="logo-cgi">CGI</p>
                </Link>
              <div className="members-portal">MEMBERS PORTAL</div>
            </div>
      <form className="auth-form">
            <div className="form-heading">Welcome To Panoram CGI</div>
            <div className="form-subheading">Create your account</div>
            <div className="auth-form-content">
            <div className="form-input-box">
              <div className="form-label">Email address</div>
              <input className={`form-input ${emailErr && 'input-invalid'}`} type="email" name="email" onChange={handleChange} value={form.email}/>
              {emailErr && <div className="input-error">
                    <img src={warning} alt="email warning"/>
                    <span>{emailErr}</span>
                    </div>}
            </div>
           
            {/* <div className="form-input-box">
              <div className="form-label">Password</div>
              <div className="password-box">
                    <input className={`form-input ${passwordErr && 'input-invalid'}`} type={showPassword?'text':'password'} name="password"  onChange={handleChange}/>
                    <img onClick={handleShowPassword} src={showPassword?visibility:visibilityOff} alt="show password"/>
                  </div>
                  {passwordErr && <div className="input-error">
                    <img src={warning} alt="password warning"/>
                    <span>{passwordErr}</span>
                    </div>}
            </div> */}
            <button className={`btn btn-${verify()?'green-fill':'grey'} login-btn-resp`} onClick={handleSubmit}> <span>Continue</span><img src={arrowRight} alt="next"/></button>
            {
              emailVerify &&
            <Notification type="success" message="Please verify your email address"/>
            }
            </div>
        </form>
    </div>
      
    
</div>)
}
export default SignUpEmail;